import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import { useMutation } from '@vue/apollo-composable';
import { toNumber } from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import UPSERT_GHG_SAVING_POTENTIAL from '~/graphql/mutations/impact-goal/UpsertGhgSavingPotential.gql';
import { useListMetricAssignmentsQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    },
    impactGoal: {
      required: true,
      type: Object
    },
    impactSolution: {
      required: true,
      type: Object
    },
    impact: {
      required: true,
      type: Object
    }
  },
  setup: function setup(props) {
    var _props$impact$impactS4, _props$impact2, _props$impact2$impact, _props$impact2$impact2, _props$impact$impactS5, _props$impact3, _props$impact3$impact, _props$impact3$impact2, _props$impact4, _props$impact4$impact, _props$impact4$impact2, _props$impactSolution, _props$impactSolution2;
    var route = useRoute();
    var _useMutation = useMutation(UPSERT_GHG_SAVING_POTENTIAL),
      upsertGhgSavingPotential = _useMutation.mutate;
    var impactSolutionId = computed(function () {
      return toNumber(route.params.solution);
    });
    var durationOfImpact = computed(function () {
      var _props$impact$impactS, _props$impact, _props$impact$impactS2, _props$impact$impactS3;
      return toNumber((_props$impact$impactS = (_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$impactS2 = _props$impact.impactSolutions[impactSolutionId.value]) === null || _props$impact$impactS2 === void 0 ? void 0 : (_props$impact$impactS3 = _props$impact$impactS2.ghgSavingPotential) === null || _props$impact$impactS3 === void 0 ? void 0 : _props$impact$impactS3.durationOfImpact) !== null && _props$impact$impactS !== void 0 ? _props$impact$impactS : 1.0);
    });
    var isRecurringImpact = ref((_props$impact$impactS4 = (_props$impact2 = props.impact) === null || _props$impact2 === void 0 ? void 0 : (_props$impact2$impact = _props$impact2.impactSolutions[impactSolutionId.value]) === null || _props$impact2$impact === void 0 ? void 0 : (_props$impact2$impact2 = _props$impact2$impact.ghgSavingPotential) === null || _props$impact2$impact2 === void 0 ? void 0 : _props$impact2$impact2.isRecurringImpact) !== null && _props$impact$impactS4 !== void 0 ? _props$impact$impactS4 : false);
    var realisticConfirmation = ref((_props$impact$impactS5 = (_props$impact3 = props.impact) === null || _props$impact3 === void 0 ? void 0 : (_props$impact3$impact = _props$impact3.impactSolutions[impactSolutionId.value]) === null || _props$impact3$impact === void 0 ? void 0 : (_props$impact3$impact2 = _props$impact3$impact.ghgSavingPotential) === null || _props$impact3$impact2 === void 0 ? void 0 : _props$impact3$impact2.realisticConfirmation) !== null && _props$impact$impactS5 !== void 0 ? _props$impact$impactS5 : false);
    var ghgSavingPotentialId = ref((_props$impact4 = props.impact) === null || _props$impact4 === void 0 ? void 0 : (_props$impact4$impact = _props$impact4.impactSolutions[impactSolutionId.value]) === null || _props$impact4$impact === void 0 ? void 0 : (_props$impact4$impact2 = _props$impact4$impact.ghgSavingPotential) === null || _props$impact4$impact2 === void 0 ? void 0 : _props$impact4$impact2.id);
    var comparativeUnit = ref((_props$impactSolution = props.impactSolution) === null || _props$impactSolution === void 0 ? void 0 : (_props$impactSolution2 = _props$impactSolution.ghgSavingPotential) === null || _props$impactSolution2 === void 0 ? void 0 : _props$impactSolution2.comparativeUnit);
    var _useListMetricAssignm = useListMetricAssignmentsQuery({
        limit: 1000,
        assigneeIds: [props.ventureId],
        ghgSavingPotentialId: ghgSavingPotentialId.value,
        metricNotionIds: ['4b017bded5fb4253bd6927c1c662ea12']
      }),
      result = _useListMetricAssignm.result,
      loading = _useListMetricAssignm.loading;
    var metricAssignments = computed(function () {
      var _result$value$listMet, _result$value, _result$value$listMet2;
      return (_result$value$listMet = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$listMet2 = _result$value.listMetricAssignments) === null || _result$value$listMet2 === void 0 ? void 0 : _result$value$listMet2.filter(function (assignment) {
        return assignment.ghgSavingPotentialId === ghgSavingPotentialId.value;
      })) !== null && _result$value$listMet !== void 0 ? _result$value$listMet : [];
    });
    return {
      metricAssignments: metricAssignments,
      loading: loading,
      durationOfImpact: durationOfImpact,
      ghgSavingPotentialId: ghgSavingPotentialId,
      isRecurringImpact: isRecurringImpact,
      realisticConfirmation: realisticConfirmation,
      upsertGhgSavingPotential: upsertGhgSavingPotential,
      comparativeUnit: comparativeUnit
    };
  },
  data: function data() {
    return {
      showExampleSolutionRecurringGhgSavings: false
    };
  },
  methods: {
    updateGhgSavingPotential: function updateGhgSavingPotential(value) {
      this.upsertGhgSavingPotential({
        input: _objectSpread({
          id: this.ghgSavingPotentialId,
          ventureId: this.$props.ventureId
        }, value)
      });
    },
    updateIsRecurringImpact: function updateIsRecurringImpact(isRecurringImpact) {
      var vars = {
        isRecurringImpact: isRecurringImpact
      };
      if (!isRecurringImpact) vars['durationOfImpact'] = 1;
      this.updateGhgSavingPotential(vars);
    },
    renderMarkdown: renderMarkdown
  }
});