var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',{staticStyle:{"border-radius":"12px !important"}},[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t(
      'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.title',
    )))]),_vm._v(" "),_c('ix-section-text',[_c('v-row',[_c('v-col',[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.text',
              ),
            ) + '<strong>' + _vm.comparativeUnit + '</strong>'
          )}})])],1),_vm._v(" "),_c('ix-form-input-question',{attrs:{"title":_vm.$t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.fiveYearQuestion'),"subtitle":_vm.$t('impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.fiveYearQuestionSubtitle')}}),_vm._v(" "),_c('v-row',[_c('v-col',[_c('impact-goal-edit-growth-factor-assignments',{attrs:{"venture-id":_vm.ventureId,"metric-assignments":_vm.metricAssignments,"simple-headers":""}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col')],1),_vm._v(" "),_c('v-row',[_c('v-checkbox',{staticClass:"shrink",staticStyle:{"margin-left":"inherit"},attrs:{"label":_vm.$t(
            'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.durationOfImpactLabel',
          ),"is-mandatory":""},on:{"change":_vm.updateIsRecurringImpact},model:{value:(_vm.isRecurringImpact),callback:function ($$v) {_vm.isRecurringImpact=$$v},expression:"isRecurringImpact"}})],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.durationOfImpactMessages',
              ),
            )
          )}}),_vm._v(" "),_c('a',{on:{"click":function($event){_vm.showExampleSolutionRecurringGhgSavings =
              !_vm.showExampleSolutionRecurringGhgSavings}}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.showExampleSolutionRecurringGhgSavings
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.exampleSolutionRecurringGhgSavingsLabel',
              ))+"\n          ")],1)]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.showExampleSolutionRecurringGhgSavings)?_c('v-alert',{attrs:{"outlined":"","color":"primary"},domProps:{"innerHTML":_vm._s(
            _vm.renderMarkdown(
              _vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.exampleSolutionRecurringGhgSavingsText',
              ),
            )
          )}}):_vm._e(),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.durationOfImpact,"disabled":!_vm.isRecurringImpact,"min":"1","label":_vm.$t('general.duration'),"type":"number"},on:{"input":(val) =>
              _vm.updateGhgSavingPotential({
                durationOfImpact: parseFloat(val),
              })}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col')],1),_vm._v(" "),_c('v-row',[_c('v-checkbox',{staticStyle:{"margin-left":"inherit"},on:{"change":() =>
            _vm.updateGhgSavingPotential({
              realisticConfirmation: _vm.realisticConfirmation,
            })},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("\n            "+_vm._s(`${_vm.$t(
                'impactGoal.impactSolutions.impactSolution.ghgSavingPotentialTool.growthFactor.confirmForecastLabel',
              )}`)+"\n            "),_c('span',[_c('span',{staticClass:"red--text"},[_vm._v(" * ")])])])]},proxy:true}]),model:{value:(_vm.realisticConfirmation),callback:function ($$v) {_vm.realisticConfirmation=$$v},expression:"realisticConfirmation"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }