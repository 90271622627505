export const fundSetupI18nText = {
  sections: {
    introduction: {
      name: {
        en: 'Introduction',
        de: 'Einführung',
      },
      header: {
        en: 'Welcome to the ImpactNexus Fund Setup',
        de: 'Willkommen zum ImpactNexus Fonds Setup',
      },
    },
    basicInformation: {
      name: {
        en: 'Basic information',
        de: 'Grundlegende Informationen',
      },
      fundManagementCompany: {
        name: {
          en: 'Fund management company',
          de: 'Fondsmanagementgesellschaft',
        },
        header: {
          en: 'Fund management company',
          de: 'Fondsmanagementgesellschaft',
        },
        pretext: {
          en: 'In this section, please provide us with general information about the entity responsible for the fund.',
          de: 'Bitte geben Sie uns in diesem Abschnitt allgemeine Informationen über die für den Fonds verantwortliche Stelle.',
        },
      },
      fund: {
        name: {
          en: 'Fund',
          de: 'Fonds',
        },
        header: {
          en: 'Fund',
          de: 'Grundlegende informationen: fonds',
        },
      },
    },
    sustainabilityApproach: {
      name: {
        en: 'Sustainability approach',
        de: 'Nachhaltigkeitsansatz',
      },
      fundManagementCompany: {
        name: {
          en: 'Fund management company',
          de: 'Fondsmanagementgesellschaft',
        },
        header: {
          en: 'Sustainability approach of the fund management company (entity)',
          de: 'Nachhaltigkeitsansatz der Fondsmanagementgesellschaft (Einheit)',
        },
      },
      fund: {
        name: {
          en: 'Fund',
          de: 'Fond',
        },
        header: {
          en: 'Select the strategy',
          de: 'Wählen Sie die Strategie aus',
        },
      },
      sustainabilityRisks: {
        name: {
          en: 'Sustainability risks',
          de: 'Nachhaltigkeitsrisiken',
        },
        header: {
          en: 'Integration of sustainability risks in decision-making',
          de: 'Nachhaltigkeitsrisiken',
        },
      },
      sfdrStrategies: {
        name: {
          en: 'Sustainable investment objective',
          de: 'Nachhaltiges Anlageziel',
        },
        esCharacteristics: {
          name: {
            en: 'E/S characteristics',
            de: 'E/S-Eigenschaften',
          },
          header: {
            en: 'E/S characteristics',
            de: 'E/S-Eigenschaften',
          },
        },
        sustainableInvestmentObjective: {
          name: {
            en: 'Sustainable investment objective',
            de: 'Nachhaltiges Anlageziel',
          },
          select: {
            name: {
              en: 'Select the sustainable investment objective',
              de: 'Wählen Sie das nachhaltige Anlageziel aus',
            },
            header: {
              en: 'Select the sustainable investment objective',
              de: 'Wählen Sie das nachhaltige Anlageziel aus',
            },
          },
          edit: {
            name: {
              en: 'Sustainable objective: ',
              de: 'Nachhaltiges Ziel: ',
            },
            header: {
              en: 'Sustainable investment objective',
              de: 'Nachhaltiges Anlageziel',
            },
          },
          pai: {
            name: {
              en: 'PAI considerations',
              de: 'PAI-Betrachtung',
            },
            header: {
              en: 'PAI considerations',
              de: 'PAI-Betrachtung',
            },
          },
        },
      },
    },
    kfwReporting: {
      name: {
        en: 'KfW Capital Reporting',
        de: 'KfW Kapital Berichterstattung',
      },
      header: {
        en: 'KfW Capital Reporting',
        de: 'KfW Kapital Berichterstattung',
      },
    },
    nextSteps: {
      name: {
        en: 'Next steps',
        de: 'Nächste Schritte',
      },
    },
  },
  fundStrategyOptions: {
    ARTICLE_6: {
      label: {
        en: 'No promotion of sustainability or ESG (**Article 6 fund**)',
        de: 'Artikel-6-Fonds: Keine Förderung von Nachhaltigkeit oder ESG',
      },
      tooltip: {
        en: '**Article 6 funds** do not promote environmental or social characteristics and sustainable investment objectives.',
        de: '**Artikel-6-Fonds ("grau")** verfolgen weder ein nachhaltiges Anlageziel noch fördern sie eine nachhaltige Entwicklung. Den Managern dieser Fonds steht es frei, zu entscheiden, ob sie Nachhaltigkeitsrisiken für ihre Anlageentscheidungen als relevant erachten.',
      },
    },
    ARTICLE_8: {
      label: {
        en: 'Promotion of environmental and/or social characteristics (**Article 8 fund**)',
        de: 'Artikel-8-Fonds: Förderung ökologischer und/oder sozialer Merkmale',
      },
      tooltip: {
        en: "**Article 8 funds ('light green')** promote environmental and/or social characteristics,  provided that the companies in which the investments have been made follow good governance practices.",
        de: '**Artikel-8-Fonds ("hellgrün")** fördern ökologische und/oder soziale Merkmale, vorausgesetzt, dass die Unternehmen, in die investiert wurde, gute Governance-Praktiken befolgen.',
      },
    },
    ARTICLE_9: {
      label: {
        en: 'Sustainable investment objective (**Article 9**)',
        de: 'Artikel-9-Fonds: Nachhaltiges Anlageziel',
      },
      tooltip: {
        en: "**Article 9 ('dark green')** funds have a sustainable objective for all their investments, while not doing significant harm to other sustainable objectives and securing good governance practices of their portfolio companies",
        de: "**Artikel-9-Fonds ('dunkelgrün')** haben ein nachhaltiges Ziel für alle ihre Investitionen, ohne anderen nachhaltigen Zielen erheblichen Schaden zuzufügen und die guten Governance-Praktiken ihrer Portfoliounternehmen zu sichern.",
      },
    },
    ARTICLE_8_PLUS: {
      label: {
        en: 'Promotion of environmental and/or social characteristics and sustainable investment objectives (**Article 8+ fund**)',
        de: 'Artikel-8+-Fonds: Förderung ökologischer und/oder sozialer Merkmale und nachhaltiges Anlageziel',
      },
      tooltip: {
        en: "**Article 8+ funds ('medium green')** promote the same environmental and/or social characteristics as Article 8 funds, but also include a proportion of portfolio companies that count as sustainable investments because they contribute to an environmental or social objective. Sustainable investment means an investment in an economic activity that contributes to an environmental or social objective, provided that the investment is compliant with the 'do no significant harm' principle (for which disclosure of the PAI indicators is required), and that the investee companies follow good governance practices.",
        de: '**Artikel-8+-Fonds ("mittelgrün")** fördern dieselben ökologischen und/oder sozialen Merkmale wie Artikel-8-Fonds, umfassen aber auch einen Anteil von Portfoliounternehmen, die als nachhaltige Investitionen gelten, weil sie zu einem ökologischen oder sozialen Ziel beitragen. Nachhaltige Investitionen sind Investitionen in eine wirtschaftliche Tätigkeit, die zu einem ökologischen oder sozialen Ziel beiträgt, vorausgesetzt, die Investition ist mit dem Grundsatz der "keine erheblichen Schäden" vereinbar (für den die Offenlegung der PAI-Indikatoren erforderlich ist), und die Portfoliounternehmen befolgen gute Governance-Praktiken.',
      },
    },
  },
  questions: {
    isReportingPais: {
      title: {
        en: 'Do you want to enable PAI reporting for this fund?',
        de: 'Möchten Sie für diesen Fonds die Berichterstattung über PAIs aktivieren?',
      },
    },
    paiAggregationGroup: {
      title: 'Please select which companies in the fund should report on PAIs',
    },
    isReportingKfw: {
      title: {
        en: 'Do you want to enable KfW reporting for this fund?',
        de: 'Möchten Sie für diesen Fonds die Berichterstattung über KfW aktivieren?',
      },
    },
    fundName: {
      title: {
        en: 'What is the name of the fund?',
        de: 'Wie lautet der Name des Fonds?',
      },
    },
    nameOfFinancialMarketParticipant: {
      title: {
        en: 'What is the name of the fund management company?',
        de: 'Wie lautet der Name der rechtlichen Einheit?',
      },
    },
    legalEntityIdentifier: {
      title: {
        en: 'What is the legal entity identifier (LEI-Code) of the fund management company?',
        de: 'Was ist die Unternehmenskennung (LEI-Code)?',
      },
    },
    subsidiaries: {
      title: {
        en: 'Are there any subsidiaries? If yes, please mention them.',
        de: 'Gibt es Tochtergesellschaften? Wenn ja, nennen Sie diese bitte.',
      },
      tooltip: {
        en: "IFRS 10  defines a subsidiary as 'An entity that is controlled by another entity.' Subsidiary is an entity which is controlled by another entity. The control means that the parent company can govern the financial and operating policies of its subsidiaries to gain benefits from the operations of subsidiary. Control can be gained if more than 50% of the voting rights are acquired by the parent. Although VC funds can have subsidiaries, this may not be a common choice of structure. VC funds primarily focus on identifying and investing in high-potential startups and early-stage companies. By not having subsidiaries, they can remain dedicated to their core business of finding and supporting promising entrepreneurial ventures. Moreover, by avoiding subsidiaries, funds can operate with greater flexibility concerning regulations and avoid potential complications associated with managing multiple legal entities. It's worth noting that some venture capital firms may establish strategic partnerships or joint ventures with other entities to expand their capabilities or gain access to specific markets. However, these partnerships are typically distinct from having subsidiaries within the fund's organizational structure. In most cases, having subsidiaries is more common for such organizational structures as joint venture structures, holding company structures, and multi-divisional structures.",
        de: "FRS 10 definiert eine Tochtergesellschaft als 'eine Entität, die von einer anderen Entität kontrolliert wird.' Eine Tochtergesellschaft ist eine Entität, die von einer anderen Entität kontrolliert wird. Kontrolle bedeutet, dass das Mutterunternehmen die finanziellen und betrieblichen Richtlinien seiner Tochtergesellschaften steuern kann, um Vorteile aus den Operationen der Tochtergesellschaft zu ziehen. Kontrolle kann erlangt werden, wenn mehr als 50% der Stimmrechte von der Muttergesellschaft erworben werden. Obwohl VC-Fonds Tochtergesellschaften haben können, ist dies möglicherweise keine gängige Strukturwahl. VC-Fonds konzentrieren sich in erster Linie auf die Identifizierung und Investition in Startups mit hohem Potenzial und in Unternehmen in der Frühphase. Indem sie keine Tochtergesellschaften haben, können sie sich ihrem Kerngeschäft widmen, vielversprechende unternehmerische Vorhaben zu finden und zu unterstützen. Darüber hinaus können Fonds durch Vermeidung von Tochtergesellschaften flexibler in Bezug auf Vorschriften agieren und potenzielle Komplikationen vermeiden, die mit der Verwaltung mehrerer rechtlicher Einheiten verbunden sind. Es ist erwähnenswert, dass einige Risikokapitalfirmen strategische Partnerschaften oder Joint Ventures mit anderen Entitäten eingehen können, um ihre Fähigkeiten zu erweitern oder Zugang zu bestimmten Märkten zu erhalten. Diese Partnerschaften sind jedoch typischerweise von Tochtergesellschaften innerhalb der Organisationsstruktur des Fonds zu unterscheiden. In den meisten Fällen ist das Vorhandensein von Tochtergesellschaften häufiger für solche Organisationsstrukturen wie Joint-Venture-Strukturen, Holdinggesellschaftsstrukturen und multidivisionale Strukturen.",
      },
    },
    entitySustainabilityExtraInfo: {
      title: {
        en: 'Where can more information on the fund management company’s sustainability-related information be found online?',
        de: 'Wo können online spezifischere Informationen zur Nachhaltigkeit der Fondsmanagementgesellschaft gefunden werden?',
      },
    },
    fundLegalEntityIdentifier: {
      title: {
        en: 'What is the fund’s legal entity identifier (LEI-Code)?',
        de: 'Was ist die Unternehmenskennung (LEI-Code) dieses Fonds?',
      },
    },
    volume: {
      title: {
        en: 'What is the fund’s volume (committed capital) in EUR?',
        de: 'Wie hoch ist das Fondsvolumen (in EUR)?',
      },
      tooltip: {
        en: 'If your fund has not had its final closing yet, please use the target amount of committed capital.',
        de: 'Wenn Ihr Fonds noch nicht endgültig geschlossen wurde, verwenden Sie bitte den Zielbetrag des zugesagten Kapitals.',
      },
    },
    investmentPeriod: {
      title: {
        en: 'What is the investment period?',
        de: 'Was ist der Anlagezeitraum?',
      },
    },
    fundSpecificInfo: {
      title: {
        en: 'Where can more fund-specific information be found online?',
        de: 'Wo können online spezifischere Informationen zum Fonds gefunden werden?',
      },
      subtitle: {
        en: "Please include the hyperlink to your website section 'Sustainability-related Disclosure'.",
        de: "Bitte fügen Sie den Hyperlink zu Ihrem Website-Bereich 'Nachhaltigkeitsbezogene Offenlegung' hinzu.",
      },
      tooltip: {
        en: "To comply with SFDR Regulation, you must create a dedicated section on your website called 'Sustainability-related Disclosures' for each fund you offer. This section should be in the same part of your website as SFDR information related to the fund management company, which include disclosures on sustainability risks, adverse sustainability impacts, and renumeration policies.",
        de: "Um die SFDR-Verordnung einzuhalten, erstellen Sie einen eigenen Bereich auf Ihrer Website mit dem Namen 'Nachhaltigkeitsbezogene Offenlegungen' für jeden Fonds, den Sie anbieten. Dieser Bereich sollte sich im selben Teil Ihrer Website befinden wie andere Produktinformationen und Marketingmaterialien.",
      },
    },
    ventureIds: {
      title: {
        en: 'Which companies do you want to assign to the fund?',
        de: 'Welche Unternehmen möchten Sie dem Fonds zuordnen?',
      },
    },
    isConsideringSustainabilityRisk: {
      title: {
        en: 'Do you integrate sustainability risks into your investment decisions?',
        de: 'Integrieren Sie Nachhaltigkeitsrisiken in Ihre Anlageentscheidungen?',
      },
      tooltip: {
        en: 'The fund management companies should include the information on integration of sustainability risks into their investment decisions or provide clear reasons for not doing so',
        de: 'Die Fondsverwaltungsgesellschaften sollten die Informationen zur Integration von Nachhaltigkeitsrisiken in ihre Anlageentscheidungen enthalten oder klare Gründe für das Nichttun angeben',
      },
    },
    sustainabilityRiskIntegrationDescription: {
      title: {
        en: 'In what manner do you integrate sustainability risks into your investment decisions?',
        de: 'In welcher Weise integrieren Sie Nachhaltigkeitsrisiken in Ihre Anlageentscheidungen?',
      },
      tooltip: {
        en: `Where a fund deems sustainability risks relevant, it should describe how sustainability risks are integrated into its investment decisions by disclosing how this is achieved and a policy in place that underpins that disclosure. The integration can be done in a variety of ways, such as:
        <br>
        1. Exclusionary screening for controversial sectors;
        <br>
        2. Inclusionary screening for investments with ESG scores higher than the average scores of a chosen ESG rating;
        <br>
        3. Active ownership to enhance the investee companies' sustainability performance and reduce risks.
        <br>
        4. Best-in-class approach to screen in the companies with the top overall or selected ESG performance.',
        <br>`,
        de: `Wenn ein Fonds Nachhaltigkeitsrisiken für relevant hält, sollte er beschreiben, wie Nachhaltigkeitsrisiken in seine Anlageentscheidungen integriert werden, indem er offenlegt, wie dies erreicht wird und eine Richtlinie vorliegt, die diese Offenlegung unterstützt. Die Integration kann auf verschiedene Weise erfolgen, z. B.:
        <br>
        1. Ausschlusskriterien für kontroverse Sektoren;
        <br>
        2. Einbeziehungskriterien für Investitionen mit ESG-Scores, die höher sind als die durchschnittlichen Scores eines ausgewählten ESG-Ratings;
        <br>
        3. Aktives Eigentum, um die Nachhaltigkeitsleistung der Beteiligungsunternehmen zu verbessern und Risiken zu reduzieren.
        <br>
        4. Best-in-Class-Ansatz, um die Unternehmen mit der besten Gesamt- oder ausgewählten ESG-Leistung zu filtern.`,
      },
    },
    likelyImpactsFromSustainabilityRisk: {
      title: {
        en: 'What are the likely impacts of sustainability risks on the returns of the fund(s) you offer?',
        de: 'Was sind die wahrscheinlichen Auswirkungen von Nachhaltigkeitsrisiken auf die Renditen der von Ihnen angebotenen Fonds?',
      },
      tooltip: {
        en: `Please consider that sustainability risks pertaining to certain investee companies in your product can impact investment value and ultimately lead to a loss of financial returns. All else being equal, a lower sustainability assessment can potentially reduce the intrinsic valuation of a security, thereby reducing the total return expectations for the financial product. On the other hand, for instance, active engagement practices with investee companies and exclusion of controversial business activities lead to better long-term investment outcomes.
        <br>
        In order to ensure transparency for your investors, you should assess the influence of the existing sustainability risks on returns of the funds you make available and disclose the results of this assessment.`,
        de: `Bitte bedenken Sie, dass die Nachhaltigkeitsrisiken bestimmter Unternehmen, in die Ihr Produkt investiert, sich auf den Investitionswert auswirken und letztlich zu einem Verlust an finanziellen Erträgen führen können. Unter sonst gleichen Bedingungen kann eine niedrigere Nachhaltigkeitsbewertung die innere Bewertung eines Wertpapiers und damit die Renditeerwartungen für das Finanzprodukt verringern. Auf der anderen Seite führen beispielsweise ein aktiver Umgang mit den Unternehmen, in die investiert wird, und der Ausschluss kontroverser Geschäftsaktivitäten zu besseren langfristigen Anlageergebnissen.
        <br>
        Um Transparenz für Ihre Anleger zu gewährleisten, sollten Sie den Einfluss der bestehenden Nachhaltigkeitsrisiken auf die Rendite der von Ihnen bereitgestellten Fonds bewerten und die Ergebnisse dieser Bewertung offenlegen.`,
      },
    },
    reasonNotConsideringSustainabilityRisk: {
      title: {
        en: 'What are the reasons for not considering the sustainability risks on your investments?',
        de: 'Was sind die Gründe, warum Sie die Nachhaltigkeitsrisiken bei Ihren Anlagen nicht berücksichtigen?',
      },
    },
    sustainabilityRiskIntegration: {
      title: {
        en: 'Which sustainability risk policy do you apply?',
        de: 'Welche Nachhaltigkeitsrisiko-Richtlinien wenden Sie an?',
      },
      tooltip: {
        en: `Often, the disclosure of SFDR 2019/2088 Article 3 Transparency of sustainability risk policies include a summary and the key points of the broader Responsible Investment Policy. Fund managers of the financial products referred to in 345/2013/EU (EuVECA Regulation) and 2011/61/EU (AIFMD) can refer to the corresponding legislative acts to understand the requirements to the disclosure of information about risks and risk management processes.
If a fund manager decides to develop such a policy, it can include the following information:

- Assessment process for identifying and prioritizing relevant sustainability factors.
- Approval date by the fund's board or manager.
- Allocation of responsibility for policy implementation.
- Methodologies for selecting sustainability indicators and assessing their impact, including associated margin of error.
- Data sources, including direct from investee companies or third-party providers.

The policy should be documented, reviewed, and approved by the fund manager on an annual basis.
`,
      },
    },
    remunerationPolicies: {
      title: {
        en: 'Which remuneration policies do you have and how are they consistent with the integration of sustainability risks?',
        de: 'Welche Vergütungspolitiken haben Sie und wie sind sie mit der Integration von Nachhaltigkeitsrisiken vereinbar?',
      },
      subtitle: {
        en: 'Following Article 5 of Regulation (EU) 2019/2088, please indicate if your fund management company has remuneration policies and, if yes, how those policies are consistent with the integration of sustainability risks. Please keep in mind that the information will later be published on your website.',
        de: 'Bitte geben Sie gemäß Artikel 5 der Verordnung (EU) 2019/2088 an, ob Ihre Fondsverwaltungsgesellschaft über eine Vergütungspolitik verfügt und wenn ja, wie diese Politik mit der Einbeziehung von Nachhaltigkeitsrisiken vereinbar ist. Bitte bedenken Sie, dass die Informationen später auf Ihrer Website veröffentlicht werden.',
      },
      tooltip: {
        en: `Since our customers are usually represented by the Private Equity (PE) and Venture Capital (VC) funds, we refer to the applicable legislation: 345/2013/EU (EuVECA Regulation) for VC and 2011/61/EU (AIFMD) for the PE and other Alternative Investment Funds (AIF). Article 13 2011/61/EU (AIFMD) explicitly requires Alternative Investment Fund Managers (AIFMs) to disclose the remuneration policy. Article 12(f) in EuVECA requires a description of the calculation of the fund manager’s remuneration.

Both legislations are only applicable if the funds exceed certain thresholds outlined in the regulation 2011/61/EU, Article 3(1) 3(2). [EuVECA refers to the thresholds outlined in Article 3(2) of the regulation 2011/61/EU](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32013R0345#:~:text=(8)-,Furthermore%2C%20this%20Regulation%20applies%20only%20to%20managers%20of%20those%20collective%20investment,point%20(b)%20of%20Article%203(2)%20of%20Directive%202011/61/EU.,-(9)). Both regulations *are not applicable* to the AIFMs managing portfolios of AIFs, whose assets under management:

- including any assets acquired through use of leverage, in total do not exceed a threshold of EUR 100 million;
- do not exceed a threshold of EUR 500 million when the portfolios of AIFs consist of AIFs that are unleveraged and have no redemption rights exercisable during a period of 5 years following the date of initial investment in each AIF.

More information on the thresholds can be seen [here](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32011L0061#:~:text=1.%C2%A0%C2%A0%C2%A0This%20Directive,in%20each%20AIF.).

If your AuM exceed the outlined thresholds or you nevertheless have the ambition to develop and disclose such a policy and show how the remuneration policy is consistent with the integration of sustainability risks, you can describe how salaries and incentive pays are aligned with performance and achievements with regard to short, medium and long-term sustainability-related priorities and goals, while accounting for risk, controls, and conduct objectives. Check our guidance to see which points you could potentially address in your policy: [link](https://storage.googleapis.com/ix-static/SFDR%20Article%205.pdf).
`,
        de: `Da unsere Kunden in der Regel durch Private-Equity- (PE) und Venture-Capital- (VC) Fonds vertreten sind, verweisen wir auf die geltenden Rechtsvorschriften: 345/2013/EU (EuVECA-Verordnung) für VC und 2011/61/EU (AIFMD) für PE und andere alternative Investmentfonds (AIF). Artikel 13 2011/61/EU (AIFMD) verpflichtet die Verwalter alternativer Investmentfonds (AIFM) ausdrücklich zur Offenlegung der Vergütungspolitik. Artikel 12(f) in EuVECA verlangt eine Beschreibung der Berechnung der Vergütung des Fondsmanagers.

Beide Gesetzgebungen sind nur anwendbar, wenn die Fonds bestimmte Schwellenwerte überschreiten, die in der Verordnung 2011/61/EU, Artikel 3(1) 3(2) beschrieben sind. [EuVECA bezieht sich auf die in Artikel 3(2) der Verordnung 2011/61/EU genannten Schwellenwerte](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32013R0345#:~:text=(8)-,Außerdem%20gilt%20diese%20Verordnung%20nur%20für%20Fondsmanager%20von%20kollektiven%20Anlagen,Punkt%20(b)%20von%20Artikel%203(2)%20der%20Richtlinie%2011/61/EU.,-(9)). Beide Verordnungen *gelten* nicht für AIFM, die Portfolios von AIFs verwalten, deren verwaltete Vermögenswerte:


- einschließlich der durch den Einsatz von Hebelfinanzierungen erworbenen Vermögenswerte, insgesamt einen Schwellenwert von 100 Mio. EUR nicht überschreiten;
- einen Schwellenwert von 500 Mio. EUR nicht überschreiten, wenn die Portfolios der AIF aus AIF bestehen, die nicht hebelfinanziert sind und keine Rücknahmerechte haben, die während eines Zeitraums von 5 Jahren nach dem Datum der Erstinvestition in jeden AIF ausgeübt werden können.

Weitere Informationen zu den Schwellenwerten finden Sie [hier](https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32011L0061#:~:text=1.%C2%A0%C2%A0%C2%A0Diese%20Richtlinie,in%20jedem%20AIF.).

Wenn Ihre AuM die skizzierten Schwellenwerte überschreiten oder Sie dennoch den Ehrgeiz haben, eine solche Politik zu entwickeln und offenzulegen und darzulegen, wie die Vergütungspolitik mit der Integration von Nachhaltigkeitsrisiken in Einklang steht, können Sie beschreiben, wie Gehälter und Leistungsanreize mit der Leistung und den Erfolgen im Hinblick auf kurz-, mittel- und langfristige nachhaltigkeitsbezogene Prioritäten und Ziele in Einklang gebracht werden, während gleichzeitig Risiken, Kontrollen und Verhaltensziele berücksichtigt werden. Schauen Sie in unserem Leitfaden nach, welche Punkte Sie möglicherweise in Ihrer Richtlinie behandeln können: [Link](https://storage.googleapis.com/ix-static/SFDR%20Article%205.pdf).
        `,
      },
      template: {
        en: `**For Alternative Investment and Venture Capital funds.**

Since the assets under management of the fund do not exceed the thresholds set out in in the regulation 2011/61/EU, Article 3(1) and 3(2), our fund does not have a remuneration policy and does not relate it to the integration of sustainability risks, accordingly.`,
        de: 'Da das verwaltete Vermögen des Fonds die in Artikel 3 Absätze 1 und 2 der Verordnung 2011/61/EU festgelegten Schwellenwerte nicht überschreitet, verfügt unser Fonds nicht über eine Vergütungspolitik und bezieht diese dementsprechend nicht auf die Integration von Nachhaltigkeitsrisiken.',
      },
      // template1: {
      //   en: '{entityName} integrates ESG and sustainability goals into the executives’ bonus pay-outs. Starting {startOfEntity}, 10% of the short-term incentive plan of the executives is tied to the achievement of the goals related to the increase of the weighting of portfolio companies with scientific climate change mitigation targets and good governance practice, given that the increase of the weighting of these companies does not deteriorate the risk-return profile of the financial products. The long-term incentive plans include a 15% weighting to strategic goals, including input measures around renewables and the energy transition of the investee companies, which can be achieved by leveraging Active Ownership and active engagement strategies. In addition to that, the variable component of Board members’ remuneration (individual contribution factor) considered customer satisfaction and employee engagement. You can see more information on our remuneration policies here: {link}',
      //   de: 'Ab {startOfEntity} integriert {entityName} ESG- und Nachhaltigkeitsziele in die Bonuszahlungen der Führungskräfte. 10% des kurzfristigen Anreizplans der Führungskräfte sind an die Erreichung der Ziele gebunden, die mit der Erhöhung des Gewichts von Portfoliounternehmen mit wissenschaftlichen Klimaschutzzielen und guter Governance-Praxis verbunden sind, vorausgesetzt, dass die Erhöhung des Gewichts dieser Unternehmen das Risiko-Rendite-Profil der Finanzprodukte nicht verschlechtert. Die langfristigen Anreizpläne beinhalten eine Gewichtung von 15% für strategische Ziele, einschließlich Input-Maßnahmen im Bereich erneuerbare Energien und der Energiewende der Beteiligungsunternehmen, die durch die Nutzung von Active Ownership und aktiven Engagementstrategien erreicht werden können. Darüber hinaus berücksichtigte die variable Komponente der Vergütung der Vorstandsmitglieder (individueller Beitrag) die Kundenzufriedenheit und das Engagement der Mitarbeiter. Weitere Informationen zu unseren Vergütungspolitiken finden Sie hier: {link}',
      // },
      // template2: {
      //   en: 'For funds with €500 million assets under management, provided the AIF are not leveraged and investors have no redemption rights for the first five years: As a manager of a qualifying venture capital fund as defined in article 3 (b) of Regulation (EU) No. 345/2013 (“EuVECA-Regulation”), {entityName} does not have and does not need to have a remuneration guideline or policy in accordance with the requirements of the EuVECA Regulation.',
      //   de: 'Für Fonds mit einem verwalteten Vermögen von 500 Millionen Euro, sofern die AIF nicht gehebelt sind und die Anleger in den ersten fünf Jahren keine Rückgaberechte haben: Als Manager eines qualifizierten Risikokapitalfonds im Sinne von Artikel 3 (b) der Verordnung (EU) Nr. 345/2013 ("EuVECA-Verordnung") hat {entityName} keine Vergütungsrichtlinie oder -politik gemäß den Anforderungen der EuVECA-Verordnung.',
      // },
    },
    fundStrategy: {
      title: {
        en: 'What is your fund’s approach to sustainability?',
        de: 'Wie lautet der Ansatz Ihres Fonds für Nachhaltigkeit?',
      },
    },
    isConsideringPais: {
      title: {
        en: 'Do you want to enable PAI reporting for this fund?',
        de: 'Möchten Sie für diesen Fonds die Berichterstattung über PAIs aktivieren?',
      },
      subtitle: {
        en: 'PAI reporting is mandatory for Article 8+ or Article 9 funds.',
        de: 'Die Berichterstattung über PAIs ist für Artikel-8+- oder Artikel-9-Fonds obligatorisch.',
      },
      tooltip: {
        en: "By disabling this your companies won't be asked about PAI KPIs",
        de: 'Wenn Sie dies deaktivieren, werden Ihre Unternehmen nicht nach PAI-KPIs gefragt',
      },
    },
    entityLevelPaiConsideration: {
      title: {
        en: 'Please explain how principal adverse impacts are considered at the entity level',
        de: 'Bitte erläutern Sie, wie die wichtigsten nachteiligen Auswirkungen auf der Ebene der Entität berücksichtigt werden',
      },
      template: {
        en: '{nameOfFinancialMarketParticipant} does not consider principal adverse impacts (PAI) of its investment decisions on sustainability factors on the entity-level (Article 4 SFDR).\n\nAs opposed to the general requirement to consider adverse impacts of investment decisions on sustainability factors at the fund management company level under Article 4 SFDR, we consider the principal adverse impacts (PAI) on the product level, i.e., per individual fund (Article 7 SFDR).\n\nAccording to [[Consolidated questions and answers (Q&A) on the SFDR (Regulation (EU) 2019/2088, Chapter IV, Question 2]](https://www.esma.europa.eu/sites/default/files/2023-05/JC_2023_18_-_Consolidated_JC_SFDR_QAs.pdf), this approach is compliant with the disclosure requirements, since the fund employs less than 500 employees and clearly states on its website that it does not consider PAIs on the entity-level.” You can find more information in our Statement on principal adverse impacts on sustainability factors.',
        de: '{nameOfFinancialMarketParticipant} berücksichtigt die wichtigsten nachteiligen Auswirkungen (PAI) seiner Anlageentscheidungen auf Nachhaltigkeitsfaktoren auf der Ebene der Entität nicht (Artikel 4 SFDR).\n\nIm Gegensatz zur allgemeinen Anforderung, die nachteiligen Auswirkungen von Anlageentscheidungen auf Nachhaltigkeitsfaktoren auf der Ebene der Fondsverwaltungsgesellschaft gemäß Artikel 4 SFDR zu berücksichtigen, berücksichtigen wir die wichtigsten nachteiligen Auswirkungen (PAI) auf der Produkt-Ebene, d. h. pro einzelnen Fonds (Artikel 7 SFDR).\n\nGemäß [[Konsolidierte Fragen und Antworten (Q&A) zur SFDR (Verordnung (EU) 2019/2088, Kapitel IV, Frage 2]](https://www.esma.europa.eu/sites/default/files/2023-05/JC_2023_18_-_Consolidated_JC_SFDR_QAs.pdf) entspricht dieser Ansatz den Offenlegungsanforderungen, da der Fonds weniger als 500 Mitarbeiter beschäftigt und auf seiner Website klar angibt, dass er PAIs auf der Ebene der Entität nicht berücksichtigt." Weitere Informationen finden Sie in unserer Erklärung zu den wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren.',
      },
    },
    notConsideringPaisReason: {
      title_NO: {
        en: "Please explain why you don't want to consider PAIs",
        de: 'Bitte erläutern Sie, warum Sie PAIs nicht berücksichtigen möchten',
      },
      tooltip_NO: {
        ARTICLE_6: {
          en: 'Under Article 4 and 7 SFDR, fund managers must consider principal adverse impacts (PAI) on sustainability factors on the fund management company (entity) and fund (product) levels. Those fund management companies, which employ less than 500 employees may opt out of PAI disclosure, given that they provide reasons for doing so ("comply or explain mechanism"). If you decide not to consider PAIs, you must publish a clear statement explaining why the fund does not consider PAIs, and if/when the fund intends to consider PAIs in the future. ImpactNexus will provide you with the corresponding template.',
          de: 'Gemäß Artikel 4 und 7 SFDR müssen Fondsmanager die wichtigsten nachteiligen Auswirkungen (PAI) auf Nachhaltigkeitsfaktoren auf der Ebene der Fondsverwaltungsgesellschaft (Entität) und des Fonds (Produkt) berücksichtigen. Fondsverwaltungsgesellschaften, die weniger als 500 Mitarbeiter beschäftigen, können sich von der Offenlegung von PAIs abmelden, sofern sie Gründe dafür angeben ("comply or explain-Mechanismus"). Wenn Sie sich entscheiden, PAIs nicht zu berücksichtigen, müssen Sie eine klare Erklärung veröffentlichen, warum der Fonds PAIs nicht berücksichtigt, und ob/wann der Fonds beabsichtigt, PAIs in Zukunft zu berücksichtigen. ImpactNexus wird Ihnen die entsprechende Vorlage zur Verfügung stellen.',
        },
        ARTICLE_8: {
          en: 'Under Article 4 and 7 SFDR, fund managers must consider principal adverse impacts (PAI) on sustainability factors on the fund management company (entity) and fund (product) levels. Those fund management companies, which employ less than 500 employees may opt out of PAI disclosure, given that they provide reasons for doing so ("comply or explain mechanism"). If you decide not to consider PAIs, you must publish a clear statement explaining why the fund does not consider PAIs, and if/when the fund intends to consider PAIs in the future. ImpactNexus will provide you with the corresponding template.',
          de: 'Gemäß Artikel 4 und 7 SFDR müssen Fondsmanager die wichtigsten nachteiligen Auswirkungen (PAI) auf Nachhaltigkeitsfaktoren auf der Ebene der Fondsverwaltungsgesellschaft (Entität) und des Fonds (Produkt) berücksichtigen. Fondsverwaltungsgesellschaften, die weniger als 500 Mitarbeiter beschäftigen, können sich von der Offenlegung von PAIs abmelden, sofern sie Gründe dafür angeben ("comply or explain-Mechanismus"). Wenn Sie sich entscheiden, PAIs nicht zu berücksichtigen, müssen Sie eine klare Erklärung veröffentlichen, warum der Fonds PAIs nicht berücksichtigt, und ob/wann der Fonds beabsichtigt, PAIs in Zukunft zu berücksichtigen. ImpactNexus wird Ihnen die entsprechende Vorlage zur Verfügung stellen.',
        },
        ARTICLE_8_PLUS: {
          en: 'Under Article 4 and 7 SFDR, fund managers must consider principal adverse impacts (PAI) on sustainability factors on the fund management company (entity) and fund (product) levels. Those fund management companies, which employ less than 500 employees may opt out of the PAI disclosure, given that they provide reasons for doing so ("comply or explain mechanism"). Article 8+ and Article 9 funds claim to make sustainable investments and it is therefore highly recommended for them to consider PAIs to show that their investments do not significantly harm sustainability factors. If you decide not to consider PAIs, you must publish a clear statement explaining why the fund does not consider PAIs, and if/when it intends to consider PAIs in the future.  ImpactNexus will provide you with the corresponding template.',
          de: 'Gemäß Artikel 4 und 7 SFDR müssen Fondsmanager die wichtigsten nachteiligen Auswirkungen (PAI) auf Nachhaltigkeitsfaktoren auf der Ebene der Fondsverwaltungsgesellschaft (Entität) und des Fonds (Produkt) berücksichtigen. Fondsverwaltungsgesellschaften, die weniger als 500 Mitarbeiter beschäftigen, können sich von der Offenlegung von PAIs abmelden, sofern sie Gründe dafür angeben ("comply or explain-Mechanismus"). Artikel 8+ und Artikel 9-Fonds geben an, nachhaltige Investitionen zu tätigen, und es wird daher dringend empfohlen, dass sie PAIs berücksichtigen, um zu zeigen, dass ihre Investitionen die Nachhaltigkeitsfaktoren nicht erheblich beeinträchtigen. Wenn Sie sich entscheiden, PAIs nicht zu berücksichtigen, müssen Sie eine klare Erklärung veröffentlichen, warum der Fonds PAIs nicht berücksichtigt, und ob/wann er beabsichtigt, PAIs in Zukunft zu berücksichtigen. ImpactNexus wird Ihnen die entsprechende Vorlage zur Verfügung stellen.',
        },
        ARTICLE_9: {
          en: 'Under Article 4 and 7 SFDR, fund managers must consider principal adverse impacts (PAI) on sustainability factors on the fund management company (entity) and fund (product) levels. Those fund management companies, which employ less than 500 employees may opt out of the PAI disclosure, given that they provide reasons for doing so ("comply or explain mechanism"). Article 8+ and Article 9 funds claim to make sustainable investments and it is therefore highly recommended for them to consider PAIs to show that their investments do not significantly harm sustainability factors. If you decide not to consider PAIs, you must publish a clear statement explaining why the fund does not consider PAIs, and if/when it intends to consider PAIs in the future.  ImpactNexus will provide you with the corresponding template.',
          de: 'Gemäß Artikel 4 und 7 SFDR müssen Fondsmanager die wichtigsten nachteiligen Auswirkungen (PAI) auf Nachhaltigkeitsfaktoren auf der Ebene der Fondsverwaltungsgesellschaft (Entität) und des Fonds (Produkt) berücksichtigen. Fondsverwaltungsgesellschaften, die weniger als 500 Mitarbeiter beschäftigen, können sich von der Offenlegung von PAIs abmelden, sofern sie Gründe dafür angeben ("comply or explain-Mechanismus"). Artikel 8+ und Artikel 9-Fonds geben an, nachhaltige Investitionen zu tätigen, und es wird daher dringend empfohlen, dass sie PAIs berücksichtigen, um zu zeigen, dass ihre Investitionen die Nachhaltigkeitsfaktoren nicht erheblich beeinträchtigen. Wenn Sie sich entscheiden, PAIs nicht zu berücksichtigen, müssen Sie eine klare Erklärung veröffentlichen, warum der Fonds PAIs nicht berücksichtigt, und ob/wann er beabsichtigt, PAIs in Zukunft zu berücksichtigen. ImpactNexus wird Ihnen die entsprechende Vorlage zur Verfügung stellen.',
        },
      },
      title_PARTIALLY: {
        en: 'Please explain why you only partially consider PAIs',
        de: 'Bitte erläutern Sie, warum Sie PAIs nur teilweise berücksichtigen',
      },
      template_NO: {
        en: `The PAI reporting requests comprehensive sustainability data, but our fund is composed of investments in (early-stage) ventures. They are not obliged to disclose non-financial information. Hence, processes to assess and report non-financial data have not yet been implemented and still need to be set up. We have partnered with ImpactNexus GmbH and engage the investee companies to encourage them to collect and provide the required information. In addition, we closely monitor the SFDR developments and plan to share PAIs in the future once the companies have available data that can be used to report on the PAIs.`,
        de: `Die Berichterstattung über PAIs fordert umfassende Nachhaltigkeitsdaten, aber unser Fonds besteht aus Investitionen in (frühphasige) Unternehmen. Sie sind nicht verpflichtet, nichtfinanzielle Informationen offenzulegen. Daher wurden Prozesse zur Bewertung und Berichterstattung nichtfinanzieller Daten noch nicht implementiert und müssen noch eingerichtet werden. Wir haben uns mit ImpactNexus GmbH zusammengetan und ermutigen die Beteiligungsunternehmen, die erforderlichen Informationen zu sammeln und bereitzustellen. Darüber hinaus überwachen wir die Entwicklungen im Rahmen der SFDR und planen, PAIs in Zukunft zu teilen, sobald die Unternehmen über verfügbare Daten verfügen, die zur Berichterstattung über die PAIs verwendet werden können.`,
      },
      template_PARTIALLY: {
        en: '{name} does partially consider principal adverse impacts on sustainability factors (PAIs). The PAI regime requests comprehensive sustainability data but some PAIs are not suitable for the investee companies of the fund as they don’t address material aspects of the business model and available data is missing. Thus, we have identified which indicators are considered relevant to assess significant harm, and for which sufficient robust data or proxies are available. Our investee companies are not obliged to disclose non-financial information under the NFRD. Hence, processes to assess and report non-financial data have not yet been implemented and still need to be set up. We will support our investee companies to improve on this.',
        de: '{name} berücksichtigt teilweise die wichtigsten nachteiligen Auswirkungen auf Nachhaltigke itsfaktoren (PAIs). Das PAI-Regime fordert umfassende Nachhaltigkeitsdaten, aber einige PAIs sind für die Beteiligungsunternehmen des Fonds nicht geeignet, da sie keine wesentlichen Aspekte des Geschäftsmodells ansprechen und Daten fehlen. Daher haben wir ermittelt, welche Indikatoren als relevant für die Bewertung erheblicher Schäden angesehen werden und für die ausreichend robuste Daten oder Proxies zur Verfügung stehen. Unsere Beteiligungsunternehmen sind nicht verpflichtet, nichtfinanzielle Informationen gemäß der NFRD offenzulegen. Daher wurden Prozesse zur Bewertung und Berichterstattung nichtfinanzieller Daten noch nicht implementiert und müssen noch eingerichtet werden. Wir werden unsere Beteiligungsunternehmen bei der Verbesserung unterstützen.',
      },
    },
    socialMetricIds: {
      title: {
        en: 'Select other adverse social impacts used to identify and assess additional principal adverse impacts of your investments.',
        de: 'Wählen Sie andere nachteilige soziale Auswirkungen aus, die zur Identifizierung und Bewertung zusätzlicher wesentlicher nachteiliger Auswirkungen Ihrer Investitionen verwendet werden.',
      },
      subtitle: {
        en: `In this step you can select other principle adverse mpacts outside of the official SFDR PAI indicators. Search for predefined indicators in the ImpactNexus KPI database or define custom indicators.`,
        de: `In diesem Schritt können Sie andere wesentliche nachteilige Auswirkungen außerhalb der offiziellen SFDR-PAI-Indikatoren auswählen. Suchen Sie nach vordefinierten Indikatoren in der ImpactNexus-KPI-Datenbank oder definieren Sie benutzerdefinierte Indikatoren.`,
      },
      tooltip: {
        en: 'If your fund addresses adverse social impacts other than the official PAI indicators, please define those indicators here.',
        de: 'Wenn Ihr Fonds nachteilige soziale Auswirkungen anspricht, die nicht in den offiziellen PAI-Indikatoren enthalten sind, definieren Sie diese Indikatoren hier.',
      },
    },
    environmentalMetricIds: {
      title: {
        en: 'Select other adverse environmental impacts used to identify and assess additional principal adverse impacts of your investments.',
        de: 'Wählen Sie andere nachteilige Umweltauswirkungen aus, die zur Identifizierung und Bewertung zusätzlicher wesentlicher nachteiliger Auswirkungen Ihrer Investitionen verwendet werden.',
      },
      subtitle: {
        en: `In this step you can select other principle adverse mpacts outside of the official SFDR PAI indicators. Search for predefined indicators in the ImpactNexus KPI database or define custom indicators.`,
        de: `In diesem Schritt können Sie andere wesentliche nachteilige Auswirkungen außerhalb der offiziellen SFDR-PAI-Indikatoren auswählen. Suchen Sie nach vordefinierten Indikatoren in der ImpactNexus-KPI-Datenbank oder definieren Sie benutzerdefinierte Indikatoren.`,
      },
      tooltip: {
        en: 'If your fund addresses adverse environmental impacts other than the official PAI indicators, please define those indicators here.',
        de: 'Wenn Ihr Fonds nachteilige Umweltauswirkungen anspricht, die nicht in den offiziellen PAI-Indikatoren enthalten sind, definieren Sie diese Indikatoren hier.',
      },
    },
    paiSelectionMethodologies: {
      title: {
        en: 'Methodologies to select and prioritize principal adverse impacts on sustainability factors.',
        de: 'Methoden zur Auswahl und Priorisierung wesentlicher nachteiliger Auswirkungen auf Nachhaltigkeitsfaktoren.',
      },
      tooltip: {
        en: `The description must contain information about:
        <br>
        a. Methodologies for selecting indicators, and identifying and assessing principal adverse impacts, taking into account their probability, severity, and potentially irremediable nature.
        <br>
        b. Any associated margin of error within the methodologies and an explanation for it.
        <br>
        c. Data sources used.
        <br>`,
        de: `Genehmigungsdatum der Richtlinie durch das Leitungsgremium.
        <br>
        a. Methoden zur Auswahl von Indikatoren und zur Identifizierung und Bewertung wesentlicher nachteiliger Auswirkungen unter Berücksichtigung ihrer Wahrscheinlichkeit, Schwere und potenziell nicht behebbaren Natur.
        <br>
        b. Jeder mit den Methoden verbundene Fehlerbereich und eine Erklärung dafür.
        <br>
        c. Verwendete Datenquellen.`,
      },
    },
    paiPolicyAdministration: {
      title: {
        en: 'Administration of policy to identify and prioritize principal adverse impacts.',
        de: 'Verwaltung der Richtlinie zur Identifizierung und Priorisierung wesentlicher nachteiliger Auswirkungen.',
      },
      tooltip: {
        en: `The description must contain information about:
        <br>
        a. Policy approval date by the governing body.
        <br>
        b. Allocation of responsibility for policy implementation within organizational strategies and procedures.`,
        de: `Die Beschreibung muss Informationen zu folgenden Punkten enthalten:
        <br>
        a. Genehmigungsdatum der Richtlinie durch das Leitungsgremium.
        <br>
        b. Zuweisung der Verantwortung für die Umsetzung der Richtlinie innerhalb der organisatorischen Strategien und Verfahren.`,
      },
    },
    considerationOfPaisOnSustainabilityEngagementPolicies: {
      title: {
        en: 'Describe the engagement policies relating to reducing principal adverse impacts following the bullet points under the info button',
        de: 'Beschreiben Sie die Engagementpolitik zur Reduzierung wesentlicher nachteiliger Auswirkungen gemäß der Beschreibung unter dem Informationsbutton',
      },
      tooltip: {
        en: `Brief summary must include:
        <br>
        a. Indicators for adverse impacts considered in the engagement policies.
        <br>
        b. How these engagement policies will be adapted if there is no reduction in the principal adverse impacts over more than one reported period.`,
        de: `Eine kurze Zusammenfassung muss Folgendes enthalten:
        <br>
        a. Indikatoren für nachteilige Auswirkungen, die in den Engagementpolitiken berücksichtigt werden.
        <br>
        b. Wie diese Engagementpolitiken angepasst werden, wenn es über mehr als einen Berichtszeitraum keine Reduzierung der wesentlichen nachteiligen Auswirkungen gibt.`,
      },
    },
    considerationOfPaisOnSustainabilityInternationalStandards: {
      title: {
        en: 'Please specify whether the fund adheres to any responsible business conduct codes and internationally recognised standards for due diligence and reporting. If yes, disclose the information provided under the info-button.',
        de: 'Bitte geben Sie an, ob der Fonds sich an Verhaltenskodizes für verantwortungsbewusstes Geschäftsverhalten und international anerkannte Standards für die Sorgfaltspflicht und Berichterstattung hält. Wenn ja, geben Sie die Informationen unter dem Info-Button bekannt.',
      },
      tooltip: {
        en: `The description must contain information about:
        <br>
        a. Indicators used to consider principal adverse impacts on sustainability factors, measuring adherence or alignment with responsible business conduct codes, internationally recognized standards, and the Paris Agreement objectives.
        <br>
        b. Methodology and data used to measure adherence or alignment, including scope of coverage, data sources, and how the methodology forecasts the principal adverse impacts of investee companies.
        <br>
        c. If a forward-looking climate scenario is used, the name and provider of the scenario and its design date must be provided.
        <br>
        d. If no forward-looking climate scenario is used, an explanation of why the financial market participant considers such scenarios to be irrelevant must be given.`,
        de: `Die Beschreibung muss Informationen zu folgenden Punkten enthalten:
        <br>
        a. Indikatoren, die zur Berücksichtigung wesentlicher nachteiliger Auswirkungen auf Nachhaltigkeitsfaktoren verwendet werden, um die Einhaltung oder Ausrichtung an Verhaltenskodizes für verantwortungsbewusstes Geschäftsverhalten, international anerkannte Standards und die Ziele des Pariser Abkommens zu messen.
        <br>
        b. Methodik und Daten, die zur Messung der Einhaltung oder Ausrichtung verwendet werden, einschließlich des Abdeckungsumfangs, der Datenquellen und wie die Methodik die wesentlichen nachteiligen Auswirkungen der Beteiligungsunternehmen prognostiziert.
        <br>
        c. Wenn ein zukunftsorientiertes Klimaszenario verwendet wird, müssen der Name und der Anbieter des Szenarios sowie sein Entwurfsdatum angegeben werden.
        <br>
        d. Wenn kein zukunftsorientiertes Klimaszenario verwendet wird, muss eine Erklärung dafür gegeben werden, warum der Finanzmarktteilnehmer solche Szenarien für irrelevant hält.`,
      },
    },
    concept: {
      title: {
        en: 'Select or create a sustainable objective',
        de: 'Wählen oder erstellen Sie ein nachhaltiges Ziel',
      },
      subtitle: {
        en: 'Please indicate, which sustainable investment objective your fund pursues. According to the SFDR, your fund can have:\n\n1) sustainable investments with the EU Taxonomy-aligned environmental objective;\n2) sustainable investments with other environmental objectives;\n 3) sustainable investments with social objectives.',
        de: 'Bitte geben Sie an, welches nachhaltige Anlageziel Ihr Fonds verfolgt. Gemäß der SFDR kann Ihr Fonds:\n\n1) nachhaltige Investitionen mit dem umweltbezogenen Ziel der EU-Taxonomie;\n2) nachhaltige Investitionen mit anderen umweltbezogenen Zielen;\n3) nachhaltige Investitionen mit sozialen Zielen haben.',
      },
    },
    sustainableObjectiveVentureIds: {
      title: {
        en: 'Which companies contribute to this sustainable objective?',
        de: 'Welche Unternehmen tragen zu diesem nachhaltigen Ziel bei?',
      },
      subbtitle: {
        en: 'If a company contributes to multiple sustainable objectives, the SFDR regulation requests to choose the one objective where the contribution is most material. However, it is technically possible to assign a company to more than one objective if preferred.',
        de: 'Wenn ein Unternehmen zu mehreren nachhaltigen Zielen beiträgt, fordert die SFDR-Verordnung, dasjenige Ziel auszuwählen, zu dem der Beitrag am bedeutendsten ist. Es ist jedoch technisch möglich, ein Unternehmen bei Bedarf mehreren Zielen zuzuordnen.',
      },
      tooltip: {
        en: 'To be in line with the principle of materiality, an investee company should only be assigned to one sustainable investment objective. This has been highlighted in the clarifications of ESMA from November 2022 according to which the main activity of an investee company should only be considered for one sustainable objective. This sometimes contradicts existing investment strategies. Therefore, it is technically possible to assign a company to more than one objective. However, to comply with the existing SFDR regulation, it is recommended to avoid multiple assignments.',
        de: 'Um im Einklang mit dem Grundsatz der Wesentlichkeit zu sein, sollte einem Beteiligungsunternehmen nur ein nachhaltiges Anlageziel zugeordnet werden. Dies wurde in den Klarstellungen der ESMA vom November 2022 hervorgehoben, wonach die Haupttätigkeit eines Beteiligungsunternehmens nur für ein nachhaltiges Ziel berücksichtigt werden sollte',
      },
    },
    metricIds: {
      title: {
        en: 'Select sustainability indicators for your sustainable investment objective',
        de: 'Wählen Sie Nachhaltigkeitsindikatoren für Ihr nachhaltiges Anlageziel',
      },
      subtitle: {
        en: '- In this step, you can select **sustainability indicators** for the sustainable investment objectives of your fund. \n- Sustainability indicators serve to measure the sustainability performance of your fund (mandatory for article 9, 8, 8+ funds) \n- You can search for predefined indicators in the ImpactNexus metrics database or define custom indicators.',
        de: '- In diesem Schritt können Sie **Nachhaltigkeitsindikatoren** für die nachhaltigen Anlageziele Ihres Fonds auswählen. \n- Nachhaltigkeitsindikatoren dienen zur Messung der Nachhaltigkeitsleistung Ihres Fonds (obligatorisch für Artikel-9-, 8-, 8+-Fonds) \n- Sie können nach vordefinierten Indikatoren in der ImpactNexus-Metriken-Datenbank suchen oder benutzerdefinierte Indikatoren definieren.',
      },
    },
    strategyDescription: {
      title: {
        en: 'Please describe the investment approach towards the sustainable objective in more detail.',
        de: 'Bitte beschreiben Sie den Anlageansatz für das nachhaltige Ziel genauer.',
      },
    },
    actionsTaken: {
      title: {
        en: 'What actions are you going to take towards the sustainable objective?',
        de: 'Welche Maßnahmen werden Sie für das nachhaltige Ziel ergreifen?',
      },
      subtitle: {
        en: 'Please list the actions that are taken within the SFDR reference period to attain the sustainable investment objective.',
        de: 'Bitte listen Sie die Maßnahmen auf, die innerhalb des SFDR-Referenzzeitraums ergriffen werden, um das nachhaltige Anlageziel zu erreichen.',
      },
    },
    isEnvironmentalGoal: {
      title: {
        en: 'Is the sustainable investment objective a social or an environmental objective?',
        de: 'Ist das nachhaltige Anlageziel ein soziales oder ein umweltbezogenes Ziel?',
      },
    },
    mandatoryBenchmark: {
      title: {
        en: 'Please explain how the sustainable investment objective of reducing carbon emissions complies with the methodological requirements of the Delegated Regulation (EU) 2020/1818.',
        de: 'Bitte erläutern Sie, wie das nachhaltige Anlageziel der Reduzierung von Treibhausgasemissionen den methodischen Anforderungen der delegierten Verordnung (EU) 2020/1818 entspricht.',
      },
      subtitle: {
        en: "The regulation disclosures require any Article 8+ and Article 9 Funds with a carbon reduction objective to track an EU Climate Transition Benchmark or an EU Paris-aligned Benchmark. For VC and PE funds, there are currently no market indexes that could be used for this. Currently, VC and PE funds have to demonstrate how the fund's effort of attaining the objective of reducing carbon emissions complies with the methodological requirements set out in Delegated Regulation (EU) 2020/1818. Click the info icon for an example statement and additional information",
        de: 'Die Offenlegungsvorschriften verlangen von allen Artikel-8+- und Artikel-9-Fonds mit einem Ziel der Reduzierung von Treibhausgasemissionen, einen EU-Klimaübergangsbenchmark oder einen EU-Paris-übergangsbenchmark zu verfolgen. Für VC- und PE-Fonds gibt es derzeit keine Marktindizes, die dafür verwendet werden könnten. Derzeit müssen VC- und PE-Fonds nachweisen, wie die Bemühungen des Fonds, das Ziel der Reduzierung von Treibhausgasemissionen zu erreichen, den methodischen Anforderungen der delegierten Verordnung (EU) 2020/1818 entsprechen. Klicken Sie auf das Info-Symbol für ein Beispielstatement und weitere Informationen',
      },
    },
    optionalBenchmark: {
      title: {
        en: 'Please explain how the sustainable investment objective complies with the methodological requirements of the Delegated Regulation (EU) 2020/1818.',
        de: 'Bitte erläutern Sie, wie das nachhaltige Anlageziel den methodischen Anforderungen der delegierten Verordnung (EU) 2020/1818 entspricht.',
      },
      subtitle: {
        en: 'As long as your fund does not have carbon emissions reduction as its sustainable investment objective, it is a voluntary decision whether to designate a reference benchmark to meet the sustainable investment objective. Click on the info icon for further explanation.',
        de: 'Solange Ihr Fonds nicht die Reduzierung von Treibhausgasemissionen als nachhaltiges Anlageziel hat, ist es eine freiwillige Entscheidung, ob ein Referenzbenchmark festgelegt wird, um das nachhaltige Anlageziel zu erreichen. Klicken Sie auf das Info-Symbol für weitere Erklärungen.',
      },
      tooltip: {
        en: 'As long as your fund does not have carbon emissions reduction as its sustainable investment objective, it is a voluntary decision whether to designate a reference benchmark to meet the sustainable investment objective. If you decide to designate such a benchmark, you will have to provide additional information on: 1. How that benchmark (e.g., a market index) is aligned with the sustainable investment objective of the financial product.2. The input data, the methodologies used to select those data, the rebalancing methodologies, and how the index is calculated (a hyperlink should be provided for this information). Please note that there is currently no trackable market indexes (e.g., developed by MSCI or S&P Global) that can be used by VC and PE investors as benchmarks for the sustainable investment objective. Alternatively, you can use the newly developed IRIS+ impact performance benchmarks, which draw from various investments to aggregate impact performance across sector-specific key performance indicators, thus allowing investors to compare their results to peers and the SDGs. The Financial Inclusion impact performance benchmark is now available in IRIS+. Building on the GIIN’s COMPASS Methodology, the IRIS+ financial inclusion impact performance benchmark assesses investors’ data across key performance indicators focused on financial inclusion. The GIIN will continue to build impact performance benchmarks, expanding to other sectors on a rolling basis, including Agriculture, Energy, Health, Land, and Real Estate. To use the benchmark, you need an IRIS+ profile (which might entail additional costs).',
        de: 'Solange Ihr Fonds nicht die Reduzierung von Treibhausgasemissionen als nachhaltiges Anlageziel hat, ist es eine freiwillige Entscheidung, ob ein Referenzbenchmark festgelegt wird, um das nachhaltige Anlageziel zu erreichen. Wenn Sie sich dafür entscheiden, einen solchen Benchmark zu benennen, müssen Sie zusätzliche Informationen bereitstellen: 1. Wie dieser Benchmark (z. B. ein Marktindex) mit dem nachhaltigen Anlageziel des Finanzprodukts in Einklang steht. 2. Die Eingabedaten, die Methoden zur Auswahl dieser Daten, die Rebalancing-Methoden und wie der Index berechnet wird (für diese Informationen sollte ein Hyperlink bereitgestellt werden). Bitte beachten Sie, dass es derzeit keine verfolgbaren Marktindizes (z. B. entwickelt von MSCI oder S&P Global) gibt, die von VC- und PE-Investoren als Benchmarks für das nachhaltige Anlageziel verwendet werden können. Alternativ können Sie die neu entwickelten IRIS+ Impact Performance Benchmarks verwenden, die aus verschiedenen Investitionen stammen, um die Auswirkungsleistung über sektorspezifische Leistungskennzahlen zu aggregieren und es den Investoren so zu ermöglichen, ihre Ergebnisse mit denen ihrer Kollegen und den SDGs zu vergleichen. Der Impact Performance Benchmark für finanzielle Inklusion ist jetzt in IRIS+ verfügbar. Aufbauend auf der COMPASS-Methodik des GIIN bewertet der Impact Performance Benchmark für finanzielle Inklusion in IRIS+ die Daten der Investoren über Schlüsselleistungskennzahlen, die sich auf finanzielle Inklusion konzentrieren. Der GIIN wird weiterhin Impact Performance Benchmarks erstellen und die Erweiterung auf andere Sektoren auf rollender Basis vorantreiben, einschließlich Landwirtschaft, Energie, Gesundheit, Land und Immobilien. Um den Benchmark zu verwenden, benötigen Sie ein IRIS+ Profil (was zusätzliche Kosten verursachen kann).',
      },
    },
    isCo2EmissionGoal: {
      title: {
        en: 'Is the sustainable investment objective to contribute to carbon emission reduction?',
        de: 'Ist das nachhaltige Anlageziel, zur Reduzierung von Treibhausgasemissionen beizutragen?',
      },
    },
    pleaseExplain: {
      en: 'Please explain how the sustainable investment objective of reducing carbon emissions complies with the methodological requirements of the Delegated Regulation (EU) 2020/1818.',
      de: 'Bitte erläutern Sie, wie das nachhaltige Anlageziel der Reduzierung von Treibhausgasemissionen den methodischen Anforderungen der delegierten Verordnung (EU) 2020/1818 entspricht.',
    },
    doCheckEuTaxonomyAlignment: {
      title: {
        en: 'Do you want to check if your companies are aligned with the EU taxonomy?',
        de: 'Möchten Sie überprüfen, ob Ihre Unternehmen mit der EU-Taxonomie übereinstimmen?',
      },
      subtitle: {
        en: `If you select "Yes" we will check if your companies' business activities significantly contribute to the EU taxonomy objective with additional questionnaires and KPIs. For this check, we will release a solution soon.`,
        de: 'Wenn Sie "Ja" auswählen, überprüfen wir, ob die Geschäftstätigkeiten Ihrer Unternehmen wesentlich zum Ziel der EU-Taxonomie beitragen, mit zusätzlichen Fragebögen und KPIs. Für diese Überprüfung werden wir bald eine Lösung veröffentlichen.',
      },
    },
    specificIndex: {
      en: 'Is a specific index designated as a reference benchmark to meet the sustainable investment objective?',
      de: 'Ist ein spezifischer Index als Referenzbenchmark zur Erfüllung des nachhaltigen Anlageziels festgelegt?',
    },
    sustainablyAlignedIndexExplanation: {
      title: {
        en: 'How is the reference benchmark continuously aligned with each of the environmental or social characteristics promoted by the financial product?',
        de: 'Wie wird der Referenzindex kontinuierlich an die von dem Finanzprodukt geförderten Umwelt- oder Sozialmerkmale angepasst?',
      },
    },
    investmentAlignedIndexExplanation: {
      title: {
        en: 'How is the alignment of the investment strategy with the methodology of the index ensured on a continuous basis?',
        de: 'Wie wird die kontinuierliche Ausrichtung der Anlagestrategie an die Methodik des Index sichergestellt?',
      },
    },
    broadMarketIndexComparison: {
      title: {
        en: 'How does the designated index differ from a relevant broad market index?',
        de: 'Wie unterscheidet sich der festgelegte Index von einem relevanten breiten Marktindex?',
      },
    },
    indexCalculationMethodology: {
      title: {
        en: 'Where can the methodology used for the calculation of the designated index be found?',
        de: 'Wo kann die für die Berechnung des festgelegten Index verwendete Methodik gefunden werden?',
      },
    },
    ventureList: {
      title: {
        en: 'The following ventures are assigned to other sustainable objectives in the fund: {ventureNames}',
        de: 'Die folgenden Unternehmen sind anderen nachhaltigen Zielen im Fonds zugeordnet: {ventureNames}',
      },
    },
  },
  btnCreateNewObjective: {
    en: 'Create new sustainable objective',
    de: 'Neues nachhaltiges Ziel erstellen',
  },
  btnBack: {
    en: 'Back',
    de: 'Zurück',
  },
  btnNext: {
    en: 'Next',
    de: 'Weiter',
  },
  btnBackOverview: {
    en: 'Back to fund overview',
    de: 'Zurück zur Fondsübersicht',
  },
  optionYes: {
    en: 'Yes',
    de: 'Ja',
  },
  optionPartially: {
    en: 'Partially',
    de: 'Teilweise',
  },
  optionNo: {
    en: 'No',
    de: 'Nein',
  },
  optionFull: {
    en: 'Full',
    de: 'Vollständig',
  },
  optionA: {
    en: 'Option A',
    de: 'Option A',
  },
  optionB: {
    en: 'Option B',
    de: 'Option B',
  },
  paiAggregationGroup: {
    fund: {
      en: 'All companies in the Fund',
      de: 'Alle Unternehmen im Fonds',
    },
    allSustainable: {
      en: 'Only companies with a sustainable investment objective',
      de: 'Nur Unternehmen mit einem nachhaltigen Anlageziel',
    },
  },
  article6Alert: {
    en: 'SFDR regulation does not require additional information for article 6 reporting.',
    de: 'Die SFDR-Verordnung erfordert keine zusätzlichen Informationen für die Berichterstattung nach Artikel 6. Fühlen Sie sich frei, zurückzukehren, wenn Sie sich entscheiden, die Strategie dieses Fonds zu aktualisieren.',
  },
  warningSelectVenture: {
    en: 'Caution: Each investee company should be assigned to only one sustainable investment objective, even though it may contradict existing investment strategies. Multiple assignments should be avoided to ensure SFDR regulation compliance.',
    de: 'Achtung: Jedes Beteiligungsunternehmen sollte nur einem nachhaltigen Anlageziel zugeordnet werden, auch wenn dies im Widerspruch zu bestehenden Anlagestrategien stehen kann. Mehrfachzuweisungen sollten vermieden werden, um die Einhaltung der SFDR-Verordnung sicherzustellen.',
  },
}

export default {}
