import { render, staticRenderFns } from "./MetricDetails.vue?vue&type=template&id=4541e99a&scoped=true"
import script from "./MetricDetails.vue?vue&type=script&setup=true&lang=ts"
export * from "./MetricDetails.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4541e99a",
  null
  
)

/* custom blocks */
import block0 from "./MetricDetails.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MetricsCellsMetricSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/cells/MetricSection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
