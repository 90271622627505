import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  setup: function setup() {
    var netTurnoverNotionId = '3903ed0623e744c6bc3cc05c472500e6';
    var activityTurnoverNotionId = 'a3f63e567c814f199da6da9076188214';
    var capexNotionId = 'f50ea4e49d8c4a71a181e694824f13d1';
    var activityCapexNotionId = '65107f294fa24c34b089de9fad875a5e';
    var opexNotionId = '840c1a7bd7ed4ad38a31c6c07b83191a';
    var activityOpexNotionId = 'fab2f694a4c141bc81a2d7168417908d';
    var reportingYear = 2024;
    // const dialog = ref(false)

    var route = useRoute();
    var variables = computed(function () {
      return {
        assigneeIds: [route.params.venture],
        metricNotionIds: [netTurnoverNotionId, activityTurnoverNotionId, capexNotionId, activityCapexNotionId, opexNotionId, activityOpexNotionId]
      };
    });
    var data = ref([]);
    var dataLoaded = function dataLoaded(assignments) {
      data.value = assignments;
    };

    // const { result } = useListMetricAssignmentsQuery()
    var euTaxonomyMetricAssignments = computed(function () {
      var _data$value$map, _data$value;
      return (_data$value$map = (_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value.map(function (e) {
        return _objectSpread(_objectSpread({}, e), {}, {
          metricValueForReportingYear: e.metricValues.find(function (value) {
            return value.period === reportingYear.toString();
          })
        });
      })) !== null && _data$value$map !== void 0 ? _data$value$map : [];
    });
    // const openedItem = ref({
    //   reportingPeriodType: MetricReportingPeriodType.Annual,
    //   metric: {},
    //   metricValueForReportingYear: {},
    // })
    return {
      dataLoaded: dataLoaded,
      variables: variables,
      // openedItem,
      // dialog,
      euTaxonomyMetricAssignments: euTaxonomyMetricAssignments,
      netTurnoverNotionId: netTurnoverNotionId,
      activityTurnoverNotionId: activityTurnoverNotionId,
      capexNotionId: capexNotionId,
      activityCapexNotionId: activityCapexNotionId,
      opexNotionId: opexNotionId,
      activityOpexNotionId: activityOpexNotionId,
      reportingYear: reportingYear
    };
  },
  data: function data() {
    return {
      // headers: [
      //   {
      //     text: 'KPI',
      //     value: 'metric.name',
      //     translatedText: 'companyPerspective.kpis.headers.kpi',
      //     cellClass: 'align-top font-weight-bold subtitle-2',
      //     sortable: false,
      //   },
      //   {
      //     text: '2022',
      //     value: 'metricValueForReportingYear',
      //     cellClass: 'align-top primary--text text-center',
      //     align: 'center',
      //     sortable: false,
      //     width: '300px',
      //   },
      // ],
    };
  },
  computed: {
    isNetTurnoverSmallerThanSum: function isNetTurnoverSmallerThanSum() {
      var _this$euTaxonomyMetri,
        _this$euTaxonomyMetri2,
        _this$euTaxonomyMetri3,
        _this = this;
      var netTurnover = (_this$euTaxonomyMetri = (_this$euTaxonomyMetri2 = this.euTaxonomyMetricAssignments.find(function (e) {
        var _e$metric;
        return ((_e$metric = e.metric) === null || _e$metric === void 0 ? void 0 : _e$metric.notionId) === _this.netTurnoverNotionId;
      })) === null || _this$euTaxonomyMetri2 === void 0 ? void 0 : (_this$euTaxonomyMetri3 = _this$euTaxonomyMetri2.metricValueForReportingYear) === null || _this$euTaxonomyMetri3 === void 0 ? void 0 : _this$euTaxonomyMetri3.actual) !== null && _this$euTaxonomyMetri !== void 0 ? _this$euTaxonomyMetri : 0;
      var activityTurnovers = this.euTaxonomyMetricAssignments.filter(function (e) {
        var _e$metric2;
        return ((_e$metric2 = e.metric) === null || _e$metric2 === void 0 ? void 0 : _e$metric2.notionId) === _this.activityTurnoverNotionId;
      }).map(function (e) {
        var _e$metricValueForRepo, _e$metricValueForRepo2;
        return (_e$metricValueForRepo = (_e$metricValueForRepo2 = e.metricValueForReportingYear) === null || _e$metricValueForRepo2 === void 0 ? void 0 : _e$metricValueForRepo2.actual) !== null && _e$metricValueForRepo !== void 0 ? _e$metricValueForRepo : 0;
      });
      var difference = netTurnover;
      activityTurnovers.forEach(function (value) {
        return difference = difference - value;
      });
      return difference < 0;
    },
    isCapexSmallerThanSum: function isCapexSmallerThanSum() {
      var _this$euTaxonomyMetri4,
        _this$euTaxonomyMetri5,
        _this$euTaxonomyMetri6,
        _this2 = this;
      var netTurnover = (_this$euTaxonomyMetri4 = (_this$euTaxonomyMetri5 = this.euTaxonomyMetricAssignments.find(function (e) {
        var _e$metric3;
        return ((_e$metric3 = e.metric) === null || _e$metric3 === void 0 ? void 0 : _e$metric3.notionId) === _this2.capexNotionId;
      })) === null || _this$euTaxonomyMetri5 === void 0 ? void 0 : (_this$euTaxonomyMetri6 = _this$euTaxonomyMetri5.metricValueForReportingYear) === null || _this$euTaxonomyMetri6 === void 0 ? void 0 : _this$euTaxonomyMetri6.actual) !== null && _this$euTaxonomyMetri4 !== void 0 ? _this$euTaxonomyMetri4 : 0;
      var activityTurnovers = this.euTaxonomyMetricAssignments.filter(function (e) {
        var _e$metric4;
        return ((_e$metric4 = e.metric) === null || _e$metric4 === void 0 ? void 0 : _e$metric4.notionId) === _this2.activityCapexNotionId;
      }).map(function (e) {
        var _e$metricValueForRepo3, _e$metricValueForRepo4;
        return (_e$metricValueForRepo3 = (_e$metricValueForRepo4 = e.metricValueForReportingYear) === null || _e$metricValueForRepo4 === void 0 ? void 0 : _e$metricValueForRepo4.actual) !== null && _e$metricValueForRepo3 !== void 0 ? _e$metricValueForRepo3 : 0;
      });
      var difference = netTurnover;
      activityTurnovers.forEach(function (value) {
        return difference = difference - value;
      });
      return difference < 0;
    },
    isOpexSmallerThanSum: function isOpexSmallerThanSum() {
      var _this$euTaxonomyMetri7,
        _this$euTaxonomyMetri8,
        _this$euTaxonomyMetri9,
        _this3 = this;
      var netTurnover = (_this$euTaxonomyMetri7 = (_this$euTaxonomyMetri8 = this.euTaxonomyMetricAssignments.find(function (e) {
        var _e$metric5;
        return ((_e$metric5 = e.metric) === null || _e$metric5 === void 0 ? void 0 : _e$metric5.notionId) === _this3.opexNotionId;
      })) === null || _this$euTaxonomyMetri8 === void 0 ? void 0 : (_this$euTaxonomyMetri9 = _this$euTaxonomyMetri8.metricValueForReportingYear) === null || _this$euTaxonomyMetri9 === void 0 ? void 0 : _this$euTaxonomyMetri9.actual) !== null && _this$euTaxonomyMetri7 !== void 0 ? _this$euTaxonomyMetri7 : 0;
      var activityTurnovers = this.euTaxonomyMetricAssignments.filter(function (e) {
        var _e$metric6;
        return ((_e$metric6 = e.metric) === null || _e$metric6 === void 0 ? void 0 : _e$metric6.notionId) === _this3.activityOpexNotionId;
      }).map(function (e) {
        var _e$metricValueForRepo5, _e$metricValueForRepo6;
        return (_e$metricValueForRepo5 = (_e$metricValueForRepo6 = e.metricValueForReportingYear) === null || _e$metricValueForRepo6 === void 0 ? void 0 : _e$metricValueForRepo6.actual) !== null && _e$metricValueForRepo5 !== void 0 ? _e$metricValueForRepo5 : 0;
      });
      var difference = netTurnover;
      activityTurnovers.forEach(function (value) {
        return difference = difference - value;
      });
      return difference < 0;
    }
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});