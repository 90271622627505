var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-data-table',{attrs:{"locale":_vm.$i18n.locale,"value":_vm.selected,"loading":_vm.loading,"dense":"","fixed-header":"","height":'calc(100vh - 200px)',"item-key":"id","headers":_vm.headers.map((header) => ({
      ...header,
      text: _vm.$t(header.value),
    })),"search":_vm.search,"items":_vm.items,"sort-by":"createdAt","server-items-length":_vm.itemCount,"footer-props":{
    'items-per-page-options': [10, 20, 30, -1],
  },"items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(false)?_c('v-text-field',{staticClass:"mx-4",attrs:{"value":_vm.search,"disabled":"","append-icon":"mdi-magnify","label":_vm.$t('labelSearch'),"single-line":"","hide-details":""},on:{"input":(v) => {
            _vm.search = v
          }}}):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":() => _vm.refetch()}},[_vm._v("\n        "+_vm._s(_vm.$t('refresh'))+"\n      ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }