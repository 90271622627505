import { render, staticRenderFns } from "./_fund.vue?vue&type=template&id=1c6cc041"
import script from "./_fund.vue?vue&type=script&lang=ts"
export * from "./_fund.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxButtonSecondary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Secondary.vue').default,IxButtonPrimary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Primary.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default,IxPageLoading: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/PageLoading.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBadge,VCol,VIcon,VProgressLinear,VRow,VTreeview})
