var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-secondary',_vm._g(_vm._b({},'ix-button-secondary',attrs,false),on),[_c('img',{staticStyle:{"display":"inline"},attrs:{"width":"18","src":require('~/assets/icons/settings.svg'),"alt":"Settings icon"}}),_vm._v(" "),_c('span',{staticClass:"ml-2 body-1"},[_vm._v(_vm._s(_vm.$t('Actions')))])])]}}])},[_vm._v(" "),_c('v-list',[(_vm.isConsideringSfdr)?_c('v-list-item',[_c('v-list-item-title',[_c('sfdr-export-dialog',{attrs:{"fund-id":_vm.fund.id,"fund-name":_vm.fund.name,"report-type":"SFDR_PERIODIC","reference-period":_vm.selectedReferencePeriod},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-secondary',_vm._g(_vm._b({staticClass:"w-full"},'ix-button-secondary',attrs,false),on),[_vm._v("\n              "+_vm._s(_vm.$t('exportLabel', {
                  reportType: _vm.reportTypeString('SFDR_PERIODIC'),
                }))+"\n            ")])]}}],null,false,394328287)})],1)],1):_vm._e(),_vm._v(" "),(_vm.isConsideringPais)?_c('v-list-item',[_c('v-list-item-title',[_c('sfdr-export-dialog',{attrs:{"fund-id":_vm.fund.id,"fund-name":_vm.fund.name,"report-type":"SFDR_PAI","reference-period":_vm.selectedReferencePeriod},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-secondary',_vm._g(_vm._b({staticClass:"w-full"},'ix-button-secondary',attrs,false),on),[_vm._v("\n              "+_vm._s(_vm.$t('exportLabel', {
                  reportType: _vm.reportTypeString('SFDR_PAI'),
                }))+"\n            ")])]}}],null,false,2109075880)})],1)],1):_vm._e(),_vm._v(" "),(_vm.isConsideringSfdr)?_c('v-list-item',[_c('v-list-item-title',[_c('sfdr-export-dialog',{attrs:{"fund-id":_vm.fund.id,"fund-name":_vm.fund.name,"report-type":"SFDR_PRECONTRACTUAL","reference-period":_vm.selectedReferencePeriod},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-secondary',_vm._g(_vm._b({staticClass:"w-full"},'ix-button-secondary',attrs,false),on),[_vm._v("\n              "+_vm._s(_vm.$t('exportLabel', {
                  reportType: _vm.reportTypeString('SFDR_PRECONTRACTUAL'),
                }))+"\n            ")])]}}],null,false,4098146781)})],1)],1):_vm._e(),_vm._v(" "),(_vm.useReferencePeriod)?_c('v-list-item',[_c('v-list-item-title',[_c('ix-dialog',{attrs:{"max-width":500},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-action',_vm._g(_vm._b({staticClass:"w-full"},'ix-button-action',attrs,false),on),[_vm._v("\n              "+_vm._s(_vm.$t('addReferencePeriod'))+"\n            ")])]}},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('addReferencePeriod'))+" ")]},proxy:true},(_vm.unconsideredReferencePeriods.length > 0)?{key:"text",fn:function(){return [_vm._v("\n            Select a new reference period to consider. We currently support\n            the following reference periods: ("+_vm._s(_vm.possibleReferencePeriods.join(', '))+").\n            "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-select',{staticClass:"w-400px",attrs:{"value":_vm.newReferencePeriod,"outlined":"","items":_vm.formattedUnconsideredReferencePeriods,"item-text":"text","item-value":"value","hide-details":"","dense":"","menu-props":{ bottom: true, offsetY: true }},on:{"change":(val) => (_vm.newReferencePeriod = val)}})]},proxy:true}:{key:"text",fn:function(){return [_vm._v("\n            This Fund is already considering all supported reference periods\n            ("+_vm._s(_vm.possibleReferencePeriods.join(', '))+")\n          ")]},proxy:true},(_vm.unconsideredReferencePeriods.length > 0)?{key:"actions",fn:function({ toggleDialog }){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":() => {
                  _vm.triggerAddReportAnswer(_vm.newReferencePeriod)
                  toggleDialog()
                }}},[_vm._v("Add")])]}}:null],null,true)})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }