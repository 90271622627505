// migrate all the logic from the script tag above to composition-api here
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    },
    linksToImpact: {
      type: Boolean,
      required: false,
      default: null
    },
    topIcon: {
      type: String,
      required: false,
      default: null
    },
    bottomIcon: {
      type: String,
      required: false,
      default: null
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var getLinksToImpact = computed(function () {
      if (props.linksToImpact === null) {
        return true;
      }
      return props.linksToImpact;
    });
    var outcome = computed(function () {
      var _props$impact, _props$impact$concept;
      return ((_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$concept = _props$impact.concept) === null || _props$impact$concept === void 0 ? void 0 : _props$impact$concept.label) || 'N/A';
    });
    var stakeholder = computed(function () {
      if (props.impact.stakeholderCategory) return $i18n.t('impactGoal.impacts.defineImpacts.defineImpact.outcomeStakeholderDialog.stakeholder.' + props.impact.stakeholderCategory);
      if (props.impact.stakeholder) return props.impact.stakeholder;
      return 'N/A';
    });
    return {
      getLinksToImpact: getLinksToImpact,
      outcome: outcome,
      stakeholder: stakeholder
    };
  }
});