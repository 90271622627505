import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import { ReportType } from '~/types/types';
export default defineComponent({
  props: {
    fund: {
      type: Object,
      required: true
    },
    isConsideringPais: {
      type: Boolean,
      required: true
    },
    isConsideringSfdr: {
      type: Boolean,
      required: true
    },
    referencePeriods: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedReferencePeriod: {
      type: Number,
      default: 2022
    },
    useReferencePeriod: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var possibleReferencePeriods = [2022, 2023];
    var newReferencePeriod = ref(2023);
    var currentReferencePeriods = computed(function () {
      return props.referencePeriods;
    });
    var unconsideredReferencePeriods = computed(function () {
      return possibleReferencePeriods.filter(function (period) {
        return !currentReferencePeriods.value.includes(period);
      });
    });
    nextTick(function () {
      if (unconsideredReferencePeriods.value.length > 0) {
        newReferencePeriod.value = unconsideredReferencePeriods.value[0];
      }
    });
    var formattedUnconsideredReferencePeriods = unconsideredReferencePeriods.value.map(function (period) {
      return {
        text: "Reference period ".concat(period),
        value: period
      };
    });
    var reportTypeString = function reportTypeString(reportType) {
      if (reportType === ReportType.SfdrPeriodic) {
        return 'Periodic';
      } else if (reportType === ReportType.SfdrPai) {
        return 'PAI';
      } else if (reportType === ReportType.SfdrPrecontractual) {
        return 'Precontractual';
      }
    };
    return {
      reportTypeString: reportTypeString,
      possibleReferencePeriods: possibleReferencePeriods,
      newReferencePeriod: newReferencePeriod,
      unconsideredReferencePeriods: unconsideredReferencePeriods,
      formattedUnconsideredReferencePeriods: formattedUnconsideredReferencePeriods,
      triggerAddReportAnswer: function triggerAddReportAnswer(referencePeriod) {
        emit('submit', referencePeriod);
      }
    };
  }
});