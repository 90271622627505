import "core-js/modules/es6.number.constructor.js";
import { renderMarkdown } from '~/functions/markdown';
import { useShowDebugging } from '~/functions/accounts';
import { useGetMetricLazyQuery } from '~/types/types';
export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true
    },
    isComputed: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    metricAssignment: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    calculationType: {
      // ONLY used for metricAggregation
      type: String,
      required: false,
      default: ''
    },
    isPortfolio: {
      type: Boolean,
      required: false
    },
    search: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    },
    badge: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    }
  },
  setup: function setup(props) {
    // FIXME this component is really bad Q

    var _useGetMetricLazyQuer = useGetMetricLazyQuery(computed(function () {
        return {
          id: props.id
        };
      })),
      result = _useGetMetricLazyQuer.result,
      load = _useGetMetricLazyQuer.load,
      refetch = _useGetMetricLazyQuer.refetch;
    var onInput = function onInput(v) {
      if (v) {
        load() || refetch();
      }
    };
    var metric = computed(function () {
      var _result$value;
      return ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getMetric) || {};
    });
    var showDebugging = useShowDebugging();
    return {
      showDebugging: showDebugging,
      metric: metric,
      onInput: onInput
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});