import { renderMarkdown } from '~/functions/markdown';
import { MetricReportingPeriodType, TypeMetricAssignedTo } from '~/types/types';
export default defineComponent({
  components: {},
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $nuxt = _useNuxtApp.$nuxt;
    var openedItem = ref(null);
    var openedPeriod = ref('');
    var openedAggregationId = ref(0);
    var dialog = ref(false);
    var groupBy = ref(null);
    var refetchList = function refetchList() {
      $nuxt.$emit('refetch-metric-aggregations');
    };
    var customKpiId = ref(null);
    var isAggregationDialogOpen = ref(false);
    return {
      // loading,
      refetchList: refetchList,
      dialog: dialog,
      openedPeriod: openedPeriod,
      openedItem: openedItem,
      openedAggregationId: openedAggregationId,
      MetricReportingPeriodType: MetricReportingPeriodType,
      groupBy: groupBy,
      TypeMetricAssignedTo: TypeMetricAssignedTo,
      customKpiId: customKpiId,
      isAggregationDialogOpen: isAggregationDialogOpen
    };
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});