var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"container",attrs:{"fluid":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-secondary',_vm._g(_vm._b({},'ix-button-secondary',attrs,false),on),[_c('img',{staticStyle:{"display":"inline"},attrs:{"width":"18","src":require('~/assets/icons/settings.svg'),"alt":"Settings icon"}}),_vm._v(" "),_c('span',{staticClass:"ml-2 body-1"},[_vm._v(_vm._s(_vm.$t('actions')))])])]}},{key:"default",fn:function(menuDialog){return [_c('v-list',[(_vm.selected.length === 1)?_c('v-list-item',[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t('exportEsgReport')))])]}},{key:"default",fn:function(dialog){return [_c('exports-esg-export',{attrs:{"venture-id":_vm.selected[0].ventureId,"venture-name":_vm.selected[0].venture.name,"website":_vm.selected[0].venture.website},on:{"close":function($event){dialog.value = false
                  menuDialog.value = false}}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('v-list-item',[_c('v-dialog',{staticStyle:{"margin-top":"500px"},attrs:{"max-width":"450","content-class":"overflow-hidden"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t('assignGroup')))])]}},{key:"default",fn:function(dialog){return [_c('esg-group-view',{attrs:{"selected":_vm.selected},on:{"close":() => {
                    menuDialog.value = false
                    dialog.value = false
                    _vm.$emit('close')
                  }}})]}}],null,true)})],1),_vm._v(" "),(_vm.selected.length === 1)?_c('v-list-item',[_c('ix-dialog',{attrs:{"max-width":1300},on:{"input":(val) => (menuDialog.value = val)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t('compareEsgPerformanceWithPortfolioAndIXAverageListItem')))])]}},{key:"title",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('compareEsgPerformanceWithPortfolioAverageDialogTitle', {
                  ventureName: _vm.selected[0].venture.name,
                }))+"\n            ")]},proxy:true},{key:"text",fn:function(){return [_c('esg-performance-comparison-to-portfolio',{attrs:{"venture-id":_vm.selected[0].ventureId,"venture-name":_vm.selected[0].venture.name}})]},proxy:true}],null,true)})],1):_vm._e(),_vm._v(" "),(_vm.selected.length === 1)?_c('v-list-item',[_c('ix-dialog',{attrs:{"max-width":1300},on:{"input":(val) => (menuDialog.value = val)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v("\n                "+_vm._s(_vm.$t('compareEsgPerformanceWithPreviousReportListItem'))+"\n              ")])]}},{key:"title",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('compareEsgPerformanceWithPreviousReportDialogTitle', {
                  ventureName: _vm.selected[0].venture.name,
                }))+"\n            ")]},proxy:true},{key:"text",fn:function({ toggleDialog }){return [_c('esg-performance-comparison-most-recent-to-previous',{attrs:{"venture-id":_vm.selected[0].ventureId,"venture-name":_vm.selected[0].venture.name},on:{"close":toggleDialog}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('v-list-item',[_c('ix-dialog-request-new-esg-questionnaire',{attrs:{"selected":_vm.selected},on:{"input":(val) => (menuDialog.value = val)}})],1),_vm._v(" "),_c('v-list-item',[_c('ix-dialog-make-esg-reports-editable',{attrs:{"selected":_vm.selected},on:{"input":(val) => (menuDialog.value = val)}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }