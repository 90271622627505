import { render, staticRenderFns } from "./TableForPortfolio.vue?vue&type=template&id=65f5fadc"
import script from "./TableForPortfolio.vue?vue&type=script&lang=ts"
export * from "./TableForPortfolio.vue?vue&type=script&lang=ts"
import style0 from "./TableForPortfolio.vue?vue&type=style&index=0&id=65f5fadc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TableForPortfolio.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MetricsActionMenu: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/ActionMenu.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxDialogMetric: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/Metric.vue').default,IxDialogMetricAggregationContent: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/MetricAggregationContent.vue').default,IxDialogMetricAssignment: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/MetricAssignment.vue').default,IxDataTableMetricAggregations: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/MetricAggregations.vue').default,IxDataTableMetricAssignments: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/MetricAssignments.vue').default,MetricsMetricValuesDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/MetricValuesDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCol,VDialog,VDivider,VRow,VSelect,VSlideXTransition,VTextField})
