var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.calculatedSfdrReport && _setup.fund)?_c('div',{staticClass:"m-8"},[_c('v-row',{staticClass:"mb-4"},[_c('span',{domProps:{"innerHTML":_vm._s(
        _setup.renderMarkdown(
          _setup.calculatedSfdrReport.isArticle9
            ? _setup.form.pretext.article9
            : _setup.form.pretext.article8,
        )
      )}})]),_vm._v(" "),_c('v-row',{staticClass:"mb-8",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_setup.form.fundDetails.fundName,"tooltip":_setup.form.fundDetails.fundNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.fund.name},on:{"input":(val) => {
              _setup.triggerUpdateFund('name', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_setup.form.fundDetails.legalName,"tooltip":_setup.form.fundDetails.legalNameTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.fund.portfolio.nameOfFinancialMarketParticipant},on:{"input":(val) => {
              _setup.triggerUpdateAccount('nameOfFinancialMarketParticipant', val)
            }}})],1),_vm._v(" "),_c('ix-survey-question-group',[_c('ix-survey-question',{attrs:{"title":_setup.form.fundDetails.legalEntityIdentifier,"tooltip":_setup.form.fundDetails.legalEntityIdentifierTooltip}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.fund?.legalEntityIdentifier ?? ''},on:{"input":(val) => {
              _setup.triggerUpdateFund('legalEntityIdentifier', val)
            }}})],1)],1)],1),_vm._v(" "),_c('ix-dialog',{attrs:{"max-width":700,"readonly":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('h1',[_vm._v("\n        "+_vm._s(_setup.calculatedSfdrReport.isArticle9
            ? _setup.form.title.article9
            : _setup.form.title.article8)+"\n        "),_c('v-icon',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("\n          mdi-information-outline\n        ")])],1)]}},{key:"title",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t('esg.surveyPages.questionAdditionalInformation'))+"\n    ")]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"markdown-field survey-question-tooltip",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_setup.form.title.article8Tooltip))}})]},proxy:true}],null,false,3182281196)}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[(_setup.loading)?_c('v-progress-linear',{staticClass:"pb-4",attrs:{"active":true,"indeterminate":true,"query":true}}):_vm._l((_setup.section.children),function(child){return _c('ix-section-or-question',_vm._b({key:child.id || child.ixId},'ix-section-or-question',child,false))})],2)],1):_c('v-container',{staticStyle:{"height":"75%"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Loading... ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"20"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }