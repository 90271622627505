var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"email-icon"},[(_vm.$auth.loggedIn)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({staticClass:"align-center",staticStyle:{"z-index":"100","background":"white"},attrs:{"fab":"","outlined":"","color":"primary","ripple":false}},on),[_c('v-icon',[_vm._v("mdi-email-outline")])],1)]}},{key:"default",fn:function(dialog){return [_c('ix-section',[_c('ix-section-title',[_vm._v("\n          "+_vm._s(_vm.$t('feedback.title'))+"\n          "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"black","depressed":"","icon":"","ripple":false},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-subtitle',[_vm._v("\n          "+_vm._s(_vm.$t('feedback.helpUsImprove'))+"\n        ")]),_vm._v(" "),_c('ix-section-text',[_c('div',{staticClass:"markdown-field",domProps:{"innerHTML":_vm._s(_setup.renderMarkdown(_vm.$t('feedback.text')))}}),_vm._v(" "),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return (() => {
                _setup.addFeedback({ payload: _setup.bugReport }).then(() => {
                  _vm.$nuxt.$emit('userMsg', {
                    color: 'green',
                    message: _setup.$i18n.t('feedback.thanks'),
                  })
                  _setup.bugReport = {
                    title: '',
                    description: '',
                    url: _setup.route.fullPath,
                  }
                  dialog.value = false
                })
              }).apply(null, arguments)}}},[_c('v-textarea',{staticClass:"text-field-icon mt-4",attrs:{"type":"text","label":_vm.$t('feedback.yourFeedback'),"placeholder":"","persistent-placeholder":"","outlined":"","rows":"3","auto-grow":"","hide-details":""},model:{value:(_setup.bugReport.description),callback:function ($$v) {_vm.$set(_setup.bugReport, "description", $$v)},expression:"bugReport.description"}}),_vm._v(" "),_c('ix-button-action',{staticClass:"pl-5 pr-5 mt-4",attrs:{"type":"submit","width":"100%","loading":_setup.loading,"disabled":!_setup.bugReport.title && !_setup.bugReport.description}},[_vm._v("\n              "+_vm._s(_vm.$t('feedback.submitFeedback'))+"\n            ")])],1)],1)],1)]}}],null,false,3387301748)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }