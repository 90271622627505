var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"flex items-stretch border font-bold"},[_c('div',{staticClass:"flex flex-col flex-none w-64 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.investments.headers[0].text)+"\n    ")]),_vm._v(" "),(_setup.showDebugging)?_c('div',{staticClass:"flex flex-col flex-auto p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.investments.headers[1].text)+" (OLD)\n      "),_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-lock")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-none w-64 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.investments.headers[1].text)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-none w-32 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.investments.headers[2].text)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-auto p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.investments.headers[3].text)+"\n    ")])]),_vm._v(" "),_vm._l((_setup.calculatedSfdrReport?.investments?.list || []),function(item){return _c('div',{key:item.id,staticClass:"flex items-stretch border"},[_c('div',{staticClass:"flex flex-col flex-none w-64 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_c('a',{attrs:{"rel":"noopener","href":`/${item.id}/company`,"target":"_blank"}},[_vm._v("\n        "+_vm._s(item.investment)+"\n      ")])]),_vm._v(" "),(_setup.showDebugging)?_c('div',{staticClass:"flex flex-col flex-auto p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_c(_setup.Treeselect,{staticClass:"force-margin",attrs:{"value":item.deprecatedNace,"multiple":true,"normalizer":(node) => {
            return {
              id: node.id.concat(' - ', node.label),
              label: node.id.concat(' - ', node.label),
              children: node.children,
            }
          },"options":_setup.nace,"disable-fuzzy-matching":""},on:{"input":(val) => _setup.triggerUpdateCountriesNace('nace', val, item)}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-none w-64 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(_setup.sectorListString(item.sector))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-none w-32 p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[_vm._v("\n      "+_vm._s(item.percentAssets)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-auto p-4 border-r border-[var(--v-lightPink-base)] border-solid"},[(_setup.showDebugging)?_c('v-badge',{attrs:{"bordered":"","color":"error","icon":"mdi-lock","overlap":""}},[_c('span',[_vm._v(_vm._s(item.deprecatedCountryText))])]):_vm._e(),_vm._v(" "),_c(_setup.Treeselect,{staticClass:"force-margin",attrs:{"value":item.country,"disable-branch-nodes":true,"multiple":true,"normalizer":(node) => {
            return {
              id: node.label,
              label: node.label,
              children: node.children,
            }
          },"options":_setup.geographies,"disable-fuzzy-matching":""},on:{"input":(val) => _setup.triggerUpdateCountriesNace('countries', val, item)}})],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }