var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('ix-page-loading'):(_vm.fund && _vm.account)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ix-section',[_c('ix-section-title',[_vm._v(" "+_vm._s(_vm.fund.name)+" ")]),_vm._v(" "),_c('ix-sub-section-subtitle',[_vm._v("\n          "+_vm._s(_vm.$t('nameOfFinancialMarketParticipantWithLEI', {
              nameOfFinancialMarketParticipant:
                _vm.account?.nameOfFinancialMarketParticipant,
              legalEntityIdentifier: _vm.account?.legalEntityIdentifier,
            }))+"\n          "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$t('volumeWithCurrency', {
              volume: _vm.fund.volume,
            }))+"\n          "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$t('companyCount', {
              numberOfCompanies: _vm.numberOfCompanies,
            }))+"\n        ")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-section-title',[_c('v-row',{staticClass:"flex justify-center align-center h-full"},[_c('v-col',{attrs:{"cols":"2"}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"7"}},[_c('v-select',{staticClass:"w-[350px]",attrs:{"value":_vm.toNumber(_vm.$route.query.referencePeriod),"outlined":"","items":_vm.formattedReferencePeriods,"item-text":"text","item-value":"value","hide-details":"","dense":"","menu-props":{ bottom: true, offsetY: true }},on:{"change":(val) => {
                  _vm.$router.push({
                    query: { ..._vm.$route.query, tab: val },
                  })
                }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('sfdr-action-menu',{attrs:{"fund":_vm.fund,"is-considering-pais":_vm.considersPais,"is-considering-sfdr":!_vm.paiOnly,"reference-periods":_vm.referencePeriods,"selected-reference-period":_vm.toNumber(_vm.$route.query.referencePeriod),"use-reference-period":true},on:{"submit":(referencePeriod) => {
                  _vm.$router.push({
                    query: { ..._vm.$route.query, referencePeriod },
                  })
                }}})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-tabs',{staticClass:"sub-header-tab",attrs:{"value":(_vm.$route.query && _vm.$route.query.tab) || _vm.headers?.[0]?.link,"slider-size":"3","background-color":"white"},on:{"change":(val) => {
        _vm.$router.push({
          query: { ..._vm.$route.query, tab: val },
        })
      }}},_vm._l((_vm.headers),function(section){return _c('v-tab',{key:section.link,attrs:{"value":section.link,"href":`#${section.link}`}},[_vm._v("\n      "+_vm._s(section.label)+"\n    ")])}),1),_vm._v(" "),(_vm.$route.query?.referencePeriod)?_c('v-tabs-items',{attrs:{"value":(_vm.$route.query && _vm.$route.query.tab) || _vm.headers?.[0]?.link}},[_c('v-tab-item',{attrs:{"value":'precontractual'}},[_c('sfdr-export-precontractual',{attrs:{"current-reference-period":_vm.toNumber(_vm.$route.query?.referencePeriod)}})],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'periodic'}},[_c('sfdr-export-periodic')],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'pai'}},[_c('sfdr-export-pai',{attrs:{"current-reference-period":_vm.toNumber(_vm.$route.query?.referencePeriod)}})],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'fundWebsiteDisclosure'}},[_c('sfdr-export-results-view',{attrs:{"current-reference-period":_vm.toNumber(_vm.$route.query?.referencePeriod),"report-type":_vm.ReportType.SfdrFundWebsiteDisclosure,"section-id":1200}})],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'entityWebsiteDisclosure'}},[_c('sfdr-export-results-view',{attrs:{"current-reference-period":_vm.toNumber(_vm.$route.query?.referencePeriod),"report-type":_vm.ReportType.SfdrEntityWebsiteDisclosure,"section-id":1201}})],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'precontractualArticle6'}},[_c('sfdr-export-results-view',{attrs:{"current-reference-period":_vm.toNumber(_vm.$route.query?.referencePeriod),"report-type":_vm.ReportType.SfdrPrecontractual,"section-id":1800}})],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'kpi'}},[_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('ix-data-table-metric-aggregations',{attrs:{"filters":{
          fundId: _vm.fund.id,
        }}})],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }