import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.string.starts-with.js";
import md5 from '~/functions/md5';
import { AccountCategory, Module, useGetAccountQuery, useGetUserQuery } from '~/types/types';
export function venturesMap(portfolio) {
  var ventureLinks = (portfolio === null || portfolio === void 0 ? void 0 : portfolio.ventureLinks) || [];
  return ventureLinks.reduce(function (dictionary, venture) {
    dictionary[venture.ventureId] = venture;
    return dictionary;
  }, {});
}
export function esgVentureLinkList(portfolio) {
  // loop through venturesMapping
  // if ventureMapping has esg module
  // return ventureMapping.ventureId

  return Object.values(venturesMap(portfolio)).filter(function (ventureLink) {
    var _ventureLink$modules;
    return (_ventureLink$modules = ventureLink.modules) === null || _ventureLink$modules === void 0 ? void 0 : _ventureLink$modules.includes(Module.Esg);
  });
}
export function getGroupNames(ventureId, venturesMap) {
  var venture = venturesMap[ventureId];
  return venture && venture.groupNames ? venture.groupNames.join(', ') : '';
}
export function isBooked(submodule, venture) {
  var _venture$modules;
  return submodule.showForModules ? venture === null || venture === void 0 ? void 0 : (_venture$modules = venture.modules) === null || _venture$modules === void 0 ? void 0 : _venture$modules.some(function (bookedModule) {
    return submodule.showForModules.includes(bookedModule);
  }) : false;
}

/**
 * returns computed() ventureID, it checks the current account and if it's venture, return it's ID & ignore the URL param
 * So url param is only used when current account is a portfolio account.
 */
export function useVentureId() {
  var _useGetAccountQuery = useGetAccountQuery(),
    result = _useGetAccountQuery.result;
  var route = useRoute();
  return computed(function () {
    var _result$value, _result$value$getAcco;
    if (((_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getAcco = _result$value.getAccount) === null || _result$value$getAcco === void 0 ? void 0 : _result$value$getAcco.type) === AccountCategory.Portfolio) {
      var _route$params$venture;
      if (route !== null && route !== void 0 && (_route$params$venture = route.params.venture) !== null && _route$params$venture !== void 0 && _route$params$venture.startsWith('rec')) {
        // md5

        return md5(route === null || route === void 0 ? void 0 : route.params.venture); // I tested that without `-`, it still will work
      }
      return route === null || route === void 0 ? void 0 : route.params.venture;
    } else {
      var _result$value2, _result$value2$getAcc;
      return (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : (_result$value2$getAcc = _result$value2.getAccount) === null || _result$value2$getAcc === void 0 ? void 0 : _result$value2$getAcc.id;
    }
  });
}
export function useShowDebugging() {
  var _useGetUserQuery = useGetUserQuery(),
    userResult = _useGetUserQuery.result;
  return computed(function () {
    try {
      var _userResult$value, _userResult$value$get, _userResult$value2, _userResult$value2$ge, _JSON$parse;
      return (((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : (_userResult$value$get = _userResult$value.getUser) === null || _userResult$value$get === void 0 ? void 0 : _userResult$value$get.isAdmin) || ((_userResult$value2 = userResult.value) === null || _userResult$value2 === void 0 ? void 0 : (_userResult$value2$ge = _userResult$value2.getUser) === null || _userResult$value2$ge === void 0 ? void 0 : _userResult$value2$ge.isImpersonated)) && ((_JSON$parse = JSON.parse(localStorage.getItem('debugging'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.includes('debugging'));
    } catch (_unused) {}
  });
}