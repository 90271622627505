import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=7fe77bc3"
import script from "./edit.vue?vue&type=script&setup=true&lang=ts"
export * from "./edit.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./edit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxBtnBack: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/btn/Back.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxTextField: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/TextField.vue').default,IxTextarea: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Textarea.vue').default,IxTreeselectEsrs: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/treeselect/esrs.vue').default,IxDataTableIroSectionLink: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/IroSectionLink.vue').default,IxDataTableIroStakeholderLink: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/IroStakeholderLink.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAlert,VCol,VIcon,VRow,VSelect})
