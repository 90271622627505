var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ix-header-portfolio'),_vm._v(" "),_c('ix-data-table-feedbacks',{attrs:{"columns":[
      'user.email',
      'title',
      'description',
      'url',
      'rating',
      'createdAt',
    ],"default-filters":{}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }