import { MetricValueKind } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    changes: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(props) {
    return {
      valueType: computed(function () {
        return props.changes.valueType || props.value.valueType;
      }),
      valueOptions: computed(function () {
        var _props$changes$valueO, _props$value$valueOpt;
        return ((_props$changes$valueO = props.changes.valueOptions) === null || _props$changes$valueO === void 0 ? void 0 : _props$changes$valueO.options) || ((_props$value$valueOpt = props.value.valueOptions) === null || _props$value$valueOpt === void 0 ? void 0 : _props$value$valueOpt.options) || [];
      })
    };
  },
  data: function data() {
    return {
      optionSearch: null,
      isMultiSelect: false,
      MetricValueKind: MetricValueKind
    };
  },
  computed: {
    units: function units() {
      return [{
        header: this.$t('units.createAUnit')
      }, this.$t('units.tonnesCO2'), this.$t('units.GWh'), this.$t('units.tonnes'), this.$t('units.EUR'), this.$t('units.USD'), this.$t('units.percent'), this.$t('units.number'), this.$t('units.yesNo'), this.$t('units.unitReference'), this.$t('units.category')];
    }
  }
});