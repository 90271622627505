import { AccountCategory, useGetAccountQuery } from '~/types/types';
export default defineComponent({
  props: {
    ventureId: {
      type: String,
      required: true
    }
  },
  setup: function setup() {
    var _useGetAccountQuery = useGetAccountQuery(),
      result = _useGetAccountQuery.result,
      loading = _useGetAccountQuery.loading;
    var showActions = computed(function () {
      var _result$value, _result$value$getAcco;
      var accountType = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getAcco = _result$value.getAccount) === null || _result$value$getAcco === void 0 ? void 0 : _result$value$getAcco.type;
      return accountType === AccountCategory.Portfolio;
    });
    var headers = computed(function () {
      var baseHeaders = ['metric.name', 'completionStatus.completionRate', 'unitName', 'comment'];
      return showActions.value ? baseHeaders.concat('action') : baseHeaders;
    });
    return {
      headers: headers,
      loading: loading
    };
  }
});