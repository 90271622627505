import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import _ from 'lodash';
import { useVentureId, useShowDebugging } from '~/functions/accounts';
import { AccountCategory, MetricReportingPeriodType, useAddMetricAssignmentBatchMutation, useDeleteMetricAssignmentMutation, useGetAccountQuery, useGetMetricAssignmentLazyQuery, useListMetricAssignmentsQuery, useUpdateMetricAssignmentMutation } from '~/types/types';
export default defineComponent({
  props: {
    height: {
      default: 'calc(100vh - 210px)',
      type: String
    },
    fixedHeader: {
      type: Boolean,
      default: function _default() {
        return false;
      },
      required: false
    },
    showGroupBy: {
      default: false,
      type: Boolean
    },
    showSearch: {
      default: false,
      type: Boolean
    },
    showCreation: {
      default: false,
      type: Boolean
    },
    showTop: {
      default: false,
      type: Boolean
    },
    headers: {
      required: false,
      type: Array,
      default: function _default() {
        return ['expandToggle', 'metric.name', 'unitName', 'comment'];
      }
    },
    commentHint: {
      require: false,
      type: String,
      default: ''
    },
    groupBy: {
      require: false,
      type: String,
      default: null
    },
    filters: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    itemsPerPage: {
      required: false,
      type: Number,
      default: function _default() {
        return -1;
      }
    },
    defaultPeriods: {
      required: false,
      type: Array,
      default: function _default() {
        return [2023, 2024];
      }
    }
  },
  setup: function setup(props, _ref) {
    var slots = _ref.slots,
      emit = _ref.emit;
    var customAction = computed(function () {
      return !!slots.action;
    }); // using !$slots.action in the template does NOT work

    var route = useRoute();
    var openedItem = ref({
      reportingPeriodType: '',
      metric: {},
      isFrozen: false,
      metricValues: [],
      children: []
    });
    var openedChild = ref({
      reportingPeriodType: '',
      metric: {},
      children: [],
      isFrozen: false,
      metricValues: []
    });
    var openedPeriod = ref('');
    var dialog = ref(false);
    var valueDialog = ref(false);
    var childrenDialog = ref(false);
    var allowedPeriods = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
    var periods = ref(props.defaultPeriods);
    var showDebugging = useShowDebugging();
    var processedHeaders = computed(function () {
      var allHeaderByKey = {
        expandToggle: {
          text: '',
          value: 'expandToggle',
          cellClass: '',
          width: '36px',
          sort: 0,
          sortable: false
        },
        id: {
          width: '20px',
          text: 'ID',
          value: 'id',
          translatedText: 'ID',
          cellClass: ' font-weight-bold subtitle-2',
          sort: 1
        },
        'metric.id': {
          width: '10px',
          text: 'KPI',
          value: 'metric.id',
          translatedText: 'KPI',
          cellClass: ' font-weight-bold subtitle-2',
          sort: 2
        },
        assigneeId: {
          width: '200px',
          value: 'assigneeId',
          translatedText: 'companyPerspective.kpis.headers.company',
          cellClass: 'text-center font-weight-bold subtitle-2',
          sort: 3
        },
        'metric.name': {
          width: '400px',
          text: 'KPI',
          value: 'metric.name',
          translatedText: 'companyPerspective.kpis.headers.kpi',
          cellClass: 'text-center font-weight-bold subtitle-2',
          sortable: true,
          sort: 4
        },
        'metric.commaSeparatedTaxonomies': {
          width: '290px',
          text: 'Related frameworks',
          value: 'metric.commaSeparatedTaxonomies',
          translatedText: 'companyPerspective.kpis.headers.relatedFrameworks',
          cellClass: '',
          sort: 5
        },
        'fund.name': {
          text: 'Fund',
          value: 'fund.name',
          translatedText: 'companyPerspective.kpis.headers.fund',
          cellClass: '',
          sort: 6
        },
        'metric.goalType': {
          text: 'Goal',
          value: 'metric.goalType',
          translatedText: 'companyPerspective.kpis.headers.goal',
          cellClass: '',
          sort: 7
        },
        'completionStatus.completionRate': {
          text: 'Target progress',
          value: 'completionStatus.completionRate',
          translatedText: 'companyPerspective.kpis.headers.targetProgress',
          cellClass: 'text-center',
          width: '50px',
          sort: 8,
          sortable: false
        },
        impacts: {
          text: 'Impact',
          value: 'impacts',
          translatedText: 'companyPerspective.kpis.headers.impact',
          cellClass: 'text-center font-weight-bold subtitle-2',
          width: '200px',
          sort: 9
        },
        unitName: {
          width: '100px',
          text: 'Unit',
          value: 'unitName',
          translatedText: 'companyPerspective.kpis.headers.unit',
          cellClass: 'text-center',
          sort: 100
        },
        action: {
          text: 'Actions',
          value: 'action',
          translatedText: 'companyPerspective.kpis.headers.actions',
          cellClass: 'align-middle subtitle-1 font-weight-bold',
          width: '100px',
          sort: 101
        },
        comment: {
          text: 'Comments',
          value: 'comment',
          translatedText: 'companyPerspective.kpis.headers.comments',
          width: '300px',
          cellClass: '',
          sort: 102
        }
      };
      var wantedHeaders = _toConsumableArray(props.headers);
      if (showDebugging.value) {
        wantedHeaders.unshift('id');
        wantedHeaders.unshift('metric.id');
      }
      var x = wantedHeaders === null || wantedHeaders === void 0 ? void 0 : wantedHeaders.map(function (header) {
        if (header !== null && header !== void 0 && header.value) {
          return _objectSpread(_objectSpread({}, allHeaderByKey[header.value]), header);
        } else {
          if (!allHeaderByKey[header]) {
            console.error(header, 'does NOT exist!!!!!', header);
          }
          return allHeaderByKey[header];
        }
      });
      var _iterator = _createForOfIteratorHelper(periods.value),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var period = _step.value;
          var i = 0;
          x[period] = {
            text: period,
            value: period,
            cellClass: 'align-center primary--text text-center',
            align: 'center',
            width: '75px',
            sortable: true,
            sort: 10 + i
          };
          i++;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return x.sort(function (a, b) {
        if (a.sort < b.sort) {
          return -1;
        }
        if (a.sort > b.sort) {
          return 1;
        }
        return 0;
      });
    });
    var options = ref({
      page: 1,
      itemsPerPage: props.itemsPerPage,
      sortBy: ['metric.name'],
      sortDesc: [false]
    });
    watch(function () {
      return props.filters;
    }, function () {
      internalFilters.value = props.filters; // a not-so-pretty implementation for search in the URL
    });
    var internalFilters = ref(_objectSpread({}, props.filters));
    var variables = computed(function () {
      return {
        filters: _objectSpread({}, internalFilters.value),
        offset: options.value.itemsPerPage * (options.value.page - 1),
        limit: options.value.itemsPerPage,
        sort: options.value.sortBy.map(function (item, index) {
          return {
            field: String(item),
            sortDesc: options.value.sortDesc[index]
          };
        })
      };
    });
    var _useGetMetricAssignme = useGetMetricAssignmentLazyQuery(),
      loadOneAssignment = _useGetMetricAssignme.load,
      refetchOneAssignment = _useGetMetricAssignme.refetch;
    var _useListMetricAssignm = useListMetricAssignmentsQuery(variables),
      result = _useListMetricAssignm.result,
      refetch = _useListMetricAssignm.refetch,
      loading = _useListMetricAssignm.loading;
    watch(result, function () {
      var _result$value;
      // FIXME this is the only place we send API response back.
      emit('data-loaded', (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listMetricAssignments);
    });
    var _useGetAccountQuery = useGetAccountQuery(),
      accountResult = _useGetAccountQuery.result;
    var account = computed(function () {
      var _accountResult$value;
      return (_accountResult$value = accountResult.value) === null || _accountResult$value === void 0 ? void 0 : _accountResult$value.getAccount;
    });
    var items = computed(function () {
      var _result$value2, _result$value2$listMe;
      return ((_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : (_result$value2$listMe = _result$value2.listMetricAssignments) === null || _result$value2$listMe === void 0 ? void 0 : _result$value2$listMe.map(function (e) {
        return _objectSpread(_objectSpread({}, e), {}, {
          metricValuesByPeriod: Object.fromEntries((e.metricValues || []).map(function (e) {
            return [e.period, e];
          }))
          // taxonomiesString: extractTaxonomiesAsString(e.metric?.sections),
        });
      }).sort(function (a, b) {
        if (props.groupBy) {
          var _$get;
          // FIXME v-data-table is buggy that we have to sort data by the groupping filed first!!!!!!!!
          return ((_$get = _.get(a, props.groupBy)) === null || _$get === void 0 ? void 0 : _$get.localeCompare(_.get(b, props.groupBy), undefined, {
            numeric: true
          })) || 0;
        }
      })) || [];
    });
    var itemCount = computed(function () {
      var _result$value$countMe, _result$value3;
      return (_result$value$countMe = (_result$value3 = result.value) === null || _result$value3 === void 0 ? void 0 : _result$value3.countMetricAssignments) !== null && _result$value$countMe !== void 0 ? _result$value$countMe : 0;
    });
    var _useUpdateMetricAssig = useUpdateMetricAssignmentMutation(),
      updateMetricAssignment = _useUpdateMetricAssig.mutate,
      onDone = _useUpdateMetricAssig.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    var _useDeleteMetricAssig = useDeleteMetricAssignmentMutation(),
      deleteMetricAssignment = _useDeleteMetricAssig.mutate;
    var getMetricValueByPeriod = function getMetricValueByPeriod(assessment, period) {
      return (assessment === null || assessment === void 0 ? void 0 : assessment.metricValues.find(function (e) {
        return e.period == period;
      })) || {};
    };
    var emitMetricValuesUpdated = function emitMetricValuesUpdated(v) {
      var _iterator2 = _createForOfIteratorHelper(v || []),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          // TODO we should get parent of updated metricAssignment in the case of computed assignment and also update their values.
          loadOneAssignment(null, {
            id: item.metricAssignmentId
          }) || refetchOneAssignment({
            id: item.metricAssignmentId
          });
        }

        // TODO this should be removed
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      emit('metricValuesUpdated', v);
    };
    var _useAddMetricAssignme = useAddMetricAssignmentBatchMutation(),
      addMetricAssignmentBatch = _useAddMetricAssignme.mutate;
    var ventureId = useVentureId();
    return {
      ventureId: ventureId,
      emitMetricValuesUpdated: emitMetricValuesUpdated,
      itemCount: itemCount,
      options: options,
      refetch: refetch,
      MetricReportingPeriodType: MetricReportingPeriodType,
      customAction: customAction,
      dialog: dialog,
      valueDialog: valueDialog,
      openedPeriod: openedPeriod,
      openedItem: openedItem,
      allowedPeriods: allowedPeriods,
      periods: periods,
      processedHeaders: processedHeaders,
      updateMetricAssignment: updateMetricAssignment,
      deleteMetricAssignment: deleteMetricAssignment,
      items: items,
      childrenDialog: childrenDialog,
      getMetricValueByPeriod: getMetricValueByPeriod,
      openedChild: openedChild,
      loading: loading,
      AccountCategory: AccountCategory,
      internalFilters: internalFilters,
      account: account,
      addMetricAssignmentBatch: addMetricAssignmentBatch,
      showDebugging: showDebugging
    };
  }
});