var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('ix-btn-back',{staticClass:"mr-4"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "),_c('span',[_vm._v(" Edit Activity ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","dense":"","type":"info"}},[_vm._v("\n      Note: Non-listed SMEs only need to list their key activities and\n      business relationships.\n    ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('name')}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.item.name,"hide-details":"","outlined":"","clearable":""},on:{"input":(val) => {
          _setup.updateActivity({ activityInput: { id: _setup.item.id, name: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('location')}}),_vm._v(" "),_c('v-autocomplete',{attrs:{"value":_setup.item.location,"items":_setup.geographies,"solo":"","label":"","item-text":"name","item-value":"name","multiple":false},on:{"input":(val) => {
          _setup.updateActivity({ activityInput: { id: _setup.item.id, location: val } })
        }},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v("\n        "+_vm._s(data.item.name)+"\n      ")]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_vm._v(" "),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.group)}})],1)]]}}])})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('industry')}}),_vm._v(" "),(_setup.item)?_c('ix-treeselect-industry',{attrs:{"multiple":false,"value":_setup.item.industry},on:{"input":(val) => {
          _setup.updateActivity({
            activityInput: {
              id: _setup.item.id,
              industry:
                String(
                  val,
                ) /* DB stores this as String but it's really not a big deal to do this type convention for now as this field is subject to change to allow multiple options */,
            },
          })
        }}}):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('desc'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_setup.item.description,"outlined":"","hide-details":"","auto-grow":""},on:{"input":(val) => {
          _setup.updateActivity({ activityInput: { id: _setup.item.id, description: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('riskProfile'),"is-mandatory":true}}),_vm._v(" "),(_setup.item)?_c('v-btn-toggle',{staticClass:"pr-4 mt-2",attrs:{"value":_setup.item.riskProfile,"color":"primary","mandatory":"","dense":""},on:{"change":(val) => {
          if (_setup.item.id) {
            // FIXME: I think v-btn-toggle is buggy that is triggers unnecessary event
            _setup.updateActivity({
              activityInput: { id: _setup.item.id, riskProfile: val },
            })
          }
        }}},[_c('v-btn',{attrs:{"value":_setup.EnumActivityRiskProfile.Normal}},[_vm._v("\n        "+_vm._s(_setup.EnumActivityRiskProfile.Normal)+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":_setup.EnumActivityRiskProfile.Heightened}},[_vm._v("\n        "+_vm._s(_setup.EnumActivityRiskProfile.Heightened)+"\n      ")]),_vm._v(" "),_c('v-btn',{attrs:{"value":_setup.EnumActivityRiskProfile.Insignificant}},[_vm._v("\n        "+_vm._s(_setup.EnumActivityRiskProfile.Insignificant)+"\n      ")])],1):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('stakeholder'),"subtitle":_vm.$t('subtitle-stakeholder')}}),_vm._v(" "),_c('v-row',[_c('ix-data-table-activity-stakeholder-link',{attrs:{"activity-id":_setup.item.id,"headers":[
          // 'id' ,
          {
            value: 'actions',
            width: '30px',
            text: ' ',
            cellClass: 'max-w-[30px]',
          },
          'stakeholder.name',
          'stakeholder.type',
          // 'relationship',
          // 'affected_by_impacts',
          'impactMaterial',
          'financialMaterial',
          'Concerns and requirements',
        ]}})],1)],1),_vm._v(" "),_c('v-spacer',{staticClass:"w-full h-10"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('sustainability-issue.title'),"subtitle":_vm.$t('sustainability-issue.subtitle'),"is-mandatory":false}}),_vm._v(" "),_c('v-row',[(_setup.item.id)?_c('ix-data-table-evidence-link',{attrs:{"headers":[
          { value: 'actions', width: '30px' },
          { value: 'description', text: _vm.$t('sustainability-issue.column') },
          { value: 'evidence', width: '200px' },
        ],"query":{
          entityUuidIds: [_setup.item.id],
          type: 'csrd-sustainability-issue',
        }}}):_vm._e()],1)],1),_vm._v(" "),_c('v-spacer',{staticClass:"w-full h-10"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('sustainability-requirement.title'),"subtitle":_vm.$t('sustainability-requirement.subtitle'),"is-mandatory":false}}),_vm._v(" "),_c('v-row',[(_setup.item.id)?_c('ix-data-table-evidence-link',{attrs:{"headers":[
          { value: 'actions', width: '30px' },
          {
            value: 'description',
            text: _vm.$t('sustainability-requirement.column'),
          },
          { value: 'evidence', width: '200px' },
        ],"query":{
          entityUuidIds: [_setup.item.id],
          type: 'csrd-sustainability-requirement',
        }}}):_vm._e()],1)],1),_vm._v(" "),_c('v-spacer',{staticClass:"w-full h-10"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('sustainability-trend.title'),"subtitle":_vm.$t('sustainability-trend.subtitle'),"is-mandatory":false}}),_vm._v(" "),_c('v-row',[(_setup.item.id)?_c('ix-data-table-evidence-link',{attrs:{"headers":[
          { value: 'actions', width: '30px' },
          { value: 'description', text: _vm.$t('sustainability-trend.column') },
          { value: 'evidence', width: '200px' },
        ],"query":{
          entityUuidIds: [_setup.item.id],
          type: 'csrd-sustainability-trend',
        }}}):_vm._e()],1)],1),_vm._v(" "),_c('v-spacer',{staticClass:"w-full h-10"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('sustainability-assessment.title'),"subtitle":_vm.$t('sustainability-assessment.subtitle'),"is-mandatory":false}}),_vm._v(" "),_c('v-row',[(_setup.item.id)?_c('ix-data-table-evidence-link',{attrs:{"headers":[
          { value: 'actions', width: '30px' },
          {
            value: 'description',
            text: _vm.$t('sustainability-assessment.column'),
          },
          { value: 'evidence', width: '200px' },
        ],"query":{
          entityUuidIds: [_setup.item.id],
          type: 'csrd-sustainability-assessment',
        }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }