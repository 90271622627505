var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('ix-toolbar-section',{attrs:{"esg-ventures":_vm.esgVentures,"selected":_vm.selected,"sort-keys":_vm.sortKeys,"has-batch-actions":false},on:{"search":(value) => (_vm.search = value),"group":(value) => (_vm.groupBy = value),"sort":_vm.sort,"finish":function($event){_vm.selected = []}}}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"hide-default-footer":_vm.items.length <= 20,"items":_vm.items,"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.filter,"items-per-page":20,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"show-select":"","group-by":_vm.groupBy,"checkbox-color":"primary","item-key":"id"},scopedSlots:_vm._u([{key:`group.header`,fn:function({ group, remove, isOpen }){return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":() => {
                    remove()
                    _vm.groupBy = null
                  }}},[(isOpen)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()],1)],1),_vm._v(" "),_c('v-col',[(_vm.groupBy === 'groupNames')?_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("\n                "+_vm._s(group || 'No group')+"\n              ")]):_vm._e()])],1)],1)]}},{key:`item.venture.name`,fn:function({ item }){return [_c('company-button',{attrs:{"venture-id":item.ventureId,"name":item.venture.name,"logo":item.venture.logo,"to":"venture-impact"}})]}},{key:`item.groupNames`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.groupNames))])]}},{key:`item.impactGoal.targetProgress`,fn:function({ item }){return [(item.impactGoal)?_c('div',{staticStyle:{"width":"250px"}},[_c('impact-goal-progress-section',{staticClass:"mt-2",attrs:{"target-progress":item.impactGoal.targetProgress,"show-message":false}})],1):_vm._e()]}},{key:`item.impactGoal.impacts`,fn:function({ item }){return _vm._l(((item.impactGoal || {}).impacts),function(impact){return _c('v-row',{key:impact.id,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('impact-pill',{attrs:{"impact":impact}})],1)],1)})}},{key:`item.geographies`,fn:function({ item }){return [_c('div',{staticClass:"font-weight-medium",staticStyle:{"max-width":"200px","color":"#5b7185 !important"}},[_vm._v("\n          "+_vm._s(item.geographies)+"\n        ")])]}},{key:`item.impactGoal.sdgs`,fn:function({ item }){return [(item.impactGoal)?_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"300px"}},[_c('sdg-list',{attrs:{"primary-sdgs":item.impactGoal.sdgs
                .toSorted((a, b) => a.id - b.id)
                .map((e) => `SDG ${e.id} - ${e.label}`)}})],1):_vm._e()]}},{key:`item.impactGoal.submitDate`,fn:function({ item }){return [_c('div',{staticClass:"text-center font-weight-medium neutral-grey-darker"},[(item.impactGoal && item.impactGoal.submitDate !== null)?_c('span',[_vm._v("\n            "+_vm._s(_vm.formatAsGermanDate(item.impactGoal.submitDate))+"\n          ")]):_vm._e()])]}},{key:`item.comment`,fn:function({ item }){return [_c('div',{staticStyle:{"min-width":"200px !important"}},[_c('v-textarea',{staticClass:"text-body-2",attrs:{"value":item.comment,"rows":"2","outlined":"","full-width":"","hide-details":"","placeholder":_vm.$t('impactGoal.listView.addComment')},on:{"change":(value) => {
                _vm.updateVentureComment({
                  ventureId: item.ventureId,
                  comment: value,
                })
              }}})],1)]}},{key:`item.action`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-white',_vm._g(_vm._b({attrs:{"small":"","value":item.change,"color":"#23A9F1"}},'ix-button-white',attrs,false),on),[_c('img',{attrs:{"width":"14","src":require('~/assets/icons/settings.svg')}})])]}}],null,true)},[_vm._v(" "),_c('v-list',[(item.impactGoal.isEditable)?_c('v-list-item',{on:{"click":function($event){return _vm.freezeImpactGoal(item.impactGoal, true)}}},[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(_vm.$t('impactGoal.listView.freezeGoalUndTarget'))+"\n              ")])],1):(!item.impactGoal.isEditable)?_c('v-list-item',{on:{"click":function($event){return _vm.freezeImpactGoal(item.impactGoal, false)}}},[_c('v-list-item-title',[_vm._v("\n                "+_vm._s(_vm.$t('impactGoal.listView.unFreezeGoalUndTarget'))+"\n              ")])],1):_vm._e(),_vm._v(" "),_c('v-list-item',[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: reportOn, attrs: reportAttrs }){return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',reportAttrs,false),reportOn),[_vm._v("\n                    "+_vm._s(_vm.$t('impactGoal.listView.exportPDF'))+"\n                  ")])]}},{key:"default",fn:function(dialog){return [_c('exports-impact-goal-export',{attrs:{"venture-id":item.ventureId,"venture-name":item.venture.name,"website":item.website},on:{"close":function($event){dialog.value = false}}})]}}],null,true)})],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }