var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('ix-btn-back',{staticClass:"mr-4"}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "),_c('span',[_vm._v(" Edit IRO ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","dense":"","type":"info"}},[_vm._v("\n      Note: Non-listed SMEs only need to list their key IROs and business\n      relationships.\n    ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('name')}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_setup.item.name,"hide-details":"","outlined":"","clearable":""},on:{"input":(val) => {
          _setup.updateIRO({ iro: { id: _setup.item.id, name: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('type')}}),_vm._v(" "),_c('v-select',{attrs:{"items":[
        { text: 'Impact', value: _setup.EnumIroType.Impact },
        { text: 'Risk', value: _setup.EnumIroType.Risk },
        { text: 'Opportunity', value: _setup.EnumIroType.Opportunity },
      ],"label":"Type","solo":"","value":_setup.item.type},on:{"input":(val) => {
          _setup.updateIRO({ iro: { id: _setup.item.id, type: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('activity')}}),_vm._v(" "),_c('v-select',{attrs:{"value":_setup.item.activityId,"items":_setup.activities,"label":"Type","solo":""},on:{"input":(val) => {
          _setup.updateIRO({ iro: { id: _setup.item.id, activityId: val } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('desc'),"is-mandatory":true}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_setup.item.description,"outlined":"","hide-details":"","auto-grow":"","clearable":""},on:{"input":(val) => {
          _setup.updateIRO({ iro: { id: _setup.item.id, description: val || '' } })
        }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ix-form-input-question',{attrs:{"title":"ESRS","sublabel":"Assign ESRS topics that are relevant to this IRO, or use the Suggest topics button suggest recommendations for you.    ","is-mandatory":false}}),_vm._v(" "),_c('ix-treeselect-esrs',{attrs:{"iro-id":_setup.item.id}})],1),_vm._v(" "),(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-data-table-iro-section-link',{attrs:{"iro-id":_setup.item.id,"headers":[
        // 'id',
        'stakeholder.name',
        'stakeholder.type',
        // 'relationship',
        // 'affected_by_impacts',
        'impactMaterial',
        'financialMaterial',
        'Concerns and requirements',
      ]}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":"Stakeholders","sublabel":"Who and is involved in this IRO and how?  List the stakeholders who are involved in this activity, and indicate if they have any sustainability-related concerns or requirements.","is-mandatory":false}}),_vm._v(" "),_c('ix-data-table-iro-stakeholder-link',{attrs:{"iro-id":_setup.item.id,"headers":[
        // 'id',
        'actions',
        'stakeholder.name',
        'stakeholder.type',
        // 'relationship',
        // 'affected_by_impacts',
        'impactMaterial',
        'financialMaterial',
        'Concerns and requirements',
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }