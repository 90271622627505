import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.slice.js";
import VueApexCharts from 'vue-apexcharts';
import { abbreviateNumber } from '~/assets/helper/functions';
import { dateRange, impactSolutionMetricValues, roundValue, totalGhgSavingsOfImpactSolution } from '~/functions/ghg';
import { renderMarkdown } from '~/functions/markdown';
import { useListMetricAssignmentsQuery } from '~/types/types';
export default defineComponent({
  name: 'ClimateImpactModuleSummary',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    climateImpact: {
      type: Object,
      required: true
    },
    ventureId: {
      type: String,
      required: true
    },
    usingGhgTool: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var locale = $i18n.defaultLocale;
    if (locale === 'en') {
      locale = 'en-US';
    } else if (locale === 'de') {
      locale = 'de-DE';
    }
    var getSpecificReductionHeader = function getSpecificReductionHeader(impactSolution) {
      var _impactSolution$ghgSa;
      var dynamicText = impactSolution === null || impactSolution === void 0 ? void 0 : (_impactSolution$ghgSa = impactSolution.ghgSavingPotential) === null || _impactSolution$ghgSa === void 0 ? void 0 : _impactSolution$ghgSa.comparativeUnit;
      return "".concat(dynamicText);
    };
    var _useListMetricAssignm = useListMetricAssignmentsQuery({
        assigneeIds: [props.ventureId],
        filters: {
          metricIds: [1217, 1218]
        }
      }),
      result = _useListMetricAssignm.result;
    var metricValues = computed(function () {
      var _result$value, _match$metricValues;
      var assignments = ((_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.listMetricAssignments) || [];
      var match = assignments.find(function (metricAssignment) {
        var _metricAssignment$met;
        return (metricAssignment === null || metricAssignment === void 0 ? void 0 : (_metricAssignment$met = metricAssignment.metric) === null || _metricAssignment$met === void 0 ? void 0 : _metricAssignment$met.id) === 1218;
      } // Metric id for "GHG emissions mitigated by company"
      );
      return (match === null || match === void 0 ? void 0 : (_match$metricValues = match.metricValues) === null || _match$metricValues === void 0 ? void 0 : _match$metricValues.filter(function (metricValue) {
        return metricValue.target !== null;
      })) || [];
    });
    var totalGhgReduction = computed(function () {
      var values = metricValues.value;
      if (!values || values.length === 0) return 0;
      return values.reduce(function (acc, metricValue) {
        return acc + ((metricValue === null || metricValue === void 0 ? void 0 : metricValue.target) || 0);
      }, 0);
    });
    var averageTotalGhgReduction = computed(function () {
      var values = metricValues.value;
      if (!values || values.length === 0) return 0;
      return totalGhgReduction.value / values.length;
    });
    var getRoundedBounds = computed(function () {
      var lower = averageTotalGhgReduction.value * 0.9;
      var upper = averageTotalGhgReduction.value * 1.1;
      return {
        lowerBound: abbreviateNumber(lower, locale),
        upperBound: abbreviateNumber(upper, locale)
      };
    });
    var getAverageAnnualReduction = computed(function () {
      var averageAnnualReduction = averageTotalGhgReduction.value.toLocaleString(locale, {
        minimumFractionDigits: 0
      });
      var hcpCompany = false;
      if (averageTotalGhgReduction.value > 50000) {
        hcpCompany = true;
      }
      return {
        averageAnnualReduction: averageAnnualReduction,
        hcpCompany: hcpCompany
      };
    });
    var economicValue = computed(function () {
      var value = roundValue(averageTotalGhgReduction.value * 83.24);
      return value.toLocaleString(locale, {
        minimumFractionDigits: 0
      });
    });
    function getComparisonPledge(impactSolution) {
      var _impactSolution$conce, _impactSolution$ghgSa2, _impactSolution$ghgSa3;
      var metricValues = impactSolutionMetricValues(impactSolution, props.climateImpact);
      var range = dateRange(metricValues);
      return $i18n.t('impactGoal.overview.climateImpactModule.protectionPledge', {
        label: (impactSolution === null || impactSolution === void 0 ? void 0 : (_impactSolution$conce = impactSolution.concept) === null || _impactSolution$conce === void 0 ? void 0 : _impactSolution$conce.label) || 'N/A',
        amount: totalGhgSavingsOfImpactSolution(metricValues, locale),
        header: getSpecificReductionHeader(impactSolution),
        percentageReduction: (impactSolution === null || impactSolution === void 0 ? void 0 : (_impactSolution$ghgSa2 = impactSolution.ghgSavingPotential) === null || _impactSolution$ghgSa2 === void 0 ? void 0 : _impactSolution$ghgSa2.specificReductionAsPercentage) || 'N/A',
        reduction: (impactSolution === null || impactSolution === void 0 ? void 0 : (_impactSolution$ghgSa3 = impactSolution.ghgSavingPotential) === null || _impactSolution$ghgSa3 === void 0 ? void 0 : _impactSolution$ghgSa3.specificReductionPerUnit) || 'N/A',
        startYear: range.at(0),
        endYear: range.at(-1)
      });
    }
    return {
      getSpecificReductionHeader: getSpecificReductionHeader,
      getRoundedBounds: getRoundedBounds,
      getAverageAnnualReduction: getAverageAnnualReduction,
      metricValues: metricValues,
      totalGhgReduction: totalGhgReduction,
      averageTotalGhgReduction: averageTotalGhgReduction,
      economicValue: economicValue,
      getComparisonPledge: getComparisonPledge,
      roundValue: roundValue
    };
  },
  computed: {
    chartData: function chartData() {
      var _this = this;
      var data = [['Period', 0]];
      this.metricValues.forEach(function (year) {
        var targetValue = year.target || 0;
        data.push([year.period, _this.roundValue(targetValue)]);
      });
      return data;
    },
    vueChartOptions: function vueChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        title: {
          text: this.$t('impactGoal.overview.climateImpactModule.chartTitle'),
          align: 'center'
        },
        xaxis: {
          categories: this.chartData.slice(1).map(function (item) {
            return item[0];
          }) // Exclude the first row (headers)
        },
        yaxis: {
          title: {
            text: this.$t('impactGoal.overview.climateImpactModule.chartYLabel')
          }
        }
      };
    },
    benchmarkText: function benchmarkText() {
      var text;
      if (this.averageTotalGhgReduction < 31) text = this.$t('impactGoal.overview.climateImpactModule.belowAverage');else if (this.averageTotalGhgReduction >= 31 && this.averageTotalGhgReduction < 50) text = this.$t('impactGoal.overview.climateImpactModule.aboveAverage');else if (this.averageTotalGhgReduction >= 50) text = this.$t('impactGoal.overview.climateImpactModule.highImpact');
      return text;
    },
    vueChartSeries: function vueChartSeries() {
      return [{
        name: 'Annual GHG reductions',
        data: this.chartData.slice(1).map(function (item) {
          return item[1];
        }) // Exclude the first row (headers)
      }];
    }
  },
  methods: {
    renderMarkdown: renderMarkdown
  }
});