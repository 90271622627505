export default {
  placeholder: 'PlaceHolder',
  placeholderStrong: '**PlaceHolder**',
  verifiedUnverified: {
    verified: 'Verified',
    unverified: 'Unverified',
  },
  defaultErrMsg: 'Something is wrong!',
  defaultSuccessMsg: 'Your changes have been successfully saved.',
  noConceptSourceAvailable: '_There is currently no source for this action._',
  general: {
    conceptActionsTableHeaders: {
      action: 'Action',
      implementation: 'Implementation',
    },
    addMissingAction: 'Add missing action',
    headers: {
      'portfolio-dashboard': 'Dashboard',
      'portfolio-esgList': 'ESG',
      'portfolio-impactGoalList': 'Impact', // v2
      'portfolio-impactList': 'Impacts',
      'portfolio-impactComparison': 'Impact Comparison',
      'portfolio-metrics': 'KPIs',
      'portfolio-task': 'Tasks',
      'portfolio-funds': 'Funds',
      'portfolio-impactScreening': 'Impact Screening',
      'admin-accounts': 'Account',
      'admin-users': 'User',
      'admin-esgs': 'ESG MGM',
      'admin-categories': 'Categories',
      'admin-kpis': 'KPI MGM',
      'admin-kpis-datapoint': 'Datapoint',
      'admin-kpis-aggregated': 'Aggregated',
      'admin-sections': 'Section',
      'admin-fundreports': 'Fund Reports',
      'admin-funds': 'Fund MGM',
      'admin-audits': 'Audit',
      'admin-feedbacks': 'Feedback',
      modules: 'Modules',
      companyInfo: 'Company info',
      esg: 'ESG',
      impact: 'Impact',
      kpis: 'KPIs',
      impactGoal: 'Impact',
      openTasks: 'Open tasks',
      impactScreening: 'Impact screening',
      impactAssessment: 'Impact assessment',
      tasks: 'Tasks',
      csrd: 'CSRD',
      csrdDashboard: 'Dashboard',
      csrdCompany: 'Company',
      csrdValueChain: 'Value Chains',
      csrdIROs: 'IROs',
      csrdStakeholders: 'Stakeholders',
      csrdESRS: 'ESRS',
    },

    generalNavigation: {
      invitePortfolioCompanies: 'Invite portfolio companies',
      inviteTeamMembers: 'Invite team members',
      signOut: 'Sign out',
      termsAndConditions: 'Terms & Conditions',
    },
    duration: 'Duration',
    backButton: 'Back',
    saveAndClose: 'Save & Close',
    submitButton: 'Submit',
    submitFeedbackButton: 'Submit feedback',
    cancelButton: 'Cancel',
    notPlanned: 'Not planned',
    plannedIn12Months: 'Planned in the next 12 months',
    doingIt: 'Doing it!',
    evidence: {
      addEvidenceButton: 'Add evidence',
      evidenceDialog: {},
    },
    contextInformation: 'Context information',
    typicalKpis_Metrics: 'Typical KPIs / Metrics to report',
    interestingReads: 'Interesting reads to help you improve',
    insightsOn: 'Insights on',
    nothingToDisclose: 'Nothing to disclose',
    description: 'Description',
    carEmployees: 'Employees', // fields for pilot
    carInsighstsOn: 'Insights on Sustainability in the Car Dealer Industry',
    carBestPracticeReports:
      'Best practice sustainability reports to help you improve',
    navigation: {
      previous: 'Previous',
      continue: 'Continue',
      areYouSure: 'Are you sure you want to continue?',
      notComplete:
        'You did not complete all mandatory questions in this section yet. Remember you cannot submit the questionnaire until all mandatory questions are answered.',
      cancel: 'Cancel',
      delete: 'Delete',
      update: 'Update',
      add: 'Add',
      getStarted: 'Get started',
      close: 'Close',
    },
    validationErrors: {
      requiredError: 'Text is required!',
      lengthError: {
        prefix: 'Text must be less than',
        suffix: 'characters',
      },
    },
    formInput: {
      combobox: {
        header: {
          base: 'Press "Enter" after typing to create a new item.',
          multiple: 'You can create multiple items.',
          delete: 'You can delete an item by pressing the "Del" key.',
        },
      },
    },
  },
  companyInfo: {
    basicInformation: 'Basic information',
    pitchLine: 'Pitch line',
    legalEntity: 'Legal entity',
    founders: 'Founders',
    headquarter: 'Headquarter',
    country: 'Country',
    foundingYear: 'Founding year',
    employeeHeadcount: 'Employee headcount (planned for this year)',
    turnover: 'Turnover (planned for this year)',
    fundingStage: 'Current funding stage',
    additionalContextInformation: 'Additional context information',
    businessModel: 'Business model',
    incomeStream: 'Main income stream',
    products: 'Products/Services',
    industries: 'Industries',
    customerGroup: 'Customer group',
    csrd: {
      yes: 'Yes',
      no: 'No',
      iDontKnow: `I don't know`,
      title:
        'Subject to the disclosure requirements of the Corporate Sustainability Reporting Directive (CSRD) and must report following the European Sustainability Reporting Standards (ESRS)',
      options: {
        2025: '2025 (reporting year 2024)',
        2026: '2026 (reporting year 2025)',
        2027: '2027 (reporting year 2026)',
        later: 'At a later date',
      },
    },
    explanation: {
      title: 'Entering your company info',
      text: `
-   In this section you can enter and edit your company information.
-   Your inputs will be saved as you enter them and shown on your dashboard
-   The data will help us to offer you peer benchmarks in the future
`,
    },
  },
  modules: {
    header: {
      availableModules: 'Available Modules',
    },

    dashboard: {
      sectionTitle: 'Key Facts',
      sectionSubTitle: 'Status of sustainability management for the portfolio',
      keyFacts: {
        numberOfVentures: 'companies in the portfolio',
        numberOfCompletedQuestionnaires:
          'companies with completed ESG assessment',
        numberOfVenturesWithCriticalEsgIssues:
          'companies still with critical ESG issues',
        numberOfVenturesWithCriticalDisclosureIssues:
          'companies still with critical disclosure issues',
      },
      impactGoalPerf: {
        sectionTitle: 'Impact Goal Performance',
        sectionSubTitle: 'Aggregation over {n} companies',
        sectionImpactBySGD: 'Impact by SDG',
        option: {
          positive: 'Positive impacts',
          negative: 'Negative impacts',
        },
        sectionImpactByGeo: 'Impact by geography',
        geo: {
          legend: 'Number of impacts affecting this geography',
        },
      },
      esg: {
        sectionTitle: 'ESG performance',
        sectionSubTitle: 'Aggregation over {n} companies',

        totalEsgScore: 'Total ESG score',
        dimensionScore: 'Dimension scores',
        disclosures: 'Disclosures',
      },

      aggregatedKPIs: {
        sectionTitle: 'Aggregate metrics',
        sectionSubTitle: 'Actual vs target performance for all ventures',
        trackedMetrics: 'Tracked metrics',
      },
    },

    companyInfo: {
      header: 'Company info',
      title: 'Company information',
      description: 'Provide basic information on your company',
      buttonContent: 'Get started',
    },

    esgAssessment: {
      header: 'ESG',
      title:
        'Modules that help you to identify, manage & report on environmental, social & governance topics that constitute a **material financial risk** for your company.',
      adverseImpactScreening: {
        title: 'Adverse impact screening',
        description: 'Quick impact screening for your business',
        duration: 'instant',
        buttonContent: 'Get started',
        upcomingText: 'Upcoming',
      },
      esgAssessment: {
        title: 'ESG Starter',
        description:
          'Obtain a quick overview about your exposure to environmental, social and governance risks',
        duration: '~30 min',
        buttonContent: 'Get started',
        notBooked: 'You did not book this module',
      },
      metrics: {
        title: 'KPI reporting',
        description:
          'Select sustainability KPIs for your company, make forecasts and track your performance',
        buttonContent: 'Get started',
      },
    },
    impactGoals: {
      header: 'Impact',
      title:
        'Modules that help you to identify, manage & report the **most relevant social & environmental impact your company** has  (independent of a direct financial impact on your company).',
      impactGoals: {
        title: 'GHG & Impact Estimator ',
        description:
          'Define the impact goals of your business and estimate the GHG reduction potential of your products and services for your climate-related impacts',
        duration: '15-30min',
        buttonContent: 'Get started',
        getStartedWithImpactsText:
          'You have not defined any impacts yet. Get started now!',
      },
      impactScoring: {
        title: 'Impact scoring',
        description:
          'Define and score your impact along the international best-practices of the impact management project',
        duration: '5 - 15 min per impact',
        buttonContent: 'Available on demand',
        upcomingText: 'Available on demand',
      },
      ghgSavingPotential: {
        title: 'GHG reduction potential',
        description:
          'Calculate the GHG reduction potential for the key climate impacts of your products and services',
        duration: '~20 min per impact',
        buttonContent: 'Start reduction potential calculation',
        upcomingText: 'Upcoming',
      },
    },
  },

  esg: {
    portfolioListView: {
      headers: {
        venture: { name: 'Company' },
        groupNames: 'Groups',
        totalscore: 'Total ESG Score',
        envperformance: 'Environmental performance',
        socperformance: 'Social performance',
        govperformance: 'Governance performance',
        disclosureDetails: 'Disclosure',
        investment: 'Value of investment',
        update: 'Submit date',
        comment: 'Portfolio manager comments',
      },
      sortBy: 'Sort by {column}',
      addComment: 'Add Comment',
    },
    header: {
      version: 'Version',
      compare: 'Compare ESG results',
      type: 'Self assessment',
      notComplete:
        'You have not completed the ESG questionnaire yet. Get started now!',
    },
    introduction: {
      welcomeTitle: 'Welcome to the ESG assessment',
      welcomeBackTitle: 'Welcome back to the ESG assessment',
      welcomeText:
        'This 30min questionnaire gives you a better understanding if your company **takes into account the most relevant ESG* aspects**.',
      welcomeBackText: `You are invited to reassess your ESG* performance. The assessment shows the data you entered during the last assessment. **Please update your information if anything has changed**. Otherwise, the information from last time will be kept.`,
      caption:
        '* ESG = Environment, social and governance criteria your company should respect according to regulation and international standard setters like GRI, SASB or KfW Capital',
    },
    introduction_v2: {
      welcomeTitle: 'Welcome to the ESG assessment',
      welcomeText:
        'This questionnaire gives you a better understanding if your company **takes into account the most relevant ESG* aspects**. The average completion time is 30 minutes.',
      welcomeBackTitle: 'Welcome back to the ESG assessment',
      welcomeBackText: `You are invited to reassess your ESG* performance. The assessment shows the data you entered during the last assessment. **Please update your information if anything has changed**. Otherwise, the information from last time will be kept.`,
      caption:
        '* ESG = Environment, social and governance criteria your company should respect according to regulation and international standard setters like GRI, SASB or KfW Capital',
    },
    whoIsItFor: {
      title: 'Who is it for?',
      text: `
- All companies can use the assessment to get a first understanding of their ESG performance
- The importance of different ESG categories varies with your business model & development stage
    `,
    },
    questionStructure: {
      title: 'Question structure',
      text: `You find questions on 15 different categories, 5 on environmental, 5 on social & 5 on governance topics.
Each category has two levels of questions:

- 1st level: Are you taking action on a topic?
- 2nd level: What actions do you undertake?
    `,
    },
    questionStructure_v2: {
      text: ` - **You find questions on 15 different categories**, 5 on environmental, 5 on social &amp; 5 on governance
    topics.
- For each of the 15 categories, **measures are proposed to track or improve** the current level of ESG
    performance.
- Please select for each category the **measures which apply to your business** and give some **information on the
    implementation status**
`,
    },
    implementation: {
      title: 'Implementation status of a measure',
      text1:
        'For each question, we propose a number of different answering options and by clicking them you change the implementation status of the measure.',
      text2: 'If you click once, the status will change to **Doing it!**, e.g.',
      text3:
        'If you click twice it will change to **Planned in the next 12 months**, e.g.',
      text4:
        'If you click a third time it will change back to **Not planned** (the default), e.g.',
      concept: 'Job bike',
    },
    moreInformation: {
      title: 'More information',
      text: 'You can find more details on the ESG questionnaire if you click the info icon on the bottom right of your screen.',
    },
    tour: {
      title: 'Welcome to the ESG Assessment!',
      subtitle:
        '## ESG Module quick tour.\n<br>\nThe following video provides you with an overview of how to conduct an ESG Assessment with the ImpactNexus software solution.',
      details: 'Details on the ESG assessment',
      section1: {
        title: 'Background of the assessment',
        text: '- The questionnaire is created around **15 different ESG categories** to capture the most material environmental, social, and governance topics that companies should take into account.\n- These topics have been **derived from an extensive review of existing ESG standards** for new and established companies (e.g. KfW Capital, GRI, SASB).\n- For the most part, the ESG survey is **designed to be answered by all companies** regardless of their development stage, business model, or sector.\n- **A perfect performance cannot be expected from a young company**. Users should see this assessment as a guiding tool that helps companies to **identify relevant ESG risks and areas of improvement.**',
        example:
          '# My Nested List\n\n- Item 1\n  - Subitem 1.1\n  - Subitem 1.2\n- Item 2\n  - Subitem 2.1\n  - Subitem 2.2\n- Item 3',
      },
      section2: {
        title: 'Question structure',
        text: '- For each of the 15 categories, measures are proposed to track or improve the current level of ESG performance. The **measures to track the status quo of ESG performance** are assigned to the section ""Monitoring & Evaluation"". **Measures to improve the status quo of ESG performance** are assigned to the section "Implementation".\n- Please follow the steps below when answering the questionnaire.\n  - For each section, please select the measures that are relevant to your business and indicate an implementation status. Please choose one of the following options:\n    - Doing it!\n    - Planned in the next 12 months.\n    - Not planned.\n  - Please select "We do not plan any measures" if none of the listed measures apply to your business.\n  - If relevant measures are missing, please add them to the section "Additional measures".\n  - If necessary, you can also add a comment that will be displayed later in the results overview.',
      },
      section3: {
        title: 'Scoring your ESG performance',
        text: '- The scoring is based on the measures you take or plan to take. The maximum attainable score for each of the 15 ESG categories is 20 points. For each of the three domains Environmental, Social and Governance, a company can obtain up to 100 points. The scoring is based on the following rules:\n  - **A minimum number of measures must be selected** in each section in order to receive points (”threshold”).\n  - **Measures that contribute to an improvement of the status quo score higher** than measures that serve to track the status quo.\n  - **If a measure is already implemented (”Doing it!”), the company gets a full score** and if it’s planned for the next 12 months, it gets a half score. There are no points for measures that are not planned.\n  - **If you add additional measures, you will receive bonus points.** So if you do not score any points on the proposed measures, for example, because you think that no relevant measures are listed, you can compensate for this by specifying additional measures and receiving the associated bonus points.\n - **The score ranges from 0 (lowest) to 100 (highest)**. However, it is important to point out that it is hard to obtain a score close to 100 (especially, for early-stage companies):\n   - 67-100: Good performance\n   - 34-66: Moderate performance\n   - 0-33: Critical performance',
      },
      section4: {
        title: 'Next steps',
        text: '- After evaluating the assessment, it is important to:\n  - Use the scores to **identify the most critical ESG topics**. Do not try to address all issues at once.\n  - **Research improvement levers**. Review the proposed measures and have look at the provided improvement suggestions in the ESG dashboard.',
      },
    },
    outro: {
      titleSubmit: 'Submit your results',
      haveYouFinished:
        "Have you finished your ESG assessment? You **won't be able to edit** your ESG assessment after submitting",
      submitButton: 'Submit',
      youHaveNotAnswered:
        'You have not answered all mandatory questions yet. Go back to find the missing question to complete this questionnaire. Completed sections show a checkmark. Incomplete sections have a blue progress bar.',
      answersAreAlreadySubmitted:
        'Answers are already submitted . You can not submit it again.',
      submitYourResults: 'Submit your results',
      areYouSureYouWantTo:
        'Are you sure you want to submit your data? Once you submitted your data you **will not be able to change your ESG assessment**',
      cancelButton: 'Cancel',
      submit_v2: 'Submit',
      congratulations: 'Congratulations, you finished the assessment!',
      congratulationsText: `
- You will find the results in the ESG
- **The score ranges from 0 (lowest) to 100 (highest).** However, it is important to point out that especially for early-stage companies it is hard to obtain a score close to 100
- **Use the scores to identify the most critical ESG topics.** Do not try to address all issues at once
- **Research suitable improvement levers.** You will find some improvement suggestions in the ESG tab for each of the 15 categories.`,
      continueButton: 'Continue',
      beforeYouGo: 'Before you get to the results, some quick feedback',
      helpUsImprove: 'Help us improve your experience',
      howHappyWereYou:
        'How happy were you with the module (structure, design & content)?',
      weLoveFeedback: 'We love feedback! Please share any improvement ideas.',
      shareYourFeedback: 'Please share your feedback here',
      submitFeedback: 'Submit feedback',
    },
    resultView: {
      assessmentResult: 'Assessment result for',
    },
    sectionDetailsExplanations: {
      whyYouShouldCare: 'Why you should care',
      energy:
        'The earth is running out of non-renewable energy resources, such as coal, crude oil, and natural gas. So, it is becoming more important to conserve energy and to find renewable sources of energy. Moreover, fossil energy resources are a major driver of environmental problems e.g. caused by increasing GHG emissions.',
      ghgEmissions:
        'Climate change will damage economies, devastate populations, increase resource scarcity and dramatically impact the cost of doing business. So for both humanitarian and business reasons, it is imperative that companies of all sizes take action.',
      transportation:
        'Making sustainable transportation choices reduces business expenses, reduces dependence on foreign oil, improves air quality, reduces GHG emissions, and promotes public health.',
      resourceConsumptionAndUsage:
        'Human activities have continuously put pressure on the existing resources and even caused the depletion of some. Sustainable management of resources is one of the best ways of ensuring the conservation of these invaluable resources for future generations.',
      pollutionAndEnvironmentalHarm:
        'Business activities can be extremely harmful to the environment. For example, many production processes create pollutants that cause long-term damages to the environment and all beings in it (including humans). To mitigate these damages, it is crucial to monitor all types of pollutants associated with the business activities and to mitigate them wherever possible.',
      labor:
        'Compliance with labor and human rights along the entire value chain is important from an ethical, business and legal perspective. In recent years, the expectations of customers, investors and regulators have increased, resulting in continuously increasing costs for misconduct (e.g. in the form of heavy fines and reputational damage.)',
      workplace:
        'Offering good and fair benefits packages and caring about the needs of your employees can improve employee morale. That, in turn, can improve employee motivation, increase retention and contribute to more productivity. On top of this, providing a good work environment can contribute significantly to a positive brand.',
      diversityInclusion:
        'An organization that prioritizes diversity, equity, and inclusion creates an environment that respects and values individual differences along varying dimensions. Such a work environment leads to better cooperation between employees, increases retention and contributes to more productivity.',
      societalHarm:
        'Businesses with their products, technologies and business models can have adverse effects on their customers, users and society at large. Accordingly, it is crucial that businesses behave responsibly and adjust their practices to avoid risks and optimize societal welfare.',
      socialResponsibility:
        'Social responsibility can help customers see your company as a positive force in society, shows accountability to investors and help attract & retain employees. The projects you undertake can help raise awareness for important causes.',
      data: 'All companies have data, such as personnel files, customer data, product information, financial transactions, etc. Following proper data protection procedures is crucial to help prevent cybercrimes, data leaks, and data losses. On top of this, there are more and more fines for inadequate behaviors (e.g. related to the GDPR).',
      stakeholderEngagement:
        "Stakeholder engagement helps organizations to proactively consider the needs and desires of anyone who has a stake in their organization, which can foster connections, trust, confidence, and buy-in for your organization's key initiatives",
      businessEthics:
        'Unethical behaviors can quickly translate into harm for businesses as well as society. Accordingly, it is crucial to enable all employees in a company to behave ethically. This can mean designing a clear code of conduct with guidelines for ethical decision-making, but more importantly, all employees must be empowered to behave adequately (e.g. through training and suitable company culture).',
      managementAdvisoryBoard:
        'Having a diverse management team & advisory board provides the opportunity to make better business decisions. Diverse teams bring more experiences and perspectives to the table allowing for better identification of risks and more innovative ideas.',
      regulationsStandards:
        'For the long-term success of a company, it is central to take relevant regulations and standards into account. As a first step, compliance with relevant regulation is of greatest relevance. However, in many industries it is almost equally important to comply with industry standards to win customers and investors. For regulation as well as standards, sustainability is becoming increasingly relevant. There are more and more sustainability regulation on issues like CO2, supply chain transparency or data processing. At the same time, customers and investors increasingly expect companies to establish a dedicated sustainability management and to distinguish themselves through recognized labels and certificates. ',
      unGlobalCompactPrinciples: `The UN Global Compact is a non-binding United Nations pact. It is a principles-based framework for business with ten key principles. For the assessment, we ask companies to report on the overarching topics of human rights, labor, the environment and anti-corruption. The full 10 principles are:

#### Human Rights

- [Principle 1](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-1): Businesses should support and respect the protection of internationally proclaimed human rights; and
- [Principle 2](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-2): make sure that they are not complicit in human rights abuses.

#### Labour

- [Principle 3](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-3): Businesses should uphold the freedom of association and the effective recognition of the right to collective bargaining;
- [Principle 4](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-4): the elimination of all forms of forced and compulsory labour;
- [Principle 5](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-5): the effective abolition of child labour; and
- [Principle 6](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-6): the elimination of discrimination in respect of employment and occupation.

#### Environment

- [Principle 7](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-7): Businesses should support a precautionary approach to environmental challenges;
- [Principle 8](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-8): undertake initiatives to promote greater environmental responsibility; and
- [Principle 9](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-9): encourage the development and diffusion of environmentally friendly technologies.


#### Anti-Corruption

- [Principle 10](https://www.unglobalcompact.org/what-is-gc/mission/principles/principle-10): Businesses should work against corruption in all its forms, including extortion and bribery.

Further information can be found here: [https://www.unglobalcompact.org/what-is-gc/mission/principles](https://www.unglobalcompact.org/what-is-gc/mission/principles))
  `,
      controversialBusinessAreasActivities: `
Transparency about controversial business areas & activities is very important to be considered a sustainable business. However, being active in a controversial domain does not automatically mean that the activity is negative. Often the specific context and subjective preferences play an important role. So please take into account the company specific explanations.

The selected options consolidate different exclusion criteria from major actors in the field. See e.g.:

- IFC (link: [http://www.ifc.org/exclusionlist](http://www.ifc.org/exclusionlist))
- KfW Capital (link: [https://www.kfw.de/PDF/Download-Center/Konzernthemen/Nachhaltigkeit/Ausschlussliste_EN.pdf](https://www.kfw.de/PDF/Download-Center/Konzernthemen/Nachhaltigkeit/Ausschlussliste_EN.pdf))
- FNG (link: [https://www.fng-siegel.org/media/downloads/FNG_Label_2021-Rules_of_Procedure.pdf](https://www.fng-siegel.org/media/downloads/FNG_Label_2021-Rules_of_Procedure.pdf))`,
      publicOrLegalControversies:
        'Being transparent about public complaints or legal actions is crucial to be considered a sustainable business. It is important to note that being involved in controversy does not automatically mean being guilty of misconduct. There can be false claims, which require rectification. If the controversies are justified it is important to clarify how future controversies will be avoided.',
      oecdGuidelinesForMultinationalEnterprises: `
The [**OECD Guidelines for Multinational Enterprises**](https://www.oecd-ilibrary.org/governance/oecd-guidelines-for-multinational-enterprises_9789264115415-en) are recommendations addressed by governments to multinational companies operating in or from adhering countries. They provide **voluntary principles and standards for responsible business conduct** in areas such as employment and industrial relations, human rights, environment, information disclosure, combating bribery, consumer interests, science and technology, competition, and taxation.

The OECD Guidelines are not aimed at introducing differences of treatment between **multinational and domestic companies**; they reflect good practice for all. The OECD Guidelines are not legally binding on companies, but they are binding on signatory governments, which are required to ensure the Guidelines are implemented and observed. While it is acknowledged that **small- and medium-sized companies** may not have the same capacities as larger companies, governments adhering to the Guidelines nevertheless encourage them to observe the Guidelines' recommendations to the fullest extent possible.`,
      unGuidingPrinciplesOnBusinessAndHumanRights: `
The **UN Guiding Principles on Business and Human Rights**

The **[UN Guiding Principles on Business and Human Rights](http://www.ohchr.org/Documents/Publications/GuidingPrinciplesBusinessHR_EN.pdf)** are a set of guidelines for states and companies **to prevent, address and remedy human rights abuses committed in business operations**.

The **principles apply to all states and to all business enterprises**, both transnational and others, regardless of their size, sector, location, ownership, and structure.

In this context, the responsibility to respect human rights requires that business enterprises:

- Avoid causing or contributing to adverse human rights impacts through their own activities, and address such impacts when they occur;
- Seek to prevent or mitigate adverse human rights impacts that are directly linked to their operations, products, or services by their business relationships, even if they have not contributed to those impacts

**The ILO conventions**

The principles and rights set out in the **eight fundamental conventions** identified in the Declaration of the International Labour Organisation on Fundamental Principles and Rights at Work and the International Bill of Human Rights cover subjects that were considered to be fundamental principles and rights at work:

- Freedom of association and the effective recognition of the right to collective bargaining
- The elimination of all forms of forced or compulsory labour
- The effective abolition of child labour
- The elimination of discrimination in respect of employment and occupation
    `,
      paiIndicators: `The principle adverse impacts indicators (PAI indicators) are a list of criteria to quantify the sustainability performance of financial products (e.g. investment funds) and their investments (e.g. companies) that the European Supervisory Authorities (ESAs) defined in [Annex I](https://ec.europa.eu/finance/docs/level-2-measures/C_2022_1931_1_EN_annexe_acte_autonome_part1_v6.pdf) to the Sustainable Finance Disclosure Regulation (SFDR). `,
    },
    scoring: {
      totalPoints:
        'Total points for the question are calculated as the sum of the category scores with a maximum of {maxValue} points.',
      monitoringEvaluation: 'Monitoring & Evaluation',
      implementation: 'Implementation',
      additionalMeasures: 'Additional measures',
      youCanObtain: 'You can obtain bonus points for custom measures:',
      category: 'Category',
      pointsImplemented: '{points} points for each implemented measure and',
      pointsPlanned: '{points} for each planned measure',
      maximumPoints: 'Maximum points for the category: {points}',
    },
    questionnaire: {
      clickOnceOrTwice:
        "Click once for if you are doing the measure already and twice if plan to implement it in the next 12 months. If you don''t want to implement any measures click the checkbox at the bottom.",
      additionalMeasuresTitle: 'Additional measures',

      additionalMeasures:
        'If your company implements additional ESG measures that are not listed above, you can define custom measures and associated activities here',
      createNewMeasure: 'Create new measure and press enter to confirm.',
      weDoNotPlanAnyMeasures:
        'We do not plan any measures. This ESG section is not relevant to our business.',
      mandatory: 'This question is mandatory',
      optional: '(Optional)',
      pleaseExplainWhyYouDoNotPlan:
        'Please explain why you do not plan to implement any measures in the text field below.',

      schemes: {
        monitoringEvaluation: {
          title: 'Monitoring & Evaluation',
          description: 'Keep track of the status quo',
        },
        implementation: {
          title: 'Implementation',
          description: 'Improve the status quo by taking action',
        },
        reductionSubstitution: {
          title: 'Substitution & Reduction',
          description:
            'Improve the status quo by finding better alternatives or minimizing your avoidable negative impact',
        },
        compensation: {
          title: 'Compensation',
          description:
            'Improve the status quo by mitigating your unavoidable negative impact',
        },
        additionalMeasures: {
          title: 'Additional measures',
          description:
            'Add custom measures and their implementation status in addition to the proposed ones',
        },
      },
    },
    surveyPages: {
      disclosureSubsectionScoreCritical: 'Critical',
      disclosureSubsectionScoreGood: 'Good',
      skipped: 'Excluded',
      good: 'Good',
      moderate: 'Moderate',
      critical: 'Critical',
      questionAdditionalInformation: 'Additional information',
      questionScoringRules: 'Scoring rules',
      notAvailable: 'N/A',
    },
    sectionDetails: {
      ghgEmissions: {
        leversComments: {
          c1: 'Guide helps companies to prepare a GHG emissions inventory (identify, track, calculate and report GHG emissions).',
          c2: 'Scope 3 emissions Standard\n\nallows companies to assess their entire value chain emissions impact and identify where to focus reduction activities.',
          c3: 'Technical Guidance for Calculating Scope 3 Emissions',
          c4: 'Guidance for Scope 2 emissions: standardizes how organisations measure emissions from purchased or acquired electricity, steam, heat, and cooling',
          c5: 'GHG accounting tool for carbon offset projects.',
          c6: '9 steps companies can take to reduce business travel emissions',
          c7: 'How to Undertake a Life Cycle Assessment of Your Products and Services',
          c8: 'Steps companies can take to offset carbon emissions.',
          c9: 'Ways to Make Factories Eco-friendly',
          c10: 'How to select a sustainability-conscious supplier',
          c11: 'How to select eco-conscious suppliers',
          c12: 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        },
      },
      energy: {
        leversComments: {
          c1: 'Energy management system Guide for companies - from the German Federal Ministry for the Environment, Nature Conservation and Nuclear Safety (BMU)',
          c2: 'Practical Guide for implementing an energy management system - from the United Nations Industrial Development Organization (UNIDO)',
          c3: '10 Strategies for Energy management',
          c4: '7 Strategies To Make Your Business More Energy Efficient',
          c5: 'How companies can conduct energy audit.',
        },
      },
      transportation: {
        leversComments: {
          c1: 'Guide of how to implement a remote working strategy.',
          c2: '9 steps companies can take to reduce business travel emissions',
          c3: '7 top tips to travel more sustainable/greener for business',
          c4: 'How to select a sustainability-conscious supplier',
          c5: 'How to select eco-conscious suppliers',
          c6: 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        },
      },
      resourceConsumptionAndUsage: {
        leversComments: {
          c1: 'How to Undertake a Life Cycle Assessment of Your Products and Services',
          c2: '6 Steps to More Effective Water Conservation for Businesses',
          c3: 'How New Businesses Can Improve Their Waste Management',
          c4: 'Top 20 tips to reduce waste in the workplace',
          c5: 'Step-by-Step Guide to Sustainable Supply Chain Management from the German Federal Ministry for the Environment, Nature Conservation, Building and Nuclear Safety (BMUB)',
          c6: 'A guide for business and policy makers to Life Cycle Thinking and Assessment from the EU Commission for Environment',
          c7: 'How to select a sustainability-conscious supplier',
          c8: 'How to select eco-conscious suppliers',
        },
      },
      pollutionAndEnvironmentalHarm: {
        leversComments: {
          c1: 'How to Undertake a Life Cycle Assessment of Your Products and Services',
          c2: 'Steps companies can take to offset carbon emissions.',
          c3: 'A guide for business and policy makers to Life Cycle Thinking and Assessment from the EU Commission for Environment',
          c4: 'How Businesses Can Improve Biodiversity',
          c5: 'Here are 5 ways companies can clean up the air',
          c6: 'How businesses can improve environmental impact consciousness',
          c7: 'How businesses can become more eco-conscious',
          c8: 'How to green your business by preventing pollution',
          c9: '5 Easy Ways Manufacturers Can Reduce Air Pollution',
          c10: '7 recommendations for incorporating Indigenous perspectives into Biodiversity considerations.',
          c11: 'Tips & recommendations for companies on how to choose eco-conscious suppliers',
        },
      },
      labor: {
        leversComments: {
          c1: "The best OECD's practices regarding employment.",
          c2: 'UN Guide of how companies should adress and respect human rights.',
          c3: 'Highlights the main international agreements regarding wages.',
          c4: 'International Labor Organization guidelines about minimum wage, living wage and fair wage.',
        },
      },

      workplace: {
        leversComments: {
          c1: 'Guide of how to implement a remote working strategy.',
          c2: 'Interesting and basic tool that may help companies figure out the fair wage in a specific country.',
          c3: 'List of the best employee benefits package.',
          c4: 'A guide to employee benefits.',
          c5: 'Insteresting guideline of how to create a home office policy with tools, templates and samples.',
          c6: 'How to adress mental health issues at the workplace policy.',
          c7: 'Fair pay must be determined for the specific context of a company (i.e. geography, sector, types of jobs, sector benchmarks)',
          c8: 'Measuring employee engagement is crucial to staying up to date with your staff’s needs and this blog gives some insight into this',
          c9: 'Highlights the main international agreements regarding wages.',
          c10: 'International Labor Organization guidelines about minimum wage, living wage and fair wage.',
        },
      },
      diversityInclusion: {
        leversComments: {
          c1: 'How to add Age in your diversity and inclusion policy',
          c2: 'Taking a closer look at diversity winners reveals what can drive real progress in the diversity aspects of your company.',
          c3: 'A substantial diversity and inclusion strategy can your company to attain top talent, driving innovative outcomes.',
          c4: 'The main requirements to really have a diverse and inclusive culture in your company',
          c5: 'How to have a disability inclusive workplace',
          c6: 'How to remove biases, apply fair paying and focus on the results and creating an inclusing hiring process to promote gender equality in the workplace.',
        },
      },
      societalHarm: {
        leversComments: {
          c1: "Strategies to address 'Fake News' as a company.",
          c2: 'Understanding the benefits of personalized pricing and creating consumer welfare to your company.',
          c3: 'How to separate your market into discrete customer groups.',
          c4: 'A guide that explains why quality matter in business success.',
          c5: 'Understanding how product quality helps companies earn customer loyalty, establish brand and manager their costs.',
          c6: 'Smart strategies of how to price your products to attract customers.',
          c7: 'A guide about fair trade price and the benefits of applying it.',
          url: 'https://www.investopedia.com/terms/f/fair-trade-price.asp',
          c8: 'Guide about how to fairly price your product.',
          c9: 'How and when a consumer contract is made, and how to be sure your transactions are legal and fair',
          c10: "How ethical sales can increase customer and team satisfaction and contribute to a company's growth.",
        },
      },
      socialResponsibility: {
        leversComments: {
          c1: 'Reasons why Corporate Social Advocacy is good for your brand',
          c2: 'Four simple ways to support local communities.',
        },
      },
      data: {
        leversComments: {
          c1: 'Deeper understanding on data privacy and regulatory practices',
          c2: 'Easier to understand technical things like consensual data collection through blogs like this that simplify the information',
          c3: 'Understanding GDPR and its guidelines to ensure fair and lawful usage of data',
          c4: 'What principles are used for fair usage and collection of data',
          c5: 'A guide on how to ensure restricted access to data',
          c6: 'Blog on understanding how to protect data and restrict access',
          c7: 'Helpful in understanding data security, protection and privacy for companies/organisations',
          c8: 'Deeper understanding of GDPR, which is essential for all organisations, particularly in the EU',
        },
      },
      stakeholderEngagement: {
        leversComments: {
          c1: 'Measuring employee engagement is crucial to staying up to date with your staff’s needs and this blog gives some insight into this',
          c2: 'Gives a comprehensive understanding of how effective community engagement can happen, what it entails and why stakeholder engagement is crucial',
          c3: 'Helpful in getting a comprehensive understanding of what stakeholder management',
          c4: 'Important to understand how to strengthen relationship with customers and users and what tools can be used',
        },
      },
      businessEthics: {
        leversComments: {
          c1: "How ethical sales can increase customer and team satisfaction and contribute to a company's growth.",
          c2: 'Understanding the Tax Management System and a guide to how to go about it',
          c3: 'A detailed paper on what can be done to protect whistleblowers and what rights and benefits can be provided to them',
          c4: 'Blog on understanding sexual harassment, how it can be avoided and what mechanisms companies can have in place',
          c5: 'Blog on ways to prevent harassment at workplace',
          c6: 'Blog protecting whistleblowers from retaliation in a workplace',
          c7: 'A blog to explain different ways to protect intellectual property',
          c8: 'Ways to protect intellectual property',
          c9: 'Anti corruption measures and policies that companies can imbibe within their strategies and operations',
          c10: '10 ways to reduce the risks of corruption and bribery in organisations',
        },
      },
      managementAdvisoryBoard: {
        leversComments: {
          c1: 'Tips to improve gender diversity in an organisation',
          c2: '5 key principles that should be the foundation of gender diversity strategies',
          c3: 'How to ensure cultural diversity in your organisation',
          c4: 'An article on why age diversity is important and how it can be ensured',
          c5: 'A report on managing age diversity and how organisations can go about it',
          c6: 'Nine action steps the researchers suggest for employers to take to strengthen age diversity and inclusion in the workplace',
          c7: 'Strategies to help organisations ensure cultural and ethnic diversity at workplace',
          c8: 'Ways to ensure and improve gender diversity in a workplace',
          c9: 'Five strategies to promote diversity in workplace',
        },
      },
      regulationsStandards: {
        leversComments: {
          c1: 'Why ESG is important for organisations',
          c2: 'A guide on integrating ESG in businesses',
          c3: 'A guide on how to identify regulations and laws that need to be complied with',
          c4: 'How To Ensure Compliance In The Workplace: 9 Tips',
          c5: "Steps to initiate a compliance program \n(Since this is a company page that is propagating its own services, I wasn't sure if they would be considered competition but it's helpful to see the steps they suggested anyway)",
        },
      },
      gris: {
        gri_102: 'General Disclosures',
        gri_201: 'Economic Performance',
        gri_205: 'Anti-corruption',
        gri_301: 'Materials',
        gri_302: 'Energy',
        gri_305: 'Emissions',
        gri_306: 'Waste',
        gri_403: 'Promotion of worker health',
        gri_405: 'Diversity & Equal Opportunity',
        gri_409: 'Forced or Compulsory Labor',
        gri_418: 'Customer Privacy',
        // gri_409: 'Occupational Health and Safety',
      },
      sdgs: {
        sdg_3: 'Good Health and Well-Being',
        sdg_5: 'Gender Equality',
        sdg_7: 'Affordable and Clean Energy',
        sdg_8: 'Decent Work and Economic Growth',
        sdg_9: 'Industry, Innovation and Infrastructure',
        sdg_12: 'Responsible Consumption and Production',
        sdg_13: 'Climate Action',
        sdg_14: 'Life Below Water',
        sdg_15: 'Life on Land',
        sdg_16: 'Peace Justice and Strong Institutions',
        sdg_17: 'Partnerships for the Goals',
      },
      kpis: {
        ghgEmissions: {
          kpi_1:
            'Number of GHG emissions (ideally split into the GHG Protocol Scopes 1, 2 and 3)',
        },
        energy: {
          kpi_1: 'Overall energy consumption per year',
          kpi_2: 'Amount/Percentage of renewable energy produced/utilized',
        },
        transportation: {
          kpi_1: 'Number of trips by mode of transportation',
          kpi_2:
            'Amount/Percentage of renewable energy produced/utilizedTotal number of kms travelled/good transported for each mode of transportation',
        },
        resourceConsumptionAndUsage: {
          kpi_1: 'Amount of waste created',
          kpi_2: 'Amount of waste recycled',
          kpi_3: 'Amount of reused material procured',
          kpi_4: 'Amount of refurbished/recycled products procured',
        },
        pollutionAndEnvironmentalHarm: {
          kpi_1: 'Amount of pollution generated for each type of pollutant',
        },
        labor: {
          kpi_1: 'Percentage of suppliers screened',
          kpi_2: 'Number of grievances filed, addressed and resolved',
        },
        workplace: {
          kpi_1:
            'Number of flexible work arrangements broken down by types of arrangements',
          kpi_2: 'Total value of social & health related benefits per employee',
          kpi_3: 'Number of employees earning minimum wage',
          kpi_4: 'Number of employees earning a living wage',
          kpi_5: 'Wage gap between different employees of a company',
        },
        diversityInclusion: {
          kpi_1: 'Percentage of female/disabled/ethnic employees',
          kpi_2: 'Ratio of female to male employees',
        },
        societalHarm: {
          kpi_1: 'Net promoter score',
          kpi_2: 'Social value creation',
        },
        socialResponsibility: {
          kpi_1:
            'Average time spent by employees for social responsibility initiatives',
          kpi_2:
            'Number of social responsibility initiatives broken down by type of initiative ',
        },
        data: {
          kpi_1: 'Number of data losses/leaks identified',
          kpi_2: 'Number of threats identified and resolved',
        },
        stakeholderEngagement: {
          kpi_1: 'Number of stakeholder meetings arranged per year',
          kpi_2:
            'Number of stakeholder feedbacks obtained broken down by type of stakeholder',
        },
        businessEthics: {
          kpi_1:
            'Share of employees trained, broken down by relevant ethical domains in the code of conduct like non-discrimination, anti-bribery or whistleblowing',
        },
        managementAdvisoryBoard: {
          kpi_1:
            'Share of management team or advisory board members by gender, cultural or ethnical background, age, disability or expertise',
          kpi_2: 'Number of board meetings held per year',
        },
        regulationsStandards: {
          kpi_1: 'Number of lawsuits or legal complaints',
          kpi_2: 'Type and number of quality and industry standards obtained',
          kpi_3: 'Type and number of sustainability certifications obtained',
        },
        ghgEmissions_v2: {
          kpi_1:
            'Number of GHG emissions (ideally split into the GHG Protocol Scopes 1, 2 and 3)',
        },
        energy_v2: {
          kpi_1: 'Overall energy consumption per year',
          kpi_2: 'Amount/Percentage of renewable energy produced/utilized',
        },
        transportation_v2: {
          kpi_1: 'Number of trips by mode of transportation',
          kpi_2:
            'Amount/Percentage of renewable energy produced/utilizedTotal number of kms travelled/good transported for each mode of transportation',
        },
        resourceConsumptionAndUsage_v2: {
          kpi_1: 'Amount of waste created',
          kpi_2: 'Amount of waste recycled',
          kpi_3: 'Amount of reused material procured',
          kpi_4: 'Amount of refurbished/recycled products procured',
        },
        pollutionAndEnvironmentalHarm_v2: {
          kpi_1: 'Amount of pollution generated for each type of pollutant',
        },
        labor_v2: {
          kpi_1: 'Percentage of suppliers screened',
          kpi_2: 'Number of grievances filed, addressed and resolved',
        },
        workplace_v2: {
          kpi_1:
            'Number of flexible work arrangements broken down by types of arrangements',
          kpi_2: 'Total value of social & health related benefits per employee',
          kpi_3: 'Number of employees earning minimum wage',
          kpi_4: 'Number of employees earning a living wage',
          kpi_5: 'Wage gap between different employees of a company',
        },
        diversityInclusion_v2: {
          kpi_1: 'Percentage of female/disabled/ethnic employees',
          kpi_2: 'Ratio of female to male employees',
        },
        societalHarm_v2: {
          kpi_1: 'Net promoter score',
          kpi_2: 'Social value creation',
        },
        socialResponsibility_v2: {
          kpi_1:
            'Average time spent by employees for social responsibility initiatives',
          kpi_2:
            'Number of social responsibility initiatives broken down by type of initiative ',
        },
        data_v2: {
          kpi_1: 'Number of data losses/leaks identified',
          kpi_2: 'Number of threats identified and resolved',
        },
        stakeholderEngagement_v2: {
          kpi_1: 'Number of stakeholder meetings arranged per year',
          kpi_2:
            'Number of stakeholder feedbacks obtained broken down by type of stakeholder',
        },
        businessEthics_v2: {
          kpi_1:
            'Share of employees trained, broken down by relevant ethical domains in the code of conduct like non-discrimination, anti-bribery or whistleblowing',
        },
        managementAdvisoryBoard_v2: {
          kpi_1:
            'Share of management team or advisory board members by gender, cultural or ethnical background, age, disability or expertise',
          kpi_2: 'Number of board meetings held per year',
        },
        regulationsStandards_v2: {
          kpi_1: 'Number of lawsuits or legal complaints',
          kpi_2: 'Type and number of quality and industry standards obtained',
          kpi_3: 'Type and number of sustainability certifications obtained',
        },
      },
    },
    comparison: {
      current: 'Current',
      previous: 'Previous',
      latestPerformanceComparedToPrevious:
        'Latest performance compared to previous ESG assessment',
      latestPerformanceComparedToIxBenchmark:
        'Latest performance compared to ImpactNexus benchmark',
      latestPerformanceComparedToBenchmarks:
        'Latest performance compared with benchmarks',
      differenceToPrevious: 'Difference to previous',
      portfolioAverage: 'Difference to portfolio average',
      ixAverage: 'Difference to ImpactNexus ESG benchmark',
      comparisonToPreviousVersion:
        'ESG performance comparison to previous version',
      itIsNotPossible:
        'It is not possible to compare the selected questionnaire with the previous version. Check if a previous version exists and if both questionnaires have been submitted.',
      commentsTitle: 'Comments for PDF export',
      commentsText:
        'Do you want to comment what contributed to the changes in the ESG scores? This will be shown in the pdf export.',
      commentsEnvironmental: 'Comment on environmental dimension',
      commentsSocial: 'Comment on social dimension',
      commentsGovernmental: 'Comment on governmental dimension',
      errorMessage:
        'An error occurred. If it persists after a browser refresh, please click the feedback icon on the top right to report a bug.',
    },
  },
  companyPerspective: {
    registrationPage: {
      impactIntelligence: 'Intelligent Sustainability Management',
      registration: 'Registration',
      portfolioOwnerHasRequestedYou:
        '<b>{company}</b> has requested you to register following modules with ImpactNexus.',
      selfRegister:
        '<b>{company}</b> - Please register here to use the following tools:',
      teamHasInvitedYou: '<b>{inviter}</b> has invited you to join the team',
      youAreRegisteredFor: 'You are registered for the',
      esgModule: 'ESG module',
      theToken: 'The token',
      theImpactToken: 'The Impact token',
      esgDidNotMatch:
        'for the registration to the ESG module did not match. Please contact your portfolio owner or the ImpactNexus support.',
      impactGoalModule: 'Impact goal module',
      impactGoalDidNotMatch:
        'for the registration to the Impact goal module did not match. Please contact your portfolio owner or the ImpactNexus support.',
      metricsModuleDidNotMatch:
        'for the registration to the Metrics module did not match. Please contact your portfolio owner or the ImpactNexus support.',
      impactModuleDidNotMatch:
        'for the registration to the Impact module did not match. Please contact your portfolio owner or the ImpactNexus support.',
      kpiModule: 'KPI module',
      impactModule: 'GHG & Impact Estimator',
      firstName: 'First Name',
      enterFirstName: 'Enter First Name',
      firstNameIsRequired: 'First Name is required',
      firstNameMustBeLessThan: 'First Name must be less than 25 characters',
      lastName: 'Last Name',
      enterLastName: 'Enter Last Name',
      lastNameIsRequired: 'Last Name is required',
      lastNameMustBeLessThan: 'Last Name must be less than 25 characters',
      email: 'E-mail',
      enterEmail: 'Enter E-mail',
      emailIsRequired: 'E-mail is required',
      emailMustBeValid: 'E-mail must be valid',
      password: 'Password',
      enterPassword: 'Enter Password',
      passwordIsRequired: 'Password is required',
      passwordMustContain:
        'Password must contain at least one lowercase letter, one number and one uppercase letter',
      confirmPassword: 'Confirm Password',
      confirmPasswordPlaceholder: 'Confirm Password',
      confirmNewPassword: 'Confirm new password',
      theTwoPasswordsDoNotMatch: 'The two passwords do not match',
      companyName: 'Company name',
      enterCompanyName: 'Enter company name',
      companyNameIsRequired: 'Company Name is required',
      companyNameMustBeLessThan: 'Company Name must be less than 25 characters',
      companyUrl: 'Company URL',
      enterCompanyUrl: 'Enter company URL',
      urlIsNotValid: 'URL is not valid',
      companyLogo: 'Company logo',
      selectCompanyImages: 'Select your company logo to upload',
      uploadedSuccessfully: 'Uploaded Successfully',
      failedToUpload: 'Failed to upload',
      agreeTermsAndConditions: 'I agree to the terms & conditions',
      iAcceptSharingData:
        'I hereby provide my consent for the sharing of my data with: <b>{company}</b>',
      byAccepting:
        'By accepting, you agree that {company} gets access to the assessment data of your venture',
      register: 'Sign up',
      youDidNotPass:
        "You did not pass the necessary tokens in the URL for a valid registration. Please check your registration link again or <a href='mailto:help@impactnexus.io'>email us</a>.",
      checkingValidityOfUrls: 'Checking validity of URL tokens...',
      thankYouForRegisetring:
        'Thank you for registering with ImpactNexus. We have sent you an email to activate your account.',
      itMightTakeFewMinutes:
        'In some cases it may take a few minutes until you can access your account.',
      backMainPage: 'Login',
      agreeToContinue: 'Agree to continue',
      filesCount: '{n} files',
    },
    homePage: {
      noVentures: 'No ventures invited yet',
      noCompaniesInPortfolio:
        "Thank you for registering with ImpactNexus. It appears you have no companies in your portfolio. You can invite them by clicking on the 'Invite portfolio companies' button in the left sidebar.",
    },
    kpis: {
      tooltipEditNotAllowed: 'You can only edit your own KPIs',
      indicatorSets: 'Indicator sets',
      searchForKpis: 'Search for KPIs',
      addSelectedApis: 'Add selected KPIs',
      noResults: 'No results, change the search term or the filters',
      enableIndicator:
        'Enable indicator sets on the left or enter a different search term above',
      nameColumn: 'Name',
      unitColumn: 'Unit',
      typeColumn: 'Type',
      taxonomyColumn: 'Taxonomy',
      assignedToColumn: 'Assign To',
      editColumn: 'Edit',
      editKpis: 'Edit',
      addKpis: 'Add',
      updateKpis: 'Update',
      kpisToYourAccount: 'Assign the selected KPIs',
      kpisToTrack: 'KPIs to track',
      partsOfThisFormCannotBeEdited:
        'Parts of this form cannot be edited because the KPI template was not created by your account (e.g. if it belongs to the ImpactNexus KPI database). You may still edit your account-specific parts of this form, like comments.',
      goal: 'Goal',
      goalNone: 'None',
      goalReduce: 'Reduce',
      goalIncrease: 'Increase',
      goalApproximate: 'Approximate',
      addPotentialCategories: 'Add potential categories for this metric',
      createCategoryInstruction:
        'Press <kbd>enter</kbd> to create the category.',
      comment: 'Comment',
      searchPredefinedKpis: 'Predefined KPI',
      defineCustomKpi: 'Custom KPI',
      overviewOfKpis: 'Overview of KPIs',
      headers: {
        kpi: 'KPI',
        relatedFrameworks: 'Related frameworks',
        goal: 'Goal',
        fund: 'Fund',
        unit: 'Unit',
        targetProgress: 'Target progress',
        impact: 'Impact',
        values: 'Values',
        actions: 'Actions',
        comments: 'Comments',
        group: 'Group',
        company: 'Company',
      },
      thereWasAProblem: 'There was a problem and the last action was aborted.',
      customKpis: 'Custom KPIs',
      yourTarget: 'Your target',
      actual: 'actual',
      target: 'target',
      recordActualKpi:
        'Record actual KPI values by clicking on the numbers or at the ⋯ icons in the table.',
      recordTargetKpi:
        'Record target KPI values by clicking the numbers or ⋯ icons in the table.',
      editKpi: 'Edit KPI',
      deleteKpi: 'Delete KPI',
      addComment: 'Add comment',
      deletingKpi: 'Deleting KPI',
      areYouSureDeleteKpi: 'Are you sure you want to delete the KPI',

      tour: {
        welcome: 'Welcome to the KPI Module!',
        quickTour: 'KPI Module quick tour',
        theFollowingVideo:
          'The following video provides you with an overview of all important functions of the Key Performance Indicator (KPI) module of the ImpactNexus software solution.',
        howToReport: 'How to report on PAI indicators?',
        theFollowingVideo2:
          'The following video provides you with an overview of all important functions of the Principle Adverse Impact (PAI) indicator module of the ImpactNexus software solution. The PAI indicators are a critical element of the Sustainable Finance Disclosure Regulation (SFDR) reporting.',
        paiIndicators: `### How to start with the PAI indicators?

In a nutshell:
Venture Capital and Private Equity funds that are classified as Article 8+ or Article 9 under SFDR need to report on 14 mandatory PAI indicators plus 2 additional PAI indicators.

In the “KPIs” module of the ImpactNexus software, open the “SFDR” section and you will see all data that is needed to report on the PAIs. You can see further information on each PAI by clicking on the grey info circle icon next to the name of the PAI. In the popup, you will find a “Description” and “Usage guidance” section to give you more information on the PAI.

Data entry happens in the column for the respective time period. By clicking on the 3 dots, a popup will provide further entry options. Comments can be provided back to the fund manager for each PAI in general, or on the specific data point in the popup.

For feedback to ImpactNexus, please use the button on the top right.`,
        kpiModuleInfoVentureQuickTour: `### How to start with the KPI Module?

In a nutshell:

The module gives an overview of all KPIs assigned to you by your fund manager as well as your self-selected KPIs.

The KPIs are sorted by different sections e.g. “Custom-KPIs, “IRIS + IMP Taxonomy” or “SFDR”. By clicking on the sections, you can roll out the list of company-level metrics for which you shall provide data.

You can see all information on a KPI by clicking on the grey info circle icon next to the name of the KPI. In addition to the general information on the KPI, you can see the plot depicting the performance of the chosen metric over the reporting period. You can edit custom KPIs but you are not able to edit assigned KPIs (e.g., the description or the unit).

For each KPI you will see the unit, target progress, and values by year. You yourself can choose the target values for the Custom KPIs. However, you cannot change them for the KPIs which have been assigned to you by the portfolio manager.

The KPI module also provides for *adding new KPIs*. To get guidance on this, please hover over the corresponding buttons: “Define custom KPIs” and “Search predefined KPIs”.

- ** + Predefined KPI:** In this section, you can choose the KPIs from the given “Indicator Sets”. Namely, IRIS Indicators referring to the Impact Reporting and PAI company data referring to SFDR Reporting. Usually, the fund managers assign KPIs from these sets to the portfolio companies. However, it is also possible for companies to choose additional KPIs they want to track.
- ** + Custom KPI:** If there is a custom KPI that you want to track in your company, click on this button and provide a name and a unit for the KPI. Additionally, you can write a description, set a goal, and leave comments.`,
      },
    },
    tasks: {
      defineTask: 'Define task',
      headers: {
        task_label: 'Task',
        task_goal: 'Goal (why)',
        venture_name: 'Company',
        task: 'Task',
        goalWhy: 'Goal (why)',
        origin: 'Origin',
        startDate: 'Start date',
        dueDate: 'Due date',
        endDate: 'End date',
        status: 'Status',
        comments: 'Comments',
        updatedAt: 'Updated at',
        actions: 'Actions',
      },
      typeToFilterRows: 'Type to filter rows',
      taskOverview: 'Task overview',
      add: 'Add',
      edit: 'Edit',
      task: 'task',
      capitalTask: 'Task',
      whatAreYouPlanning: 'What are you planning to do?',
      whatIsThePurpose:
        'What is the purpose of this task, what do you want to achieve?',
      partsOfThisFormCanNotBeEdited:
        'Parts of this form cannot be edited because the task template was not created by your account. You may still edit your account-specific parts of this form.',
      howWasThisTaskCreated: 'How was this task created?',
      whatIsTheCurrentProgress: 'What is the current progress of this task?',
      update: 'Update',
      editTask: 'Edit task',
      deleteTask: 'Delete task',
      areYouSureDelete: 'Are you sure you want to delete the task',
      tasksOverview:
        'The following **video** provides you with an overview of all the important functions of the Task Module of the ImpactNexus software solution.',
      tasksOverviewTitle: 'Welcome to the Task Module!',
    },
  },
  feedback: {
    title: 'Feedback',
    helpUsImprove: 'Help us improve your experience',
    text: 'Problems?\n* We are sorry if something does not work yet and we are happy to fix it! Let us know what happened and we will come back to you as soon as we can\n\nImprovement suggestions?\n* We love feedback! Please let us know what you like and how we can improve',
    yourFeedback: 'Your feedback',
    submitFeedback: 'Submit',
    thanks: 'Danke für Ihre Rückmeldung!',
  },
  database: {
    question: {
      pitchLine: {
        label: 'Pitch line',
        sublabel:
          'Describe the value proposition of your venture in one sentence',
        tooltip: '',
      },
      legalEntity: {
        label: 'Legal entity',
        sublabel:
          'If applicable: What is your legal form (e.g. GmbH, e.G., Ltd.)?',
        tooltip: '',
      },
      founders: {
        label: 'Founders',
        sublabel:
          'Name the founders of your company (first and last name). Confirm each entry with enter.',
        tooltip: '',
      },
      headquarter: {
        label: 'Headquarter',
        sublabel: 'Please enter the city your headquarter is situated.',
        tooltip: '',
      },
      country: {
        label: 'Country',
        sublabel: 'Please enter the country your headquarter is situated.',
        tooltip: '',
      },
      foundingYear: {
        label: 'Founding year',
        sublabel:
          'If applicable: When has your organization been created as a legal entity?',
        tooltip: '',
      },
      employeeHeadcountPlannedForThisYear: {
        label: 'Employee headcount (planned for this year)',
        sublabel:
          'Please indicate the number of regular employees, without freelancers or interns',
        tooltip: '',
      },
      turnoverPlannedForThisYear: {
        label: 'Turnover (planned for this year)',
        sublabel: '',
        tooltip: '',
      },
      currentFundingStage: {
        label: 'Current funding stage',
        sublabel: '',
        tooltip: '',
      },
      businessModel: {
        label: 'Business model',
        sublabel:
          'Please select one or multiple business model types that apply to you.',
        tooltip: '',
      },
      mainIncomeStream: {
        label: 'Main income stream',
        sublabel:
          'Please select one or multiple income streams that apply to you.',
        tooltip: '',
      },
      products_Services: {
        label: 'Products/Services',
        sublabel:
          'What products or services are you providing? Confirm each entry with enter.',
        tooltip: '',
      },
      industries: {
        label: 'Industries',
        sublabel: 'Please select one or multiple industries that apply to you.',
        tooltip: '',
      },
      subindustries: {
        label: 'Subindustries',
        sublabel: '',
        tooltip: '',
      },
      technologies: {
        label: 'Technologies',
        sublabel: '',
        tooltip: '',
      },
      customerGroup: {
        label: 'Customer group',
        sublabel:
          'Who will pay for your products and services (please make it concrete, e.g., private consumers, SMEs or manufacturing companies)? Confirm each entry with enter.',
        tooltip: '',
      },
      doYouMonitorGreenhouseGasGhgEmissionsGeneratedDirectlyOr: {
        label:
          'Do you monitor greenhouse gas (GHG) emissions generated directly or indirectly by your venture?',
        sublabel: 'e.g. calculating carbon footprints',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      thereAreGenerallyThreeTypesOfEmissionsScope12: {
        label:
          'There are generally three types of emissions (Scope 1, 2, 3). Which of these do you monitor?',
        sublabel: '',
        tooltip: '',
      },
      scope1DirectGhgEmissions: {
        label: 'Scope 1 (Direct GHG emissions)',
        sublabel:
          'Emissions from sources owned or controlled by the venture (e.g. emissions from heating of venture facilities or company vehicles)',
        tooltip: '',
      },
      scope2ElectricityIndirectGhgEmission: {
        label: 'Scope 2 (Electricity indirect GHG emission)',
        sublabel:
          'Emissions from the generation of purchased electricity consumed by the venture (e.g. heating & cooling for own use)',
        tooltip: '',
      },
      scope3OtherIndirectGhgEmissions: {
        label: 'Scope 3 (Other indirect GHG emissions)',
        sublabel:
          'Emissions which are a consequence of the activities of the venture, but occur from sources not owned or controlled by the venture (e.g. emissions from leased assets, employee commute, business travel, franchises)',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouTakeMeasuresToMakeYourEnergyConsumptionMore: {
        label:
          'Do you take measures to make your energy consumption more sustainable?',
        sublabel:
          'e.g. using renewable energy, improving energy efficiency,...',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v2: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToSustainablyManageYourEnergy: {
        label:
          'What measures do you take to sustainably manage your energy consumption?',
        sublabel: '',
        tooltip: '',
      },
      monitorEnergyUsage: {
        label: 'Monitor energy usage',
        sublabel: 'e.g.  tracking kWh used',
        tooltip: '',
      },
      procureRenewableEnergy: {
        label: 'Procure renewable energy',
        sublabel: 'e.g. changing your energy supplier, installing solar panels',
        tooltip: '',
      },
      reduceEnergyUsageUseEnergy_EfficientTechnologies: {
        label: 'Reduce energy usage & use energy-efficient technologies',
        sublabel:
          'e.g. using auto-shutoff switch for electrical devices, using equipment with higher power savings like LED lights',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v2: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      areYouCommittedToMakingSustainableTransportationChoices: {
        label:
          'Are you committed to making sustainable transportation choices?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v3: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseSustainableTransportationActionsDoYouFollowIn: {
        label:
          'Which of these sustainable transportation actions do you follow in your venture?',
        sublabel: '',
        tooltip: '',
      },
      reducingBusinessRelatedTravel: {
        label: 'Reducing business related travel',
        sublabel: 'by using teleconferencing services - e.g. Zoom, Skype',
        tooltip: '',
      },
      favourUsingEco_FriendlyModesOfTransportation: {
        label: 'Favour using eco-friendly modes of transportation',
        sublabel:
          'e.g. public transport, electric vehicles, bike, walking, ...',
        tooltip: '',
      },
      compensatingForUnavoidableTransportationRelatedEmissions: {
        label: 'Compensating for unavoidable transportation related emissions',
        sublabel: 'e.g. using carbon offsets',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v3: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouTakeMeasuresToReduceTheResourceUsageIn: {
        label:
          'Do you take measures to reduce the resource usage in your venture?',
        sublabel: 'e.g. reduce waste generation or water usage',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v4: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureSustainableResourceManagement: {
        label:
          'What measures do you take to ensure sustainable resource management at your venture?',
        sublabel: '',
        tooltip: '',
      },
      minimizeWasteGenerationAndMaximizeWasteRecyclingThroughEfficientProcesses:
        {
          label:
            'Minimize waste generation and maximize waste recycling through efficient processes or by design',
          sublabel:
            'e.g. efficient machinery to avoid material waste, circular or cradle2cradle product design to avoid plastic waste',
          tooltip: '',
        },
      minimizeWaterUseAndWaterConsumptionThroughWaterEfficiencyAnd: {
        label:
          'Minimize water use and water consumption through water efficiency and saving measures',
        sublabel:
          'e.g. by efficient wastewater management, water-saving equipment',
        tooltip: '',
      },
      useOfRecycledMaterialsAnd_OrRefurbishedEquipment: {
        label: 'Use of recycled materials and/or refurbished equipment',
        sublabel: 'e.g. recycled plastic/metal or refurbished IT equipment',
        tooltip: '',
      },
      procureAndUseSustainablyProducedProductsAndRawMaterials: {
        label:
          'Procure and use sustainably produced products and raw materials',
        sublabel:
          'e.g. food, office supplies, minerals and/or sustainable substitutes like alternative plastics, recyclable packaging',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v4: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouTakeMeasuresToAvoidNegativeEnvironmentalImpactsGenerated: {
        label:
          'Do you take measures to avoid negative environmental impacts generated as a direct result of you providing your services or manufacturing/delivering your products?',
        sublabel: 'e.g. pollution, loss of biodiversity',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v5: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNoNotRelevant: {
        label: "Please explain why you chose 'No, not relevant'.",
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToManageAndReduce_: {
        label:
          'What measures do you take to manage and reduce/prevent negative environmental impacts?',
        sublabel: '',
        tooltip: '',
      },
      monitoringEnvironmentalPollution: {
        label: 'Monitoring environmental pollution',
        sublabel: 'e.g. by tracking air or water quality',
        tooltip: '',
      },
      usingMoreEco_FriendlyAlternativesWherePossible: {
        label: 'Using more eco-friendly alternatives where possible',
        sublabel: 'e.g. non-toxic chemicals, organic materials',
        tooltip: '',
      },
      assureThatThereAreNoSpillsOrLeaksOfHazardous: {
        label:
          'Assure that there are no spills or leaks of hazardous or toxic materials',
        sublabel:
          'e.g. by treatment, handling, storage and disposal of hazardous substances like paint, batteries or chemicals according to standard safety procedures',
        tooltip: '',
      },
      conductingNoActivitiesThatAreHarmfulToBiodiversity_Sensitive: {
        label:
          'Conducting no activities that are harmful to biodiversity-sensitive areas',
        sublabel: 'e.g. operations in rain forests, habitat destruction',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v5: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      businessModelOther: {
        label: 'Business model (Other)',
        sublabel: "Please specify 'Other\u2019",
        tooltip: '',
      },
      mainIncomeStreamOther: {
        label: 'Main income stream (Other)',
        sublabel: "Please specify 'Other\u2019",
        tooltip: '',
      },
      industriesOther: {
        label: 'Industries (Other)',
        sublabel: 'Not able to find your industry? Please specify here.',
        tooltip: '',
      },
      subindustriesOther: {
        label: 'Subindustries (Other)',
        sublabel: "Please specify 'Other\u2019",
        tooltip: '',
      },
      technologiesOther: {
        label: 'Technologies (Other)',
        sublabel: "Please specify 'Other\u2019",
        tooltip: '',
      },
      doYouTakeIntoAccountEmploymentHumanRightsLegislationsFor: {
        label:
          'Do you take into account employment & human rights legislations for your venture and/or your key suppliers?',
        sublabel:
          'e.g. no forced labor, no child labor, adherence to regional labor legislation',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v6: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      adherenceToWhichOfTheFollowingDoYouMonitor_: {
        label:
          'Adherence to which of the following do you monitor / assure in your operations?',
        sublabel: '',
        tooltip: '',
      },
      humanRightsForOwnVenture: {
        label: 'Human rights for own venture',
        sublabel: 'e.g. no forced labor, no child labor, no human trafficking',
        tooltip: '',
      },
      regionalLaborLegislationOccupationalHealthSafetyStandards: {
        label:
          'Regional labor legislation & occupational health & safety standards',
        sublabel: 'e.g. minimum wages, working hours, workers safety',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v6: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouWorkTogetherWithKeySuppliersOrExternalService: {
        label:
          'Do you work together with key suppliers or external service providers in your value chain?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v7: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      doYouAdditionallyTakeIntoAccountEmploymentHumanRightsLegislations: {
        label:
          'Do you additionally take into account employment & human rights legislations for your key suppliers/external service providers?',
        sublabel: '',
        tooltip: '',
      },
      humanRightsForKeySuppliers_ExternalServiceProviders: {
        label: 'Human rights for key suppliers/external service providers',
        sublabel: 'e.g. no forced labor, no child labor, no human trafficking',
        tooltip: '',
      },
      regionalLaborLegislationOccupationalHealthSafetyStandards_v2: {
        label:
          'Regional labor legislation & occupational health & safety standards',
        sublabel: 'e.g. minimum wages, working hours, workers safety',
        tooltip: '',
      },
      doesYourVentureProvideAQualityWorkplaceForItsEmployees: {
        label:
          'Does your venture provide a quality workplace for its employees?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v8: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingCriteriaDoesYourVentureCurrentlySatisfy: {
        label:
          'Which of the following criteria does your venture currently satisfy?',
        sublabel: '',
        tooltip: '',
      },
      family_FriendlyWorkplace: {
        label: 'Family-friendly workplace',
        sublabel: 'e.g. parental leave, childcare support, care of relatives',
        tooltip: '',
      },
      flexibleWorkingRoutine: {
        label: 'Flexible working routine',
        sublabel:
          'e.g. home-office, part-time positions, job-sharing options, leaves of absence',
        tooltip: '',
      },
      livingWage: {
        label: 'Living wage',
        sublabel: 'e.g. satisfies all basic needs, decent standard of living',
        tooltip: '',
      },
      financialSocialAnd_OrHealthBenefitsInAdditionTo: {
        label:
          'Financial, social and/or health benefits in addition to legally required benefits',
        sublabel:
          'e.g. company pension plan, additional vacation days, company-paid health checks',
        tooltip: '',
      },
      employeeTrainings: {
        label: 'Employee trainings',
        sublabel: 'e.g. external trainings, coaching options',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v7: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v8: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doesYourVentureSupportDiversityAndInclusionInTheWorkplace: {
        label:
          'Does your venture support diversity and inclusion in the workplace?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v9: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingDiversityInclusionCharacteristicsAreSupportedBy: {
        label:
          'Which of the following diversity & inclusion characteristics are supported by your venture?',
        sublabel:
          'e.g. in the recruitment strategy, by a disability-friendly workplace, by offering language courses',
        tooltip: '',
      },
      genderDiversity: {
        label: 'Gender diversity',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity: {
        label: 'Cultural & Ethnic diversity',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity: {
        label: 'Age-related diversity',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion: {
        label: 'Disability Inclusion',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v9: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doesYourVentureMakeContributionsToSociety: {
        label: 'Does your venture make contributions to society?',
        sublabel:
          'e.g. support local communities, research projects, pro bono activities',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v10: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingSocietalContributionsBestDescribeYourActivities: {
        label:
          'Which of the following societal contributions best describe your activities?',
        sublabel: '',
        tooltip: '',
      },
      localCommunityDevelopment: {
        label: 'Local community development',
        sublabel: 'e.g. collaborating with neighboring non-profit initiatives',
        tooltip: '',
      },
      promotingPositiveSocialChange: {
        label: 'Promoting positive social change',
        sublabel:
          'e.g. enable employees to enage in voluntary social activities',
        tooltip: '',
      },
      advocatingSustainabilityInSociety: {
        label: 'Advocating sustainability in society',
        sublabel:
          'e.g. host environmental awareness programs, join local discussions',
        tooltip: '',
      },
      fightingEnvironmentalDamages: {
        label: 'Fighting environmental damages',
        sublabel:
          'e.g. encourage your employees to go paperless, meatless and to reduce, reuse & recycle',
        tooltip: '',
      },
      philanthropy: {
        label: 'Philanthropy',
        sublabel: 'e.g. host a donation drive, sponsor education programs',
        tooltip: '',
      },
      creatingPublicKnowledgeAnd_OrOpenSourceTools: {
        label: 'Creating public knowledge and/or open source tools',
        sublabel:
          'e.g. create free whitepaper on sustainability topic, help develop open source WIKI',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v10: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouTakeMeasuresToAvoidNegativeSocialImpactsGenerated: {
        label:
          'Do you take measures to avoid negative social impacts generated as a direct result of you providing your services or manufacturing/delivering your products?',
        sublabel:
          'e.g. fair prices and marketing, offer good product quality, avoid health risk for stakeholders',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v11: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToManageAndReduce__v2: {
        label:
          'What measures do you take to manage and reduce/prevent negative social impacts?',
        sublabel: '',
        tooltip: '',
      },
      usingFairSellingPractices: {
        label: 'Using fair selling practices',
        sublabel:
          'e.g. no misleading marketing, fair customer contracts, no monopolies',
        tooltip: '',
      },
      assureAccessibility_Affordability: {
        label: 'Assure accessibility / affordability',
        sublabel:
          'e.g. differential pricing for underserved customer groups, enable the elderly to use digital tools',
        tooltip: '',
      },
      optimizeCustomerWelfare: {
        label: 'Optimize customer welfare',
        sublabel: 'e.g. avoid health risks, optimize quality',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v11: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouFulfillBestPracticesAndRegulatoryStandardsForThe: {
        label:
          'Do you fulfill best practices and regulatory standards for the treatment of data?',
        sublabel:
          'e.g. compliance with GDPR, transparency about data usage, data security',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v12: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingMeasuresBestDescribeDataProcessingAnd: {
        label:
          'Which of the following measures best describe data processing and data protection activities in your venture?',
        sublabel: '',
        tooltip: '',
      },
      consentualDataCollection: {
        label: 'Consentual Data Collection',
        sublabel: 'e.g. web cookies, user account information',
        tooltip: '',
      },
      fairLawfulUsage: {
        label: 'Fair & Lawful Usage',
        sublabel:
          'e.g. using data only for the intended purpose that is approved by the customer',
        tooltip: '',
      },
      restrictedAccess: {
        label: 'Restricted Access',
        sublabel:
          'e.g. only allowing a handful of individuals to have authorized access to the data',
        tooltip: '',
      },
      dataSecurity: {
        label: 'Data security',
        sublabel:
          'e.g. penetration testing, firewall, anti-malware, anti-virus',
        tooltip: '',
      },
      trainingsForEmployees: {
        label: 'Trainings for employees',
        sublabel:
          'e.g. identifying common data security threats like email viruses',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v12: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouActivelyEngageWithYourStakeholders: {
        label: 'Do you actively engage with your stakeholders?',
        sublabel:
          'e.g. your customers, suppliers or the community you operate in',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v13: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseStakeholdersHaveYouInteractedWithInThe: {
        label:
          'Which of these stakeholders have you interacted with in the last six months?',
        sublabel:
          'e.g. ask customers for feedback, conduct employee satisfaction surveys, enquire about the situation of your suppliers or involve with the stakeholders in your local community',
        tooltip: '',
      },
      customersUsers: {
        label: 'Customers & users',
        sublabel: '',
        tooltip: '',
      },
      employees: {
        label: 'Employees',
        sublabel: '',
        tooltip: '',
      },
      suppliersVendors: {
        label: 'Suppliers & Vendors',
        sublabel: '',
        tooltip: '',
      },
      localCommunitiesYouOperateIn: {
        label: 'Local communities you operate in',
        sublabel: '',
        tooltip: '',
      },
      governments: {
        label: 'Governments',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v13: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      whichOfTheFollowingEthicalBehaviorsAreRespectedInYour: {
        label:
          'Which of the following ethical behaviors are respected in your company?',
        sublabel: '',
        tooltip: '',
      },
      anti_BriberyAnti_Corruption: {
        label: 'Anti-Bribery & Anti-Corruption',
        sublabel: '',
        tooltip: '',
      },
      taxCompliance: {
        label: 'Tax compliance',
        sublabel: '',
        tooltip: '',
      },
      respectOfTheIntellectualPropertyOfOthers: {
        label: 'Respect of the intellectual property of others',
        sublabel: 'e.g. by transparently disclosing true origins',
        tooltip: '',
      },
      whistleblowing: {
        label: 'Whistleblowing',
        sublabel: '',
        tooltip: '',
      },
      fairCompetition: {
        label: 'Fair competition',
        sublabel:
          'e.g. by avoiding false, misleading, and deceptive advertising',
        tooltip: '',
      },
      anti_Harassment: {
        label: 'Anti-Harassment',
        sublabel: '',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v14: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouHaveAQualifiedAndDiverseManagementTeam: {
        label: 'Do you have a qualified and diverse management team?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v14: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseCharacteristicsBestDefineTheCompositionOfThe: {
        label:
          'Which of these characteristics best define the composition of the management team?',
        sublabel: '',
        tooltip: '',
      },
      genderDiversity_v2: {
        label: 'Gender diversity',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity_v2: {
        label: 'Cultural & ethnic diversity',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity_v2: {
        label: 'Age-related diversity',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion_v2: {
        label: 'Disability inclusion',
        sublabel: '',
        tooltip: '',
      },
      expertise: {
        label: 'Expertise',
        sublabel: 'e.g. subject specialists with varied skills',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v15: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      genderDiversity_v3: {
        label: 'Gender diversity',
        sublabel: '',
        tooltip: '',
      },
      culturalEthnicDiversity_v3: {
        label: 'Cultural & ethnic diversity',
        sublabel: '',
        tooltip: '',
      },
      age_RelatedDiversity_v3: {
        label: 'Age-related diversity',
        sublabel: '',
        tooltip: '',
      },
      disabilityInclusion_v3: {
        label: 'Disability inclusion',
        sublabel: '',
        tooltip: '',
      },
      expertise_v2: {
        label: 'Expertise',
        sublabel: 'e.g. subject specialists with varied skills',
        tooltip: '',
      },
      independence: {
        label: 'Independence',
        sublabel:
          "independent advisors are not employed by the venture and don't do any business with the venture",
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v16: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doYouTakeIntoAccountRegulationAsWellAsQuality: {
        label:
          'Do you take into account regulation as well as quality and sustainability standards?',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v15: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whichOfTheFollowingMeasuresDoYouTakeToComply: {
        label:
          'Which of the following measures do you take to comply with regulations and standards?',
        sublabel: '',
        tooltip: '',
      },
      identifyAndComplyWithRelevantRegulations: {
        label: 'Identify and comply with relevant regulations',
        sublabel:
          'e.g. have dedicated resources, develop policies to comply with regulation like GDPR',
        tooltip: '',
      },
      adhereToQualityAndIndustryStandards: {
        label: 'Adhere to quality and industry standards',
        sublabel: 'e.g. HACCP, ISO norms',
        tooltip: '',
      },
      activelyManageEsgPerformance: {
        label: 'Actively manage ESG performance',
        sublabel:
          'e.g. have dedicated resources for ESG management, obtain sustainability certificates or prepare a company ESG policy',
        tooltip: '',
      },
      pleaseProvideABriefExampleThatJustifiesYourSelectionEg_v17: {
        label:
          'Please provide a brief example that justifies your selection (e.g., actions you undertake or targets you have).',
        sublabel:
          'Even if none of the above answering options apply, please explain what you are doing.',
        tooltip: '',
      },
      doesYourVentureViolateOneOrMoreOfTheCore: {
        label:
          'Does your venture violate one or more of the core principles of the UN Global Compact?',
        sublabel:
          'Please select the principles that are violated by your venture. If there is no violation, please select the option "None of the above"',
        tooltip: '',
      },
      pleaseExplainToWhatExtentYouViolateThePrinciples: {
        label: 'Please explain to what extent you violate the principle(s).',
        sublabel: '',
        tooltip: '',
      },
      pleaseIndicateIfYouAreActiveInAnyOfThe: {
        label:
          'Please indicate if you are active in any of the business areas listed below',
        sublabel:
          'Please report if you directly produce, sell or promote any of the controversial business areas listed below. You can further specify your activities in the free text below. If you are not active in any of the listed activities, please select "None of the above"',
        tooltip: '',
      },
      pleaseExplainToWhatExtentYouAreActiveInThe: {
        label:
          'Please explain to what extent you are active in the business areas.',
        sublabel: '',
        tooltip: '',
      },
      pleaseIndicateIfYouDerivedAnyRevenuesFromFossilFuels: {
        label: 'Please indicate if you derived any revenues from fossil fuels',
        sublabel: '',
        tooltip:
          'This includes revenues from\n- exploration, mining, extraction, distribution, or refining of hard coal and lignite;\n- the exploration, extraction, distribution (including transportation, storage and trade), or refining of liquid fossil fuels; or\n- exploring and extracting fossil gaseous fuels or from their dedicated distribution (including transportation, storage and trade).',
      },
      pleaseProvideDetailsOnYourRevenuesFromFossilFuels: {
        label: 'Please provide details on your revenues from fossil fuels',
        sublabel: '',
        tooltip: '',
      },
      fairPay: {
        label: 'Fair pay',
        sublabel: 'e.g. no gender pay gap, rational CEO to worker pay ratio',
        tooltip: '',
      },
      fightNegativeSocialImpacts: {
        label: 'Fight negative social impacts',
        sublabel:
          'e.g. from technology risks or potential abuses like fake news or deep fakes',
        tooltip: '',
      },
      doYouHaveAnAdvisoryBoard: {
        label: 'Do you have an advisory board?',
        sublabel: '',
        tooltip: '',
      },
      whichOfTheseCharacteristicsBestDefineTheCompositionOfThe_v2: {
        label:
          'Which of these characteristics best define the composition of the advisory board?',
        sublabel: '',
        tooltip: '',
      },
      haveThereBeenAnyPublicComplaintsOrLegalActionsOn: {
        label:
          'Have there been any public complaints or legal actions on social, environmental, or governance issues over the last three years?',
        sublabel:
          'e.g. on issues like employee rights, consumer harm or pollution',
        tooltip: '',
      },
      pleaseProvideDetailsOnAllReportedComplaintsClaimsOrLegal: {
        label:
          'Please provide details on all reported complaints, claims or legal actions',
        sublabel: '',
        tooltip: '',
      },
      pleaseExplainWhyYouChoseNo_v16: {
        label: "Please explain why you chose 'No'.",
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToReduceGhgEmissionsNot: {
        label:
          'What measures do you take to reduce GHG emissions not related to energy or transportation? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakeYourEnergyConsumption: {
        label:
          'What measures do you take to make your energy consumption more sustainable? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v2: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakeYourCompanysTransportation: {
        label:
          'What measures do you take to make your company\u2019s transportation activities more sustainable? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v3: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureSustainableResourceConsumption: {
        label:
          'What measures do you take to ensure sustainable resource consumption and usage at your company? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v4: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToAvoidNegativeEnvironmentalImpacts: {
        label:
          'What measures do you take to avoid negative environmental impacts generated as a direct result of your company?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v5: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      withRegardToYourCompanyAndYourSupplyChainWhat: {
        label:
          'With regard to your company and your supply chain: What measures do you take to adhere to labor and human rights legislation as well as occupational health and safety standards? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v6: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureTheQualityOf: {
        label:
          'What measures do you take to ensure the quality of your workplace for your employees?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v7: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureDiversityAndInclusion: {
        label:
          'What measures do you take to ensure diversity and inclusion in your company? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v8: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToAvoidSocietalHarmGenerated: {
        label:
          'What measures do you take to avoid societal harm generated as a direct result of your company? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v9: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToMakePositiveContributionsTo: {
        label:
          'What measures do you take to make positive contributions to society?',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v10: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureAndImproveResponsible: {
        label:
          'What measures do you take to ensure and improve responsible data processing and data protection in your company? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v11: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureActiveStakeholderEngagement: {
        label:
          'What measures do you take to ensure active stakeholder engagement? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v12: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToEnsureEthicalBehaviorIn: {
        label:
          'What measures do you take to ensure ethical behavior in your company? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v13: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      withRegardToYourManagementTeamOrYourAdvisoryBoard: {
        label:
          'With regard to your management team or your advisory board: What measures do you take to ensure a qualified and diverse setup?  ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v14: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      whatMeasuresDoYouTakeToComplyWithRelevantRegulations: {
        label:
          'What measures do you take to comply with relevant regulations, standards and ESG requirements? ',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v15: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      doesYourVentureAdhereToTheOecdGuidelinesForMultinational: {
        label:
          'Does your venture adhere to the OECD Guidelines for Multinational Enterprises?',
        sublabel:
          "Choose between comply or explain and indicate whether your venture adheres to the OECD Guidelines for Multinational Enterprises. If you select \u201CNo\u201D or \u201CWe don't want to report on this\u201D please explain why you have chosen this answering option. *Please note that companies that act responsibly and in compliance with the law can implicitly assume that they comply with the guidelines.*",
        tooltip: '',
      },
      additionalComments_v16: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      doesYourVentureAdhereToTheUnGuidingPrinciplesOn: {
        label:
          'Does your venture adhere to the UN Guiding Principles on Business and Human Rights including the ILO conventions?',
        sublabel:
          "Choose between comply or explain and indicate whether your venture adheres to the UN Guiding Principles on Business and Human Rights. If you select \u201CNo\u201D or \u201CWe don't want to report on this\u201D please explain why you have chosen this answering option. *Please note that companies that act responsibly and in compliance with the law can implicitly assume that they comply with the principles.*",
        tooltip: '',
      },
      additionalComments_v17: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v18: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v19: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
      additionalComments_v20: {
        label: 'Additional comments',
        sublabel: '',
        tooltip: '',
      },
    },
    option: {
      n1: {
        label: '1',
        sublabel: '',
        tooltip: '',
      },
      n2_10: {
        label: '2-10',
        sublabel: '',
        tooltip: '',
      },
      n11_50: {
        label: '11-50',
        sublabel: '',
        tooltip: '',
      },
      n51_200: {
        label: '51-200',
        sublabel: '',
        tooltip: '',
      },
      n201_500: {
        label: '201-500',
        sublabel: '',
        tooltip: '',
      },
      n501_1000: {
        label: '501-1000',
        sublabel: '',
        tooltip: '',
      },
      n1001_5000: {
        label: '1001-5000',
        sublabel: '',
        tooltip: '',
      },
      n10000Plus: {
        label: '10000+',
        sublabel: '',
        tooltip: '',
      },
      lessThanEuros0_1M: {
        label: '<\u20AC0.1m',
        sublabel: '',
        tooltip: '',
      },
      euros0_1M_Euros0_5M: {
        label: '\u20AC0.1m - \u20AC0.5m',
        sublabel: '',
        tooltip: '',
      },
      euros0_5M_Euros1M: {
        label: '\u20AC0.5m - \u20AC1m',
        sublabel: '',
        tooltip: '',
      },
      lessThanEuros1M: {
        label: '<\u20AC1m',
        sublabel: '',
        tooltip: '',
      },
      euros1M_Euros5M: {
        label: '\u20AC1m - \u20AC5m',
        sublabel: '',
        tooltip: '',
      },
      euros5M_Euros10M: {
        label: '\u20AC5m - \u20AC10m',
        sublabel: '',
        tooltip: '',
      },
      greatherThanEuros10M: {
        label: '>\u20AC10m',
        sublabel: '',
        tooltip: '',
      },
      bootstrapping_NoExternalFunding: {
        label: 'Bootstrapping / no external funding',
        sublabel: '',
        tooltip: '',
      },
      pre_Seed: {
        label: 'Pre-Seed',
        sublabel: '',
        tooltip: '',
      },
      seed: {
        label: 'Seed',
        sublabel: '',
        tooltip: '',
      },
      seriesA: {
        label: 'Series A',
        sublabel: '',
        tooltip: '',
      },
      seriesB: {
        label: 'Series B',
        sublabel: '',
        tooltip: '',
      },
      seriesCPlus: {
        label: 'Series C+',
        sublabel: '',
        tooltip: '',
      },
      manufacturing: {
        label: 'Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      marketplaceEcommerce: {
        label: 'Marketplace & ecommerce',
        sublabel: '',
        tooltip: '',
      },
      saas: {
        label: 'SaaS',
        sublabel: '',
        tooltip: '',
      },
      other: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      advertising: {
        label: 'Advertising',
        sublabel: '',
        tooltip: '',
      },
      commission: {
        label: 'Commission',
        sublabel: '',
        tooltip: '',
      },
      sellingOwnInventory: {
        label: 'Selling own inventory',
        sublabel: '',
        tooltip: '',
      },
      subscription: {
        label: 'Subscription',
        sublabel: '',
        tooltip: '',
      },
      other_v2: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      dating: {
        label: 'Dating',
        sublabel: '',
        tooltip: '',
      },
      education: {
        label: 'Education',
        sublabel: '',
        tooltip: '',
      },
      energy: {
        label: 'Energy',
        sublabel: '',
        tooltip: '',
      },
      enterpriseSoftware: {
        label: 'Enterprise software',
        sublabel: '',
        tooltip: '',
      },
      eventTech: {
        label: 'Event tech',
        sublabel: '',
        tooltip: '',
      },
      fashion: {
        label: 'Fashion',
        sublabel: '',
        tooltip: '',
      },
      fintech: {
        label: 'Fintech',
        sublabel: '',
        tooltip: '',
      },
      food: {
        label: 'Food',
        sublabel: '',
        tooltip: '',
      },
      gaming: {
        label: 'Gaming',
        sublabel: '',
        tooltip: '',
      },
      health: {
        label: 'Health',
        sublabel: '',
        tooltip: '',
      },
      homeLiving: {
        label: 'Home living',
        sublabel: '',
        tooltip: '',
      },
      hosting: {
        label: 'Hosting',
        sublabel: '',
        tooltip: '',
      },
      jobsRecruitment: {
        label: 'Jobs recruitment',
        sublabel: '',
        tooltip: '',
      },
      kids: {
        label: 'Kids',
        sublabel: '',
        tooltip: '',
      },
      legal: {
        label: 'Legal',
        sublabel: '',
        tooltip: '',
      },
      marketing: {
        label: 'Marketing',
        sublabel: '',
        tooltip: '',
      },
      media: {
        label: 'Media',
        sublabel: '',
        tooltip: '',
      },
      music: {
        label: 'Music',
        sublabel: '',
        tooltip: '',
      },
      realEstate: {
        label: 'Real estate',
        sublabel: '',
        tooltip: '',
      },
      robotics: {
        label: 'Robotics',
        sublabel: '',
        tooltip: '',
      },
      security: {
        label: 'Security',
        sublabel: '',
        tooltip: '',
      },
      semiconductors: {
        label: 'Semiconductors',
        sublabel: '',
        tooltip: '',
      },
      sports: {
        label: 'Sports',
        sublabel: '',
        tooltip: '',
      },
      telecom: {
        label: 'Telecom',
        sublabel: '',
        tooltip: '',
      },
      transportation: {
        label: 'Transportation',
        sublabel: '',
        tooltip: '',
      },
      travel: {
        label: 'Travel',
        sublabel: '',
        tooltip: '',
      },
      wellnessBeauty: {
        label: 'Wellness beauty',
        sublabel: '',
        tooltip: '',
      },
      other_v3: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      accessories: {
        label: 'Accessories',
        sublabel: '',
        tooltip: '',
      },
      accommodation: {
        label: 'Accommodation',
        sublabel: '',
        tooltip: '',
      },
      adtech: {
        label: 'Adtech',
        sublabel: '',
        tooltip: '',
      },
      agritech: {
        label: 'Agritech',
        sublabel: '',
        tooltip: '',
      },
      apparel: {
        label: 'Apparel',
        sublabel: '',
        tooltip: '',
      },
      autonomousSensorTech: {
        label: 'Autonomous & sensor tech',
        sublabel: '',
        tooltip: '',
      },
      banking: {
        label: 'Banking',
        sublabel: '',
        tooltip: '',
      },
      bettingGambling: {
        label: 'Betting & gambling',
        sublabel: '',
        tooltip: '',
      },
      biotechnology: {
        label: 'Biotechnology',
        sublabel: '',
        tooltip: '',
      },
      boardGames: {
        label: 'Board games',
        sublabel: '',
        tooltip: '',
      },
      bookingSearch: {
        label: 'Booking & search',
        sublabel: '',
        tooltip: '',
      },
      businessTravel: {
        label: 'Business travel',
        sublabel: '',
        tooltip: '',
      },
      cleanEnergy: {
        label: 'Clean energy',
        sublabel: '',
        tooltip: '',
      },
      cloudInfrastructure: {
        label: 'Cloud & infrastructure',
        sublabel: '',
        tooltip: '',
      },
      consolePcGaming: {
        label: 'Console & pc gaming',
        sublabel: '',
        tooltip: '',
      },
      construction: {
        label: 'Construction',
        sublabel: '',
        tooltip: '',
      },
      contentProduction: {
        label: 'Content production',
        sublabel: '',
        tooltip: '',
      },
      crmSales: {
        label: 'CRM & sales',
        sublabel: '',
        tooltip: '',
      },
      cryptoAndDefi: {
        label: 'Crypto and defi',
        sublabel: '',
        tooltip: '',
      },
      dataProtection: {
        label: 'Data protection',
        sublabel: '',
        tooltip: '',
      },
      deviceSecurityAntivirus: {
        label: 'Device security & antivirus',
        sublabel: '',
        tooltip: '',
      },
      ecommerceSolutions: {
        label: 'Ecommerce solutions',
        sublabel: '',
        tooltip: '',
      },
      energyEfficiency: {
        label: 'Energy efficiency',
        sublabel: '',
        tooltip: '',
      },
      energyProviders: {
        label: 'Energy providers',
        sublabel: '',
        tooltip: '',
      },
      energyStorage: {
        label: 'Energy storage',
        sublabel: '',
        tooltip: '',
      },
      esports: {
        label: 'Esports',
        sublabel: '',
        tooltip: '',
      },
      financialManagementSolutions: {
        label: 'Financial management solutions',
        sublabel: '',
        tooltip: '',
      },
      fitness: {
        label: 'Fitness',
        sublabel: '',
        tooltip: '',
      },
      foodLogisticsDelivery: {
        label: 'Food logistics & delivery',
        sublabel: '',
        tooltip: '',
      },
      footwear: {
        label: 'Footwear',
        sublabel: '',
        tooltip: '',
      },
      healthPlatform: {
        label: 'Health platform',
        sublabel: '',
        tooltip: '',
      },
      identityAccess: {
        label: 'Identity & access',
        sublabel: '',
        tooltip: '',
      },
      in_StoreRetailRestaurantTech: {
        label: 'In-store retail & restaurant tech',
        sublabel: '',
        tooltip: '',
      },
      innovativeFood: {
        label: 'Innovative food',
        sublabel: '',
        tooltip: '',
      },
      insurance: {
        label: 'Insurance',
        sublabel: '',
        tooltip: '',
      },
      intellectualProperty: {
        label: 'Intellectual property ',
        sublabel: '',
        tooltip: '',
      },
      kitchenCookingTech: {
        label: 'Kitchen & cooking tech',
        sublabel: '',
        tooltip: '',
      },
      legalDocumentsManagement: {
        label: 'Legal documents management',
        sublabel: '',
        tooltip: '',
      },
      legalInformation: {
        label: 'Legal information',
        sublabel: '',
        tooltip: '',
      },
      legalMatterManagement: {
        label: 'Legal matter management ',
        sublabel: '',
        tooltip: '',
      },
      logisticsDelivery: {
        label: 'Logistics & delivery',
        sublabel: '',
        tooltip: '',
      },
      luxury: {
        label: 'Luxury',
        sublabel: '',
        tooltip: '',
      },
      maintenance: {
        label: 'Maintenance',
        sublabel: '',
        tooltip: '',
      },
      marketingAnalytics: {
        label: 'Marketing analytics',
        sublabel: '',
        tooltip: '',
      },
      medicalDevices: {
        label: 'Medical devices',
        sublabel: '',
        tooltip: '',
      },
      mobileGaming: {
        label: 'Mobile gaming',
        sublabel: '',
        tooltip: '',
      },
      mobility: {
        label: 'Mobility',
        sublabel: '',
        tooltip: '',
      },
      mortgagesLending: {
        label: 'Mortgages & lending',
        sublabel: '',
        tooltip: '',
      },
      navigationMapping: {
        label: 'Navigation & mapping',
        sublabel: '',
        tooltip: '',
      },
      oilGas: {
        label: 'Oil & gas',
        sublabel: '',
        tooltip: '',
      },
      onlineTravelAgency: {
        label: 'Online travel agency',
        sublabel: '',
        tooltip: '',
      },
      payments: {
        label: 'Payments',
        sublabel: '',
        tooltip: '',
      },
      pharmaceuticals: {
        label: 'Pharmaceuticals',
        sublabel: '',
        tooltip: '',
      },
      publicSafety: {
        label: 'Public safety',
        sublabel: '',
        tooltip: '',
      },
      publishing: {
        label: 'Publishing',
        sublabel: '',
        tooltip: '',
      },
      realEstateServices: {
        label: 'Real estate services',
        sublabel: '',
        tooltip: '',
      },
      realEstateSoftware: {
        label: 'Real estate software',
        sublabel: '',
        tooltip: '',
      },
      regtech: {
        label: 'Regtech',
        sublabel: '',
        tooltip: '',
      },
      regtechCompliance: {
        label: 'Regtech & compliance',
        sublabel: '',
        tooltip: '',
      },
      searchBuyAndRent: {
        label: 'Search, buy and rent',
        sublabel: '',
        tooltip: '',
      },
      self_ServiceAndLawyerMarketplace: {
        label: 'Self-service and lawyer marketplace',
        sublabel: '',
        tooltip: '',
      },
      socialMedia: {
        label: 'Social media',
        sublabel: '',
        tooltip: '',
      },
      sportLeagueClub: {
        label: 'Sport league & club',
        sublabel: '',
        tooltip: '',
      },
      sportMedia: {
        label: 'Sport media',
        sublabel: '',
        tooltip: '',
      },
      sportPlatformApplication: {
        label: 'Sport platform & application',
        sublabel: '',
        tooltip: '',
      },
      sportSupplements: {
        label: 'Sport supplements',
        sublabel: '',
        tooltip: '',
      },
      sportEquipment: {
        label: 'Sport equipment',
        sublabel: '',
        tooltip: '',
      },
      streaming: {
        label: 'Streaming',
        sublabel: '',
        tooltip: '',
      },
      travelAnalyticsSoftware: {
        label: 'Travel analytics & software',
        sublabel: '',
        tooltip: '',
      },
      vehicleProduction: {
        label: 'Vehicle production',
        sublabel: '',
        tooltip: '',
      },
      wasteSolutions: {
        label: 'Waste solutions',
        sublabel: '',
        tooltip: '',
      },
      water: {
        label: 'Water',
        sublabel: '',
        tooltip: '',
      },
      wealthManagement: {
        label: 'Wealth management',
        sublabel: '',
        tooltip: '',
      },
      workspaces: {
        label: 'Workspaces',
        sublabel: '',
        tooltip: '',
      },
      other_v4: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      n3DTechnology: {
        label: '3D technology',
        sublabel: '',
        tooltip: '',
      },
      artificialIntelligence: {
        label: 'Artificial intelligence',
        sublabel: '',
        tooltip: '',
      },
      augmentedReality: {
        label: 'Augmented reality',
        sublabel: '',
        tooltip: '',
      },
      autonomousSensorTech_v2: {
        label: 'Autonomous & sensor tech',
        sublabel: '',
        tooltip: '',
      },
      bigData: {
        label: 'Big data',
        sublabel: '',
        tooltip: '',
      },
      blockchain: {
        label: 'Blockchain',
        sublabel: '',
        tooltip: '',
      },
      computerVision: {
        label: 'Computer vision',
        sublabel: '',
        tooltip: '',
      },
      connectedDevice: {
        label: 'Connected device',
        sublabel: '',
        tooltip: '',
      },
      deepLearning: {
        label: 'Deep learning',
        sublabel: '',
        tooltip: '',
      },
      deepTech: {
        label: 'Deep tech',
        sublabel: '',
        tooltip: '',
      },
      hardware: {
        label: 'Hardware',
        sublabel: '',
        tooltip: '',
      },
      iotInternetofthings: {
        label: 'IOT internetofthings',
        sublabel: '',
        tooltip: '',
      },
      machineLearning: {
        label: 'Machine learning',
        sublabel: '',
        tooltip: '',
      },
      mobileApp: {
        label: 'Mobile app',
        sublabel: '',
        tooltip: '',
      },
      nanotech: {
        label: 'Nanotech',
        sublabel: '',
        tooltip: '',
      },
      naturalLanguageProcessing: {
        label: 'Natural language processing',
        sublabel: '',
        tooltip: '',
      },
      quantumTechnologies: {
        label: 'Quantum technologies',
        sublabel: '',
        tooltip: '',
      },
      recognitionTechnology: {
        label: 'Recognition technology',
        sublabel: '',
        tooltip: '',
      },
      virtualReality: {
        label: 'Virtual reality',
        sublabel: '',
        tooltip: '',
      },
      other_v5: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      yesAlreadyDoingIt_PlannedInTheNext12: {
        label: 'Yes, already doing it / planned in the next 12 months',
        sublabel: '',
        tooltip: '',
      },
      no: {
        label: 'No',
        sublabel: '',
        tooltip: '',
      },
      noNotRelevant: {
        label: 'No, not relevant',
        sublabel: '',
        tooltip: '',
      },
      yesAlreadyHavingOne_PlannedInTheNext12: {
        label: 'Yes, already having one / planned in the next 12 months',
        sublabel: '',
        tooltip: '',
      },
      yes: {
        label: 'Yes',
        sublabel: '',
        tooltip: '',
      },
      noButPlannedInTheNext12Months: {
        label: 'No, but planned in the next 12 months',
        sublabel: '',
        tooltip: '',
      },
      noNotPlanned: {
        label: 'No, not planned',
        sublabel: '',
        tooltip: '',
      },
      naturalResources: {
        label: 'Natural resources',
        sublabel:
          'Providing products from natural resources (agriculture, forestry, fishing, mining, gas, water, waste etc.)',
        tooltip: '',
      },
      manufacturing_v2: {
        label: 'Manufacturing',
        sublabel:
          'Developing products with an own production (hardware, chemicals, processed food, etc.)',
        tooltip: '',
      },
      softwareDigitalTechnologies: {
        label: 'Software & Digital technologies',
        sublabel: 'Providing digital services or digital infrastructure',
        tooltip: '',
      },
      tradeIntermediation: {
        label: 'Trade & Intermediation',
        sublabel:
          'Trading and selling products and or services of other companies (Wholesale and retail trade, financial intermediation)',
        tooltip: '',
      },
      technicalAndPhysicalServices: {
        label: 'Technical and physical services',
        sublabel:
          'Providing goods with physical labor (Logistics, food services, sharing, construction, crafts, accommodation, etc.)',
        tooltip: '',
      },
      informationServices: {
        label: 'Information services',
        sublabel:
          'Providing intellectual and knowledge-based services (advisory, art, entertainment etc.)',
        tooltip: '',
      },
      other_v6: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      humanRightsPrinciples: {
        label: 'Human rights principles',
        sublabel: '',
        tooltip:
          '1. Principle: Businesses should support and respect the protection of internationally proclaimed human rights\n2. Principle: Businesses should make sure that they are is not complicit in human rights abuses',
      },
      laborPrinciples: {
        label: 'Labor principles',
        sublabel: '',
        tooltip:
          '3. Principle: Businesses should uphold the freedom of association and the effective recognition of the right to collective bargaining\n4. Principle: Businesses should uphold the elimination of forced or compulsory labor\n5. Principle: Businesses should uphold the effective abolition of child labor\n6. Principle: Businesses should uphold the elimination of discrimination in respect of employment and occupation',
      },
      environmentPrinciples: {
        label: 'Environment principles',
        sublabel: '',
        tooltip:
          '7. Principle: Businesses should support a precautionary approach to environmental challenges\n8. Principle: Businesses should undertake initiatives to promote greater environmental responsibility\n9. Principle: Businesses should encourage the development and diffusion of environmentally friendly technologies',
      },
      anti_CorruptionPrinciple: {
        label: 'Anti-corruption principle',
        sublabel: '',
        tooltip:
          '10. Principle: Businesses should work against corruption in all its forms, including extortion and bribery',
      },
      noneOfTheAbove: {
        label: 'None of the above',
        sublabel: '',
        tooltip: '',
      },
      animalTesting: {
        label: 'Animal testing',
        sublabel: '',
        tooltip: '',
      },
      alcoholicBeverages: {
        label: 'Alcoholic beverages',
        sublabel: '',
        tooltip: '',
      },
      driftNetFishingInTheMarineEnvironment: {
        label: 'Drift net fishing in the marine environment',
        sublabel: '',
        tooltip: '',
      },
      nuclearEnergyAndNuclearPowerPlants: {
        label: 'Nuclear energy and nuclear power plants',
        sublabel: '',
        tooltip: '',
      },
      chlorinatedHydrocarbon: {
        label: 'Chlorinated hydrocarbon',
        sublabel: '',
        tooltip: '',
      },
      embryoResearch: {
        label: 'Embryo research',
        sublabel: '',
        tooltip: '',
      },
      violentVideoGames: {
        label: 'Violent video games',
        sublabel: '',
        tooltip: '',
      },
      gambling: {
        label: 'Gambling',
        sublabel: '',
        tooltip: '',
      },
      greenGeneticEngineering: {
        label: 'Green genetic engineering',
        sublabel: '',
        tooltip: '',
      },
      furs: {
        label: 'Furs',
        sublabel: '',
        tooltip: '',
      },
      ozone_DepletingSubstances: {
        label: 'Ozone-depleting substances',
        sublabel: '',
        tooltip: '',
      },
      pesticides_Herbicides: {
        label: 'Pesticides/herbicides',
        sublabel: '',
        tooltip: '',
      },
      pornography: {
        label: 'Pornography',
        sublabel: '',
        tooltip: '',
      },
      militaryArmamentWeaponsAndMunition: {
        label: 'Military armament, weapons, and munition',
        sublabel: '',
        tooltip: '',
      },
      tobacco: {
        label: 'Tobacco',
        sublabel: '',
        tooltip: '',
      },
      unbondedAsbestosFibers: {
        label: 'Unbonded asbestos fibers',
        sublabel: '',
        tooltip: '',
      },
      noneOfTheAbove_v2: {
        label: 'None of the above',
        sublabel: '',
        tooltip: '',
      },
      thereAreRevenuesFromFossilFuels: {
        label: 'There are revenues from fossil fuels',
        sublabel: '',
        tooltip: '',
      },
      noThereAreNoRevenuesFromFossilFuels: {
        label: 'No, there are no revenues from fossil fuels',
        sublabel: '',
        tooltip: '',
      },
      thereHaveBeenPublicComplaintsOrLegalActionsInThe: {
        label:
          'There have been public complaints or legal actions in the last 3 years',
        sublabel: '',
        tooltip: '',
      },
      noThereHaveBeenNoPublicComplaintsOrLegalActions: {
        label:
          'No, there have been no public complaints or legal actions in the last 3 years',
        sublabel: '',
        tooltip: '',
      },
      n5001_10000: {
        label: '5001-10000',
        sublabel: '',
        tooltip: '',
      },
      yesWeAdhereToTheOecdGuidelinesForMultinationalEnterprises: {
        label:
          'Yes, we adhere to the OECD Guidelines for Multinational Enterprises.',
        sublabel: '',
        tooltip: '',
      },
      noWeDontAdhereToTheOecdGuidelinesForMultinational: {
        label:
          "No, we don't adhere to the OECD Guidelines for Multinational Enterprises.",
        sublabel: '',
        tooltip: '',
      },
      weDontWantToReportOnThis: {
        label: "We don't want to report on this.",
        sublabel: '',
        tooltip: '',
      },
      yesWeAdhereToTheGuidingPrinciplesOnBusinessAnd: {
        label:
          'Yes, we adhere to the guiding principles on Business and Human Rights, including the principles and rights set out in the eight fundamental ILO conventions',
        sublabel: '',
        tooltip: '',
      },
      noWeDontAdhereToTheGuidingPrinciplesOnBusiness: {
        label:
          "No, we don't adhere to the guiding principles on Business and Human Rights, including the principles and rights, set out in the eight fundamental ILO conventions",
        sublabel: '',
        tooltip: '',
      },
      weDontWantToReportOnThis_v2: {
        label: "We don't want to report on this.",
        sublabel: '',
        tooltip: '',
      },
      other_v7: {
        label: 'Other',
        sublabel: '',
        tooltip: '',
      },
      defenseAndSpaceManufacturing: {
        label: 'Defense and Space Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      computerHardwareManufacturing: {
        label: 'Computer Hardware Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      softwareDevelopment: {
        label: 'Software Development',
        sublabel: '',
        tooltip: '',
      },
      computerNetworkingProducts: {
        label: 'Computer Networking Products',
        sublabel: '',
        tooltip: '',
      },
      technologyInformationAndInternet: {
        label: 'Technology, Information and Internet',
        sublabel: '',
        tooltip: '',
      },
      semiconductorManufacturing: {
        label: 'Semiconductor Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      telecommunications: {
        label: 'Telecommunications',
        sublabel: '',
        tooltip: '',
      },
      lawPractice: {
        label: 'Law Practice',
        sublabel: '',
        tooltip: '',
      },
      legalServices: {
        label: 'Legal Services',
        sublabel: '',
        tooltip: '',
      },
      businessConsultingAndServices: {
        label: 'Business Consulting and Services',
        sublabel: '',
        tooltip: '',
      },
      biotechnologyResearch: {
        label: 'Biotechnology Research',
        sublabel: '',
        tooltip: '',
      },
      medicalPractices: {
        label: 'Medical Practices',
        sublabel: '',
        tooltip: '',
      },
      hospitalsAndHealthCare: {
        label: 'Hospitals and Health Care',
        sublabel: '',
        tooltip: '',
      },
      pharmaceuticalManufacturing: {
        label: 'Pharmaceutical Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      veterinaryServices: {
        label: 'Veterinary Services',
        sublabel: '',
        tooltip: '',
      },
      medicalEquipmentManufacturing: {
        label: 'Medical Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      personalCareProductManufacturing: {
        label: 'Personal Care Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      retailApparelAndFashion: {
        label: 'Retail Apparel and Fashion',
        sublabel: '',
        tooltip: '',
      },
      sportingGoodsManufacturing: {
        label: 'Sporting Goods Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      tobaccoManufacturing: {
        label: 'Tobacco Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      retailGroceries: {
        label: 'Retail Groceries',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageManufacturing: {
        label: 'Food and Beverage Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      computersAndElectronicsManufacturing: {
        label: 'Computers and Electronics Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      manufacturing_v3: {
        label: 'Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      furnitureAndHomeFurnishingsManufacturing: {
        label: 'Furniture and Home Furnishings Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      retail: {
        label: 'Retail',
        sublabel: '',
        tooltip: '',
      },
      entertainmentProviders: {
        label: 'Entertainment Providers',
        sublabel: '',
        tooltip: '',
      },
      gamblingFacilitiesAndCasinos: {
        label: 'Gambling Facilities and Casinos',
        sublabel: '',
        tooltip: '',
      },
      travelArrangements: {
        label: 'Travel Arrangements',
        sublabel: '',
        tooltip: '',
      },
      hospitality: {
        label: 'Hospitality',
        sublabel: '',
        tooltip: '',
      },
      restaurants: {
        label: 'Restaurants',
        sublabel: '',
        tooltip: '',
      },
      spectatorSports: {
        label: 'Spectator Sports',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageServices: {
        label: 'Food and Beverage Services',
        sublabel: '',
        tooltip: '',
      },
      moviesVideosAndSound: {
        label: 'Movies, Videos and Sound',
        sublabel: '',
        tooltip: '',
      },
      broadcastMediaProductionAndDistribution: {
        label: 'Broadcast Media Production and Distribution',
        sublabel: '',
        tooltip: '',
      },
      museumsHistoricalSitesAndZoos: {
        label: 'Museums, Historical Sites, and Zoos',
        sublabel: '',
        tooltip: '',
      },
      artistsAndWriters: {
        label: 'Artists and Writers',
        sublabel: '',
        tooltip: '',
      },
      performingArts: {
        label: 'Performing Arts',
        sublabel: '',
        tooltip: '',
      },
      recreationalFacilities: {
        label: 'Recreational Facilities',
        sublabel: '',
        tooltip: '',
      },
      banking_v2: {
        label: 'Banking',
        sublabel: '',
        tooltip: '',
      },
      insurance_v2: {
        label: 'Insurance',
        sublabel: '',
        tooltip: '',
      },
      financialServices: {
        label: 'Financial Services',
        sublabel: '',
        tooltip: '',
      },
      leasingRealEstate: {
        label: 'Leasing Real Estate',
        sublabel: '',
        tooltip: '',
      },
      investmentBanking: {
        label: 'Investment Banking',
        sublabel: '',
        tooltip: '',
      },
      investmentManagement: {
        label: 'Investment Management',
        sublabel: '',
        tooltip: '',
      },
      accounting: {
        label: 'Accounting',
        sublabel: '',
        tooltip: '',
      },
      construction_v2: {
        label: 'Construction',
        sublabel: '',
        tooltip: '',
      },
      wholesaleBuildingMaterials: {
        label: 'Wholesale Building Materials',
        sublabel: '',
        tooltip: '',
      },
      architectureAndPlanning: {
        label: 'Architecture and Planning',
        sublabel: '',
        tooltip: '',
      },
      civilEngineering: {
        label: 'Civil Engineering',
        sublabel: '',
        tooltip: '',
      },
      aviationAndAerospaceComponentManufacturing: {
        label: 'Aviation and Aerospace Component Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      motorVehicleManufacturing: {
        label: 'Motor Vehicle Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      chemicalManufacturing: {
        label: 'Chemical Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      machineryManufacturing: {
        label: 'Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      mining: {
        label: 'Mining',
        sublabel: '',
        tooltip: '',
      },
      oilAndGas: {
        label: 'Oil and Gas',
        sublabel: '',
        tooltip: '',
      },
      shipbuilding: {
        label: 'Shipbuilding',
        sublabel: '',
        tooltip: '',
      },
      utilities: {
        label: 'Utilities',
        sublabel: '',
        tooltip: '',
      },
      textileManufacturing: {
        label: 'Textile Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      paperAndForestProductManufacturing: {
        label: 'Paper and Forest Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      railroadEquipmentManufacturing: {
        label: 'Railroad Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      farming: {
        label: 'Farming',
        sublabel: '',
        tooltip: '',
      },
      ranching: {
        label: 'Ranching',
        sublabel: '',
        tooltip: '',
      },
      dairyProductManufacturing: {
        label: 'Dairy Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      fisheries: {
        label: 'Fisheries',
        sublabel: '',
        tooltip: '',
      },
      primaryAndSecondaryEducation: {
        label: 'Primary and Secondary Education',
        sublabel: '',
        tooltip: '',
      },
      higherEducation: {
        label: 'Higher Education',
        sublabel: '',
        tooltip: '',
      },
      educationAdministrationPrograms: {
        label: 'Education Administration Programs',
        sublabel: '',
        tooltip: '',
      },
      researchServices: {
        label: 'Research Services',
        sublabel: '',
        tooltip: '',
      },
      armedForces: {
        label: 'Armed Forces',
        sublabel: '',
        tooltip: '',
      },
      legislativeOffices: {
        label: 'Legislative Offices',
        sublabel: '',
        tooltip: '',
      },
      administrationOfJustice: {
        label: 'Administration of Justice',
        sublabel: '',
        tooltip: '',
      },
      internationalAffairs: {
        label: 'International Affairs',
        sublabel: '',
        tooltip: '',
      },
      governmentAdministration: {
        label: 'Government Administration',
        sublabel: '',
        tooltip: '',
      },
      executiveOffices: {
        label: 'Executive Offices',
        sublabel: '',
        tooltip: '',
      },
      lawEnforcement: {
        label: 'Law Enforcement',
        sublabel: '',
        tooltip: '',
      },
      publicSafety_v2: {
        label: 'Public Safety',
        sublabel: '',
        tooltip: '',
      },
      publicPolicyOffices: {
        label: 'Public Policy Offices',
        sublabel: '',
        tooltip: '',
      },
      marketingServices: {
        label: 'Marketing Services',
        sublabel: '',
        tooltip: '',
      },
      newspaperPublishing: {
        label: 'Newspaper Publishing',
        sublabel: '',
        tooltip: '',
      },
      bookAndPeriodicalPublishing: {
        label: 'Book and Periodical Publishing',
        sublabel: '',
        tooltip: '',
      },
      printingServices: {
        label: 'Printing Services',
        sublabel: '',
        tooltip: '',
      },
      informationServices_v2: {
        label: 'Information Services',
        sublabel: '',
        tooltip: '',
      },
      libraries: {
        label: 'Libraries',
        sublabel: '',
        tooltip: '',
      },
      environmentalServices: {
        label: 'Environmental Services',
        sublabel: '',
        tooltip: '',
      },
      freightAndPackageTransportation: {
        label: 'Freight and Package Transportation',
        sublabel: '',
        tooltip: '',
      },
      individualAndFamilyServices: {
        label: 'Individual and Family Services',
        sublabel: '',
        tooltip: '',
      },
      religiousInstitutions: {
        label: 'Religious Institutions',
        sublabel: '',
        tooltip: '',
      },
      civicAndSocialOrganizations: {
        label: 'Civic and Social Organizations',
        sublabel: '',
        tooltip: '',
      },
      consumerServices: {
        label: 'Consumer Services',
        sublabel: '',
        tooltip: '',
      },
      truckTransportation: {
        label: 'Truck Transportation',
        sublabel: '',
        tooltip: '',
      },
      warehousingAndStorage: {
        label: 'Warehousing and Storage',
        sublabel: '',
        tooltip: '',
      },
      airlinesAndAviation: {
        label: 'Airlines and Aviation',
        sublabel: '',
        tooltip: '',
      },
      maritimeTransportation: {
        label: 'Maritime Transportation',
        sublabel: '',
        tooltip: '',
      },
      itServicesAndItConsulting: {
        label: 'IT Services and IT Consulting',
        sublabel: '',
        tooltip: '',
      },
      marketResearch: {
        label: 'Market Research',
        sublabel: '',
        tooltip: '',
      },
      publicRelationsAndCommunicationsServices: {
        label: 'Public Relations and Communications Services',
        sublabel: '',
        tooltip: '',
      },
      designServices: {
        label: 'Design Services',
        sublabel: '',
        tooltip: '',
      },
      non_ProfitOrganizations: {
        label: 'Non-profit Organizations',
        sublabel: '',
        tooltip: '',
      },
      fundraising: {
        label: 'Fundraising',
        sublabel: '',
        tooltip: '',
      },
      strategicManagementServices: {
        label: 'Strategic Management Services',
        sublabel: '',
        tooltip: '',
      },
      writingAndEditing: {
        label: 'Writing and Editing',
        sublabel: '',
        tooltip: '',
      },
      staffingAndRecruiting: {
        label: 'Staffing and Recruiting',
        sublabel: '',
        tooltip: '',
      },
      businessSkillsTraining: {
        label: 'Business Skills Training',
        sublabel: '',
        tooltip: '',
      },
      ventureCapitalAndPrivateEquityPrincipals: {
        label: 'Venture Capital and Private Equity Principals',
        sublabel: '',
        tooltip: '',
      },
      politicalOrganizations: {
        label: 'Political Organizations',
        sublabel: '',
        tooltip: '',
      },
      translationAndLocalization: {
        label: 'Translation and Localization',
        sublabel: '',
        tooltip: '',
      },
      computerGames: {
        label: 'Computer Games',
        sublabel: '',
        tooltip: '',
      },
      eventsServices: {
        label: 'Events Services',
        sublabel: '',
        tooltip: '',
      },
      retailArtSupplies: {
        label: 'Retail Art Supplies',
        sublabel: '',
        tooltip: '',
      },
      appliancesElectricalAndElectronicsManufacturing: {
        label: 'Appliances, Electrical, and Electronics Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      onlineAudioAndVideoMedia: {
        label: 'Online Audio and Video Media',
        sublabel: '',
        tooltip: '',
      },
      nanotechnologyResearch: {
        label: 'Nanotechnology Research',
        sublabel: '',
        tooltip: '',
      },
      musicians: {
        label: 'Musicians',
        sublabel: '',
        tooltip: '',
      },
      transportationLogisticsSupplyChainAndStorage: {
        label: 'Transportation, Logistics, Supply Chain and Storage',
        sublabel: '',
        tooltip: '',
      },
      plasticsManufacturing: {
        label: 'Plastics Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      computerAndNetworkSecurity: {
        label: 'Computer and Network Security',
        sublabel: '',
        tooltip: '',
      },
      wirelessServices: {
        label: 'Wireless Services',
        sublabel: '',
        tooltip: '',
      },
      alternativeDisputeResolution: {
        label: 'Alternative Dispute Resolution',
        sublabel: '',
        tooltip: '',
      },
      securityAndInvestigations: {
        label: 'Security and Investigations',
        sublabel: '',
        tooltip: '',
      },
      facilitiesServices: {
        label: 'Facilities Services',
        sublabel: '',
        tooltip: '',
      },
      outsourcingAndOffshoringConsulting: {
        label: 'Outsourcing and Offshoring Consulting',
        sublabel: '',
        tooltip: '',
      },
      wellnessAndFitnessServices: {
        label: 'Wellness and Fitness Services',
        sublabel: '',
        tooltip: '',
      },
      alternativeMedicine: {
        label: 'Alternative Medicine',
        sublabel: '',
        tooltip: '',
      },
      mediaProduction: {
        label: 'Media Production',
        sublabel: '',
        tooltip: '',
      },
      animationAndPost_Production: {
        label: 'Animation and Post-production',
        sublabel: '',
        tooltip: '',
      },
      leasingNon_ResidentialRealEstate: {
        label: 'Leasing Non-residential Real Estate',
        sublabel: '',
        tooltip: '',
      },
      capitalMarkets: {
        label: 'Capital Markets',
        sublabel: '',
        tooltip: '',
      },
      thinkTanks: {
        label: 'Think Tanks',
        sublabel: '',
        tooltip: '',
      },
      philanthropicFundraisingServices: {
        label: 'Philanthropic Fundraising Services',
        sublabel: '',
        tooltip: '',
      },
      e_LearningProviders: {
        label: 'E-Learning Providers',
        sublabel: '',
        tooltip: '',
      },
      wholesale: {
        label: 'Wholesale',
        sublabel: '',
        tooltip: '',
      },
      wholesaleImportAndExport: {
        label: 'Wholesale Import and Export',
        sublabel: '',
        tooltip: '',
      },
      industrialMachineryManufacturing: {
        label: 'Industrial Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      photography: {
        label: 'Photography',
        sublabel: '',
        tooltip: '',
      },
      humanResourcesServices: {
        label: 'Human Resources Services',
        sublabel: '',
        tooltip: '',
      },
      retailOfficeEquipment: {
        label: 'Retail Office Equipment',
        sublabel: '',
        tooltip: '',
      },
      mentalHealthCare: {
        label: 'Mental Health Care',
        sublabel: '',
        tooltip: '',
      },
      graphicDesign: {
        label: 'Graphic Design',
        sublabel: '',
        tooltip: '',
      },
      internationalTradeAndDevelopment: {
        label: 'International Trade and Development',
        sublabel: '',
        tooltip: '',
      },
      beverageManufacturing: {
        label: 'Beverage Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      retailLuxuryGoodsAndJewelry: {
        label: 'Retail Luxury Goods and Jewelry',
        sublabel: '',
        tooltip: '',
      },
      renewableEnergySemiconductorManufacturing: {
        label: 'Renewable Energy Semiconductor Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      glassCeramicsAndConcreteManufacturing: {
        label: 'Glass, Ceramics and Concrete Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      packagingAndContainersManufacturing: {
        label: 'Packaging and Containers Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      automationMachineryManufacturing: {
        label: 'Automation Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      governmentRelationsServices: {
        label: 'Government Relations Services',
        sublabel: '',
        tooltip: '',
      },
      horticulture: {
        label: 'Horticulture',
        sublabel: '',
        tooltip: '',
      },
      farmingRanchingForestry: {
        label: 'Farming, Ranching, Forestry',
        sublabel: '',
        tooltip: '',
      },
      ranchingAndFisheries: {
        label: 'Ranching and Fisheries',
        sublabel: '',
        tooltip: '',
      },
      forestryAndLogging: {
        label: 'Forestry and Logging',
        sublabel: '',
        tooltip: '',
      },
      oilGasAndMining: {
        label: 'Oil, Gas, and Mining',
        sublabel: '',
        tooltip: '',
      },
      coalMining: {
        label: 'Coal Mining',
        sublabel: '',
        tooltip: '',
      },
      metalOreMining: {
        label: 'Metal Ore Mining',
        sublabel: '',
        tooltip: '',
      },
      nonmetallicMineralMining: {
        label: 'Nonmetallic Mineral Mining',
        sublabel: '',
        tooltip: '',
      },
      electricPowerTransmissionControlAndDistribution: {
        label: 'Electric Power Transmission, Control, and Distribution',
        sublabel: '',
        tooltip: '',
      },
      electricPowerGeneration: {
        label: 'Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      hydroelectricPowerGeneration: {
        label: 'Hydroelectric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      fossilFuelElectricPowerGeneration: {
        label: 'Fossil Fuel Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      nuclearElectricPowerGeneration: {
        label: 'Nuclear Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      solarElectricPowerGeneration: {
        label: 'Solar Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      environmentalQualityPrograms: {
        label: 'Environmental Quality Programs',
        sublabel: '',
        tooltip: '',
      },
      geothermalElectricPowerGeneration: {
        label: 'Geothermal Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      biomassElectricPowerGeneration: {
        label: 'Biomass Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      naturalGasDistribution: {
        label: 'Natural Gas Distribution',
        sublabel: '',
        tooltip: '',
      },
      waterWasteSteamAndAirConditioningServices: {
        label: 'Water, Waste, Steam, and Air Conditioning Services',
        sublabel: '',
        tooltip: '',
      },
      waterSupplyAndIrrigationSystems: {
        label: 'Water Supply and Irrigation Systems',
        sublabel: '',
        tooltip: '',
      },
      steamAndAir_ConditioningSupply: {
        label: 'Steam and Air-Conditioning Supply',
        sublabel: '',
        tooltip: '',
      },
      buildingConstruction: {
        label: 'Building Construction',
        sublabel: '',
        tooltip: '',
      },
      residentialBuildingConstruction: {
        label: 'Residential Building Construction',
        sublabel: '',
        tooltip: '',
      },
      nonresidentialBuildingConstruction: {
        label: 'Nonresidential Building Construction',
        sublabel: '',
        tooltip: '',
      },
      utilitySystemConstruction: {
        label: 'Utility System Construction',
        sublabel: '',
        tooltip: '',
      },
      subdivisionOfLand: {
        label: 'Subdivision of Land',
        sublabel: '',
        tooltip: '',
      },
      highwayStreetAndBridgeConstruction: {
        label: 'Highway, Street, and Bridge Construction',
        sublabel: '',
        tooltip: '',
      },
      specialtyTradeContractors: {
        label: 'Specialty Trade Contractors',
        sublabel: '',
        tooltip: '',
      },
      buildingStructureAndExteriorContractors: {
        label: 'Building Structure and Exterior Contractors',
        sublabel: '',
        tooltip: '',
      },
      buildingEquipmentContractors: {
        label: 'Building Equipment Contractors',
        sublabel: '',
        tooltip: '',
      },
      buildingFinishingContractors: {
        label: 'Building Finishing Contractors',
        sublabel: '',
        tooltip: '',
      },
      animalFeedManufacturing: {
        label: 'Animal Feed Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      sugarAndConfectioneryProductManufacturing: {
        label: 'Sugar and Confectionery Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      fruitAndVegetablePreservesManufacturing: {
        label: 'Fruit and Vegetable Preserves Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      meatProductsManufacturing: {
        label: 'Meat Products Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      seafoodProductManufacturing: {
        label: 'Seafood Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      bakedGoodsManufacturing: {
        label: 'Baked Goods Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      breweries: {
        label: 'Breweries',
        sublabel: '',
        tooltip: '',
      },
      distilleries: {
        label: 'Distilleries',
        sublabel: '',
        tooltip: '',
      },
      apparelManufacturing: {
        label: 'Apparel Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      fashionAccessoriesManufacturing: {
        label: 'Fashion Accessories Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      leatherProductManufacturing: {
        label: 'Leather Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      footwearManufacturing: {
        label: 'Footwear Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      womensHandbagManufacturing: {
        label: "Women's Handbag Manufacturing",
        sublabel: '',
        tooltip: '',
      },
      oilAndCoalProductManufacturing: {
        label: 'Oil and Coal Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      chemicalRawMaterialsManufacturing: {
        label: 'Chemical Raw Materials Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      artificialRubberAndSyntheticFiberManufacturing: {
        label: 'Artificial Rubber and Synthetic Fiber Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      agriculturalChemicalManufacturing: {
        label: 'Agricultural Chemical Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      paintCoatingAndAdhesiveManufacturing: {
        label: 'Paint, Coating, and Adhesive Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      soapAndCleaningProductManufacturing: {
        label: 'Soap and Cleaning Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      plasticsAndRubberProductManufacturing: {
        label: 'Plastics and Rubber Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      rubberProductsManufacturing: {
        label: 'Rubber Products Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      clayAndRefractoryProductsManufacturing: {
        label: 'Clay and Refractory Products Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      glassProductManufacturing: {
        label: 'Glass Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      woodProductManufacturing: {
        label: 'Wood Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      limeAndGypsumProductsManufacturing: {
        label: 'Lime and Gypsum Products Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      abrasivesAndNonmetallicMineralsManufacturing: {
        label: 'Abrasives and Nonmetallic Minerals Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      primaryMetalManufacturing: {
        label: 'Primary Metal Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      fabricatedMetalProducts: {
        label: 'Fabricated Metal Products',
        sublabel: '',
        tooltip: '',
      },
      cutleryAndHandtoolManufacturing: {
        label: 'Cutlery and Handtool Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      architecturalAndStructuralMetalManufacturing: {
        label: 'Architectural and Structural Metal Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      boilersTanksAndShippingContainerManufacturing: {
        label: 'Boilers, Tanks, and Shipping Container Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      constructionHardwareManufacturing: {
        label: 'Construction Hardware Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      springAndWireProductManufacturing: {
        label: 'Spring and Wire Product Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      turnedProductsAndFastenerManufacturing: {
        label: 'Turned Products and Fastener Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      metalTreatments: {
        label: 'Metal Treatments',
        sublabel: '',
        tooltip: '',
      },
      metalValveBallAndRollerManufacturing: {
        label: 'Metal Valve, Ball, and Roller Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      agricultureConstructionMiningMachineryManufacturing: {
        label: 'Agriculture, Construction, Mining Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      commercialAndServiceIndustryMachineryManufacturing: {
        label: 'Commercial and Service Industry Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      hvacAndRefrigerationEquipmentManufacturing: {
        label: 'HVAC and Refrigeration Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      metalworkingMachineryManufacturing: {
        label: 'Metalworking Machinery Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      enginesAndPowerTransmissionEquipmentManufacturing: {
        label: 'Engines and Power Transmission Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      communicationsEquipmentManufacturing: {
        label: 'Communications Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      audioAndVideoEquipmentManufacturing: {
        label: 'Audio and Video Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      measuringAndControlInstrumentManufacturing: {
        label: 'Measuring and Control Instrument Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      magneticAndOpticalMediaManufacturing: {
        label: 'Magnetic and Optical Media Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      electricLightingEquipmentManufacturing: {
        label: 'Electric Lighting Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      householdApplianceManufacturing: {
        label: 'Household Appliance Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      transportationEquipmentManufacturing: {
        label: 'Transportation Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      motorVehiclePartsManufacturing: {
        label: 'Motor Vehicle Parts Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      householdAndInstitutionalFurnitureManufacturing: {
        label: 'Household and Institutional Furniture Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      officeFurnitureAndFixturesManufacturing: {
        label: 'Office Furniture and Fixtures Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      mattressAndBlindsManufacturing: {
        label: 'Mattress and Blinds Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMotorVehiclesAndParts: {
        label: 'Wholesale Motor Vehicles and Parts',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFurnitureAndHomeFurnishings: {
        label: 'Wholesale Furniture and Home Furnishings',
        sublabel: '',
        tooltip: '',
      },
      wholesalePhotographyEquipmentAndSupplies: {
        label: 'Wholesale Photography Equipment and Supplies',
        sublabel: '',
        tooltip: '',
      },
      wholesaleComputerEquipment: {
        label: 'Wholesale Computer Equipment',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMetalsAndMinerals: {
        label: 'Wholesale Metals and Minerals',
        sublabel: '',
        tooltip: '',
      },
      wholesaleAppliancesElectricalAndElectronics: {
        label: 'Wholesale Appliances, Electrical, and Electronics',
        sublabel: '',
        tooltip: '',
      },
      wholesaleHardwarePlumbingHeatingEquipment: {
        label: 'Wholesale Hardware, Plumbing, Heating Equipment',
        sublabel: '',
        tooltip: '',
      },
      wholesaleMachinery: {
        label: 'Wholesale Machinery',
        sublabel: '',
        tooltip: '',
      },
      wholesaleRecyclableMaterials: {
        label: 'Wholesale Recyclable Materials',
        sublabel: '',
        tooltip: '',
      },
      wholesaleLuxuryGoodsAndJewelry: {
        label: 'Wholesale Luxury Goods and Jewelry',
        sublabel: '',
        tooltip: '',
      },
      wholesalePaperProducts: {
        label: 'Wholesale Paper Products',
        sublabel: '',
        tooltip: '',
      },
      wholesaleDrugsAndSundries: {
        label: 'Wholesale Drugs and Sundries',
        sublabel: '',
        tooltip: '',
      },
      wholesaleApparelAndSewingSupplies: {
        label: 'Wholesale Apparel and Sewing Supplies',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFootwear: {
        label: 'Wholesale Footwear',
        sublabel: '',
        tooltip: '',
      },
      wholesaleFoodAndBeverage: {
        label: 'Wholesale Food and Beverage',
        sublabel: '',
        tooltip: '',
      },
      wholesaleRawFarmProducts: {
        label: 'Wholesale Raw Farm Products',
        sublabel: '',
        tooltip: '',
      },
      wholesaleChemicalAndAlliedProducts: {
        label: 'Wholesale Chemical and Allied Products',
        sublabel: '',
        tooltip: '',
      },
      wholesalePetroleumAndPetroleumProducts: {
        label: 'Wholesale Petroleum and Petroleum Products',
        sublabel: '',
        tooltip: '',
      },
      wholesaleAlcoholicBeverages: {
        label: 'Wholesale Alcoholic Beverages',
        sublabel: '',
        tooltip: '',
      },
      internetMarketplacePlatforms: {
        label: 'Internet Marketplace Platforms',
        sublabel: '',
        tooltip: '',
      },
      retailMotorVehicles: {
        label: 'Retail Motor Vehicles',
        sublabel: '',
        tooltip: '',
      },
      retailFurnitureAndHomeFurnishings: {
        label: 'Retail Furniture and Home Furnishings',
        sublabel: '',
        tooltip: '',
      },
      retailAppliancesElectricalAndElectronicEquipment: {
        label: 'Retail Appliances, Electrical, and Electronic Equipment',
        sublabel: '',
        tooltip: '',
      },
      retailBuildingMaterialsAndGardenEquipment: {
        label: 'Retail Building Materials and Garden Equipment',
        sublabel: '',
        tooltip: '',
      },
      foodAndBeverageRetail: {
        label: 'Food and Beverage Retail',
        sublabel: '',
        tooltip: '',
      },
      retailHealthAndPersonalCareProducts: {
        label: 'Retail Health and Personal Care Products',
        sublabel: '',
        tooltip: '',
      },
      retailGasoline: {
        label: 'Retail Gasoline',
        sublabel: '',
        tooltip: '',
      },
      retailMusicalInstruments: {
        label: 'Retail Musical Instruments',
        sublabel: '',
        tooltip: '',
      },
      retailBooksAndPrintedNews: {
        label: 'Retail Books and Printed News',
        sublabel: '',
        tooltip: '',
      },
      retailFlorists: {
        label: 'Retail Florists',
        sublabel: '',
        tooltip: '',
      },
      retailOfficeSuppliesAndGifts: {
        label: 'Retail Office Supplies and Gifts',
        sublabel: '',
        tooltip: '',
      },
      retailRecyclableMaterialsUsedMerchandise: {
        label: 'Retail Recyclable Materials & Used Merchandise',
        sublabel: '',
        tooltip: '',
      },
      onlineAndMailOrderRetail: {
        label: 'Online and Mail Order Retail',
        sublabel: '',
        tooltip: '',
      },
      railTransportation: {
        label: 'Rail Transportation',
        sublabel: '',
        tooltip: '',
      },
      groundPassengerTransportation: {
        label: 'Ground Passenger Transportation',
        sublabel: '',
        tooltip: '',
      },
      urbanTransitServices: {
        label: 'Urban Transit Services',
        sublabel: '',
        tooltip: '',
      },
      interurbanAndRuralBusServices: {
        label: 'Interurban and Rural Bus Services',
        sublabel: '',
        tooltip: '',
      },
      taxiAndLimousineServices: {
        label: 'Taxi and Limousine Services',
        sublabel: '',
        tooltip: '',
      },
      schoolAndEmployeeBusServices: {
        label: 'School and Employee Bus Services',
        sublabel: '',
        tooltip: '',
      },
      shuttlesAndSpecialNeedsTransportationServices: {
        label: 'Shuttles and Special Needs Transportation Services',
        sublabel: '',
        tooltip: '',
      },
      pipelineTransportation: {
        label: 'Pipeline Transportation',
        sublabel: '',
        tooltip: '',
      },
      sightseeingTransportation: {
        label: 'Sightseeing Transportation',
        sublabel: '',
        tooltip: '',
      },
      postalServices: {
        label: 'Postal Services',
        sublabel: '',
        tooltip: '',
      },
      technologyInformationAndMedia: {
        label: 'Technology, Information and Media',
        sublabel: '',
        tooltip: '',
      },
      periodicalPublishing: {
        label: 'Periodical Publishing',
        sublabel: '',
        tooltip: '',
      },
      bookPublishing: {
        label: 'Book Publishing',
        sublabel: '',
        tooltip: '',
      },
      moviesAndSoundRecording: {
        label: 'Movies and Sound Recording',
        sublabel: '',
        tooltip: '',
      },
      soundRecording: {
        label: 'Sound Recording',
        sublabel: '',
        tooltip: '',
      },
      sheetMusicPublishing: {
        label: 'Sheet Music Publishing',
        sublabel: '',
        tooltip: '',
      },
      radioAndTelevisionBroadcasting: {
        label: 'Radio and Television Broadcasting',
        sublabel: '',
        tooltip: '',
      },
      cableAndSatelliteProgramming: {
        label: 'Cable and Satellite Programming',
        sublabel: '',
        tooltip: '',
      },
      telecommunicationsCarriers: {
        label: 'Telecommunications Carriers',
        sublabel: '',
        tooltip: '',
      },
      satelliteTelecommunications: {
        label: 'Satellite Telecommunications',
        sublabel: '',
        tooltip: '',
      },
      creditIntermediation: {
        label: 'Credit Intermediation',
        sublabel: '',
        tooltip: '',
      },
      savingsInstitutions: {
        label: 'Savings Institutions',
        sublabel: '',
        tooltip: '',
      },
      loanBrokers: {
        label: 'Loan Brokers',
        sublabel: '',
        tooltip: '',
      },
      securitiesAndCommodityExchanges: {
        label: 'Securities and Commodity Exchanges',
        sublabel: '',
        tooltip: '',
      },
      investmentAdvice: {
        label: 'Investment Advice',
        sublabel: '',
        tooltip: '',
      },
      insuranceCarriers: {
        label: 'Insurance Carriers',
        sublabel: '',
        tooltip: '',
      },
      insuranceAgenciesAndBrokerages: {
        label: 'Insurance Agencies and Brokerages',
        sublabel: '',
        tooltip: '',
      },
      claimsAdjustingActuarialServices: {
        label: 'Claims Adjusting, Actuarial Services',
        sublabel: '',
        tooltip: '',
      },
      fundsAndTrusts: {
        label: 'Funds and Trusts',
        sublabel: '',
        tooltip: '',
      },
      insuranceAndEmployeeBenefitFunds: {
        label: 'Insurance and Employee Benefit Funds',
        sublabel: '',
        tooltip: '',
      },
      pensionFunds: {
        label: 'Pension Funds',
        sublabel: '',
        tooltip: '',
      },
      trustsAndEstates: {
        label: 'Trusts and Estates',
        sublabel: '',
        tooltip: '',
      },
      realEstateAndEquipmentRentalServices: {
        label: 'Real Estate and Equipment Rental Services',
        sublabel: '',
        tooltip: '',
      },
      leasingResidentialRealEstate: {
        label: 'Leasing Residential Real Estate',
        sublabel: '',
        tooltip: '',
      },
      leasingRealEstateAgentsAndBrokers: {
        label: 'Leasing Real Estate Agents and Brokers',
        sublabel: '',
        tooltip: '',
      },
      equipmentRentalServices: {
        label: 'Equipment Rental Services',
        sublabel: '',
        tooltip: '',
      },
      consumerGoodsRental: {
        label: 'Consumer Goods Rental',
        sublabel: '',
        tooltip: '',
      },
      commercialAndIndustrialEquipmentRental: {
        label: 'Commercial and Industrial Equipment Rental',
        sublabel: '',
        tooltip: '',
      },
      professionalServices: {
        label: 'Professional Services',
        sublabel: '',
        tooltip: '',
      },
      itSystemDesignServices: {
        label: 'IT System Design Services',
        sublabel: '',
        tooltip: '',
      },
      advertisingServices: {
        label: 'Advertising Services',
        sublabel: '',
        tooltip: '',
      },
      holdingCompanies: {
        label: 'Holding Companies',
        sublabel: '',
        tooltip: '',
      },
      industryAssociations: {
        label: 'Industry Associations',
        sublabel: '',
        tooltip: '',
      },
      professionalOrganizations: {
        label: 'Professional Organizations',
        sublabel: '',
        tooltip: '',
      },
      administrativeAndSupportServices: {
        label: 'Administrative and Support Services',
        sublabel: '',
        tooltip: '',
      },
      officeAdministration: {
        label: 'Office Administration',
        sublabel: '',
        tooltip: '',
      },
      executiveSearchServices: {
        label: 'Executive Search Services',
        sublabel: '',
        tooltip: '',
      },
      temporaryHelpServices: {
        label: 'Temporary Help Services',
        sublabel: '',
        tooltip: '',
      },
      telephoneCallCenters: {
        label: 'Telephone Call Centers',
        sublabel: '',
        tooltip: '',
      },
      collectionAgencies: {
        label: 'Collection Agencies',
        sublabel: '',
        tooltip: '',
      },
      securityGuardsAndPatrolServices: {
        label: 'Security Guards and Patrol Services',
        sublabel: '',
        tooltip: '',
      },
      securitySystemsServices: {
        label: 'Security Systems Services',
        sublabel: '',
        tooltip: '',
      },
      janitorialServices: {
        label: 'Janitorial Services',
        sublabel: '',
        tooltip: '',
      },
      wasteCollection: {
        label: 'Waste Collection',
        sublabel: '',
        tooltip: '',
      },
      wasteTreatmentAndDisposal: {
        label: 'Waste Treatment and Disposal',
        sublabel: '',
        tooltip: '',
      },
      education_v2: {
        label: 'Education',
        sublabel: '',
        tooltip: '',
      },
      secretarialSchools: {
        label: 'Secretarial Schools',
        sublabel: '',
        tooltip: '',
      },
      technicalAndVocationalTraining: {
        label: 'Technical and Vocational Training',
        sublabel: '',
        tooltip: '',
      },
      cosmetologyAndBarberSchools: {
        label: 'Cosmetology and Barber Schools',
        sublabel: '',
        tooltip: '',
      },
      flightTraining: {
        label: 'Flight Training',
        sublabel: '',
        tooltip: '',
      },
      fineArtsSchools: {
        label: 'Fine Arts Schools',
        sublabel: '',
        tooltip: '',
      },
      sportsAndRecreationInstruction: {
        label: 'Sports and Recreation Instruction',
        sublabel: '',
        tooltip: '',
      },
      languageSchools: {
        label: 'Language Schools',
        sublabel: '',
        tooltip: '',
      },
      physicians: {
        label: 'Physicians',
        sublabel: '',
        tooltip: '',
      },
      dentists: {
        label: 'Dentists',
        sublabel: '',
        tooltip: '',
      },
      chiropractors: {
        label: 'Chiropractors',
        sublabel: '',
        tooltip: '',
      },
      optometrists: {
        label: 'Optometrists',
        sublabel: '',
        tooltip: '',
      },
      physicalOccupationalAndSpeechTherapists: {
        label: 'Physical, Occupational and Speech Therapists',
        sublabel: '',
        tooltip: '',
      },
      familyPlanningCenters: {
        label: 'Family Planning Centers',
        sublabel: '',
        tooltip: '',
      },
      outpatientCareCenters: {
        label: 'Outpatient Care Centers',
        sublabel: '',
        tooltip: '',
      },
      medicalAndDiagnosticLaboratories: {
        label: 'Medical and Diagnostic Laboratories',
        sublabel: '',
        tooltip: '',
      },
      homeHealthCareServices: {
        label: 'Home Health Care Services',
        sublabel: '',
        tooltip: '',
      },
      ambulanceServices: {
        label: 'Ambulance Services',
        sublabel: '',
        tooltip: '',
      },
      hospitals: {
        label: 'Hospitals',
        sublabel: '',
        tooltip: '',
      },
      nursingHomesAndResidentialCareFacilities: {
        label: 'Nursing Homes and Residential Care Facilities',
        sublabel: '',
        tooltip: '',
      },
      servicesForTheElderlyAndDisabled: {
        label: 'Services for the Elderly and Disabled',
        sublabel: '',
        tooltip: '',
      },
      communityServices: {
        label: 'Community Services',
        sublabel: '',
        tooltip: '',
      },
      emergencyAndReliefServices: {
        label: 'Emergency and Relief Services',
        sublabel: '',
        tooltip: '',
      },
      vocationalRehabilitationServices: {
        label: 'Vocational Rehabilitation Services',
        sublabel: '',
        tooltip: '',
      },
      childDayCareServices: {
        label: 'Child Day Care Services',
        sublabel: '',
        tooltip: '',
      },
      performingArtsAndSpectatorSports: {
        label: 'Performing Arts and Spectator Sports',
        sublabel: '',
        tooltip: '',
      },
      theaterCompanies: {
        label: 'Theater Companies',
        sublabel: '',
        tooltip: '',
      },
      danceCompanies: {
        label: 'Dance Companies',
        sublabel: '',
        tooltip: '',
      },
      circusesAndMagicShows: {
        label: 'Circuses and Magic Shows',
        sublabel: '',
        tooltip: '',
      },
      sportsTeamsAndClubs: {
        label: 'Sports Teams and Clubs',
        sublabel: '',
        tooltip: '',
      },
      racetracks: {
        label: 'Racetracks',
        sublabel: '',
        tooltip: '',
      },
      museums: {
        label: 'Museums',
        sublabel: '',
        tooltip: '',
      },
      historicalSites: {
        label: 'Historical Sites',
        sublabel: '',
        tooltip: '',
      },
      zoosAndBotanicalGardens: {
        label: 'Zoos and Botanical Gardens',
        sublabel: '',
        tooltip: '',
      },
      amusementParksAndArcades: {
        label: 'Amusement Parks and Arcades',
        sublabel: '',
        tooltip: '',
      },
      golfCoursesAndCountryClubs: {
        label: 'Golf Courses and Country Clubs',
        sublabel: '',
        tooltip: '',
      },
      skiingFacilities: {
        label: 'Skiing Facilities',
        sublabel: '',
        tooltip: '',
      },
      accomodationServices: {
        label: 'Accomodation Services',
        sublabel: '',
        tooltip: '',
      },
      hotelsAndMotels: {
        label: 'Hotels and Motels',
        sublabel: '',
        tooltip: '',
      },
      bed_And_BreakfastsHostelsHomestays: {
        label: 'Bed-and-Breakfasts, Hostels, Homestays',
        sublabel: '',
        tooltip: '',
      },
      caterers: {
        label: 'Caterers',
        sublabel: '',
        tooltip: '',
      },
      mobileFoodServices: {
        label: 'Mobile Food Services',
        sublabel: '',
        tooltip: '',
      },
      barsTavernsAndNightclubs: {
        label: 'Bars, Taverns, and Nightclubs',
        sublabel: '',
        tooltip: '',
      },
      repairAndMaintenance: {
        label: 'Repair and Maintenance',
        sublabel: '',
        tooltip: '',
      },
      vehicleRepairAndMaintenance: {
        label: 'Vehicle Repair and Maintenance',
        sublabel: '',
        tooltip: '',
      },
      electronicAndPrecisionEquipmentMaintenance: {
        label: 'Electronic and Precision Equipment Maintenance',
        sublabel: '',
        tooltip: '',
      },
      commercialAndIndustrialMachineryMaintenance: {
        label: 'Commercial and Industrial Machinery Maintenance',
        sublabel: '',
        tooltip: '',
      },
      reupholsteryAndFurnitureRepair: {
        label: 'Reupholstery and Furniture Repair',
        sublabel: '',
        tooltip: '',
      },
      footwearAndLeatherGoodsRepair: {
        label: 'Footwear and Leather Goods Repair',
        sublabel: '',
        tooltip: '',
      },
      personalAndLaundryServices: {
        label: 'Personal and Laundry Services',
        sublabel: '',
        tooltip: '',
      },
      personalCareServices: {
        label: 'Personal Care Services',
        sublabel: '',
        tooltip: '',
      },
      laundryAndDrycleaningServices: {
        label: 'Laundry and Drycleaning Services',
        sublabel: '',
        tooltip: '',
      },
      petServices: {
        label: 'Pet Services',
        sublabel: '',
        tooltip: '',
      },
      householdServices: {
        label: 'Household Services',
        sublabel: '',
        tooltip: '',
      },
      healthAndHumanServices: {
        label: 'Health and Human Services',
        sublabel: '',
        tooltip: '',
      },
      publicHealth: {
        label: 'Public Health',
        sublabel: '',
        tooltip: '',
      },
      publicAssistancePrograms: {
        label: 'Public Assistance Programs',
        sublabel: '',
        tooltip: '',
      },
      airWaterAndWasteProgramManagement: {
        label: 'Air, Water, and Waste Program Management',
        sublabel: '',
        tooltip: '',
      },
      conservationPrograms: {
        label: 'Conservation Programs',
        sublabel: '',
        tooltip: '',
      },
      housingAndCommunityDevelopment: {
        label: 'Housing and Community Development',
        sublabel: '',
        tooltip: '',
      },
      communityDevelopmentAndUrbanPlanning: {
        label: 'Community Development and Urban Planning',
        sublabel: '',
        tooltip: '',
      },
      economicPrograms: {
        label: 'Economic Programs',
        sublabel: '',
        tooltip: '',
      },
      militaryAndInternationalAffairs: {
        label: 'Military and International Affairs',
        sublabel: '',
        tooltip: '',
      },
      operationsConsulting: {
        label: 'Operations Consulting',
        sublabel: '',
        tooltip: '',
      },
      dataInfrastructureAndAnalytics: {
        label: 'Data Infrastructure and Analytics',
        sublabel: '',
        tooltip: '',
      },
      electricalEquipmentManufacturing: {
        label: 'Electrical Equipment Manufacturing',
        sublabel: '',
        tooltip: '',
      },
      windElectricPowerGeneration: {
        label: 'Wind Electric Power Generation',
        sublabel: '',
        tooltip: '',
      },
      wineries: {
        label: 'Wineries',
        sublabel: '',
        tooltip: '',
      },
      landscapingServices: {
        label: 'Landscaping Services',
        sublabel: '',
        tooltip: '',
      },
      courtsOfLaw: {
        label: 'Courts of Law',
        sublabel: '',
        tooltip: '',
      },
      correctionalInstitutions: {
        label: 'Correctional Institutions',
        sublabel: '',
        tooltip: '',
      },
      fireProtection: {
        label: 'Fire Protection',
        sublabel: '',
        tooltip: '',
      },
      housingPrograms: {
        label: 'Housing Programs',
        sublabel: '',
        tooltip: '',
      },
      transportationPrograms: {
        label: 'Transportation Programs',
        sublabel: '',
        tooltip: '',
      },
      utilitiesAdministration: {
        label: 'Utilities Administration',
        sublabel: '',
        tooltip: '',
      },
      spaceResearchAndTechnology: {
        label: 'Space Research and Technology',
        sublabel: '',
        tooltip: '',
      },
      oilExtraction: {
        label: 'Oil Extraction',
        sublabel: '',
        tooltip: '',
      },
      naturalGasExtraction: {
        label: 'Natural Gas Extraction',
        sublabel: '',
        tooltip: '',
      },
      embeddedSoftwareProducts: {
        label: 'Embedded Software Products',
        sublabel: '',
        tooltip: '',
      },
      mobileComputingSoftwareProducts: {
        label: 'Mobile Computing Software Products',
        sublabel: '',
        tooltip: '',
      },
      desktopComputingSoftwareProducts: {
        label: 'Desktop Computing Software Products',
        sublabel: '',
        tooltip: '',
      },
      itSystemCustomSoftwareDevelopment: {
        label: 'IT System Custom Software Development',
        sublabel: '',
        tooltip: '',
      },
      itSystemOperationsAndMaintenance: {
        label: 'IT System Operations and Maintenance',
        sublabel: '',
        tooltip: '',
      },
      itSystemInstallationAndDisposal: {
        label: 'IT System Installation and Disposal',
        sublabel: '',
        tooltip: '',
      },
      itSystemTrainingAndSupport: {
        label: 'IT System Training and Support',
        sublabel: '',
        tooltip: '',
      },
      itSystemDataServices: {
        label: 'IT System Data Services',
        sublabel: '',
        tooltip: '',
      },
      itSystemTestingAndEvaluation: {
        label: 'IT System Testing and Evaluation',
        sublabel: '',
        tooltip: '',
      },
      internetNews: {
        label: 'Internet News',
        sublabel: '',
        tooltip: '',
      },
      blogs: {
        label: 'Blogs',
        sublabel: '',
        tooltip: '',
      },
      interiorDesign: {
        label: 'Interior Design',
        sublabel: '',
        tooltip: '',
      },
      socialNetworkingPlatforms: {
        label: 'Social Networking Platforms',
        sublabel: '',
        tooltip: '',
      },
      businessIntelligencePlatforms: {
        label: 'Business Intelligence Platforms',
        sublabel: '',
        tooltip: '',
      },
      businessContent: {
        label: 'Business Content',
        sublabel: '',
        tooltip: '',
      },
      dataSecuritySoftwareProducts: {
        label: 'Data Security Software Products',
        sublabel: '',
        tooltip: '',
      },
      mobileGamingApps: {
        label: 'Mobile Gaming Apps',
        sublabel: '',
        tooltip: '',
      },
      internetPublishing: {
        label: 'Internet Publishing',
        sublabel: '',
        tooltip: '',
      },
      mediaAndTelecommunications: {
        label: 'Media and Telecommunications',
        sublabel: '',
        tooltip: '',
      },
      blockchainServices: {
        label: 'Blockchain Services',
        sublabel: '',
        tooltip: '',
      },
      retailArtDealers: {
        label: 'Retail Art Dealers',
        sublabel: '',
        tooltip: '',
      },
      yes_v2: {
        label: 'Yes',
        sublabel: '',
        tooltip: '',
      },
      no_v2: {
        label: 'No',
        sublabel: '',
        tooltip: '',
      },
      iDontKnow: {
        label: "I don't know",
        sublabel: '',
        tooltip: '',
      },
    },
  },

  impactGoal: {
    listView: {
      headers: {
        venture: { name: 'Company' },
        groupNames: 'Groups',
        impactGoal: {
          targetProgress: 'Target progress',
          impacts: 'Impacts',
          sdgs: 'Related SDGs',
          submitDate: 'Submit date',
        },
        geographies: 'Affected regions',
        comment: 'Portfolio manager comments',
        action: 'Actions',
      },
      sortBy: 'Sort by {column}',
      addComment: '+Add Comment',
      exportPDF: 'Export impact report as PDF',
      unFreezeGoalUndTarget: 'Unfreeze impact goal and target values',
      freezeGoalUndTarget: 'Freeze impact goal and target values',
    },
    navigation: {
      introduction: { label: 'Introduction' },
      euTaxonomy: { label: 'EU taxonomy' },
      eligibilityCheck: { label: 'Eligibility & alignment check' },
      financialMetrics: { label: 'Financial KPIs' },
      impactDefinition: { label: 'My Impacts' },
      impactNumber: { label: 'Impact ' },
      problemDescription: { label: 'Problem description' },
      internationalFrameworks: { label: 'International frameworks' },
      impactSolutions: { label: 'Solutions' },
      impactSolutionNumber: { label: 'Solution ' },
      ghgSavingPotential: { label: 'GHG reduction potential' },
      importantNotes: { label: 'Important notes' },
      impactProfile: { label: 'Impact profile' },
      fairComparison: { label: 'Fair comparison' },
      emissionSources: { label: 'Emission sources' },
      growthFactor: { label: 'Forecast' },
      result: { label: 'Result' },
      impactMeasurement: { label: 'Impact measurement' },
      impactTargets: { label: 'Impact targets' },
      nextSteps: { label: 'Next steps' },
    },
    overview: {
      selfAssessmentTooltip: 'Self assessment',
      guidanceTitle: 'More information',
      guidanceText:
        'More information on the methodology of the tool can be found <a href="https://impactnexus.io/the-impact-goal-and-ghg-impact-module">here</a>',
      editButton: 'Edit impact',
      title: 'Impact overview',
      progressSubtitle: 'Impact goal progress',
      impactsSubtitle: 'Impacts',
      correspondingImpactSolutions: 'Corresponding solutions:',
      climateImpactModule: {
        title: 'Climate impact from our solutions',
        belowAverage:
          'This company is below the average GHG reduction potential. A company is considered high climate potential if its solutions help reduce more than 50,000 tonnes of CO2e on a yearly average over the next 5 years.  This is an expert opinion based on case studies conducted by the Borderstep Institute for Innovation and Sustainability.',
        aboveAverage:
          'This company is above the average GHG reduction potential. A company is considered high climate potential if its solutions help reduce more than 50,000 tonnes of CO2e on a yearly average over the next 5 years.  This is an expert opinion based on case studies conducted by the Borderstep Institute for Innovation and Sustainability.',
        highImpact: 'We are a High Climate potential company!',
        reductionTextHcp: `We are a high climate potential company because our solutions have an estimated average annual reduction of
        {averageAnnualReduction}
        tonnes of CO2e.`,
        reductionText: `Our solutions have an estimated average annual reduction of
        {averageAnnualReduction}
        tonnes of CO2e.`,
        representedByMoney: `Our annual economic value of the planned GHG reduction is {economicValue} €.`,
        representedByMoneyTooltip: `The annual economic value is calculated by multiplying the average annual reduction by the average CO2 price in the EU Emissions Trading System from 2023 (€83.24/tonne CO2e).`,
        protectionPledge: `Our solution {label} plans to save {amount} tonnes of CO2e from {startYear} to {endYear}, by reducing GHG emissions by {percentageReduction}% per {header} ({reduction} kg CO2e).`,
        chartTitle: 'Annual planned GHG reductions (in tonnes CO2e)',
        chartYLabel: 'tonnes CO2e',
      },
      ourSolutions: {
        title: 'Our solutions',
        solution: 'Solution',
        PRODUCT_APPLICATION: 'Product application',
        CIRCULAR_ECONOMY: 'Circular economy',
        EXTRACTION_AND_MANUFACTURING: 'Extraction & manufacturing',
        LOGISTICS: 'Transport & logistics',
        CUSTOMER_EMPOWERMENT: 'Customer empowerment',
        HYBRID: 'Hybrid',
        PRODUCT: 'Product',
        SERVICE: 'Service',
        plannedSavings:
          'Planned savings of {savingsAmount} tonnes of CO2e from {startYear} to {endYear}',
      },
      ourImpacts: {
        title: 'Our impacts',
        solutionsText: 'Solutions delivering this impact: ',
      },
      ourMeasurement: {
        title: 'Our measurement and KPIs',
        subtitle:
          "We track the impact of our solutions using the following KPIs. The target values represent our yearly goals, while the actual values (when available) show what we've achieved. The target progress indicates how close we are to reaching our goal for the forecasted period.",
        forecastFor: "Target progress for '{metricName}' ({impactName})",
      },
    },
    introButton: 'Click continue to describe this impact in detail.',
    intro: {
      title: 'Welcome to the GHG & Impact Estimator',
      description: `<p>This module allows you to define your main <strong>impacts</strong>, <strong>solutions</strong> and estimate your company&apos;s <strong>GHG reduction potential</strong> in accordance with best practices in impact management and measurement. In this module you will:</p>
      <ul>
          <li>Define the impacts your company has on people and the environment, and align those impacts to international frameworks</li>
          <li>Define your solutions (your products or services) that help to deliver your impact</li>
          <li>Determine the GHG reduction potential of your solutions by comparing them to a traditional less climate friendly product or service (optional)</li>
          <li>Set KPIs and quantitative targets to measure and track your solution</li>
      </ul>
      <p>If you&apos;d like to quantify your GHG reduction immediately, choose <strong>Quantify GHG reduction now</strong> and we&apos;ll create you an Impact and Solution automatically which you can edit later.</p>
      `,
      QuantifyGhgReductionNowButton: 'Quantify GHG reduction now',
      newGhgSolution: {
        name: 'My product or service',
        description: 'A description of my product or service',
        targetGroup: 'End users',
      },
    },
    euTaxonomy: {
      intro: `
## What is the EU Taxonomy?

The EU Taxonomy is a classification system that helps companies and investors identify
"environmentally sustainable” economic activities to make sustainable investment decisions.
Environmentally sustainable economic activities are described as those which "make a
substantial contribution to at least one of the EU's climate and environmental objectives,
while at the same time not significantly harming any of these objectives and meeting minimum safeguards.”

The EU Taxonomy has six environmental objectives:
- Climate change mitigation.
- Climate change adaptation.
- The sustainable use and protection of water and marine resources.
- The transition to a circular economy.
- Pollution prevention and control.
- The protection and restoration of biodiversity and ecosystems.

### Eligibility:
To qualify as a sustainable activity, an economic activity must make a significant contribution to at least one of the six environmental objectives mentioned above.

### Alignment:
An activity becomes aligned with the taxonomy when it satisfies the following three criteria:
- Substantial contribution: The activity must make a significant contribution to one of the six economic activities outlined in the Technical Screening Criteria (TSC), which are in line with the taxonomy objectives.
- Do-no-significant-harm (DNSH): The activity should not cause significant harm to the other environmental objectives beyond the one it substantially contributes to.
- Minimum social safeguards (MSS) compliance: The company must adhere to the minimum social safeguards described in the Taxonomy Regulation.

For more details  see https://ec.europa.eu/sustainable-finance-taxonomy/home
      `,
      checkIntro: `
In this section, both EU taxonomy eligibility and alignment will be verified. By providing the relevant links to the websites about your activities, the **eligibility check** identifies business activities that could potentially contribute to the sustainable objectives of the EU Taxonomy.

Whether this is actually the case will be examined in detail for each business activity individually as part of the **EU Taxonomy alignment check**.

In addition to the automatic suggestions, you can select EU Taxonomy eligible business activities manually.
`,
      acitivityQuestion: {
        title: 'Identify business activities',
        subtitle:
          'Please provide us with 1 to 3 links to websites that best describe your business activities, e.g., a link to the product description on your company website or a blog article that explains what you are working on. Based on this information, the NEXUS automatically checks if you conduct activities that are EU Taxonomy eligible.',
      },
      explanation: {
        title: 'Details on the EU taxonomy eligibility check',
        backgroundTitle: 'Background of the eligibility and alignment check',
        background: `
*   The **EU Taxonomy** offers a list of sectors and business activites that could potentially contribute to the impact objectives of the EU. Currently, there are **six environmental objectives**, but only two are elaborated in detail - climate change mitigation and climate change adaption.
*   In the **eligibility check**, you find out if the activities of your company are covered by the EU Taxonomy, which currently means that they have the potential to contribute significantly to climate change mitigation or climate change adaption.
*   The **alignment check**. This serves to determine if your business activites really contribute substantially to climate change mitigation or climate change adaption, while doing no significant harm to the six environmental objectives. If your company respects the minimum safeguards defined by the EU is checked in the disclosure section of the ESG questionnaire.
  `,
        implementationTitle: 'Conducting the eligibility and alignment check',
        implementation: `
*   First, we like you to provide us with 1 to 3 links to websites that best describe your business activities, e.g. a link to the product description on your company website or a blog article that explains what you are working on. Based on this information, the NEXUS automatically checks if you conduct activities that are EU Taxonomy eligible. A full list of these activities can be found [here](https://ec.europa.eu/sustainable-finance-taxonomy/activities).
*   In the dropdown, you can search, select and remove the EU eligible taxonomies manually. In case the NEXUS identified any activities that might apply to you, you can add those too.
*   Go to the next page when you have identified all activities that belong to your business.
  `,
      },
      alignmentQuestionnaire: {
        noQuestionnaireId:
          'Something went wrong. Please exit the questionnaire and try again.',
        noneOfTheActivities:
          'None of your selected business activities contribute to this impact.',
        leaveButton: 'Exit questionnaire',
      },
      financial: {
        intro: `
Thank you for completing the EU Taxonomy eligibility and alignment check! You can now view your activity or activities that are aligned according to the requirements set out in the EU Taxonomy.

As part of the SFDR periodic disclosure, Taxonomy-aligned activities need to be expressed as share of some KPIs, such as turnover, capital expenditure (CapEx), and operational expenditure (OpEx). Hence, this information first needs to be collected for your aligned activities at the company level. Then, these KPIs allow for calculations at the fund level.

- **Turnover**: Total sales from products/services minus sales rebates, value-added tax, and other related taxes.
- **Capital expenditure (CapEx)**: Refers to additions to tangible and intangible assets in the financial year, which include property, plant, equipment, intangible assets, investment property, agriculture, and leases.
- **Operational expenditure (OpEx)**: Refers to direct expenditures needed for the daily upkeep of assets such as property, plant, and equipment. This includes activities like research and development, building renovations, short-term lease, maintenance, and repairs.`,
      },
    },
    outro: {
      afterSubmit: {
        title: 'Congratulations, you defined you impacts and solutions!',
        description: `
- Visit the **impact tab** to see the impact summary
- Create your own **improvement roadmap** and define three measures to reach your impact targets in the **task tab**
- Regularly **update progress** on the defined **quantitative targets in the KPI tab**
`,
        feedback: {
          title: 'Before you get to the results, some quick feedback',
          description: 'Help us improve your experience',
          rating: {
            title:
              'How happy were you with the module (structure, design & content)?',
            descriptions: {
              0: 'No rating',
              1: 'Awful',
              2: 'Poor',
              3: 'Fair',
              4: 'Good',
              5: 'Excellent',
            },
          },
          comment: {
            title: 'We love feedback! Please share any improvement ideas.',
            label: 'Please share your feedback here',
          },
        },
        toImpactTab: 'To impact overview',
        toTaskTab: 'To improvement roadmap',
        toKPITab: 'To KPI tracking',
      },
    },
    impacts: {
      defineImpacts: {
        impactDefinition: {
          title: 'My Impacts',
          exampleTitle: 'Example impacts:',
          description: `Impacts are the structural and/or systemic effects of a company's activities on the people (from individuals to broader society) and/or the natural environment.`,
          exampleImpacts: [
            {
              outcome: 'Reduced CO2 emissions',
              stakeholder: 'People and the natural environment',
            },
            {
              outcome: 'Increased access to clean water',
              stakeholder: 'People',
            },
            {
              outcome: 'Improved education access',
              stakeholder: 'People',
            },
          ],
        },
        defineImpact: {
          title: 'Define your impact(s) ',
          // subtitle:
          //   'Focus on the 1-3 most relevant impacts for your company. You can create a custom impact or select from the dropdown of templates.',
          subtitle: `Focus on the 1-3 most relevant impacts for your company. You can create a custom impact or select from the dropdown of templates.
          <br/>
          <br/>
          In case your activities contribute to climate-related issues, you may choose from the templates we provide (Reduced CO2 emissions, Climate Change Adaptation). Alternatively, you have the option to create a custom impact template of your own. Please follow these steps:
          <br/>
          <br/>
          1. Include all your impacts, using the predefined and/or custom impact templates.
          <br/>
          2. Proceed by providing all necessary information regarding your first impact.
          <br/>
          3. Complete the questionnaire for each impact.`,
          deleteImpactButton: 'Delete impact',
          deleteImpactConfirmation:
            'Do you really want to delete this impact? You cannot undo this action.',
          addImpactButton: 'Add impact',
          impactTypes: [
            'Reduced CO2 emissions',
            'Climate change adaptation',
            'Custom impact',
          ],
          outcomeStakeholderDialog: {
            title: 'Describe the impact that your solutions help to deliver',
            introParagraph:
              '<p>In this section you will define your impact, which describes the effect of your solutions on people or the natural environment. Once the impact is defined, you can return to the <strong>My impacts</strong> screen and add your solution(s) that contribute towards achieving this impact. If you have multiple solutions that provide different benefits, you may need to define several impacts.</p>',
            ixMethodologyTitle:
              'What methodology does ImpactNexus use for impacts?',
            ixMethodology: `<p>The definition of impacts used by ImpactNexus is based upon international methodologies on impact management, including the Impact Management Platform and the Social Impact Navigator / IOOI Framework of PHINEO.</p>
            <p>Impacts are the structural and/or systemic effects of a company&apos;s activities on the people (from individuals to broader society) and/or the natural environment. When you add an impact in the ImpactNexus app, you will define its:</p>
            <ul>
                <li>Outcomes - The specific benefits, values or changes at the level of the people (target groups and stakeholders) and/or the natural environment that helps to deliver the desired impact. The outcome is used as the title for your impact and is displayed on your impact reports.</li>
                <li>Affected party - Either people, the natural environment, or both</li>
                <li>Affected countries or regions - The locations most affected by the outcomes and impact</li>
            </ul>
            <p>If you are using an impact template such as <strong>Reduced CO2 emissions</strong>, then some fields may not be editable.</p>`,
            sourceAssignmentsInput: {
              title: 'Evidence for the relevance of the problem',
              subtitle:
                'If available, please add external evidence that clarifies **why this problem is relevant for people and/or planet** (esp. quantitative evidence)”',
            },
            outcome: {
              title:
                'What are the benefits, values or changes for people and/or the natural environment?',
              subtitle: `<p>This is the <strong>intended outcome</strong> expected from your solution (product or service) for the people and/or the natural environment, that will help achieve the impact.</p>
              <p>For example:</p>
              <ul>
                  <li>Reduced CO2 emissions</li>
                  <li>Reduced river pollution</li>
                  <li>Increased number of educated young adults</li>
                  <li>Increased self-efficacy for families</li>
              </ul>`,
            },
            stakeholder: {
              title: 'Who or what is affected by the outcome?',
              PEOPLE: 'People',
              NATURAL_ENVIRONMENT: 'Natural environment',
              PEOPLE_AND_NATURAL_ENVIRONMENT: 'People and natural environment',
              subtitle: `<ul>
              <li>If the outcome mostly affects individuals, groups or society as a whole, choose <strong>People</strong></li>
              <li>If the outcome mostly effects the environment, such as land, water, atmosphere or living beings that rely on the environment, choose <strong>Natural environment</strong></li>
              <li>If the outcome affects both people and the natural environment, choose <strong>People and natural environment</strong>. For example, the reduction of emissions has a positive effect on the environment, but also for the communities who are exposed to the emissions.</li>
          </ul>`,
              placeholder: 'Who is affected by this outcome?',
            },
            affectedRegions: {
              title: 'Affected countries and regions',
              subtitle:
                'Which countries or regions are most affected by the outcome?  Choose from the list below. You have the option to select regions or countries.',
            },
          },
        },
        classifyImpact: {
          title: 'International frameworks',
          sdgInput: {
            title: 'Select related SDGs',
            tooltip:
              'SDGs, or Sustainable Development Goals, are a collection of 17 global goals set by the United Nations in 2015. These goals, expected to be achieved by 2030, cover a broad range of sustainability issues, including poverty, hunger, health, education, climate change, gender equality, clean water and sanitation, and social justice. The SDGs aim to enhance global partnerships to foster peace and prosperity for people and the planet.',
            subtitle: `Please select the Sustainable Development Goals (SDGs) linked to the impact. Click [here](https://sdgs.un.org/goals) for more info on the SDGs.`,
            description: {
              prefix: `* The SDGs are a set of 17 goals with a total of 169 targets for the countries of the world.\n`,
              suffix: `* Companies can contribute to the achievement of the SDG, if they make a positive contribution to at least one of the targets listed below: \n`,
            },
            confirm: 'Confirm SDG contribution',
            sdgs: {
              1: {
                id: 1,
                title: '*End poverty in all its forms everywhere*',
                short: 'No Poverty',
                colorInfo: {
                  hex: '#e5243b',
                  rgb: [229, 36, 59],
                },
                keywords: {
                  tags: ['poverty'],
                  descriptions: [
                    'extreme poverty',
                    'basic standard of living',
                    'social protection',
                  ],
                  groups: ['poorest', 'vulnerable'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_1.png',
                targets: [
                  {
                    id: 1,
                    sdgId: 1,
                    externalId: 1,
                    label:
                      'By 2030, *eradicate extreme poverty for all people everywhere*, currently measured as people living on less than $1.25 a day',
                  },
                  {
                    id: 2,
                    sdgId: 1,
                    externalId: 2,
                    label:
                      'By 2030, **reduce** at least by half the proportion of men, women and children of all ages living in *poverty* in all its dimensions according to national definitions',
                  },
                  {
                    id: 3,
                    sdgId: 1,
                    externalId: 3,
                    label:
                      'Implement nationally *appropriate social protection systems and measures* for all, including floors, and by 2030 achieve substantial coverage of the poor and the vulnerable',
                  },
                  {
                    id: 4,
                    sdgId: 1,
                    externalId: 4,
                    label:
                      'By 2030, ensure that all men and women, in particular the poor and the vulnerable, have *equal rights to economic resources*, as well as access to basic services, ownership and control over land and other forms of property, inheritance, natural resources, appropriate new technology and financial services, including microfinance',
                  },
                  {
                    id: 5,
                    sdgId: 1,
                    externalId: 5,
                    label:
                      'By 2030, *build the resilience of the poor* and those in vulnerable situations and *reduce* their exposure and vulnerability to climate-related extreme events and other economic, social and environmental shocks and disasters',
                  },
                  {
                    id: 6,
                    sdgId: 1,
                    externalId: 'a',
                    label:
                      'Ensure *significant mobilization of resources*from a variety of sources, including through enhanced development cooperation, in order to provide adequate and predictable means for developing countries, in particular least developed countries, to implement programmes and policies to end poverty in all its dimensions',
                  },
                  {
                    id: 7,
                    sdgId: 1,
                    externalId: 'b',
                    label:
                      'Create sound policy frameworks at the national, regional and international levels, based on pro-poor and gender-sensitive development strategies, to support accelerated *investment in poverty eradication actions*',
                  },
                ],
              },
              2: {
                id: 2,
                title:
                  '*End hunger, achieve food security and improved nutrition and promote sustainable agriculture*',
                short: 'Zero Hunger',
                colorInfo: {
                  hex: '#e5b735',
                  rgb: [229, 183, 53],
                },
                keywords: {
                  tags: [
                    'hunger',
                    'food security',
                    'nutrition',
                    'sustainable agriculture',
                  ],
                  descriptions: [
                    'malnutrition',
                    'food production',
                    'agricultural productivity',
                    'agricultural investments',
                    'food markets',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_2.png',
                targets: [
                  {
                    id: 8,
                    sdgId: 2,
                    externalId: 1,
                    label:
                      'By 2030, *end hunger* and ensure access by all people, in particular the poor and people in vulnerable situations, including infants, to safe, nutritious and sufficient food all year round',
                  },
                  {
                    id: 9,
                    sdgId: 2,
                    externalId: 2,
                    label:
                      'By 2030, *end all forms of malnutrition*, including achieving, by 2025, the internationally agreed targets on stunting and wasting in children under 5 years of age, and address the nutritional needs of adolescent girls, pregnant and lactating women and older persons',
                  },
                  {
                    id: 10,
                    sdgId: 2,
                    externalId: 3,
                    label:
                      'By 2030, *double the agricultural productivity and incomes* of small-scale food producers, in particular women, indigenous peoples, family farmers, pastoralists and fishers, including through secure and equal access to land, other productive resources and inputs, knowledge, financial services, markets and opportunities for value addition and non-farm employment',
                  },
                  {
                    id: 11,
                    sdgId: 2,
                    externalId: 4,
                    label:
                      'By 2030,* ensure sustainable food production systems* and implement resilient agricultural practices that increase productivity and production, that help maintain ecosystems, that strengthen capacity for adaptation to climate change, extreme weather, drought, flooding and other disasters and that progressively improve land and soil quality',
                  },
                  {
                    id: 12,
                    sdgId: 2,
                    externalId: 5,
                    label:
                      'By 2020, *maintain the genetic diversity of seeds, cultivated plants* and farmed and domesticated animals and their related wild species, including through soundly managed and diversified seed and plant banks at the national, regional and international levels, and promote access to and fair and equitable sharing of benefits arising from the *utilization of genetic resources* and associated traditional knowledge, as internationally agreed',
                  },
                  {
                    id: 13,
                    sdgId: 2,
                    externalId: 'a',
                    label:
                      'Increase investment, including through enhanced international cooperation, in rural infrastructure, agricultural research and extension services, technology development and plant and livestock gene banks in order to *enhance agricultural productive capacity* in developing countries, in particular least developed countries',
                  },
                  {
                    id: 14,
                    sdgId: 2,
                    externalId: 'b',
                    label:
                      'Correct and* prevent trade restrictions and distortions in world agricultural markets*, including through the parallel elimination of all forms of agricultural export subsidies and all export measures with equivalent effect, in accordance with the mandate of the Doha Development Round',
                  },
                  {
                    id: 15,
                    sdgId: 2,
                    externalId: 'c',
                    label:
                      'Adopt measures to *ensure the proper functioning of food commodity markets* and their derivatives and facilitate timely access to market information, including on food reserves, in order to help limit extreme food price volatility',
                  },
                ],
              },
              3: {
                id: 3,
                title:
                  '*Ensure healthy lives and promote well-being for all at all ages*',
                short: 'Good Health and Well-being',
                colorInfo: {
                  hex: '#4c9f38',
                  rgb: [76, 159, 56],
                },
                keywords: {
                  tags: ['health', 'well-being'],
                  descriptions: [
                    'reproductive health',
                    'maternal health',
                    'child health',
                    'epidemics',
                    'communicable diseases',
                    'non-communicable diseases',
                    'environmental diseases',
                    'universal health coverage',
                    'medicines',
                    'vaccines',
                  ],
                  groups: ['women', 'children'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_3.png',
                targets: [
                  {
                    id: 16,
                    sdgId: 3,
                    externalId: 1,
                    label:
                      'By 2030, **reduce* the global maternal mortality ratio* to less than 70 per 100,000 live births',
                  },
                  {
                    id: 17,
                    sdgId: 3,
                    externalId: 2,
                    label:
                      'By 2030, *end preventable deaths of newborns and children* under 5 years of age, with all countries aiming to *reduce* neonatal mortality to at least as low as 12 per 1,000 live births and under-5 mortality to at least as low as 25 per 1,000 live births',
                  },
                  {
                    id: 18,
                    sdgId: 3,
                    externalId: 3,
                    label:
                      'By 2030, *end the epidemics of AIDS, tuberculosis, malaria* and neglected tropical diseases and combat hepatitis, water-borne diseases and other communicable diseases',
                  },
                  {
                    id: 19,
                    sdgId: 3,
                    externalId: 4,
                    label:
                      'By 2030, **reduce** by one third *premature mortality* from non-communicable diseases through prevention and treatment and promote mental health and well-being',
                  },
                  {
                    id: 20,
                    sdgId: 3,
                    externalId: 5,
                    label:
                      'Strengthen the *prevention and treatment of substance abuse*, including narcotic drug abuse and harmful use of alcohol',
                  },
                  {
                    id: 21,
                    sdgId: 3,
                    externalId: 6,
                    label:
                      'By 2020, *halve* the number of global *deaths and injuries from road traffic accidents*',
                  },
                  {
                    id: 22,
                    sdgId: 3,
                    externalId: 7,
                    label:
                      'By 2030, *ensure universal access to sexual and reproductive health-care services*, including for family planning, information and education, and the integration of reproductive health into national strategies and programmes',
                  },
                  {
                    id: 23,
                    sdgId: 3,
                    externalId: 8,
                    label:
                      '*Achieve universal health coverage*, including financial risk protection, access to quality essential health-care services and access to safe, effective, quality and affordable essential medicines and vaccines for all',
                  },
                  {
                    id: 24,
                    sdgId: 3,
                    externalId: 9,
                    label:
                      'By 2030, substantially *reduce* the number of *deaths and illnesses* from hazardous chemicals and air, water and soil *pollution and contamination*',
                  },
                  {
                    id: 25,
                    sdgId: 3,
                    externalId: 'a',
                    label:
                      'Strengthen the *implementation of the World Health Organization* *Framework Convention on Tobacco Control* in all countries, as appropriate',
                  },
                  {
                    id: 26,
                    sdgId: 3,
                    externalId: 'b',
                    label:
                      '*Support the research and development of vaccines and medicines* for the communicable and non-communicable diseases that primarily affect developing countries, provide access to affordable essential medicines and vaccines, in accordance with the Doha Declaration on the TRIPS Agreement and Public Health, which affirms the right of developing countries to use to the full the provisions in the Agreement on Trade-Related Aspects of Intellectual Property Rights regarding flexibilities to protect public health, and, in particular, provide access to medicines for all',
                  },
                  {
                    id: 27,
                    sdgId: 3,
                    externalId: 'c',
                    label:
                      'Substantially *increase health financing* and the recruitment, development, training and retention of the health workforce in developing countries, especially in least developed countries and small island developing States',
                  },
                  {
                    id: 28,
                    sdgId: 3,
                    externalId: 'd',
                    label:
                      'Strengthen the capacity of all countries, in particular developing countries, for early warning, *risk reduction and management of national and global health risks*',
                  },
                ],
              },
              4: {
                id: 4,
                title:
                  '*Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all*',
                short: 'Quality Education',
                colorInfo: {
                  hex: '#c5192d',
                  rgb: [197, 25, 45],
                },
                keywords: {
                  tags: ['education'],
                  descriptions: [
                    'skills',
                    'technical education',
                    'vocational educaiton',
                    'training',
                    'higher education',
                    'knowledge',
                    'values',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_4.png',
                targets: [
                  {
                    id: 29,
                    sdgId: 4,
                    externalId: 1,
                    label:
                      'By 2030, ensure that all girls and boys complete free, equitable and quality *primary and secondary education* leading to relevant and effective learning outcomes',
                  },
                  {
                    id: 30,
                    sdgId: 4,
                    externalId: 2,
                    label:
                      'By 2030, ensure that all girls and boys have *access to* quality early childhood development, *care and pre-primary education* so that they are ready for primary education',
                  },
                  {
                    id: 31,
                    sdgId: 4,
                    externalId: 3,
                    label:
                      'By 2030, ensure equal access for all women and men to affordable and quality technical, *vocational and tertiary education*, including university',
                  },
                  {
                    id: 32,
                    sdgId: 4,
                    externalId: 4,
                    label:
                      'By 2030, substantially* increase the number of youth and adults who have relevant skills*, including technical and vocational skills, for employment, decent jobs and entrepreneurship',
                  },
                  {
                    id: 33,
                    sdgId: 4,
                    externalId: 5,
                    label:
                      'By 2030, *eliminate gender disparities in education* and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples and children in vulnerable situations',
                  },
                  {
                    id: 34,
                    sdgId: 4,
                    externalId: 6,
                    label:
                      'By 2030, ensure that all youth and a substantial proportion of adults, both men and women, *achieve literacy and numeracy*',
                  },
                  {
                    id: 35,
                    sdgId: 4,
                    externalId: 7,
                    label:
                      'By 2030, ensure that all learners *acquire the knowledge and skills* needed to promote sustainable development, including, among others, through education for sustainable development and sustainable lifestyles, human rights, gender equality, promotion of a culture of peace and non-violence, global citizenship and appreciation of cultural diversity and of culture’s contribution to sustainable development',
                  },
                  {
                    id: 36,
                    sdgId: 4,
                    externalId: 'a',
                    label:
                      '*Build and upgrade education facilities* that are child, disability and gender sensitive and provide safe, non-violent, inclusive and effective learning environments for all',
                  },
                  {
                    id: 37,
                    sdgId: 4,
                    externalId: 'b',
                    label:
                      'By 2020, substantially *expand globally the number of scholarships available* to developing countries, in particular least developed countries, small island developing States and African countries, for enrolment in higher education, including vocational training and information and communications technology, technical, engineering and scientific programmes, in developed countries and other developing countries',
                  },
                  {
                    id: 38,
                    sdgId: 4,
                    externalId: 'c',
                    label:
                      'By 2030, substantially *increase the supply of qualified teachers*, including through international cooperation for teacher training in developing countries, especially least developed countries and small island developing States',
                  },
                ],
              },
              5: {
                id: 5,
                title:
                  '*Achieve gender equality and empower all women and girls*',
                short: 'Gender Equality',
                colorInfo: {
                  hex: '#ff3a21',
                  rgb: [255, 58, 33],
                },
                keywords: {
                  tags: ['gender', 'women', 'girls'],
                  descriptions: [
                    'women empowerment',
                    'girls empowerment',
                    'discrimination against women',
                    'violence against women',
                    'sexual health',
                    'reproductive health',
                    'reproductive rights',
                    'unpaid work',
                    'access to productive resources',
                    'equal participation',
                    'political life',
                    'economic life',
                    'public life',
                  ],
                  groups: ['women', 'girls'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_5.png',
                targets: [
                  {
                    id: 39,
                    sdgId: 5,
                    externalId: 1,
                    label:
                      'End all forms of *discrimination against all women and girls* everywhere',
                  },
                  {
                    id: 40,
                    sdgId: 5,
                    externalId: 2,
                    label:
                      '*Eliminate all forms of violence* against all women and girls in the public and private spheres, including trafficking and sexual and other types of exploitation',
                  },
                  {
                    id: 41,
                    sdgId: 5,
                    externalId: 3,
                    label:
                      'Eliminate all harmful practices, such as child, early and *forced marriage* and female genital mutilation',
                  },
                  {
                    id: 42,
                    sdgId: 5,
                    externalId: 4,
                    label:
                      'Recognize and value *unpaid care and domestic work* through the provision of public services, infrastructure and social protection policies and the promotion of shared responsibility within the household and the family as nationally appropriate',
                  },
                  {
                    id: 43,
                    sdgId: 5,
                    externalId: 5,
                    label:
                      'Ensure women’s full and effective participation and *equal opportunities for leadership* at all levels of decision-making in political, economic and public life',
                  },
                  {
                    id: 44,
                    sdgId: 5,
                    externalId: 6,
                    label:
                      'Ensure *universal access to sexual and reproductive health and reproductive rights* as agreed in accordance with the Programme of Action of the International Conference on Population and Development and the Beijing Platform for Action and the outcome documents of their review conferences',
                  },
                  {
                    id: 45,
                    sdgId: 5,
                    externalId: 'a',
                    label:
                      'Undertake reforms to *give women equal rights to economic resources*, as well as access to ownership and control over land and other forms of property, financial services, inheritance and natural resources, in accordance with national laws',
                  },
                  {
                    id: 46,
                    sdgId: 5,
                    externalId: 'b',
                    label:
                      'Enhance the use of enabling technology, in particular information and communications technology, to *promote the empowerment of women*',
                  },
                  {
                    id: 47,
                    sdgId: 5,
                    externalId: 'c',
                    label:
                      'Adopt and strengthen sound policies and enforceable legislation for the *promotion of gender equality* and the empowerment of all women and girls at all levels',
                  },
                ],
              },
              6: {
                id: 6,
                title:
                  '*Ensure availability and sustainable management of water and sanitation for all*',
                short: 'Clean Water and Sanitation',
                colorInfo: {
                  hex: '#26bde2',
                  rgb: [38, 189, 226],
                },
                keywords: {
                  tags: ['water', 'sanitation'],
                  descriptions: [
                    'drinking water',
                    'hygiene',
                    'water resources',
                    'water management',
                    'sanitation management',
                  ],
                  groups: ['international cooperation', 'local communities'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_6.png',
                targets: [
                  {
                    id: 48,
                    sdgId: 6,
                    externalId: 1,
                    label:
                      'By 2030, achieve universal and equitable *access to safe and affordable drinking water for all*',
                  },
                  {
                    id: 49,
                    sdgId: 6,
                    externalId: 2,
                    label:
                      'By 2030, achieve access to adequate and equitable **sanitation and hygiene** for all and end open defecation, paying special attention to the needs of women and girls and those in vulnerable situations',
                  },
                  {
                    id: 50,
                    sdgId: 6,
                    externalId: 3,
                    label:
                      'By 2030, *improve water quality* by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater and substantially increasing recycling and safe reuse globally',
                  },
                  {
                    id: 51,
                    sdgId: 6,
                    externalId: 4,
                    label:
                      'By 2030, substantially *increase water-use efficiency* across all sectors and ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially *reduce* the number of people suffering from water scarcity',
                  },
                  {
                    id: 52,
                    sdgId: 6,
                    externalId: 5,
                    label:
                      'By 2030, implement integrated *water resources management* at all levels, including through transboundary cooperation as appropriate',
                  },
                  {
                    id: 53,
                    sdgId: 6,
                    externalId: 6,
                    label:
                      'By 2020, protect and *restore water-related ecosystems*, including mountains, forests, wetlands, rivers, aquifers and lakes',
                  },
                  {
                    id: 54,
                    sdgId: 6,
                    externalId: 'a',
                    label:
                      'By 2030, expand international cooperation and *capacity-building support to developing countries* in *water- and sanitation-related activities* and programmes, including water harvesting, desalination, water efficiency, wastewater treatment, recycling and reuse technologies',
                  },
                  {
                    id: 55,
                    sdgId: 6,
                    externalId: 'b',
                    label:
                      'Support and strengthen the participation of local communities in improving *water and sanitation management*',
                  },
                ],
              },
              7: {
                id: 7,
                title:
                  '*Ensure access to affordable, reliable, sustainable and modern energy for all*',
                short: 'Affordable and Clean Energy',
                colorInfo: {
                  hex: '#fcc30b',
                  rgb: [252, 195, 11],
                },
                keywords: {
                  tags: ['energy'],
                  descriptions: ['energy access', 'renewable energy'],
                  groups: ['international cooperation'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_7.png',
                targets: [
                  {
                    id: 56,
                    sdgId: 7,
                    externalId: 1,
                    label:
                      'By 2030, ensure universal access to *affordable, reliable and modern energy services*',
                  },
                  {
                    id: 57,
                    sdgId: 7,
                    externalId: 2,
                    label:
                      'By 2030, increase substantially the *share of renewable energy* in the global energy mix',
                  },
                  {
                    id: 58,
                    sdgId: 7,
                    externalId: 3,
                    label:
                      'By 2030, double the global rate of *improvement in energy efficiency*',
                  },
                  {
                    id: 59,
                    sdgId: 7,
                    externalId: 'a',
                    label:
                      'By 2030, enhance international cooperation to facilitate *access to clean energy* research and technology, including renewable energy, energy efficiency and advanced and cleaner fossil-fuel technology, and promote investment in energy infrastructure and clean energy technology',
                  },
                  {
                    id: 60,
                    sdgId: 7,
                    externalId: 'b',
                    label:
                      'By 2030, expand infrastructure and upgrade technology for *supplying modern and sustainable energy services* for all in developing countries, in particular least developed countries, small island developing States, and land-locked developing countries, in accordance with their respective programmes of support',
                  },
                ],
              },
              8: {
                id: 8,
                title:
                  '*Promote sustained, inclusive and sustainable economic growth, full and *productive employment and decent work* for all*',
                short: 'Decent Jobs and Economic Growth',
                colorInfo: {
                  hex: '#a21942',
                  rgb: [162, 25, 66],
                },
                keywords: {
                  tags: ['economic growth', 'employment', 'decent work'],
                  descriptions: [
                    'forced labour',
                    'human trafficking',
                    'child labour',
                  ],
                  groups: ['children'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_8.png',
                targets: [
                  {
                    id: 61,
                    sdgId: 8,
                    externalId: 1,
                    label:
                      'Sustain *per capita economic growth* in accordance with national circumstances and, in particular, at least 7 per cent gross domestic product growth per annum in the least developed countries',
                  },
                  {
                    id: 62,
                    sdgId: 8,
                    externalId: 2,
                    label:
                      'Achieve *higher levels of economic productivity* through diversification, technological upgrading and innovation, including through a focus on high-value added and labour-intensive sectors',
                  },
                  {
                    id: 63,
                    sdgId: 8,
                    externalId: 3,
                    label:
                      'Promote development-oriented policies that support productive activities, *decent job creation, entrepreneurship, creativity and innovation*, and encourage the formalization and growth of micro-, small- and medium-sized enterprises, including through access to financial services',
                  },
                  {
                    id: 64,
                    sdgId: 8,
                    externalId: 4,
                    label:
                      'Improve progressively, through 2030, global resource efficiency in consumption and production and endeavour to *decouple economic growth from environmental degradation*, in accordance with the 10-year framework of programmes on *sustainable consumption and production*, with developed countries taking the lead',
                  },
                  {
                    id: 65,
                    sdgId: 8,
                    externalId: 5,
                    label:
                      'By 2030, achieve full and *productive employment and decent work* for all women and men, including for young people and persons with disabilities, and equal pay for work of equal value',
                  },
                  {
                    id: 66,
                    sdgId: 8,
                    externalId: 6,
                    label:
                      'By 2020, substantially *reduce the proportion of youth not in employment, education or training*',
                  },
                  {
                    id: 67,
                    sdgId: 8,
                    externalId: 7,
                    label:
                      'Take immediate and effective measures to *eradicate forced labour, end modern slavery and human trafficking* and secure the prohibition and elimination of the worst forms of child labour, including recruitment and use of child soldiers, and by 2025 end child labour in all its forms',
                  },
                  {
                    id: 68,
                    sdgId: 8,
                    externalId: 8,
                    label:
                      '*Protect labour rights and promote safe and secure working environments* for all workers, including migrant workers, in particular women migrants, and those in precarious employment',
                  },
                  {
                    id: 69,
                    sdgId: 8,
                    externalId: 9,
                    label:
                      'By 2030, devise and implement policies to promote *sustainable tourism* that creates jobs and promotes local culture and products',
                  },
                  {
                    id: 70,
                    sdgId: 8,
                    externalId: 10,
                    label:
                      'Strengthen the capacity of domestic financial institutions to encourage and expand *access to banking, insurance and financial services for all*',
                  },
                  {
                    id: 71,
                    sdgId: 8,
                    externalId: 'a',
                    label:
                      '*Increase Aid for Trade support* for developing countries, in particular least developed countries, including through the Enhanced Integrated Framework for Trade-Related Technical Assistance to Least Developed Countries',
                  },
                  {
                    id: 72,
                    sdgId: 8,
                    externalId: 'b',
                    label:
                      'By 2020, develop and operationalize a global strategy for *youth employment* and implement the Global Jobs Pact of the International Labour Organization',
                  },
                ],
              },
              9: {
                id: 9,
                title:
                  '*Build resilient infrastructure, promote inclusive and *sustainable industrialization* and foster innovation',
                short: 'Industry, Innovation and Infrastructure',
                colorInfo: {
                  hex: '#fd6925',
                  rgb: [253, 105, 37],
                },
                keywords: {
                  tags: ['infrastructure', 'industrialization', 'innovation'],
                  descriptions: [
                    'research',
                    'technology',
                    'technical support',
                    'ICT',
                    'information and communication technology',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_9.png',
                targets: [
                  {
                    id: 73,
                    sdgId: 9,
                    externalId: 1,
                    label:
                      'Develop quality, reliable, *sustainable and resilient infrastructure*, including regional and transborder infrastructure, to support economic development and human well-being, with a focus on affordable and equitable access for all',
                  },
                  {
                    id: 74,
                    sdgId: 9,
                    externalId: 2,
                    label:
                      'Promote inclusive and *sustainable industrialization* and, by 2030, significantly raise industry’s share of employment and gross domestic product, in line with national circumstances, and double its share in least developed countries',
                  },
                  {
                    id: 75,
                    sdgId: 9,
                    externalId: 3,
                    label:
                      'Increase the *access of small-scale industrial and other enterprises*, in particular in developing countries, to financial services, including affordable credit, and their integration into value chains and markets',
                  },
                  {
                    id: 76,
                    sdgId: 9,
                    externalId: 4,
                    label:
                      'By 2030, upgrade infrastructure and retrofit industries to make them sustainable, with increased *resource-use efficiency* and greater *adoption of clean and environmentally sound technologies and industrial processes*, with all countries taking action in accordance with their respective capabilities',
                  },
                  {
                    id: 77,
                    sdgId: 9,
                    externalId: 5,
                    label:
                      '*Enhance scientific research*, upgrade the technological capabilities of industrial sectors in all countries, in particular developing countries, including, by 2030, encouraging innovation and substantially increasing the number of research and development workers per 1 million people and public and private research and development spending',
                  },
                  {
                    id: 78,
                    sdgId: 9,
                    externalId: 'a',
                    label:
                      'Facilitate sustainable and resilient *infrastructure development in developing countries* through enhanced financial, technological and technical support to African countries, least developed countries, landlocked developing countries and small island developing States',
                  },
                  {
                    id: 79,
                    sdgId: 9,
                    externalId: 'b',
                    label:
                      'Support domestic technology development, *research and innovation* in developing countries, including by ensuring a conducive policy environment for, inter alia, industrial diversification and value addition to commodities',
                  },
                  {
                    id: 80,
                    sdgId: 9,
                    externalId: 'c',
                    label:
                      'Significantly increase *access to information and communications technology* and strive to provide universal and affordable access to the Internet in least developed countries by 2020',
                  },
                ],
              },
              10: {
                id: 10,
                title: '*Reduce inequality within and among countries*',
                short: 'Reduce Inequalities',
                colorInfo: {
                  hex: '#dd1367',
                  rgb: [221, 19, 103],
                },
                keywords: {
                  tags: ['inequality'],
                  descriptions: [
                    'income',
                    'sex',
                    'age',
                    'disability',
                    'race',
                    'class',
                    'ethnicity',
                    'religion',
                    'migration',
                    'development assistance',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_10.png',
                targets: [
                  {
                    id: 81,
                    sdgId: 10,
                    externalId: 1,
                    label:
                      'By 2030, progressively achieve and *sustain income growth* of the bottom 40 per cent of the population at a rate higher than the national average',
                  },
                  {
                    id: 82,
                    sdgId: 10,
                    externalId: 2,
                    label:
                      'By 2030, empower and *promote the social, economic and political inclusion* of all, irrespective of age, sex, disability, race, ethnicity, origin, religion or economic or other status',
                  },
                  {
                    id: 83,
                    sdgId: 10,
                    externalId: 3,
                    label:
                      'Ensure *equal opportunity and reduce inequalities* of outcome, including by eliminating discriminatory laws, policies and practices and promoting appropriate legislation, policies and action in this regard',
                  },
                  {
                    id: 84,
                    sdgId: 10,
                    externalId: 4,
                    label:
                      'Adopt policies, especially fiscal, wage and social protection policies, and progressively *achieve greater equality*',
                  },
                  {
                    id: 85,
                    sdgId: 10,
                    externalId: 5,
                    label:
                      'Improve the regulation and *monitoring of global financial markets* and institutions and strengthen the implementation of such regulations',
                  },
                  {
                    id: 86,
                    sdgId: 10,
                    externalId: 6,
                    label:
                      'Ensure *enhanced representation and voice for developing countries* in decision-making in global international economic and financial institutions in order to deliver more effective, credible, accountable and legitimate institutions',
                  },
                  {
                    id: 87,
                    sdgId: 10,
                    externalId: 7,
                    label:
                      'Facilitate orderly, safe, regular and *responsible migration* and mobility of people, including through the implementation of planned and well-managed migration policies',
                  },
                  {
                    id: 88,
                    sdgId: 10,
                    externalId: 'a',
                    label:
                      'Implement the principle of special and differential treatment for developing countries, in particular least developed countries, in accordance with World Trade Organization agreements',
                  },
                  {
                    id: 89,
                    sdgId: 10,
                    externalId: 'b',
                    label:
                      'Encourage official development assistance and financial flows, including *foreign direct investment, to States where the need is greatest*, in particular least developed countries, African countries, small island developing States and landlocked developing countries, in accordance with their national plans and programmes',
                  },
                  {
                    id: 90,
                    sdgId: 10,
                    externalId: 'c',
                    label:
                      'By 2030, *reduce* to less than 3 per cent the *transaction costs of migrant remittances* and eliminate remittance corridors with costs higher than 5 per cent',
                  },
                ],
              },
              11: {
                id: 11,
                title:
                  '*Make cities and human settlements inclusive, safe, resilient and sustainable*',
                short: 'Sustainable Cities and Communities',
                colorInfo: {
                  hex: '#fd9d24',
                  rgb: [253, 157, 36],
                },
                keywords: {
                  tags: ['cities', 'human settlements'],
                  descriptions: [
                    'community cohesion',
                    'personal security',
                    'innovation',
                    'employment',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_11.png',
                targets: [
                  {
                    id: 91,
                    sdgId: 11,
                    externalId: 1,
                    label:
                      'By 2030, ensure access for all to adequate, *safe and affordable housing* and basic services and upgrade slums',
                  },
                  {
                    id: 92,
                    sdgId: 11,
                    externalId: 2,
                    label:
                      'By 2030, provide access to safe, affordable, accessible and *sustainable transport systems for all*, improving road safety, notably by expanding public transport, with special attention to the needs of those in vulnerable situations, women, children, persons with disabilities and older persons',
                  },
                  {
                    id: 93,
                    sdgId: 11,
                    externalId: 3,
                    label:
                      'By 2030, enhance inclusive and *sustainable urbanization* and capacity for participatory, integrated and sustainable human settlement planning and management in all countries',
                  },
                  {
                    id: 94,
                    sdgId: 11,
                    externalId: 4,
                    label:
                      'Strengthen efforts to protect and *safeguard the world’s cultural and natural heritage*',
                  },
                  {
                    id: 95,
                    sdgId: 11,
                    externalId: 5,
                    label:
                      'By 2030, significantly *reduce the number of deaths* and the number of people affected and substantially decrease the direct economic losses relative to global gross domestic product caused by disasters, including water-related disasters, with a focus on protecting the poor and people in vulnerable situations',
                  },
                  {
                    id: 96,
                    sdgId: 11,
                    externalId: 6,
                    label:
                      'By 2030, reduce the adverse *per capita environmental impact of cities*, including by paying special attention to air quality and municipal and other waste management',
                  },
                  {
                    id: 97,
                    sdgId: 11,
                    externalId: 7,
                    label:
                      'By 2030, provide universal access to safe, inclusive and accessible,*green and public spaces*, in particular for women and children, older persons and persons with disabilities',
                  },
                  {
                    id: 98,
                    sdgId: 11,
                    externalId: 'a',
                    label:
                      'Support positive economic, social and environmental links between urban, per-urban and rural areas by strengthening *national and regional development planning*',
                  },
                  {
                    id: 99,
                    sdgId: 11,
                    externalId: 'b',
                    label:
                      'By 2020, substantially increase the number of cities and human settlements adopting and implementing *integrated policies and plans towards inclusion*, resource efficiency, mitigation and adaptation to climate change, resilience to disasters, and develop and implement, in line with the Sendai Framework for Disaster Risk Reduction 2015-2030, holistic disaster risk management at all levels',
                  },
                  {
                    id: 100,
                    sdgId: 11,
                    externalId: 'c',
                    label:
                      '*Support least developed countries*, including through financial and technical assistance, in building sustainable and resilient buildings utilizing local materials',
                  },
                ],
              },
              12: {
                id: 12,
                title:
                  '*Ensure *sustainable consumption and production* patterns*',
                short: 'Responsible Consumption and Production',
                colorInfo: {
                  hex: '#c9992d',
                  rgb: [201, 153, 45],
                },
                keywords: {
                  tags: ['consumption', 'production'],
                  descriptions: ['toxic materials', 'environment'],
                  groups: ['international agreements'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_12.png',
                targets: [
                  {
                    id: 101,
                    sdgId: 12,
                    externalId: 1,
                    label:
                      'Implement the 10-year framework of programmes on *sustainable consumption and production*, all countries taking action, with developed countries taking the lead, taking into account the development and capabilities of developing countries',
                  },
                  {
                    id: 102,
                    sdgId: 12,
                    externalId: 2,
                    label:
                      'By 2030, achieve the sustainable management and *efficient use of natural resources*',
                  },
                  {
                    id: 103,
                    sdgId: 12,
                    externalId: 3,
                    label:
                      'By 2030, *halve* per capita global *food waste* at the retail and consumer levels and *reduce* food losses along production and supply chains, including post-harvest losses',
                  },
                  {
                    id: 104,
                    sdgId: 12,
                    externalId: 4,
                    label:
                      'By 2020, achieve the *environmentally sound management of chemicals* and all wastes throughout their life cycle, in accordance with agreed international frameworks, and significantly *reduce* their release to air, water and soil in order to minimize their adverse impacts on human health and the environment',
                  },
                  {
                    id: 105,
                    sdgId: 12,
                    externalId: 5,
                    label:
                      'By 2030, substantially *reduce waste generation* through prevention, reduction, recycling and reuse',
                  },
                  {
                    id: 106,
                    sdgId: 12,
                    externalId: 6,
                    label:
                      'Encourage companies, especially large and transnational companies, to adopt *sustainable practices* and to integrate sustainability information into their reporting cycle',
                  },
                  {
                    id: 107,
                    sdgId: 12,
                    externalId: 7,
                    label:
                      '*Promote public procurement practices that are sustainable*, in accordance with national policies and priorities',
                  },
                  {
                    id: 108,
                    sdgId: 12,
                    externalId: 8,
                    label:
                      'By 2030, ensure that people everywhere have the relevant information and awareness for *sustainable development and lifestyles* in harmony with nature',
                  },
                  {
                    id: 109,
                    sdgId: 12,
                    externalId: 'a',
                    label:
                      'Support developing countries to strengthen their scientific and technological capacity to move towards more *sustainable patterns of consumption and production*',
                  },
                  {
                    id: 110,
                    sdgId: 12,
                    externalId: 'b',
                    label:
                      'Develop and implement tools to *monitor sustainable development impacts* for sustainable tourism that creates jobs and promotes local culture and products',
                  },
                  {
                    id: 111,
                    sdgId: 12,
                    externalId: 'c',
                    label:
                      'Rationalize *inefficient fossil-fuel subsidies* that encourage wasteful consumption by removing market distortions, in accordance with national circumstances, including by restructuring taxation and phasing out those harmful subsidies, where they exist, to reflect their environmental impacts, taking fully into account the specific needs and conditions of developing countries and minimizing the possible adverse impacts on their development in a manner that protects the poor and the affected communities',
                  },
                ],
              },
              13: {
                id: 13,
                title:
                  '*Take urgent action to combat climate change and its impacts*.',
                short: 'Climate Action',
                colorInfo: {
                  hex: '#3f7e44',
                  rgb: [63, 126, 68],
                },
                keywords: {
                  tags: ['climate change'],
                  descriptions: ['resilience', 'natural disasters'],
                  groups: ['poorest', 'vulnerable'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_13.png',
                targets: [
                  {
                    id: 112,
                    sdgId: 13,
                    externalId: 1,
                    label:
                      'Strengthen resilience and *adaptive capacity to climate-related hazards* and natural disasters in all countries',
                  },
                  {
                    id: 113,
                    sdgId: 13,
                    externalId: 2,
                    label:
                      '*Integrate climate change measures* into national policies, strategies and planning',
                  },
                  {
                    id: 114,
                    sdgId: 13,
                    externalId: 3,
                    label:
                      'Improve education, awareness-raising and human and institutional capacity on *climate change mitigation, adaptation*, impact reduction and early warning',
                  },
                  {
                    id: 115,
                    sdgId: 13,
                    externalId: 'a',
                    label:
                      'Implement the commitment undertaken by developed-country parties to the United Nations Framework Convention on Climate Change to a goal of mobilizing jointly $100 billion annually by 2020 from all sources to address the needs of developing countries in the context of meaningful mitigation actions and transparency on implementation and fully operationalize the Green Climate Fund through its capitalization as soon as possible',
                  },
                  {
                    id: 116,
                    sdgId: 13,
                    externalId: 'b',
                    label:
                      'Promote mechanisms for *raising capacity for effective climate change-related planning* and management in least developed countries and small island developing States, including focusing on women, youth and local and marginalized communities * Acknowledging that the United Nations Framework Convention on Climate Change is the primary international, intergovernmental forum for negotiating the global response to climate change.',
                  },
                ],
              },
              14: {
                id: 14,
                title:
                  '*Conserve and sustainably use the oceans, seas and marine resources for sustainable development*',
                short: 'Life Below Water',
                colorInfo: {
                  hex: '#0a97d9',
                  rgb: [10, 151, 217],
                },
                keywords: {
                  tags: ['oceans', 'seas', 'marine resources'],
                  descriptions: [
                    'marine ecosystems',
                    'coastal ecosystems',
                    'marine pollution',
                  ],
                  groups: [
                    'small islands developing States',
                    'SIDS',
                    'least developed countries',
                    'LDCs',
                  ],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_14.png',
                targets: [
                  {
                    id: 117,
                    sdgId: 14,
                    externalId: 1,
                    label:
                      'By 2025, prevent and significantly *reduce marine pollution* of all kinds, in particular from land-based activities, including marine debris and nutrient pollution',
                  },
                  {
                    id: 118,
                    sdgId: 14,
                    externalId: 2,
                    label:
                      'By 2020, sustainably manage and *protect marine and coastal ecosystems* to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration in order to achieve healthy and productive oceans',
                  },
                  {
                    id: 119,
                    sdgId: 14,
                    externalId: 3,
                    label:
                      'Minimize and *address the impacts of ocean acidification*, including through enhanced scientific cooperation at all levels',
                  },
                  {
                    id: 120,
                    sdgId: 14,
                    externalId: 4,
                    label:
                      'By 2020, effectively *regulate harvesting and end overfishing*, illegal, unreported and unregulated fishing and destructive fishing practices and implement science-based management plans, in order to restore fish stocks in the shortest time feasible, at least to levels that can produce maximum sustainable yield as determined by their biological characteristics',
                  },
                  {
                    id: 121,
                    sdgId: 14,
                    externalId: 5,
                    label:
                      'By 2020, *conserve at least 10 per cent of coastal and marine areas*, consistent with national and international law and based on the best available scientific information',
                  },
                  {
                    id: 122,
                    sdgId: 14,
                    externalId: 6,
                    label:
                      'By 2020, prohibit certain forms of fisheries subsidies which contribute to overcapacity and overfishing, eliminate subsidies that contribute to illegal, unreported and unregulated fishing and refrain from introducing new such subsidies, recognizing that appropriate and effective special and differential treatment for developing and least developed countries should be an integral part of the World Trade Organization fisheries subsidies negotiation',
                  },
                  {
                    id: 123,
                    sdgId: 14,
                    externalId: 7,
                    label:
                      'By 2030, *increase the economic benefits to Small Island developing States* and least developed countries from the sustainable use of marine resources, including through sustainable management of fisheries, aquaculture and tourism',
                  },
                  {
                    id: 124,
                    sdgId: 14,
                    externalId: 'a',
                    label:
                      'Increase scientific knowledge, *develop research capacity and transfer marine technology*, taking into account the Intergovernmental Oceanographic Commission Criteria and Guidelines on the Transfer of Marine Technology, in order to improve ocean health and to enhance the contribution of marine biodiversity to the development of developing countries, in particular small island developing States and least developed countries',
                  },
                  {
                    id: 125,
                    sdgId: 14,
                    externalId: 'b',
                    label:
                      'Provide *access for small-scale artisanal fishers to marine resources and markets*',
                  },
                  {
                    id: 126,
                    sdgId: 14,
                    externalId: 'c',
                    label:
                      'Enhance the conservation and *sustainable use of oceans and their resources* by implementing international law as reflected in UNCLOS, which provides the legal framework for the conservation and *sustainable use of oceans and their resources*, as recalled in paragraph 158 of The Future We Want',
                  },
                ],
              },
              15: {
                id: 15,
                title:
                  '*Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, *combat desertification*, and halt and reverse land degradation and halt biodiversity loss*',
                short: 'Life on Land',
                colorInfo: {
                  hex: '#56c02b',
                  rgb: [86, 192, 43],
                },
                keywords: {
                  tags: [
                    'terrestrial ecosystems',
                    'forests',
                    'desertification',
                    'land',
                    'biodiversity',
                  ],
                  descriptions: [
                    'degraded lands',
                    'forest management',
                    'natural habitats',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_15.png',
                targets: [
                  {
                    id: 127,
                    sdgId: 15,
                    externalId: 1,
                    label:
                      'By 2020, ensure the conservation, restoration and *sustainable use of terrestrial and inland freshwater ecosystems* and their services, in particular forests, wetlands, mountains and drylands, in line with obligations under international agreements',
                  },
                  {
                    id: 128,
                    sdgId: 15,
                    externalId: 2,
                    label:
                      'By 2020, promote the implementation of *sustainable management of all types of forests*, halt deforestation, restore degraded forests and substantially increase afforestation and reforestation globally',
                  },
                  {
                    id: 129,
                    sdgId: 15,
                    externalId: 3,
                    label:
                      'By 2030, *combat desertification*, restore degraded land and soil, including land affected by desertification, drought and floods, and strive to achieve a land degradation-neutral world',
                  },
                  {
                    id: 130,
                    sdgId: 15,
                    externalId: 4,
                    label:
                      'By 2030, ensure the *conservation of mountain ecosystems*, including their biodiversity, in order to enhance their capacity to provide benefits that are essential for sustainable development',
                  },
                  {
                    id: 131,
                    sdgId: 15,
                    externalId: 5,
                    label:
                      'Take urgent and significant action to *reduce the degradation of natural habitats, halt the loss of biodiversity* and, by 2020, protect and prevent the extinction of threatened species',
                  },
                  {
                    id: 132,
                    sdgId: 15,
                    externalId: 6,
                    label:
                      'Promote fair and equitable sharing of the benefits arising from the *utilization of genetic resources* and promote appropriate access to such resources, as internationally agreed',
                  },
                  {
                    id: 133,
                    sdgId: 15,
                    externalId: 7,
                    label:
                      'Take urgent action to *end poaching and trafficking of protected species of flora and fauna* and address both demand and supply of illegal wildlife products',
                  },
                  {
                    id: 134,
                    sdgId: 15,
                    externalId: 8,
                    label:
                      'By 2020, introduce measures to prevent the introduction and significantly *reduce the impact of invasive alien species on land and water ecosystems* and control or eradicate the priority species',
                  },
                  {
                    id: 135,
                    sdgId: 15,
                    externalId: 9,
                    label:
                      'By 2020, *integrate ecosystem and biodiversity values* into national and local planning, development processes, poverty reduction strategies and accounts',
                  },
                  {
                    id: 136,
                    sdgId: 15,
                    externalId: 'a',
                    label:
                      'Mobilize and significantly increase financial resources from all sources to *conserve and sustainably use biodiversity and ecosystems*',
                  },
                  {
                    id: 137,
                    sdgId: 15,
                    externalId: 'b',
                    label:
                      'Mobilize significant resources from all sources and at all levels to *finance sustainable forest management* and provide adequate incentives to developing countries to advance such management, including for conservation and reforestation',
                  },
                  {
                    id: 138,
                    sdgId: 15,
                    externalId: 'c',
                    label:
                      'Enhance global support for efforts to combat poaching and trafficking of protected species, including by increasing the capacity of local communities to *pursue sustainable livelihood opportunities*',
                  },
                ],
              },
              16: {
                id: 16,
                title:
                  '*Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels*',
                short: 'Peace and Justice - Strong Institutions',
                colorInfo: {
                  hex: '#00689d',
                  rgb: [0, 104, 157],
                },
                keywords: {
                  tags: [
                    'peaceful societies',
                    'inclusive societies',
                    'justice',
                    'institutions',
                  ],
                  descriptions: [
                    'human rights',
                    'rule of law',
                    'violence',
                    'armed conflict',
                    'weak institutions',
                  ],
                  groups: [],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_16.png',
                targets: [
                  {
                    id: 139,
                    sdgId: 16,
                    externalId: 1,
                    label:
                      'Significantly *reduce all forms of violence and related death rates* everywhere',
                  },
                  {
                    id: 140,
                    sdgId: 16,
                    externalId: 2,
                    label:
                      '*End abuse, exploitation, trafficking* and all forms of violence against and torture of children',
                  },
                  {
                    id: 141,
                    sdgId: 16,
                    externalId: 3,
                    label:
                      '*Promote the rule of law* at the national and international levels and ensure equal access to justice for all',
                  },
                  {
                    id: 142,
                    sdgId: 16,
                    externalId: 4,
                    label:
                      'By 2030, significantly *reduce illicit financial and arms flows*, strengthen the recovery and return of stolen assets and combat all forms of organized crime',
                  },
                  {
                    id: 143,
                    sdgId: 16,
                    externalId: 5,
                    label:
                      'Substantially *reduce corruption and bribery* in all their forms',
                  },
                  {
                    id: 144,
                    sdgId: 16,
                    externalId: 6,
                    label:
                      'Develop *effective, accountable and transparent institutions* at all levels',
                  },
                  {
                    id: 145,
                    sdgId: 16,
                    externalId: 7,
                    label:
                      'Ensure responsive, inclusive, participatory and *representative decision-making* at all levels',
                  },
                  {
                    id: 146,
                    sdgId: 16,
                    externalId: 8,
                    label:
                      'Broaden and strengthen the *participation of developing countries in the institutions of global governance*',
                  },
                  {
                    id: 147,
                    sdgId: 16,
                    externalId: 9,
                    label:
                      'By 2030, *provide legal identity for all*, including birth registration',
                  },
                  {
                    id: 148,
                    sdgId: 16,
                    externalId: 10,
                    label:
                      'Ensure *public access to information and protect fundamental freedoms*, in accordance with national legislation and international agreements',
                  },
                  {
                    id: 149,
                    sdgId: 16,
                    externalId: 'a',
                    label:
                      '*Strengthen relevant national institutions*, including through international cooperation, for building capacity at all levels, in particular in developing countries, to prevent violence and combat terrorism and crime',
                  },
                  {
                    id: 150,
                    sdgId: 16,
                    externalId: 'b',
                    label:
                      '*Promote and enforce non-discriminatory laws and policies* for sustainable development',
                  },
                ],
              },
              17: {
                id: 17,
                title:
                  '*Strengthen the means of implementation and revitalize the global partnership for sustainable development*',
                short: 'Partnerships for the Goals',
                colorInfo: {
                  hex: '#19486a',
                  rgb: [25, 72, 106],
                },
                keywords: {
                  tags: ['means of implementation', 'partnerships'],
                  descriptions: ['resource mobilization'],
                  groups: ['developing countries', 'LDCs'],
                },
                icon_url:
                  'https://raw.githubusercontent.com/UNStats-SDGs/sdgs-data/master/images/en/TGG_Icon_Color_17.png',
                targets: [
                  {
                    id: 151,
                    sdgId: 17,
                    externalId: 1,
                    label:
                      '*Strengthen domestic resource mobilization*, including through international support to developing countries, to improve domestic capacity for tax and other revenue collection',
                  },
                  {
                    id: 152,
                    sdgId: 17,
                    externalId: 2,
                    label:
                      'Developed countries to implement fully their official *development assistance commitments*, including the commitment by many developed countries to achieve the target of 0.7 per cent of ODA/GNI to developing countries and 0.15 to 0.20 per cent of ODA/GNI to least developed countries; ODA providers are encouraged to consider setting a target to provide at least 0.20 per cent of ODA/GNI to least developed countries',
                  },
                  {
                    id: 153,
                    sdgId: 17,
                    externalId: 3,
                    label:
                      'Mobilize additional *financial resources for developing countries* from multiple sources',
                  },
                  {
                    id: 154,
                    sdgId: 17,
                    externalId: 4,
                    label:
                      '*Assist developing countries in attaining long-term debt sustainability* through coordinated policies aimed at fostering debt financing, debt relief and debt restructuring, as appropriate, and address the external debt of highly indebted poor countries to *reduce* debt distress',
                  },
                  {
                    id: 155,
                    sdgId: 17,
                    externalId: 5,
                    label:
                      'Adopt and implement *investment promotion regimes for least developed countries*',
                  },
                  {
                    id: 156,
                    sdgId: 17,
                    externalId: 6,
                    label:
                      'Enhance North-South, South-South and triangular *regional and international cooperation* on and access to science, technology and innovation and enhance knowledge sharing on mutually agreed terms, including through improved coordination among existing mechanisms, in particular at the United Nations level, and through a global technology facilitation mechanism',
                  },
                  {
                    id: 157,
                    sdgId: 17,
                    externalId: 7,
                    label:
                      '*Promote the development, transfer, dissemination and diffusion of environmentally sound technologies* to developing countries on favourable terms, including on concessional and preferential terms, as mutually agreed',
                  },
                  {
                    id: 158,
                    sdgId: 17,
                    externalId: 8,
                    label:
                      'Fully operationalize the technology bank and science, technology and *innovation capacity-building mechanism for least developed countries* by 2017 and enhance the use of enabling technology, in particular information and communications technology',
                  },
                  {
                    id: 159,
                    sdgId: 17,
                    externalId: 9,
                    label:
                      'Enhance international support for implementing effective and *targeted capacity-building in developing countries* to support national plans to implement all the sustainable development goals, including through North-South, South-South and triangular cooperation',
                  },
                  {
                    id: 160,
                    sdgId: 17,
                    externalId: 10,
                    label:
                      'Promote a universal, rules-based, open, *non-discriminatory and equitable multilateral trading system* under the World Trade Organization, including through the conclusion of negotiations under its Doha Development Agenda',
                  },
                  {
                    id: 161,
                    sdgId: 17,
                    externalId: 11,
                    label:
                      'Significantly *increase the exports of developing countries*, in particular with a view to doubling the least developed countries’ share of global exports by 2020',
                  },
                  {
                    id: 162,
                    sdgId: 17,
                    externalId: 12,
                    label:
                      'Realize timely implementation of *duty-free and quota-free market access on a lasting basis for all least developed countries*, consistent with World Trade Organization decisions, including by ensuring that preferential rules of origin applicable to imports from least developed countries are transparent and simple, and contribute to facilitating market access',
                  },
                  {
                    id: 163,
                    sdgId: 17,
                    externalId: 13,
                    label:
                      'Enhance *global macroeconomic stability*, including through policy coordination and policy coherence',
                  },
                  {
                    id: 164,
                    sdgId: 17,
                    externalId: 14,
                    label:
                      'Enhance *policy coherence for sustainable development*',
                  },
                  {
                    id: 165,
                    sdgId: 17,
                    externalId: 15,
                    label:
                      '*Respect each country’s policy space and leadership* to establish and implement policies for poverty eradication and sustainable development Multi-stakeholder partnerships',
                  },
                  {
                    id: 166,
                    sdgId: 17,
                    externalId: 16,
                    label:
                      '*Enhance the global partnership for sustainable development*, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology and financial resources, to support the achievement of the sustainable development goals in all countries, in particular developing countries',
                  },
                  {
                    id: 167,
                    sdgId: 17,
                    externalId: 17,
                    label:
                      'Encourage and promote *effective public, public-private and civil society partnerships*, building on the experience and resourcing strategies of partnerships Data, monitoring and accountability',
                  },
                  {
                    id: 168,
                    sdgId: 17,
                    externalId: 18,
                    label:
                      'By 2020, enhance *capacity-building support to developing countries*, including for least developed countries and small island developing States, to increase significantly the availability of high-quality, timely and reliable data disaggregated by income, gender, age, race, ethnicity, migratory status, disability, geographic location and other characteristics relevant in national contexts',
                  },
                  {
                    id: 169,
                    sdgId: 17,
                    externalId: 19,
                    label:
                      'By 2030, build on existing initiatives to *develop measurements of progress on sustainable development* that complement gross domestic product, and support statistical capacity-building in developing countries',
                  },
                ],
              },
            },
          },
        },
        irisImpactCategories: {
          title: 'Select related IRIS+ Impact Categories',
          subtitle: `Please select the IRIS+ Impact Categories linked to the impact. Click [here](https://iris.thegiin.org/) for more info on IRIS.`,
          categories: [
            { id: 1, label: 'Agriculture' },
            { id: 2, label: 'Air' },
            { id: 3, label: 'Biodiversity & Ecosystems' },
            { id: 4, label: 'Climate' },
            { id: 5, label: 'Diversity & Inclusion' },
            { id: 6, label: 'Education' },
            { id: 7, label: 'Employment' },
            { id: 8, label: 'Energy' },
            { id: 9, label: 'Financial Services' },
            { id: 10, label: 'Health' },
            { id: 11, label: 'Real Estate' },
            { id: 12, label: 'Land' },
            { id: 13, label: 'Oceans & Coastal Zones' },
            { id: 14, label: 'Pollution' },
            { id: 15, label: 'Waste' },
            { id: 16, label: 'Water' },
            { id: 17, label: 'Cross Category' },
            { id: 18, label: 'Infrastructure' },
          ],
        },
      },
    },
    impactSolutions: {
      title: 'Solutions',
      description: 'Which solutions do you offer to generate the impact?',
      cardTitle: 'Solution',
      confirmDeleteTitle: 'Delete solution',
      confirmDeleteText:
        'Do you really want to delete this solution? You cannot undo this action.',
      deleteCancelButton: 'Cancel',
      deleteButton: 'Delete',
      addSolutionButton: 'Add solution',
      impactSolution: {
        title: 'Describe the solution that generates a positive Impact',
        text: '<p>In this step, you will define your solution. This is your innovative product or service, process or technology that will help to deliver your impact,&nbsp;<strong>Reduced CO2 emissions.&nbsp;</strong>If your company offers multiple solutions, you can define another solution after this one.</p>',
        howIxDefinesSolutionsTitle: 'How does ImpactNexus define solutions?',
        howIxDefinesSoutionsText: `<p>Solutions are your company&apos;s outputs (products or services) that are required to deliver the outcomes and impact. When you add a solution, you will define its:</p>
        <ul>
            <li>Name - This is used as the solution&apos;s title on your impact reports</li>
            <li>How the solution works - A brief description of your solution so people can understand what it is</li>
            <li>Customer or target group - Who will use your solution</li>
            <li>GHG reductions potential (optional) - A comparison with a traditional product or service to determine how much CO2e your solution could save</li>
        </ul>`,
        solutionKindTitle: 'What kind of solution is it?',
        solutionKindOptions: [
          { label: 'Product', value: 'PRODUCT' },
          { label: 'Service', value: 'SERVICE' },
          { label: 'Hybrid', value: 'HYBRID' },
        ],
        solutionConceptTitle: 'What is the name of your solution?',
        solutionConceptSubtitle: `<p>For example:</p>
          <ul>
              <li>Repairable modular smartphone</li>
              <li>Business travel advice software</li>
              <li>Small wind turbines</li>
          </ul>`,
        solutionTargetGroupTitle:
          'Who is the customer or target group of your solution?',
        solutionTargetGroupSubtitle: `<p>Your target group is the population or group of people that your solution is designed to assist or that is going to use your solution. It may be individuals, communities or companies, but could also be an environmental entity such as ecosystems. Try to be as specific as you can.</p>
        <p>For example:</p>
        <ul>
            <li>End consumers</li>
            <li>Homeowners</li>
            <li>Rural communities</li>
            <li>Concrete plants</li>
            <li>Small and medium companies</li>
        </ul>`,
        solutionTargetGroupPlaceHolder: '',
        solutionErrors: {
          requiredError: 'Text is required!',
          lengthError: 'Text must be less than 100 characters',
        },
        solutionDescription: 'How does the solution work?',
        solutionDescriptionSubtitle: `<p>Describe in one or two sentences the approach of your solution. This may include the steps taken, technologies used or strategies implemented to explain how your solution that leads to the desired impact. This description will appear on your impact report and will help people understand how your solution works.</p>
        <p>For example:</p>
        <ul>
            <li>The modular design of our smartphone allows users to easily remove and replace components, like the battery or camera, without specialized tools or professional help, enabling quick repairs and upgrades.</li>
            <li>Our software provides companies with tools and recommendations to book environmental friendly business travel.</li>
            <li>Our small wind turbines can be easily installed in various locations, including highly dense urban or industrial areas, making renewable electricity production more accessible.</li>
        </ul>`,
        solutionEvidence: {
          title: 'Evidence for the Impact',
          subtitle:
            'If available, please add external evidence that clarifies that this solution is working and can help to solve the problem addressed. This can be articles, scientific literature or other sources that proves the solution.',
        },
        solutionDescriptionPlaceHolder: '',
        useGhgSavingPotentialToolTitle:
          'Do you want to quantify your GHG reduction potential of this solution?',
        useGhgSavingPotentialToolip:
          'Calculating your GHG emission savings helps to make informed decisions about actions that can effectively reduce your carbon footprint. This process enables the setting of clear, measurable emission reduction goals and allows for tracking progress over time. It also aids in prioritizing resources, by identifying the most effective and cost-efficient strategies for emission reduction. The awareness generated by understanding potential emission savings can encourage proactive action towards reduced CO2 emissions. Furthermore, for policymakers, this calculation serves as a critical tool for the development and evaluation of policies aimed at reducing GHG emissions, ensuring these are based on solid evidence. Finally, for businesses and organizations, quantifying GHG emission savings is a valuable resource for sustainability reporting, contributing to greater transparency and accountability in environmental impact',
        ghgSavingPotentialTool: {
          welcome: {
            title:
              'Quantify the GHG reduction potential of your product or services',
            contents: `<p><strong>To quantify your GHG reduction, you will:</strong></p>
                  <ul>
                  <li>Identify the <strong>Impact profile</strong> of your solution. This profile will enable us to provide you with specific guidance for calculating the emission reductions of your solution.</li>
                  <li>Create a <strong>Comparative scenario and Functional unit</strong>, which compares your solution to an established product or service which provides the same function.</li>
                  <li>Add the relevant GHG <strong>Emission sources</strong> for both your solution and the comparitive scenario.</li>
                  <li>Set a <strong>Forecast</strong> to calculate your GHG savings potential over the next 5 years, and determine if your solution leads to recurring GHG reductions.</li>
                  </ul>
                  <p> </p>
                  <p><strong>What you need to get started:</strong></p>
                  <ul>
                  <li>Make sure you have <strong>good knowledge of your solution</strong>. It is helpful to know how the product or service works, how you manufacture or offer it, and what raw materials, processes or energy consumption are required.</li>
                  </ul>
                  <p> </p>
                  <p><strong>Important notes:</strong></p>
                  <ul>
                  <li>This tool <strong>does not do carbon foot-printing</strong>, but quantifies the <strong>potential GHG emission reductions</strong> of your solution.</li>
                  <li>This tool does not perform an exact calculation, but results in a <strong>high-quality estimate</strong> of the GHG reduction potential.</li>
                  <li>Please be sure to make<strong> realistic and fair assumptions</strong> and always state them <strong>transparently</strong>.</li>
                  </ul>`,
            benefitsQuantifyingGhg:
              'What are the benefits of quantifying my GHG reduction potential?',
            benefitsQuantifyingGhgContent: `<p>When you quantify your GHG reduction potential, your completed impact report will:</p>
                                            <ul>
                                                <li>Display the total annual GHG reduction that your company aims to provide over the next 5 years.</li>
                                                <li>Indicate if you are a high climate potential company (saving on average 50,000+ tonnes CO2e per year).</li>
                                                <li>Show the reduction in GHG emissions per functional unit, and the % reduction each solution achieves over its comparative scenario.</li>
                                            </ul>
                                            <br />
                                            <p>These additions can help promote the benefits that your company&apos;s solutions offer over traditional products and services, both to your investors and end-users.</p>`,
            // <a target="_blank" rel="noopener noreferrer" href="https://impactnexus.io/"><u><strong>Show an example impact report with quantified GHG reduction</strong></u></a>`,
            endText: `If you prefer not to quantify your solution's GHG right now, you can go back to My Impacts.`,
          },
          impactProfile: {
            title: 'Impact profile',
            body: `<p>The impact profile describes how your solution triggers a reduction of GHG emissions. We use the impact profile to provide you with more accurate guidance during the GHG calculation process.</p>
                    <p>If your solution reduces GHG emissions in several ways, choose the profile where you expect to have the greatest potential. Your selection does not affect any calculations, and you can change it later.</p>`,
            optionsHeader: `<p>Select the most suitable impact profile for your solution. <span style="color: rgb(184, 49, 47);">*</span></p>`,
            introductionImpactProfile: 'Introduction to impact profiles',
            introductionImpactProfileContent: `<p>To calculate the GHG reduction potential, the focus is on the impact of companies in contributing to climate protection with their products and services. Products and services that contribute to climate protection are called "solutions". In principle, climate protection means either the reduction of greenhouse gas (GHG) emissions or the removal of greenhouse gases from the atmosphere with the help of greenhouse gas sinks. The different ways in which a company&apos;s solution can contribute to climate protection can be categorized into impact profiles.</p>
                                        <p>The impact profiles describe how a company&apos;s solution contributes to the reduction of greenhouse gas emissions. In other words, they show how a solution can lead to the displacement of emission-intensive solutions or activities.</p>
                                        Due to the diversity of business models and solutions, it is not always possible to clearly assign the solution to just one impact profile - solutions often have different levers and can therefore cover several impact profiles. Users should choose the impact profile with the highest potential.`,
            impactProfiles: [
              {
                id: 'CIRCULAR_ECONOMY',
                label: 'Circular Economy',
                value: 'CIRCULAR_ECONOMY',
                sublabel:
                  'My solution enables the reduction of GHG emissions by establishing a Circular Economy.',
                tooltip:
                  '<p>A solution with the "Circular Economy" impact profile enables the reduction of GHG emissions by creating a closed-loop system, optimizing the use of resources and minimising waste. Thus, the solution leads to the avoidance of emission-intensive activities, such as multiple new purchases, new production of products, or a large amount of waste.<br><br><strong>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. The company is responsible for the distribution and processing (cleaning) of the reusable containers. Existing deposit systems will be used. The company does not produce the reusable containers itself but handles the logistical processes and thus enables reusable use in a wide range of applications.<br><br><strong>Example 2:</strong> A company designs a smartphone with modular components, making it easier for users to repair and upgrade their phones. The dismountable parts are exchangeable and spare parts are easily available.If the battery has not enough capacity or the camera is broken, the user can remove them and install the new components. Disposal and production of intact or new smartphones will be avoided.</p>',
              },
              {
                id: 'EXTRACTION_AND_MANUFACTURING',
                label: 'Raw Material Extraction and Manufacturing',
                value: 'EXTRACTION_AND_MANUFACTURING',
                sublabel:
                  'My solution reduces GHG emissions during its production, provision or raw material use.',
                tooltip:
                  '<p>A solution with the  "Raw material extraction and manufacturing" impact profile reduces GHG emissions during its production or provision of raw materials. This impact is achieved through the reduction of raw material use or related activities (efficiency) and/or by avoiding the use of emission-intensive raw materials or activities (substitution). The solution directly displaces a more emission-intensive competing product or service that is produced with conventional methods or raw materials.<br><br><strong>Example 1:</strong> A company optimizes the cement production by using an alternative raw material to reduce the process-related CO2 emissions.<br><br><strong>Example 2:</strong> A company manufactures clothing from discarded textile waste, such as cotton bedding. This approach directly reduces GHG emissions in the manufacturing process by eliminating the need for new production of textile fibres.</p>',
              },
              {
                id: 'LOGISTICS',
                label: 'Transport and Logistics',
                value: 'LOGISTICS',
                sublabel:
                  'My solution reduces GHG emissions by optimizing transport routes and/or using climate-friendly transport modes.',
                tooltip:
                  '<p>A solution with the  "Transport and Logistics" impact profile reduces GHG emissions by optimizing transport routes and/or by using climate-friendly transport modes. The solution directly displaces a more emission-intensive competing transportation option<br><br><strong>Example 1:</strong> A company uses a digital platform to network food retailers with local farms to increase the supply of seasonal and regional products using short transport routes.<br><br><strong>Example 2:</strong> A company offers cycled delivery of products for the last mile. The transporter traffic in cities will be reduced and replaced by bicycles which transport the packages to the end-consumer. Furthermore, the bicycles can be faster due to more efficient routes which are not traversable for big transporters. For bigger deliveries cargo bicycles are used.</p>',
              },
              {
                id: 'PRODUCT_APPLICATION',
                label: 'Product Use',
                value: 'PRODUCT_APPLICATION',
                sublabel:
                  'My solution enables its users to reduce greenhouse gas emissions through each use or operation.',
                tooltip:
                  '<p>A solution with the &ldquo;Product use" impact profile is a product with which its users can reduce greenhouse gas emissions through each use or operation. The solution enables users to replace emission-intensive activities, while the product is in use. Thus, the impact is either repeated or permanent and extends over a longer period of time.<br><br><strong>Example 1:</strong> A company develops small wind turbines that can easily be installed in different locations. The smaller size makes renewable electricity production with wind turbines available to highly dense urban or industrial areas. Thus, it allows GHG reductions through easier production of renewable electricity.<br><br><strong>Example 2:</strong> A company develops a new line of washing machines equipped with advanced washing algorithms that optimize the washing process for maximum efficiency and performance. These algorithms control factors such as wash cycle duration, temperature, and agitation intensity to ensure thorough cleaning while minimizing energy consumption and reducing environmental impact.</p>',
              },
              {
                id: 'CUSTOMER_EMPOWERMENT',
                label: 'Customer empowerment',
                value: 'CUSTOMER_EMPOWERMENT',
                sublabel:
                  'My solution empowers my customers with advisory, informative or supportive services to reduce GHG emissions.',
                tooltip:
                  '<p>A solution with the  "Customer Empowerment" impact profile enables its users to reduce GHG emissions with advisory, informative or supportive services. The impact is achieved by helping the users to implement climate protection measures more easily or take more climate-friendly decisions.<br><br><strong>Example 1:</strong> A company develops a software that enables companies to make their employee business travel more climate friendly. GHG emissions can be avoided by using the software and implementing corresponding climate-friendly advice.<br><br><strong>Example 2:</strong> A company offers a web platform for monitoring industrial systems to detect critical operating conditions. This enables customers to carry out predictive maintenance of CHP units, solar systems, air compressors, etc. and to improve the energy efficiency and profitability. GHG emissions can be reduced due to the optimized running of the systems.</p>',
              },
            ],
          },
          fairComparison: {
            title: 'Comparative scenario and Functional unit',
            comparativeScenarioAndFunctionalUnitDesc:
              'In this step, you set up a comparative scenario and a functional unit, so that you can fairly compare your solution to a currently established product or service.',
            functionOfYourImpactSolutionTitle: 'Function of your solution',
            functionOfYourImpactSolutionQuestion:
              'What function does your solution provide?',
            functionOfYourImpactSolutionWarning:
              '<p><em>Attention: This focuses on the function of your solution for your customers, not the broader of goal of the climate mitigation/GHG reduction.</em></p>',

            comparativeScenarioTitle: 'Comparative Scenario',
            comparativeScenarioText:
              'Describe the comparative scenario that provides the same function. This scenario should reflect the established situation without your solution. If there are several established solutions, choose the one with the best climate performance to avoid green-washing.',
            comparativeScenarioQuestion:
              '<p>How is the fuction currently provided with your solution?</p>',
            functionalUnitTitle: 'Functional Unit',
            functionalUnitText:
              'For the comparison, you need a measuring unit, often called a "functional unit". This unit should be something you can measure and predict based on your business forecasts. In the next step, we will connect the greenhouse gas emissions to this measuring unit.',
            functionalUnitQuestion: 'Which functional unit should be used?',
            describeComparisonTitle:
              'Describe the fair comparison you are making by completing this sentence:',
            describeComparisonSubtitle:
              'I compare [my solution] with [comparative scenario] on the basis of [functional unit]',
            guidanceForProfile: 'Guidance for your impact profile',
            fairComparisonsPerImpactProfile: {
              CIRCULAR_ECONOMY: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. The main function is <strong>offering customers the possibility to buy packaged dry foods</strong>.<br> <br><strong>Example 2:</strong> A company designs a smartphone with modular components, making it easier for users to repair and upgrade their phones. Its main function is <strong>mobile communication, internet connectivity, organisation, navigation and other smartphone features</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>For your impact profile, it is useful to compare your solution to a currently established product or process that provides the same function but is single-use only or is not repairable or recyclable etc. You can think about what your clients would use or do without your solution to get the same benefit or function.<br> <br><strong>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. A possible comparison is a <strong>conventional single-use packaging</strong>, as this provides the same function. <br> <br><strong>Example 2:</strong> A company designs a smartphone with modular components, making it easier for users to repair and upgrade their phones. A possible comparison is a <strong>conventional smartphone with glued and welded components</strong> which are not dismountable and repairable.</p>',
                guidanceFunctionalUnit:
                  '<p>With your impact profile, a sold or used quantity of the relevant product or activity, that is becoming part of a circular economy, is usually a good functional unit. The functional unit may include a quantity that is typical for the comparison scenario. Factors like the frequency of reuse or lifetime extensions of your solution will be considered in the next steps when calculating emissions.<br><strong><br>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. A good functional unit would be a <strong>500g packet of dried food</strong>.<br><strong><br>Example 2:</strong> A company designs a smartphone with modular components, making it easier for users to repair and upgrade their phones. A good functional unit would be a <strong>three-year usage period of a smartphone</strong>.</p>',
              },
              EXTRACTION_AND_MANUFACTURING: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Example 1:</strong> A company optimizes the cement production by using an alternative raw material. The function would be the <strong>production of cement</strong>.<br> <br><strong>Example 2:</strong> A company produces clothing from discarded textile waste, primarily cotton bedding. The function of this product is to <strong>provide clothing for everyday life</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>For your impact profile, it is useful to compare your solution to a currently established product that provides the same function and would be displaced by your innovation.<br> <br><strong>Example 1:</strong> A company optimizes the cement production by using an alternative raw material. A possible comparison would be a <strong>conventional production of cement</strong>.<br> <br><strong>Example 2:</strong> A company produces clothing from discarded textile waste, primarily cotton bedding. A useful comparison would be a <strong>conventionally produced clothing</strong> with the same function (e.g. T-Shirts).</p>',
                guidanceFunctionalUnit:
                  '<p>With your impact profile, a quantity of raw material used, or a quantity of goods produced is usually a good choice.<br><strong><br>Example 1:</strong> A company optimizes the cement production by using an alternative raw material. A good functional unit would be <strong>one tonne of cement produced.<br><br>Example 2:</strong> A company produces clothing from discarded textile waste, primarily cotton bedding. A good functional unit would be <strong>1 T-Shirt</strong> or another piece of clothing.<br>Typical other functional units are: MWh of energy produced, number of backpacks sold, production of 160.000 kWh thermal energy.</p>',
              },
              LOGISTICS: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Example 1:</strong> A company uses a digital platform to network food retailers with local farms to increase the supply of seasonal and regional products using e-transporters and short transport routes. The function is the <strong>supply of groceries to a supermarket</strong>.<br> <br><strong>Example 2:</strong> A company offers cycled delivery of products for the last mile. The main function is the <strong>delivery of a package to the end-consumer in the city</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>For your impact profile, it is useful to compare your solution to a currently established product or service that provides the same function and would be displaced by your solution.<br> <br><strong>Example 1: </strong>A company uses a digital platform to network food retailers with local farms to increase the supply of seasonal and regional products using e-transporters and short transport routes. A possible comparison scenario would be the <strong>current supply system of supermarkets with groceries from distant locations</strong>.<br> <br><strong>Example 2:</strong> A company offers cycled delivery of products for the last mile. A possible comparison scenario would be <strong>delivering products with conventional transporters</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>With your impact profile, a quantity of goods or passengers transported over a certain distance, or a distance travelled is usually a good choice.<br><strong><br>Example 1: </strong>A company uses a digital platform to network food retailers with local farms to increase the supply of seasonal and regional products using e-transporters and short transport routes. A good functional unit would be the <strong>supply of 1 tonne of food to a supermarket</strong>.<br><strong><br>Example 2:</strong> A company offers cycled delivery of products for the last mile. A good functional unit would be <strong>delivering 100 products (total weight of 3 tonnes) over 25 km</strong>.<br>Typical other functional units are: a meal delivered, transportation of a person over 100 km, transportation of goods over 100 km.</p>',
              },
              PRODUCT_APPLICATION: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Example 1:</strong> A company develops small wind turbines, that can be installed in highly dense urban or industrial areas. The function is the <strong>production and supply of electricity in highly dense urban and industrial areas</strong>.<br> <br><strong>Example 2: </strong>A company develops a new line of washing machines equipped with advanced washing algorithms that optimize the washing process for maximum efficiency and performance. The function is <strong>doing the laundry</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>In the case of your impact profile, think about what your customer would do or use without your solution to get the same function and which activities would be displaced by your solution.<br> <br><strong>Example 1:</strong> A company develops small wind turbines, that can be installed in highly dense urban or industrial areas. A possible comparative scenario would the <strong>electricity supply from the grid</strong>. <br> <br><strong>Example 2:</strong> A company develops a new line of washing machines equipped with advanced washing algorithms that optimize the washing process for maximum efficiency and performance. A possible comparative scenario would be <strong>conventional washing machines</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>With your impact profile, it usually makes sense to use a functional unit that reflects what can be achieved with your solution.<br><br><strong>Example 1:</strong> A company develops small wind turbines, that can be installed in highly dense urban or industrial areas. A good functional unit would be the amount of energy produced by one average small wind turbine (10 kW) in a year: <strong>20.000 kWh of electricity</strong>.<br><strong><br>Example 2:</strong> A company develops a new line of washing machines equipped with advanced washing algorithms that optimize the washing process for maximum efficiency and performance. A good functional unit would be the number of washing cycles of one washing machine in a year: <strong>150 washing cycles</strong>.<br><br>Typical other functional units are: 1MW installed solar panel capacity, processing of 100 kg of a material, production of 160.000 kWh useful heat.</p>',
              },

              CUSTOMER_EMPOWERMENT: {
                guidanceFunctionOfYourImpactSolution:
                  '<p><strong>Example 1: </strong>A company develops a software that allows companies to make their business travel more climate-friendly by involving employees. The common function is the <strong>booking of business travels</strong>.<br> <br><strong>Example 2:</strong> A company offers a web platform for monitoring industrial systems to detect and improve critical operating conditions. The function of this product is <strong>monitoring and running technical equipment</strong>.</p>',
                guidanceComparativeScenario:
                  '<p>In the case of your impact profile, think about what your customer would do without your advisory, informative or supportive solution to get the same function and which activities would be displaced with the help of your solution. It is possible that your customer would not use any other product or service.<br> <br><strong>Example 1:</strong> A company develops a software that allows companies to make their business travel more climate-friendly by involving employees. A possible comparison would be a <strong>conventional software which shows options for business travel without the focus on environmental-friendly and employee involving options</strong>. <br> <br><strong>Example 2:</strong> A company offers a web platform for monitoring industrial systems to detect and improve critical operating conditions. A useful comparison would be a) <strong>a conventional monitoring software which is less efficient</strong> or b) <strong>running technical equipment without a monitoring system</strong>.</p>',
                guidanceFunctionalUnit:
                  '<p>For your impact profile, an average customer or a specific activity is often a good functional unit. As those can differ a lot, use an average customer / activity, that can represent a large part of your business. If possible, try to choose a more specific unit.<br><strong><br>Example 1:</strong> A company develops a software that allows companies to make their business travel more climate-friendly by involving employees. A good functional unit would be <strong>1 average business travel (1 Person, 200 km one way / per trip)</strong>.<br><strong><br>Example 2:</strong> A company offers a web platform for monitoring industrial systems to detect and improve critical operating conditions. A good functional unit would be <strong>1 average customer</strong>.</p>',
              },
            },
          },
          emissionSources: {
            guidanceForProfile: 'Guidance for your impact profile',

            limitations: {
              showGuidanceForLimitationsLabel: 'Guidance for limitations',
              title: 'Describe in bullet points the limitations you have made',
              subtitle: 'Which emission sources did you omit and why?',
              inputGuidance: 'Use asterisks (*) as bullet points.',
              placeholder: `* We have not included the GHG emissions from the production of the batteries.
* This is because we use second life batteries from electric vehicles.
* ...`,
              examples: `Explain the limitations of your data, for example:
                          <ul>
                              <li>Transportation, Production &amp; Components are the same as for the comparison scenario.</li>
                              <li>End of Life - Emissions are not considered due to data availability.</li>
                              <li>Raw Materials are not taken into account as the impact on greenhouse gas emissions is not likely to be particularly relevant in this context.</li>
                              <li>Transport emissions are not taken into account as they are estimated to be very small.</li>
                          </ul>`,
            },
            emissionSourcesTableHeaders: [
              {
                text: '',
                value: 'actions',
              },
              {
                text: 'I compare ...',
                value: 'sourceOfEmission',
              },
              {
                text: 'Emission of comparative scenario',
                value: 'baselineConsumption',
              },
              {
                text: 'Emission of your solution',
                value: 'solutionConsumption',
              },
              {
                text: 'Why does the difference occur?',
                value: 'howSolutionWorks',
              },
            ],
            addEmissionSourceButton: 'Add an emission source',
            scenarioOptions: [
              {
                id: 'A',
                label: 'Calculate precise emissions with emission sources',
                sublabel:
                  'Specify up to three of the most relevant emission sources and back them up with data. An emission source is anything that is necessary to provide the function of your solution and/or the comparative scenario, and also leads to GHG emissions. E.g. the consumption of energy, the need for transportation or the consumption of materials.',
              },
              {
                id: 'B',
                label:
                  'Enter an estimated total amount of GHG emissions for each scenario',
                sublabel:
                  'Use this option if you have already calculated a product carbon footprint that fits your functional unit, or you want to make an educated guess about the total GHG emissions per functional unit of each scenario. Please note that while an educated guess is much easier to provide, the result is likely to be less accurate, and also less convincing because it can be difficult to comprehend if no evidence is provided.',
              },
            ],
            scenarioDetails: {
              scenarioA: {
                warning: `It looks like your solution leads to more greenhouse gas
                          emissions than the comparative scenario. This may be because you missed
                          some emission sources or some data is inaccurate. It is also possible
                          your solution does not reduce any emissions. Please check your entry is
                          correct.`,
                label: 'Calculate precise emissions with emission sources',
                text: `<ol>
                            <li><strong>Choose the most relevant emission sources:</strong> Focus on up to three emission sources.</li>
                            <li><strong>Consider only the emission sources that vary across the scenarios:</strong> As we are only interested in the differences between the scenarios, skip emissions that occur in both scenarios in the same amount. Depending on your impact profile, include raw materials, mobility, and energy data that are necessary for your solution but differ in the scenarios.</li>
                            <li><strong>Enter the corresponding GHG emission factor and consumption amount:</strong> You will find integrated databases to find fitting GHG emission factors or typical reference data.</li>
                            <li><strong>Consider the functional unit and a year:</strong> Always mention the unit for comparison and, if applicable, consider a one-year timeframe.</li>
                            <li><strong>Detail differences:</strong> For each emission source, explain how the scenarios differ:<ul>
                                    <li>Change in consumption: It could involve a smaller amount needed, or the source is not used in one scenario (zero consumption) but is used in the other.</li>
                                </ul>
                            </li>
                        </ol>
                        <ul>
                            <ul>
                                <li>Change in GHG emission factor: There might be different GHG emission factors for the same source in each scenario.</li>
                            </ul>
                        </ul>`,
                addFactorAndAmount: 'Add emission factor and specify an amount',
                guidancePerImpactProfile: {
                  CIRCULAR_ECONOMY: {
                    emissionSourceGuidanceForProfile: `<p>With your impact profile, the relevant emission sources can be found in all life phases of your innovation. Depending on the scenario, relevant emissions may be found in different life phases. Think about those activities that differ between your solution and the comparative scenario.</p>
                    <strong>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. Relevant emission sources are:
                    <ul>
                        <li><strong>raw material use</strong> (plastic of multiple path food packaging vs. plastic of single-use packaging),</li>
                        <li><strong>transportation</strong> (distance from end consumer back to deposit system and back to the supermarket vs. no way back),</li>
                        <li><strong>use phase</strong> (cleaning of re-useable packaging vs. no cleaning),<br>end of life (reuse of packaging vs. disposal and burning or recycling of packaging).</li>
                    </ul>
                    </br>
                    <strong>Example 2:</strong> A company designs a smartphone with modular components, making it easier for users to repair and upgrade their phones. Relevant emission sources are:
                    <ul>
                        <li><strong>raw material extraction</strong> (raw material extraction for spare parts vs. raw material extraction for a new smartphone)</li>
                        <li><strong>production</strong> (of spare parts vs. whole new smartphone)</li>
                        <li><strong>end of life</strong> (reuse or extended use of existing smartphone vs. disposal of conventional smartphone).</li>
                    </ul>`,
                  },
                  EXTRACTION_AND_MANUFACTURING: {
                    emissionSourceGuidanceForProfile: `<p>With your impact profile, your relevant emission sources are usually found in the different phases: raw material extraction, and/or production phase, and/or transportation phase. Think about the main difference between the production or provision of your solution and the comparative scenario.</p>
                    <strong>Example 1:</strong> A company optimizes the cement production by using an alternative raw material. Relevant emission sources are:
                    <ul>
                        <li><strong>raw material extraction </strong>(raw material used for CO2-reduced cement vs. raw materials used for conventional cement)</li>
                        <li><strong>production </strong>(optimized cement production vs. conventional cement production)</li>
                    </ul>
                    </br>
                    <strong>Example 2:</strong> A company produces clothing from discarded textile waste, primarily cotton bedding. Relevant emission sources are:
                    <ul>
                        <li><strong>raw material extraction </strong>(virgin fibres vs. second hand fibre)</li>
                        <li><strong>transportation </strong>(import of virgin fibre vs. inland provision of second-hand fibre)</li>
                    </ul>`,
                  },
                  LOGISTICS: {
                    emissionSourceGuidanceForProfile: `<p>With your impact profile, your relevant emission sources are usually found in the transportation and logistics processes. Think about those activities that differ between your solution and the comparative scenario.</p>
                    <strong>Example 1:</strong> A company uses a digital platform to network food retailers with local farms to increase the supply of seasonal and regional products using e-transporters and short transport routes. Relevant emission sources are:
                    <ul>
                        <li><strong>use phase</strong> (use digital platform vs no platform use), </li>
                        <li><strong>transportation</strong> (delivery of local food vs. distant food) </li>
                        <li><strong>production of food</strong> (seasonal food vs. no seasonal food)</li>
                    </ul>
                    </br>
                    <strong>Example 2:</strong> A company offers cycled delivery of products for the last mile. Relevant emission sources are:
                    <ul>
                        <li><strong>production</strong> (of conventional transporter vs bike)</li>
                        <li><strong>transportation</strong> (with conventional transporter vs. bike)</li>
                    </ul>`,
                  },
                  PRODUCT_APPLICATION: {
                    emissionSourceGuidanceForProfile: `<p>With your impact profile, you usually find the relevant emission sources in the use phase of your solution as well as the comparison solution or in the business activities (incl. upstream chains) of your customers. Think about the changes that happen when using your solution instead of the comparative scenario.</p>
                    <strong>Example 1:</strong> A company develops small wind turbines, that can be installed in highly dense urban or industrial areas. Relevant emission sources are:
                    <ul>
                        <li><strong>generation of electricity&rdquo;</strong> (small wind turbines vs. grid mix)</li>
                    </ul>
                    </br>
                    <strong>Example 2:</strong> A company develops a new line of washing machines equipped with advanced washing algorithms that optimize the washing process for maximum efficiency and performance. Relevant emission sources are:
                    <ul>
                        <li><strong>electricity consumption</strong> (efficient washing machine vs. conventional washing machine)</li>
                    </ul>`,
                  },
                  CUSTOMER_EMPOWERMENT: {
                    emissionSourceGuidanceForProfile: `<p>With your impact profile, you can usually find the relevant emission sources in the business activities (incl. upstream chains) of your customers or with third parties. Think about the changes that happen when your customers use your solution instead of the comparative scenario.</p>
                    <strong>Example 1:</strong> A company developed a software that allows companies to make their business travel more climate-friendly by involving employees. Relevant emission sources are:
                    <ul>
                        <li><strong>use phase</strong> (running the software vs. no software)</li>
                        <li><strong>transportation</strong> (e.g. taking the train vs. plane for inland business travels)</li>
                    </ul>
                    </br>
                    <strong>Example 2:</strong> A company offers a web platform for monitoring industrial systems to detect and improve critical operating conditions. Relevant emission sources are:
                    <ul>
                        <li><strong>use phase of the plants</strong> (e.g. efficient vs. non-efficient energy consumption)</li>
                    </ul>`,
                  },
                },
              },
              scenarioB: {
                title:
                  'Enter an estimated total amount of GHG for each scenario',
                comparativeScenario: {
                  title: 'Comparative scenario',
                  subtitle: 'GHG amount in kg CO2',
                  placeholder: 'GHG amount in kg CO2',
                },
                solution: {
                  title: 'Your Solution',
                  subtitle: 'GHG amount in kg CO2',
                  placeholder: 'GHG amount in kg CO2',
                },
                comments: {
                  title:
                    'Please justify your assumptions with references or other evidence and calculation methods!',
                  placeholder: 'Assumptions',
                },
              },
            },
            emissionSourceGuidelines: {
              introduction: `<p>To calculate your reduction potential, the relevant emission data for your solution and the comparative scenario must be collected per functional unit. To achieve this, you need to provide GHG emissions for both scenarios. Guiding questions, examples and references or links to external databases will help you to compile all necessary values and also provide evidence to support your claims.</p>
                              <p>There are two methods of calculation. Please choose an option:</p>`,
            },
            emissionSourceFormsTooltips: {
              ghgFactorTooltip: `<p>A GHG emission factor is a numerical value that helps quantify the relationship between human activities and the greenhouse gas emissions they produce. The GHG emission factor represents the amount of greenhouse gas emissions produced per unit of activity or output, such as emissions per unit of energy produced or emissions per kilometer travelled in a particular means of transport.</p>
                                  <p><strong>Example:</strong> Electricity supplied from grid - production mix in Germany 2022: <strong>0.3876 kg CO2e/kWh</strong></p>
                                  <p>You can search for a GHG factor in the database or enter your own factor.</p>
                                  <p>When selecting an GHG emission factor, pay attention to the life-cycle phases (sometimes called LCA-Activity) that the GHG factor covers. Try to cover with a GHG factor as many life cycles as possible or not to cover any life-cycle phase twice when adding more than one emission source.</p>`,
              tooltipPerImpactProfile: {
                PRODUCT_APPLICATION: {
                  consumptionAmountTooltip: `<p>This is the amount that is consumed based on the functional unit. The unit must be the same as the unit of the GHG factor.</p>
                  <p>E.g. if the unit of the GHG factor is <strong>kg CO2e / kWh</strong> , your consumption must be expressed in <strong>kWh.</strong></p>
                  <p>The reference value database helps you to find typical consumption amounts.</p>`,
                },
                CIRCULAR_ECONOMY: {
                  consumptionAmountTooltip: `<p>This is the amount that is consumed based on the functional unit. In the case of your impact profile, note that circular economy products are often used several times or over a longer period. This means that you may have to apply a factor for reuse or lifetime extension.</p>
                  <p><strong>Example 1:</strong> A company develops a reusable deposit system for dry food packaging. The functional unit is the packaging of 500g of dry food. The reusable food packaging has an average circulation of 35 cycles. Therefore, only 1/35 of the production emissions of the reusable food packaging are needed.</p>
                  <p>The unit must be the same as the unit of the GHG factor.</p>
                  <p>E.g. if the unit of the GHG factor is <strong>kg CO2e / kWh</strong>, your consumption must be expressed in <strong>kWh</strong>.</p>
                  <p>The reference value database helps you to find typical consumption amounts.</p>`,
                },
                EXTRACTION_AND_MANUFACTURING: {
                  consumptionAmountTooltip: `<p>This is the amount that is consumed based on the functional unit. The unit must be the same as the unit of the GHG factor.</p>
                  <p>E.g. if the unit of the GHG factor is <strong>kg CO2e / kWh</strong> , your consumption must be expressed in <strong>kWh.</strong></p>
                  <p>The reference value database helps you to find typical consumption amounts.</p>`,
                },
                LOGISTICS: {
                  consumptionAmountTooltip: `<p>This is the amount that is consumed based on the functional unit. The unit must be the same as the unit of the GHG factor.</p>
                  <p>E.g. if the unit of the GHG factor is <strong>kg CO2e / kWh</strong> , your consumption must be expressed in <strong>kWh.</strong></p>
                  <p>The reference value database helps you to find typical consumption amounts.</p>`,
                },
                CUSTOMER_EMPOWERMENT: {
                  consumptionAmountTooltip: `<p>This is the amount that is consumed based on the functional unit. The unit must be the same as the unit of the GHG factor.</p>
                  <p>E.g. if the unit of the GHG factor is <strong>kg CO2e / kWh</strong> , your consumption must be expressed in <strong>kWh.</strong></p>
                  <p>The reference value database helps you to find typical consumption amounts.</p>`,
                },
              },
            },
            idematComparisonModal: {
              title: 'Find the appropriate emission source',
              keywordLabel: 'Search term (*)',
              noDataText: 'No data available. Check search term and filters.',
              searchInfo: `<i>Search in English only*</i>`,
              closeModal: 'Cancel',
              addAndCloseModal: 'Add source',
              tableHeaders: {
                name: 'Name',
                category: 'Category',
                ghgFactor: 'GHG Factor',
                unit: 'Unit',
                notes: 'Notes',
                source: 'Source',
              },
            },
            comparativeEmissionSourceForm: {
              title: 'Comparative Scenario',
              guidance:
                'Add an emission factor and consumption amount for the comparative scenario:',
              baseline: {
                ownGhgText: 'Enter your own factor',
                searchGhgText: 'Search for GHG factor',
                conceptLabel: 'Name of GHG factor',
                customEvidenceLabel: 'Source / URL',
                ghgFactorLabel: 'GHG factor',
                consumptionAmountLabel: 'Amount',
                evidenceLabel: 'Justification / supporting documents',
                unitLabel: 'Unit',
                assumptionLabel:
                  'This data represents the comparative scenario because...',
              },
              closeModal: 'Save',
              cancelModal: 'Cancel',
            },
            yourSolutionEmissionSourceForm: {
              title: 'Your solution',
              guidance:
                'What is the difference between the comparative scenario and your solution? Explain why the difference occurs.',
              solution: {
                title: 'Your solution',
                ownGhgText: 'Enter your own factor',
                searchGhgText: 'Search for GHG factor',
                conceptLabel: 'Name of GHG factor',
                customEvidenceLabel: 'Source / URL',
                ghgFactorLabel: 'GHG factor (CO2e)',
                consumptionAmountLabel: 'Amount',
                consumptionCheckBoxLabel: 'Change in consumption',
                ghgCheckBoxLabel: 'Alternative GHG emission factor',
                bothCheckBoxLabel: 'Both',
                howSolutionWorksLabel: 'This difference occurs because...',
                assumptionTitle:
                  'Add an alternative emission factor and/or enter a different consumption amount for your solution:',
                unitLabel: 'Unit',
                assumptionLabel:
                  'This assumption represents my solution fairly because...',
              },
              closeModal: 'Save',
              cancelModal: 'Cancel',
            },
            customComparisonModal: {
              title: 'Enter your own GHG emission factor',
              guidanceLabel: 'Guidance on selecting an emission factor',
              guidanceText: `<p>Where possible, always use the emission factor for the exact activity/material/process that you are working with, and to use the same database for all factors. When the exact factor is not available, the following alternatives are recommended (in order of preference):</p>
                            <ol>
                                <li><strong>Use a proxy emission factor:</strong> Choose the option that closely aligns with a conservative approach.<br>For instance, if specific data for gasoline motorcycles is unavailable, consider selecting the option for low-powered gasoline cars.</li>
                                <li><strong>Use a customised emission factor:</strong> Introduce an emission factor from another database (provide the source and explain the calculations made, if applicable). When multiple datasets are available, opt for the one that encompasses all life cycle stages, is calculated for your region, and is the most up-to-date (in order of preference).</li>
                                <li><strong>Use in-house data:</strong> Add evidence and justify the calculation.</li>
                            </ol>`,
              customName: 'Name of GHG factor',
              customGhg: `GHG factor (kg CO2e)`,
              customUnit: 'Unit',
              exampleName: `_Example:_ Electricity grid mix Germany 2023`,
              exampleGhg: `_Example:_ 0.3876`,
              exampleUnit: `_Example:_ kWh`,
              customEvidence: 'Source / URL',
              closeModal: 'Cancel',
              addAndCloseModal: 'Save',
            },
          },
          growthFactor: {
            title: 'Forecast',
            fiveYearQuestion:
              'How many units of your functional unit does your company plan to provide in each of the next five years?',
            fiveYearQuestionSubtitle:
              'We recommend using a five year forecast but you can also decide to use a longer or shorter time frame and use a different start date.',
            text: `<p>This forecast allows you to estimate the potential GHG reduction over the next 5 year period. It is an estimate of the number of units (of your functional unit) that your company plans to provide in each of the next five years. If your functional unit describes an impact, the forecast should reflect how often the impact will occur each year.</p>
                    Your functional unit is: `,
            explanationTitle:
              'Please explain and justify the forecast you made',
            explanationPlaceholder: 'Explanation / justification',
            durationOfImpactLabel: 'My solution leads to recurring savings',
            durationOfImpactMessages:
              'A solution leads to recurring GHG savings if each unit produced will provide its GHG reduction more than once over its lifetime. If this is the case, please tick the box and enter the duration (in years) that each unit will continue to provide savings. In most cases, this is the lifetime of the product in years.',
            confirmForecastLabel:
              'I confirm that I have given an accurate and realistic forecast.',
            exampleSolutionRecurringGhgSavingsLabel:
              'Example solution with recurring GHG savings',
            exampleSolutionRecurringGhgSavingsText:
              'A typical example is the installation of a renewable energy system such as PV (solar panels), where each unit installed will lead to a yearly reduction in GHG emissions for approximately 20 years as it continues to produce renewable energy.',
          },
          result: {
            title: 'Result',
          },
        },
      },
    },
  },
}
