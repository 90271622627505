import { render, staticRenderFns } from "./measure.vue?vue&type=template&id=55270413&scoped=true"
import script from "./measure.vue?vue&type=script&lang=ts"
export * from "./measure.vue?vue&type=script&lang=ts"
import style0 from "./measure.vue?vue&type=style&index=0&id=55270413&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55270413",
  null
  
)

/* custom blocks */
import block0 from "./measure.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSectionTitle: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionTitle.vue').default,IxFormInputQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/Question.vue').default,IxCustomSelect: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/CustomSelect.vue').default,IxButtonAction: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Action.vue').default,IxDialogMetric: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/Metric.vue').default,MetricsAssignmentBatchDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/metrics/AssignmentBatchDialog.vue').default,IxDataTableMetrics: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/data-table/Metrics.vue').default,IxSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SectionText.vue').default,IxSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Section.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDialog,VIcon,VSpacer})
