import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { abbreviateNumber, replaceCommaAndParseFloatOrNull } from '~/assets/helper/functions';
import { renderMarkdown } from '~/functions/markdown';
import { MetricReportingPeriodType, MetricValueKind, useUpsertMetricValuesMutation } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    reportingPeriodType: {
      type: String,
      required: true
    },
    metric: {
      type: Object,
      required: true
    },
    unitName: {
      type: String,
      default: ''
    },
    metricValue: {
      type: Object,
      required: true
    },
    metricValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    children: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    isFrozen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var showTarget = ref(false);
    var clearTargets = ref(false);
    watch(function () {
      return props.metricValue;
    }, function (newValue) {
      var _props$metricValue, _props$metricValue2;
      mv.value = _objectSpread({}, newValue);
      showTarget.value = ((_props$metricValue = props.metricValue) === null || _props$metricValue === void 0 ? void 0 : _props$metricValue.target) !== null && ((_props$metricValue2 = props.metricValue) === null || _props$metricValue2 === void 0 ? void 0 : _props$metricValue2.target) !== undefined || props.metricValues.some(function (e) {
        return e.target !== null && e.target !== undefined;
      });
    });
    var dialog = ref(false);
    var mv = ref(_objectSpread({}, props.metricValue)); /// FIXME since value is used for dialog, we have to maintain the state here.

    var inputs = ref([]);
    var input = function input(val) {
      // When ever user make a change, we store it in the local state
      var index = inputs.value.findIndex(function (i) {
        return i.metricAssignmentId === val.metricAssignmentId && i.period === val.period;
      });
      var toAdd;
      if (index > -1) {
        toAdd = _objectSpread(_objectSpread({}, inputs.value[index]), val);
        inputs.value.splice(index, 1); // remove the obj the value
      } else {
        toAdd = val;
      }
      inputs.value.push(toAdd);
    };
    var _useUpsertMetricValue = useUpsertMetricValuesMutation(),
      mutate = _useUpsertMetricValue.mutate,
      loading = _useUpsertMetricValue.loading;
    var update = function update() {
      mutate({
        inputs: inputs.value.map(function (i) {
          var _props$metric, _props$metric2;
          if (i.hasOwnProperty('actual') && [MetricValueKind.Int, MetricValueKind.Float].includes((_props$metric = props.metric) === null || _props$metric === void 0 ? void 0 : _props$metric.valueType)) {
            i.actual = replaceCommaAndParseFloatOrNull(i.actual);
          }
          if (i.hasOwnProperty('target') && [MetricValueKind.Int, MetricValueKind.Float].includes((_props$metric2 = props.metric) === null || _props$metric2 === void 0 ? void 0 : _props$metric2.valueType)) {
            i.target = replaceCommaAndParseFloatOrNull(i.target);
          }
          return i;
        })
      }).then(function () {
        emit('metricValuesUpdated', inputs.value);
        window.$nuxt.$emit('userMsg');
        resetAndCloseDialog();
      });
    };
    var skipped = computed(function () {
      return mv.value.skipped;
    });
    var disabled = computed(function () {
      if (inputs.value.length === 0) return true;
      if (skipped.value) {
        var commentFromInputs = inputs.value.find(function (item) {
          return item.comment;
        });
        var commentString = commentFromInputs ? commentFromInputs.comment : mv.value.comment;
        return !commentString;
      }
      return false;
    });
    var resetAndCloseDialog = function resetAndCloseDialog() {
      var _props$metricValue3;
      mv.value = ref(_objectSpread({}, props.metricValue));
      inputs.value = []; // clear pending inputs
      showTarget.value = !!((_props$metricValue3 = props.metricValue) !== null && _props$metricValue3 !== void 0 && _props$metricValue3.target) || props.metricValues.some(function (e) {
        return !!e.target;
      });
      clearTargets.value = false;
      emit('input', false);
    };
    var handleSkipped = function handleSkipped(doSkip) {
      mv.value.skipped = doSkip;
      var val = {
        metricAssignmentId: mv.value.metricAssignmentId,
        period: mv.value.period,
        skipped: doSkip
      };
      if (doSkip) {
        var toClear = {
          actual: null,
          text: null,
          options: null
        };
        val = _objectSpread(_objectSpread({}, val), toClear);
        mv.value = _objectSpread(_objectSpread(_objectSpread({}, mv.value), toClear), {}, {
          skipped: doSkip
        });
      }
      input(val);
    };
    var handleShowTarget = function handleShowTarget(doShow) {
      showTarget.value = doShow;
      if (doShow === false) {
        // if checkbox is unticked we delete the targets
        // for quarterly/monthly
        clearTargets.value = true;
        // for annual
        mv.value = _objectSpread(_objectSpread({}, mv.value), {}, {
          target: null
        });
        var val = {
          metricAssignmentId: mv.value.metricAssignmentId,
          period: mv.value.period,
          target: null
        };
        input(val);
      }
    };
    var scrolled = ref(false);
    var onScroll = function onScroll(evt) {
      if (evt.target.scrollTop <= 0 && scrolled.value) {
        scrolled.value = false;
      } else if (evt.target.scrollTop > 0 && !scrolled.value) {
        scrolled.value = true;
      }
    };
    return {
      scrolled: scrolled,
      onScroll: onScroll,
      loading: loading,
      mv: mv,
      toNumber: _.toNumber,
      dialog: dialog,
      update: update,
      input: input,
      inputs: inputs,
      skipped: skipped,
      disabled: disabled,
      showTarget: showTarget,
      resetAndCloseDialog: resetAndCloseDialog,
      MetricValueKind: MetricValueKind,
      MetricReportingPeriodType: MetricReportingPeriodType,
      handleSkipped: handleSkipped,
      handleShowTarget: handleShowTarget,
      clearTargets: clearTargets
    };
  },
  computed: {
    isOnlyLastQuarterForValueOfInvestmentFilled: function isOnlyLastQuarterForValueOfInvestmentFilled() {
      var _this$metric;
      // Early return if metric is not "Value of investment"
      if (((_this$metric = this.metric) === null || _this$metric === void 0 ? void 0 : _this$metric.id) !== 395) return false;

      // Initialize an object to track actual values by quarter
      var quarterValues = {};
      this.metricValues.filter(function (e) {
        var _e$period;
        return (_e$period = e.period) === null || _e$period === void 0 ? void 0 : _e$period.includes('Q');
      }).forEach(function (e) {
        var quarterNumber = e.period.slice(-1);
        quarterValues[quarterNumber] = e.actual;
      });

      // Update quarterValues based on inputs
      this.inputs.forEach(function (item) {
        var quarterNumber = item.period.slice(-1);
        quarterValues[quarterNumber] = item.actual;
      });

      // Check that the first three quarters are empty (null or 0) and the fourth quarter is filled (non-null, non-zero)
      return !quarterValues['1'] && !quarterValues['2'] && !quarterValues['3'] && !!quarterValues['4'];
    }
  },
  methods: {
    formatNumber: function formatNumber(num, locale) {
      if (num === null) {
        // if we run Number(null) it will return 0, so this is an edge case
        return '⋯';
      }
      num = Number(num);
      if (isNaN(num)) {
        return '⋯';
      }
      return abbreviateNumber(num, locale); // .toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    formatOptions: function formatOptions(options) {
      if (options === undefined || options === null || options.length < 1) {
        return '⋯';
      }
      return options.join(', ');
    },
    renderMarkdown: renderMarkdown
  }
});