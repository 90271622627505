var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","no-gutters":""}},[_c('v-stepper',{staticClass:"esg-edit-stepper mt-16 pa-10 pb-7",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ix-section',[_c('ix-section-title',[_vm._v("\n              "+_vm._s(_vm.$t('impactGoal.intro.title'))+"\n            ")]),_vm._v(" "),_c('ix-section-text',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(_vm.$t('impactGoal.intro.description')))}}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),(_vm.impactGoal)?_c('ix-button-action',{attrs:{"disabled":_vm.QuantifyGhgReductionButtonDisabled},on:{"click":function($event){_vm.handleQuantifyGhgReductionInput(
                    _vm.impactGoal.impacts,
                    {
                      // Reduced CO2 emissions
                      impactGoalId: _vm.impactGoal.id,
                      conceptId: 13613,
                      hasFrozenOutcome: true,
                      stakeholderCategory:
                        _vm.StakeholderCategoryType.PeopleAndNaturalEnvironment,
                    },
                    {
                      geographies: [280],
                      irisCategories: [4],
                    },
                    {
                      impactSolution: {
                        solutionDescription: _vm.$t(
                          'impactGoal.intro.newGhgSolution.description',
                        ),
                        kind: 'PRODUCT',
                        targetGroup: _vm.$t(
                          'impactGoal.intro.newGhgSolution.targetGroup',
                        ),
                        useGhgSavingPotentialTool: true,
                        ventureId: _vm.ventureId,
                      },
                      solutionConcept: { label:  _vm.$t(
                          'impactGoal.intro.newGhgSolution.name',
                        ), },
                    },
                  )}}},[_vm._v("\n                "+_vm._s(_vm.$t('impactGoal.intro.QuantifyGhgReductionNowButton'))+"\n              ")]):_vm._e(),_vm._v(" "),_c('ix-button-action',{on:{"click":function($event){return _vm.$emit('nextSection')}}},[_vm._v("\n                "+_vm._s(_vm.$t('general.navigation.continue'))+"\n              ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }