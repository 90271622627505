var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',{staticClass:"d-flex align-center mb-2"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('metrics-action-menu',{attrs:{"portfolio":_vm.portfolio}})],1),_vm._v(" "),_c('v-col',{staticClass:"relative p-2",attrs:{"cols":"auto"}},[_c('p',{staticClass:"mb-2 absolute top-[-10px] text-[14px]"},[_vm._v("\n        "+_vm._s(_vm.$t('assignedTo'))+"\n      ")]),_vm._v(" "),_c('v-btn-toggle',{staticClass:"p-2",attrs:{"value":_vm.$route.query.kpiAssigned,"dense":"","color":"primary","mandatory":""},on:{"change":(v) => _vm.$router.push({ query: { ..._vm.$route.query, kpiAssigned: v } })}},[_c('v-btn',{attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.$t('company')))]),_vm._v(" "),_c('v-btn',{attrs:{"value":"portfolio"}},[_vm._v(" "+_vm._s(_vm.$t('generalPartner')))])],1)],1),_vm._v(" "),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.kpiAssigned != 'portfolio'),expression:"$route.query.kpiAssigned != 'portfolio'"}],staticClass:"relative p-2",attrs:{"cols":"auto"}},[_c('p',{staticClass:"mb-0 absolute top-[-10px] text-[14px]"}),_vm._v(" "),_c('v-btn-toggle',{attrs:{"value":_vm.$route.query.type,"dense":"","color":"primary","mandatory":""},on:{"change":(v) => _vm.$router.push({ query: { ..._vm.$route.query, type: v } })}},[_c('v-btn',{attrs:{"value":""}},[_vm._v(" Aggregated")]),_vm._v(" "),_c('v-btn',{attrs:{"value":'assignment'}},[_vm._v(" Datapoint")])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"p-2",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"text-[14px]",attrs:{"value":_vm.$route.query.search,"hide-details":"","label":_vm.$t('search.label'),"clearable":"","outlined":"","dense":"","flat":"","append-icon":"mdi-magnify"},on:{"input":(val) => {
            _vm.$router.push({ query: { ..._vm.$route.query, search: val } })
          }}})],1),_vm._v(" "),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$route.query.type == 'assignment' &&
        _vm.$route.query.kpiAssigned != 'portfolio'
      ),expression:"\n        $route.query.type == 'assignment' &&\n        $route.query.kpiAssigned != 'portfolio'\n      "}],attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"text-[12px]",staticStyle:{"max-width":"250px"},attrs:{"items":[
          { text: 'No grouping', value: null },
          { text: 'Group by KPI', value: 'metric.name' },
          { text: 'Group by company', value: 'assignee.name' },
          { text: 'Group by impacts', value: 'impactIds' },
          {
            text: 'Group by Frameworks',
            value: 'metric.commaSeparatedTaxonomies',
          },
        ],"hide-details":"","dense":"","solo":"","label":"Group by"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}})],1),_vm._v(" "),_c('v-col',{staticClass:"p-2",attrs:{"cols":"auto"}},[_c('ix-dialog-metric',{on:{"update:metric":({ id }) => {
            _vm.customKpiId = id
            _vm.isAggregationDialogOpen = true
          }},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('ix-button-action',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.type !== 'assignment'),expression:"$route.query.type !== 'assignment'"}],staticClass:"text-[12px]",attrs:{"icon":"mdi-plus"}},'ix-button-action',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t('defineKPI'))+"\n          ")])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"width":"75%","scrollable":""},model:{value:(_vm.isAggregationDialogOpen),callback:function ($$v) {_vm.isAggregationDialogOpen=$$v},expression:"isAggregationDialogOpen"}},[_c('ix-dialog-metric-aggregation-content',{attrs:{"metric-id":_vm.customKpiId},on:{"input":_vm.refetchList,"close":() => {
              _vm.isAggregationDialogOpen = false
              _vm.customKpiId = null
            }}})],1),_vm._v(" "),_c('ix-dialog-metric-assignment',{on:{"input":({ id }) => {
            _vm.refetchList()
          }},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('ix-button-action',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.type == 'assignment'),expression:"$route.query.type == 'assignment'"}],staticClass:"text-[12px]",attrs:{"icon":"mdi-plus"}},'ix-button-action',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t('defineKPI'))+"\n          ")])]}}])})],1),_vm._v(" "),_c('v-col',{staticClass:"p-2",attrs:{"cols":"auto"}},[_c('ix-button-action',{attrs:{"to":{
          name: 'portfolio-metrics-search',
          query: {
            assignedTos:
              _vm.$route.query.kpiAssigned == 'portfolio'
                ? [
                    _vm.TypeMetricAssignedTo.FundMgmt,
                    _vm.TypeMetricAssignedTo.FundCompany,
                    _vm.TypeMetricAssignedTo.Fund,
                  ]
                : [_vm.TypeMetricAssignedTo.Company, null],
          },
        },"nuxt":"","icon":"mdi-plus"}},[_vm._v("\n        "+_vm._s(_vm.$t('searchKPI'))+"\n      ")])],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"m-0 p-0"},[_c('v-slide-x-transition',{attrs:{"leave-absolute":""}},[_c('ix-data-table-metric-aggregations',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$route.query.kpiAssigned != 'portfolio' &&
            _vm.$route.query.type != 'assignment'
          ),expression:"\n            $route.query.kpiAssigned != 'portfolio' &&\n            $route.query.type != 'assignment'\n          "}],attrs:{"height":'calc(100vh - 225px)',"search":_vm.$route.query.search}})],1),_vm._v(" "),_c('v-slide-x-transition',{attrs:{"leave-absolute":""}},[(
            _vm.$route.query.kpiAssigned != 'portfolio' &&
            _vm.$route.query.type == 'assignment'
          )?_c('ix-data-table-metric-assignments',{staticClass:"m-0 p-0",attrs:{"show-top":true,"items-per-page":10,"fixed-header":true,"height":'calc(100vh - 225px)',"show-group-by":"","headers":[
            'expandToggle',
            'assigneeId',
            'metric.name',
            'unitName',
            'comment',
            'action',
            'metric.commaSeparatedTaxonomies',
          ],"filters":{ keyword: _vm.$route.query.search },"group-by":_vm.groupBy}}):_vm._e()],1),_vm._v(" "),(_vm.portfolio)?_c('v-slide-x-transition',{attrs:{"leave-absolute":""}},[_c('ix-data-table-metric-assignments',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.kpiAssigned == 'portfolio'),expression:"$route.query.kpiAssigned == 'portfolio'"}],staticClass:"m-0 p-0",attrs:{"items-per-page":10,"fixed-header":true,"height":'calc(100vh - 225px)',"filters":{
            assigneeIds: [_vm.portfolio.id],
            keyword: _vm.$route.query.search,
          }}})],1):_vm._e()],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(_vm.openedItem)?_c('metrics-metric-values-dialog',{attrs:{"metric":_vm.openedItem.metric,"unit-name":_vm.openedItem.metric.unitName,"is-frozen":_vm.openedItem.isFrozen,"reporting-period-type":_vm.openedItem.reportingPeriodType || _vm.MetricReportingPeriodType.Annual,"metric-values":(_vm.openedItem.metricValues || []).filter((v) =>
        v.period.includes(_vm.openedPeriod),
      ) || [],"metric-value":_vm.openedItem.metricValuesByPeriod[_vm.openedPeriod] || {}},on:{"metricValuesUpdated":() => {
        // FIXME this data structure is still very bad as it NOT super clear if it's MetricAggregationType or MetricAssignmentType
        _vm.refetchList()
        _vm.dialog = false
      }},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }