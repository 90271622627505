var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ix-section',[_c('ix-section-title',[_vm._v(_vm._s(_vm.$t('impactGoal.impacts.defineImpacts.impactDefinition.title')))]),_vm._v(" "),_c('ix-section-text',[(_vm.impactGoal)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impacts.defineImpacts.impactDefinition.description',
              ))+"\n          ")]),_vm._v(" "),_c('h3',[_vm._v("\n            "+_vm._s(_vm.$t(
                'impactGoal.impacts.defineImpacts.impactDefinition.exampleTitle',
              ))+"\n          ")]),_vm._v(" "),_c('v-layout',{staticClass:"align-center mb-5",staticStyle:{"gap":"16px"}},[_c('v-layout',{staticStyle:{"gap":"16px"}},[_c('v-layout',{attrs:{"column":""}},[_c('impact-pill',{attrs:{"impact":{
                    concept: {
                      label: _vm.$t(
                        'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[0].outcome',
                      ),
                    },
                    stakeholder: _vm.$t(
                      'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[0].stakeholder',
                    ),
                  }}})],1),_vm._v(" "),_c('v-layout',{attrs:{"column":""}},[_c('impact-pill',{attrs:{"impact":{
                    concept: {
                      label: _vm.$t(
                        'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[1].outcome',
                      ),
                    },
                    stakeholder: _vm.$t(
                      'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[1].stakeholder',
                    ),
                  }}})],1),_vm._v(" "),_c('v-layout',{attrs:{"column":""}},[_c('impact-pill',{attrs:{"impact":{
                    concept: {
                      label: _vm.$t(
                        'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[2].outcome',
                      ),
                    },
                    stakeholder: _vm.$t(
                      'impactGoal.impacts.defineImpacts.impactDefinition.exampleImpacts[2].stakeholder',
                    ),
                  }}})],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{staticClass:"mb-4",attrs:{"title":_vm.$t('impactGoal.impacts.defineImpacts.defineImpact.title'),"is-mandatory":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.renderMarkdown(
                _vm.$t('impactGoal.impacts.defineImpacts.defineImpact.subtitle'),
              )
            )}}),_vm._v(" "),_c('div',{staticClass:"space-y-2"},_vm._l((_vm.impactGoal.impacts),function(impact,index){return _c('div',{key:index,staticClass:"impact-box"},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',[_c('impact-pill',{attrs:{"impact":impact}})],1),_vm._v(" "),(_vm.impactGoal.isEditable)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-layout',{attrs:{"column":"","fill-height":"","align-content-space-between":"","justify-center":""}},[_c('v-btn',{staticClass:"my-1",staticStyle:{"border-radius":"12px"},attrs:{"color":"primary","icon":"","tile":"","small":"","elevation":"0","outlined":"","nuxt":"","to":_vm.nuxtLink(impact.id)}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"border-radius":"12px"},attrs:{"icon":"","small":"","tile":"","elevation":"0","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete")])],1)],1)],1)]}},{key:"default",fn:function(deleteImpactDialog){return [_c('ix-section',{staticStyle:{"border-radius":"12px !important"}},[_c('ix-section-title',{staticStyle:{"border-bottom":"0px !important"}},[_c('div',[_vm._v("\n                              "+_vm._s(_vm.$t(
                                  'impactGoal.impacts.defineImpacts.defineImpact.deleteImpactButton',
                                ))+"\n                            ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","small":"","color":"#DEEEF7"},on:{"click":function($event){deleteImpactDialog.value = false}}},[_c('v-icon',{staticClass:"font-weight-bold",attrs:{"small":"","color":"primary"}},[_vm._v("\n                                mdi-close\n                              ")])],1)],1),_vm._v(" "),_c('ix-section-text',[_vm._v("\n                            "+_vm._s(_vm.$t(
                                'impactGoal.impacts.defineImpacts.defineImpact.deleteImpactConfirmation',
                              ))+"\n                            "),_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('ix-button-action',{attrs:{"icon":"mdi-delete"},on:{"click":() => {
                                      _vm.deleteImpact({ impactId: impact.id })
                                      deleteImpactDialog.value = false
                                    }}},[_vm._v(_vm._s(_vm.$t(
                                      'impactGoal.impacts.defineImpacts.defineImpact.deleteImpactButton',
                                    )))])],1)],1)],1)],1)]}}],null,true)})],1)],1):_vm._e()],1)],1)}),0),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('ix-button-action',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"loading":_vm.loading,"disabled":!_vm.impactGoal.isEditable,"icon":"mdi-plus"}},'ix-button-action',attrs,false),on),[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'impactGoal.impacts.defineImpacts.defineImpact.addImpactButton',
                      ))+"\n                  ")])]}}],null,false,913784951)},[_vm._v(" "),_c('v-list',[_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.hasMitigationImpact},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g({},on),[_c('v-list-item',_vm._b({attrs:{"disabled":_vm.hasMitigationImpact},on:{"click":function($event){return _vm.handleImpactInput(
                              {
                                // Reduced CO2 emissions
                                impactGoalId: _vm.impactGoal.id,
                                conceptId: _vm.mitigationConceptId,
                                hasFrozenOutcome: true,
                                stakeholderCategory:
                                  _vm.StakeholderCategoryType.PeopleAndNaturalEnvironment,
                              },
                              {
                                geographies: [280],
                                irisCategories: [4],
                              },
                            )}}},'v-list-item',attrs,false),[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(
                              'impactGoal.impacts.defineImpacts.defineImpact.impactTypes[0]',
                            )))])],1)],1)]}}],null,false,3378653913)},[_vm._v("\n                    "+_vm._s(_vm.$t('duplicateWarning'))+"\n                  ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"left":"","disabled":!_vm.hasAdaptationImpact},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g({},on),[_c('v-list-item',_vm._b({attrs:{"disabled":_vm.hasAdaptationImpact},on:{"click":function($event){return _vm.handleImpactInput(
                              {
                                // climate change adaptation
                                impactGoalId: _vm.impactGoal.id,
                                conceptId: _vm.adapationConceptId,
                                hasFrozenOutcome: true,
                                stakeholderCategory:
                                  _vm.StakeholderCategoryType.People,
                              },
                              {
                                geographies: [280],
                              },
                            )}}},'v-list-item',attrs,false),[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(
                              'impactGoal.impacts.defineImpacts.defineImpact.impactTypes[1]',
                            )))])],1)],1)]}}],null,false,719839930)},[_vm._v("\n                    "+_vm._s(_vm.$t('duplicateWarning'))+"\n                  ")]),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.createImpact({
                        input: {
                          // custom impact
                          impactGoalId: _vm.impactGoal.id,
                        },
                      })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(
                        'impactGoal.impacts.defineImpacts.defineImpact.impactTypes[2]',
                      )))])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }