import { render, staticRenderFns } from "./ActionMenu.vue?vue&type=template&id=023d3669&scoped=true"
import script from "./ActionMenu.vue?vue&type=script&lang=ts"
export * from "./ActionMenu.vue?vue&type=script&lang=ts"
import style0 from "./ActionMenu.vue?vue&type=style&index=0&id=023d3669&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023d3669",
  null
  
)

/* custom blocks */
import block0 from "./ActionMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxButtonSecondary: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/Secondary.vue').default,ExportsEsgExport: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/exports/EsgExport.vue').default,EsgGroupView: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/GroupView.vue').default,EsgPerformanceComparisonToPortfolio: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PerformanceComparisonToPortfolio.vue').default,IxDialog: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/Dialog.vue').default,EsgPerformanceComparisonMostRecentToPrevious: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/esg/PerformanceComparisonMostRecentToPrevious.vue').default,IxDialogRequestNewEsgQuestionnaire: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/RequestNewEsgQuestionnaire.vue').default,IxDialogMakeEsgReportsEditable: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/dialog/MakeEsgReportsEditable.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VContainer,VDialog,VList,VListItem,VListItemTitle,VMenu})
